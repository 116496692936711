import { ProfileFormItem } from './components/ProfileFormItem';

export const ProfileFormInfo = (props) => {
  const { config } = props;

  return (
    <>
      {config.general.map((item) => (
        <ProfileFormItem
          orient="horizontal"
          errors={props.errors}
          edit
          {...item}
          key={item.name}
        />
      ))}
    </>
  );
};
