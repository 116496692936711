import { createSlice } from '@reduxjs/toolkit';

const navbarSlice = createSlice({
  name: 'navbar',
  initialState: {
    open: false,
    mobileOpen: false,
  },
  reducers: {
    navbarToggleMobile: (state, action) => {
      state.mobileOpen = !state.mobileOpen;
    },
    navbarCloseMobile: (state, action) => {
      state.mobileOpen = false;
    },
    navbarToggle: (state, action) => {
      state.open = !state.open;
    },
  },
});

export const { navbarToggle, navbarCloseMobile, navbarToggleMobile } = navbarSlice.actions;

export const selectFuseNavbar = ({ fuse }) => fuse.navbar;

export default navbarSlice.reducer;
