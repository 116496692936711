import Box from '@mui/material/Box';
import { useForm, FormProvider } from 'react-hook-form';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { useSearchParams } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';
import omitBy from 'lodash/omitBy';
import isNil from 'lodash/isNil';
import qs from 'query-string';
import Card from '@mui/material/Card';
import { IconButton, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/styles';
import TuneIcon from '@mui/icons-material/Tune';
import { socialTypesConfig } from '../../configs/social-types';
import { categoriesConfig } from '../../configs/categories-config';
import { ContentCategories } from '../../components/Content';
import { messages } from '../campaigns-feed/messages';
import CampaignsFeedSocials from '../campaigns-feed/CampaignsFeedSocials';
import {useTranslation} from "react-i18next";

export const Fields = {
  types: 'types',
  socialTypes: 'socialTypes',
  categories: 'categories' as const,
};

const config = {
  socialTypes: {
    label: messages.filters.socialTypes,
    name: Fields.socialTypes,
    items: socialTypesConfig.map((item) => ({
      label: item.title,
      value: item.key,
      color: item.color,
      icon: <item.Icon />,
    })),
  },
};

type FormData = {
  categories: number;
};

function CampaignsFeedListFilters() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const [searchParams, setSearchParams] = useSearchParams();
  const [openFilters, setOpenFilters] = useState(!matches);
  const { t } = useTranslation();

  const defaultValues = useMemo(() => {
    const query = qs.parse(searchParams.toString());

    if (query.socialTypes) {
      query.socialTypes = !Array.isArray(query.socialTypes)
        ? [query.socialTypes]
        : query.socialTypes;
    }

    if (query.categories && !Array.isArray(query.categories)) {
      query.categories = [Number(query.categories)];
    } else if (Array.isArray(query.categories)) {
      query.categories = query.categories.map(Number);
    }

    if (!query.categories) {
      query.categories = [];
    }

    return query;
  }, []);

  const methods = useForm<FormData>({
    mode: 'onChange',
    defaultValues,
  });

  const categories = methods.watch(Fields.categories);
  const socialTypes = methods.watch(Fields.socialTypes);

  const submit = (fd: any) => {
    const fdFiltered = omitBy(omitBy(fd, isNil), (val) => !val);
    const query = qs.stringify(fdFiltered);
    const params = new URLSearchParams(query);
    setSearchParams(params);
  };

  const { setValue, handleSubmit, watch } = methods;

  useEffect(() => {
    const subscription = watch(handleSubmit(submit));
    return () => subscription.unsubscribe();
  }, [handleSubmit, watch]);

  const handleChange = (name, id) => {
    const values = methods.getValues(name);
    const set = new Set(values);

    if (set.has(id)) {
      set.delete(id);
    } else {
      set.add(id);
    }
    setValue(name, Array.from(set));
  };

  const handleClickCategories = (id: string) => {
    handleChange(Fields.categories, id);
  };

  return (
    <FormProvider {...methods}>
      <Card
        className="min-w-[320px] lg:w-[320px] w-full lg:min-h-[300px] p-4 pr-6 h-full order-[0] lg:order-[2]"
        onSubmit={methods.handleSubmit(submit)}
        style={{ opacity: 0.5, pointerEvents: 'none' }}
      >
        <Typography
          variant="subtitle1"
          onClick={() => matches && setOpenFilters(!openFilters)}
          className="flex items-center font-black"
        >
          {t('campaign.filtersTitle', { defaultValue: 'Фільтри' })}
          {matches && (
            <IconButton className="ml-auto">
              <TuneIcon />
            </IconButton>
          )}
        </Typography>

        {openFilters && (
          <>
            <Box
              mt={1}
              mb={2}
            >
              <Divider className="mt-2 mb-3" />
            </Box>

            <div>
              <Typography
                className="mb-2"
                variant="subtitle2"
                fontWeight="bold"
              >
                {config.socialTypes.label}:
              </Typography>

              <CampaignsFeedSocials
                activeIds={socialTypes || []}
                onChange={(id) => handleChange(Fields.socialTypes, id)}
                onClick={handleClickCategories}
              />
            </div>

            <div>
              <Typography
                className="mb-2"
                variant="subtitle2"
                fontWeight="bold"
              >
                {messages.filters.categories}:
              </Typography>

              <ContentCategories
                categories={categoriesConfig.map((item) => ({
                  ...item,
                  title: item.label,
                  id: item.strapiId,
                }))}
                activeIds={categories}
                onClick={handleClickCategories}
              />
            </div>
          </>
        )}
      </Card>
    </FormProvider>
  );
}

export default CampaignsFeedListFilters;
