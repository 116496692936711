import { Bar } from 'react-chartjs-2';
import sortBy from 'lodash/sortBy';
import { CardChart } from '../../components/CardChart';
//
// ChartJS.register(
//   ArcElement,
//   Tooltip,
//   Legend,
//   CategoryScale,
//   LinearScale,
//   PointElement,
//   LineElement,
//   BarElement,
//   DoughnutController,
//   Colors,
//   // BarController
// );

// console.log(ChartJS.overrides.bar);
//
// ChartJS.overrides.bar.plugins = {
//   ...ChartJS.overrides.bar.plugins,
//   indexAxis: 'y',
//   legend: {
//     enabled: false,
//     display: false,
//   },
//   colors: {
//     enabled: true,
//   },
// };

// ChartJS.overrides.bar.plugins.colors = {
//   enabled: true,
//   // position: 'bottom',
// };

// const labels = demoAgeGender.data[0].total_value.breakdowns[0].results.map(
//   (item) => item.dimension_values
// );
// const values = demoAgeGender.data[0].total_value.breakdowns[0].results.map((item) => item.value);

export const CityDemographics = ({ data: _data }) => {
  if (!_data) {
    return null;
  }

  const values = _data.totalValue.breakdowns[0].results.map((item) => item.value);
  const sortedValues = sortBy(values, (val) => -val);
  const MAX_ITEMS_COUNT = 20;
  const minValue = sortedValues[MAX_ITEMS_COUNT - 1];

  const filteredValues = [];
  const labels = [];
  let other = 0;

  _data.totalValue.breakdowns[0].results.forEach((item) => {
    if (item.value >= minValue) {
      const city = item.dimensionValues[0].split(',')[0];
      labels.push(city);
      filteredValues.push(item.value);
    } else {
      other = item.value + other;
    }
  });

  filteredValues.push(other);
  labels.push('Інші');

  const data = {
    labels,
    type: 'bar',
    options: {
      responsive: true,
      plugins: {
        legend: {
          position: 'right',
        },
        colors: {
          enabled: true,
        },
      },

      tooltips: {
        enabled: true,
        mode: 'single',
        callbacks: {
          label(tooltipItems, data) {
            return `${tooltipItems.yLabel} %`;
          },
        },
      },
    },
    datasets: [
      {
        label: 'К-ть фоловерів',
        data: values,
        backgroundColor: [
          '#e60049',
          '#0bb4ff',
          '#50e991',
          '#e6d800',
          '#9b19f5',
          '#ffa300',
          '#dc0ab4',
          '#b3d4ff',
          '#00bfa0',
          '#ea5545',
          '#f46a9b',
          '#ef9b20',
          '#edbf33',
          '#ede15b',
          '#bdcf32',
          '#87bc45',
          '#27aeef',
          '#b33dc6',
          '#b30000',
          '#7c1158',
          '#4421af',
          '#1a53ff',
          '#0d88e6',
          '#00b7c7',
          '#5ad45a',
          '#8be04e',
          '#ebdc78',
        ],
      },
    ],
  };

  return (
    <CardChart title="Демографія фоловерів по містам за 90 днів:">
      <Bar
        datasetIdKey="id-city"
        style={{ maxHeight: 700 }}
        // height={600}
        data={data}
        options={{
          plugins: {
            legend: {
              enabled: false,
              display: false,
            },
          },
          // aspectRatio: 2,
          indexAxis: 'y',
          maintainAspectRatio: false,

          // bar: {
          //   datasets: {
          //     barThickness: 10,
          //   }
          // }
        }}
      />
    </CardChart>
  );
};
