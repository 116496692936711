import { Dialog, DialogContent, DialogProps } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { ReactNode } from 'react';
import { DialogClose } from '../DialogClose';

type DialogConfirmProps = Omit<DialogProps, 'maxWidth'> & {
  title: ReactNode;
  onClose: () => void;
  iconHide?: boolean;
  buttonText?: string;
  icon?: ReactNode;
  buttons?: ReactNode;
  maxWidth?: number | string;
  content?: ReactNode;
};
export const DialogConfirm = ({
  title,
  onClose,
  iconHide,
  buttonText = 'Close',
  icon,
  buttons,
  maxWidth,
  content,
  ...rest
}: DialogConfirmProps) => (
  <Dialog
    {...rest}
    onClose={onClose}
    PaperProps={{
      variant: 'elevation',
    }}
  >
    <Box maxWidth={maxWidth}>
      <div className="flex flex-shrink-0 items-center justify-between rounded-t-md border-b border-neutral-100 border-opacity-100 md:p-5 p-4 mr-6">
        <div className="text-xl font-black leading-normal text-neutral-800">{title || 'title'}</div>
      </div>

      <DialogClose onClose={onClose} />

      {!iconHide && (
        <DialogContent>
          <Box
            display="flex"
            justifyContent="center"
            mt={2}
          >
            {icon || (
              <CheckCircleOutlineIcon
                style={{ fontSize: 120 }}
                color="success"
              />
            )}
          </Box>
        </DialogContent>
      )}

      {content && (
        <div className="relative flex-auto md:p-5 p-4">
          <Typography
            whiteSpace="pre-wrap"
            variant="caption"
            className="text-[0.9rem] font-medium"
            dangerouslySetInnerHTML={{ __html: content }}
          />
        </div>
      )}

      <div className="flex flex-shrink-0 flex-wrap items-center justify-end rounded-b-md border-t border-neutral-100 border-opacity-100 md:p-5 p-4 dark:border-opacity-50">
        {buttons || (
          <Box minWidth={120}>
            <Button
              fullWidth
              type="button"
              variant="contained"
              color="success"
              onClick={onClose}
            >
              {buttonText}
            </Button>
          </Box>
        )}
      </div>
    </Box>
  </Dialog>
);
