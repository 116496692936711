import { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaTiktok } from 'react-icons/fa';
import { Button } from '../../../components/Button';
import { AppRoutes } from '../../../configs/app-routes';
import { messages } from '../../../messages';
import { TiktokService } from '../tiktok.service';

export const TiktokConnectButton = ({
  text: _text,
  edit,
  socialId,
  loading,
  remoteConnect,
}: {
  text?: ReactNode;
  edit?: boolean;
  loading?: boolean;
  socialId?: string;
  remoteConnect?: boolean;
}) => {
  const navigate = useNavigate();
  const onClick = () => {
    if (edit) {
      navigate(AppRoutes.profileSocialsViewById(socialId));
      return;
    }

    if (remoteConnect) {
      TiktokService.oauth();
    } else {
      navigate(AppRoutes.profileSocialsConnectTiktok());
    }
  };

  const text = _text || (edit ? messages.socials.update : messages.socials.connect);

  return (
    <>
      <Button
        startIcon={<FaTiktok className="w-[24px] h-[24px]" />}
        onClick={onClick}
        size="medium"
        fullWidth
        variant="contained"
        color="secondary"
        loading={loading}
      >
        {text}
      </Button>
    </>
  );
};
