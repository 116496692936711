import styled from '@emotion/styled';
import { Controller } from 'react-hook-form';
import { messages } from './messages';

const SwitchButton = styled.div`
  height: 52px;
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 4px;
  display: flex;
  margin-bottom: 20px;
`;

const SwitchButtonItem = styled('button')`
  width: 50%;
  background-color: ${({ active, theme }) =>
    active ? theme.palette.secondary.main : 'transparent'};
  color: ${({ active }) => (active ? 'white' : 'black')};
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
`;

export function SignUpSwitcher(props) {
  return (
    <Controller
      control={props.control}
      name="isBrand"
      render={({ field }) => (
        <SwitchButton>
          <SwitchButtonItem
            type="button"
            onClick={() => field.onChange(true)}
            active={field.value}
          >
            {messages.brand}
          </SwitchButtonItem>
          <SwitchButtonItem
            type="button"
            onClick={() => field.onChange(false)}
            active={!field.value}
          >
            {messages.influencer}
          </SwitchButtonItem>
        </SwitchButton>
      )}
    />
  );
}
