import { Alert } from '@mui/lab';
import Snackbar from '@mui/material/Snackbar';

const TOAST_DEFAULT_TIMEOUT = 5000;

const ToastField = ({ hide, variant, duration, ...props }) => {
  return (
    <Snackbar
      open
      autoHideDuration={TOAST_DEFAULT_TIMEOUT}
      onClose={props.disableOnClose ? undefined : hide}
      anchorOrigin={props.anchorOrigin}
      className="z-[10001]"
    >
      <Alert
        variant="filled"
        elevation={6}
        data-testid="toast-field"
        severity={variant}
        {...props}
        onClose={props.disableOnClose ? undefined : hide}
      >
        {props.text}
      </Alert>
    </Snackbar>
  );
};

export { ToastField };
