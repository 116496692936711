import _regions from './regions.json';

const regions = _regions.map((item) => JSON.parse(item));
export const regionsForList = regions.map((item) => ({
  ...item,
  label: item.region,
  value: item.level_1,
}));

export const getRegionNameByCode = (value) => {
  return regionsForList.find((item) => item.value === value);
};
