import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../utils/axiosBaseQuery';

export const campaignFeedApi = createApi({
  reducerPath: 'campaign-feed',
  tagTypes: ['campaign-feed'],
  baseQuery: axiosBaseQuery({ baseUrl: '/api/campaigns' }),
  endpoints: (builder) => ({
    get: builder.query({
      query: (query) => {
        return {
          url: `/`,
          params: query,
        };
      },
    }),
  }),
});

export const useCampaignFeedGetQuery = campaignFeedApi.endpoints.get.useQuery;
