export const profileConfigFields = {
  categories: 'categories',
  interests: 'interests',
  about: 'about',
  data: 'data',
  createdAt: 'createdAt',
  newPassword: 'newPassword',
  displayName: 'displayName',
  email: 'email',
  firstName: 'firstName',
  id: 'id',
  isFilledProfile: 'isFilledProfile',
  isShowInSearch: 'showInSearch',
  lastName: 'lastName',
  orders: 'orders',
  portfolio: 'portfolio',

  publishedAt: 'publishedAt',
  role: 'role',
  updatedAt: 'updatedAt',
  city: 'city',
  region: 'region',

  password: 'password',
  confirmPassword: 'confirmPassword',
  brandLink: 'brandLink',
  brandLinks: 'brandLinks',

  address: {
    region: 'address.region',
    city: 'address.city',
    location: 'address.location',
    index: 'address.index',
    newPost: 'address.newPost',
  },

  // to add:
  gender: 'gender',
  birthday: 'birthday',
  notShowBirthday: 'notShowBirthday',
  image: 'image',
  phone: 'phone',

  brand: {
    title: 'brand.title',
    image: 'brand.image',
  },

  brands: 'brands',
  hobbies: 'hobbies',
  influencers: 'influencers',
};
