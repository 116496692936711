import { styled } from '@mui/material/styles';
import { configuration } from '../../configs/configuration';

const Root = styled('div')(({ theme }) => ({
  '& > .logo-icon': {
    height: 50,
    transition: theme.transitions.create(['width', 'height'], {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.easeInOut,
    }),
  },
}));

function Logo() {
  return (
    <Root className="flex items-center">
      <img
        className="logo-icon"
        style={{ borderRadius: 8 }}
        src={configuration.global.logo}
        alt="logo"
      />
    </Root>
  );
}

export function LogoInverted() {
  return (
    <Root className="flex items-center">
      <img
        className="logo-icon"
        style={{ borderRadius: 8 }}
        src={configuration.global.logoInverted}
        alt="logo"
      />
    </Root>
  );
}

export default Logo;
