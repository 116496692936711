import { useEffect, useCallback, useMemo } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { Link } from 'react-router-dom';
import omitBy from 'lodash/omitBy';
import isNil from 'lodash/isNil';
import Checkbox from '@mui/material/Checkbox';
import { useTranslation } from 'react-i18next';
import { ProfileCard } from './Profile.styles';
import { ProfileFormInfo } from './ProfileFormInfo';
import { getProfileFormConfig } from './profileConfig';
import { ProfileContacts } from './components/ProfileContacts';
import { useProfile } from './useProfile';
import { ProfileInterest } from './ProfileInterest';
import { Button } from '../../components/Button';
import { ProfilePortfolio } from './components/ProfilePortfolio';
import { CanView } from '../../components/CanView';
import { Roles } from '../../configs/configuration';
import { AppRoutes } from '../../configs/app-routes';
import { useFormFocus } from '../../hooks/useFormFocus';
import { profileConfigFields } from './fields';
import { PanelHeader } from '../../components/PanelHeader/PanelHeader';
import { Panel } from '../../components/Panel';
import { CampaignFormItemContainer } from '../campaign-create/components/CampaignFormItem';
import { CampaignFormSubtitle } from '../campaign-create/CampaignStyles';
import { i18n } from '../../i18n';
import { ProfilePriceList } from './components/ProfilePriceList';

const getSchemaUser = () =>
  yup.object().shape({
    [profileConfigFields.firstName]: yup.string().required().max(120),
    [profileConfigFields.lastName]: yup.string().required().max(120),
    [profileConfigFields.gender]: yup.string().required(),
    // // [profileConfigFields.birthday]: yup.string().required(),
    [profileConfigFields.email]: yup.string().email().max(120),
    [profileConfigFields.about]: yup.string().required().max(500),
    [profileConfigFields.categories]: yup
      .array()
      .of(yup.string())
      .min(
        1,
        i18n.t('profile.categoriesRequired', { defaultValue: 'Виберіть хоча б одну категорію' })
      ),

    portfolio: yup.array().of(
      yup.object().shape({
        link: yup.string().min(15).url(),
        brand: yup.string().required(),
      })
    ),
  });

const getSchemaBrand = () =>
  yup.object().shape({
    [profileConfigFields.firstName]: yup.string().required().max(200),
    // [profileConfigFields.lastName]: yup.string().required(),
    // [profileConfigFields.gender]: yup.string().required(),
    // [profileConfigFields.birthday]: yup.string().required(),
    [profileConfigFields.email]: yup.string().email().required(),
    [profileConfigFields.about]: yup.string().required().max(1000),
    [profileConfigFields.brandLink]: yup
      .string()
      .url(i18n.t('form.linkValidation', { defaultValue: 'Введіть коректне посилання' })),
    [profileConfigFields.categories]: yup
      .array()
      .of(yup.string())
      .min(
        1,
        i18n.t('profile.categoriesRequired', { defaultValue: 'Виберіть хоча б одну категорію' })
      ),

    [profileConfigFields.brandLinks]: yup.array().of(
      yup.object().shape({
        value: yup.string().url(),
      })
    ),
  });

function Profile() {
  const { profile, change, changeState, isLoaded } = useProfile();
  const { t } = useTranslation();
  const config = useMemo(() => getProfileFormConfig(profile), [profile]);

  const schema = useMemo(
    () => (profile?.role === Roles.USER ? getSchemaUser() : getSchemaBrand()),
    []
  );

  const defaultValues = useMemo(() => {
    const filteredProfile = omitBy(profile, isNil);

    return {
      portfolio: [
        {
          brand: '',
          link: '',
        },
      ],
      prices: [
        {
          type: '',
          value: '',
          description: '',
        },
      ],
      notShowBirthday: true,
      ...filteredProfile,
      categories: profile?.categories?.map(({ category }) => {
        return category?.id;
      }),
      socials: undefined,
    };
  }, [config, profile]);

  console.log('defaultValues', defaultValues);

  const pending = changeState === 'pending';

  const changeProfile = useCallback(
    (object) => {
      const dto = { ...defaultValues, ...object };
      return change(dto);
    },
    [change, defaultValues]
  );

  const submit = (dto) => {
    changeProfile(dto);
  };

  const methods = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });

  useFormFocus(methods);

  useEffect(() => {
    methods.reset(defaultValues);
  }, [profile, isLoaded]);

  const showShowInSearchField = profile.isFilledProfile;

  if (!profile || !isLoaded) {
    return null;
  }

  return (
    <>
      <Panel className="flex flex-col flex-1 md:ltr:pr-32 md:rtl:pl-32">
        <PanelHeader
          title={t('profile.generalInfoTitle', { defaultValue: 'Основна інформація' })}
          subtitle={
            profile?.role === Roles.BRAND
              ? t('profile.generalInfoSubtitleBrand', {
                  defaultValue:
                    'Заповніть основну інформацію про себе: ваші дані, контакти та інтереси',
                })
              : t('profile.generalInfoSubtitle', {
                  defaultValue: 'Заповніть основну інформацію про Ваш бренд',
                })
          }
        />

        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(submit)}>
            <ProfileCard>
              {/* <ProfileAvatar /> */}

              <ProfileFormInfo
                errors={methods.formState.errors}
                onSubmit={changeProfile}
                config={config}
              />

              <CanView
                role={Roles.USER}
                condition={showShowInSearchField}
              >
                <CampaignFormItemContainer
                  className="flex-row items-center md:items-start"
                  orient="horizontal"
                >
                  <CampaignFormSubtitle htmlFor={profileConfigFields.isShowInSearch}>
                    {t('profile.isShowInSearch', {
                      defaultValue: 'Показувати мою анкету в пошуку:',
                    })}
                  </CampaignFormSubtitle>

                  <div style={{ marginTop: 4 }}>
                    <Controller
                      name={profileConfigFields.isShowInSearch}
                      render={({ field }) => (
                        <Checkbox
                          size="large"
                          checked={field.value || false}
                          {...field}
                        />
                      )}
                    />
                  </div>
                </CampaignFormItemContainer>
              </CanView>
            </ProfileCard>

            <div className="mb-8">{/* <Divider /> */}</div>

            <ProfileCard
              title={t('profile.contactsTitle', { defaultValue: 'Контактні дані' })}
              subtitle={t('profile.contactsSubtitle', {
                defaultValue: 'Надайте Вашу інформацію для подальшої роботи',
              })}
            >
              <ProfileContacts
                profile={profile}
                errors={methods.formState.errors}
                onSubmit={changeProfile}
                config={config}
              />
            </ProfileCard>

            <CanView role={Roles.USER}>
              <div className="mb-8">{/* <Divider /> */}</div>

              <ProfilePortfolio />

              <div className="mb-8" />

              <ProfilePriceList />
            </CanView>

            {profile?.role === Roles.BRAND ? (
              <div className="max-w-2xl">
                <ProfileInterest />
              </div>
            ) : (
              <div className="mt-12">
                <ProfileCard
                  title={t('profile.interestsTitle', { defaultValue: 'Інтереси' })}
                  subtitle={t('profile.interestsSubtitle', {
                    defaultValue:
                      'Заповніть цю інформацію, щоб ми могли запропонувати вам співпрацю, яка максимально відповідає вашим уподобанням',
                  })}
                >
                  <ProfileInterest />
                </ProfileCard>
              </div>
            )}

            <div className="flex flex-wrap mt-2">
              <Button
                disabled={pending}
                fullWidth
                type="submit"
                variant="contained"
                color="primary"
                size="medium"
                loading={pending}
                className="w-[180px] mr-3 mt-2"
              >
                {t('profile.save', { defaultValue: 'Зберегти' })}
              </Button>

              <CanView role={Roles.USER}>
                <Button
                  component={Link}
                  to={AppRoutes.profileSocials()}
                  fullWidth
                  variant="contained"
                  color="secondary"
                  size="medium"
                  className="w-[230px] mt-2 mr-3"
                >
                  {t('profile.goToSocials', { defaultValue: 'Перейти до моїх платформ' })}
                </Button>
              </CanView>

              <Button
                component={Link}
                to={AppRoutes.profileView(profile.uuid)}
                fullWidth
                type="button"
                variant="outlined"
                color="secondary"
                size="medium"
                className="w-[230px] mt-2"
              >
                {t('profile.seeProfile', { defaultValue: 'Переглянути профайл' })}
              </Button>
            </div>
          </form>
        </FormProvider>
      </Panel>
    </>
  );
}

export default Profile;
