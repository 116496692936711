import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import { ComponentType, ReactNode } from 'react';

type PanelSuccessProps = {
  title: ReactNode;
  content?: ReactNode;
  footerButton?: ReactNode;
  footer?: ReactNode;
  Icon?: ComponentType<any>;
};

export const PanelSuccess = ({
  title,
  content,
  footerButton,
  Icon = HourglassEmptyIcon,
  footer = null,
}: PanelSuccessProps) => {
  return (
    <Box my={4}>
      <Typography
        variant="h6"
        textAlign="center"
        color="text.primary"
        whiteSpace="pre-wrap"
      >
        {title}
      </Typography>

      <Box
        my={3}
        textAlign="center"
      >
        <Icon
          color="primary"
          sx={{ fontSize: 120 }}
        />
      </Box>

      {content && (
        <Typography
          display="block"
          variant="body2"
          textAlign="center"
          color="text.secondary"
          className="max-w-[480px] m-auto"
        >
          {content}
        </Typography>
      )}

      {footer}

      {footerButton && (
        <Box
          mt={2}
          maxWidth={240}
          mx="auto"
        >
          {footerButton}
        </Box>
      )}
    </Box>
  );
};
