import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../../utils/axiosBaseQuery';

export const notificationsPushApi = createApi({
  reducerPath: 'push-notifications',
  tagTypes: ['push-notifications'],
  baseQuery: axiosBaseQuery({ baseUrl: '/api/notifications/push' }),
  endpoints: (builder) => ({
    register: builder.mutation({
      query: (body: { userId: string; token: string }) => ({
        url: `/register`,
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const { useRegisterMutation } = notificationsPushApi;
