import { ReactNode } from 'react';
import { messages } from '../../../messages';
import { SocialEntity } from '../../../types';
import { ContentText } from '../../../components/Content';
import { Number } from '../../../components/Number/Number';
import { SocialConnectView } from '../../socials-connect/components/SocialsConnectView';
import { SocialsImages } from '../components/SocialsImages';

export type SocialTiktokConnectViewProps = {
  social: SocialEntity;
  beforeImagesBlock?: ReactNode;
};
export const SocialTiktokConnectView = ({ social }: SocialTiktokConnectViewProps) => {
  const { json } = social;

  return (
    <>
      <SocialConnectView
        social={social}
        isShowImage={false}
        isStyleGrid={false}
        content={
          <>
            {json?.likesCount && (
              <ContentText
                label={messages.socials.statistics.tiktok.likes}
                content={<Number amount={json?.likesCount} />}
              />
            )}

            {json?.videoCount && (
              <ContentText
                label={messages.socials.statistics.tiktok.media}
                content={<Number amount={json?.videoCount} />}
              />
            )}
          </>
        }
      />

      <div className="max-w-6xl">
        <SocialsImages
          beforeImagesBlock={
            <div className="max-w-[600px]">
              Прикріпіть скріни аналітики за тиждень
              <ul>
                <li>&#x2022; лайки/перегляди/коментарі;</li>
                <li>&#x2022; скрін ЦА (країни, стать, вік);</li>
              </ul>
            </div>
          }
          rowOrient
          social={social}
          name="images"
        />
      </div>
    </>
  );
};
