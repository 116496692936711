import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { DialogConfirm } from '../DialogConfirm';

export const DialogDefaultConfirmation = (props) => {
  const { t } = useTranslation();
  return (
    <DialogConfirm
      iconHide
      maxWidth={520}
      content={t('dialogConfirmation.content', { defaultValue: 'Цю дію не можна буде відмінити' })}
      buttons={
        <>
          <Box minWidth={120}>
            <Button
              fullWidth
              type="button"
              variant="contained"
              size='small'
              color="primary"
              onClick={props.onSubmit}
            >
              {t('dialogConfirmation.yes', { defaultValue: 'Так' })}
            </Button>
          </Box>
          <Box
            minWidth={120}
            ml={2}
          >
            <Button
              fullWidth
              type="button"
              size='small'
              variant="outlined"
              color="primary"
              onClick={props.onClose}
            >
              {t('dialogConfirmation.no', { defaultValue: 'Ні' })}
            </Button>
          </Box>
        </>
      }
      {...props}
    />
  );
};
