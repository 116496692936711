import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Divider } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { useTranslation } from 'react-i18next';
import { OrdersState } from '../../configs/configuration';
import { PanelSuccess } from '../../components/PanelSuccess';
import { AppRoutes } from '../../configs/app-routes';
import { Anchors } from '../../configs/anchors';
import { useScrollToLocation } from '../../hooks/useScrollToLocation';
import { useCampaignOrdersPublicationQuery } from '../orders/campaign-orders-api';
import { OrdersPublicationFinished } from './OrdersPublicationFinished';
import { OrdersPublicationOffer } from './OrdersPublicationOffer';
import { OrdersPublicationForm } from './OrdersPublicationForm';
import { CampaignEntity } from '../../types';

export const OrdersPublication = ({ campaign }: { campaign: CampaignEntity }) => {
  const { t } = useTranslation();
  const { data: publication = {}, refetch } = useCampaignOrdersPublicationQuery(campaign.id);

  useScrollToLocation();

  if (!publication) {
    return null;
  }

  const finished = publication.state === OrdersState.FINISHED;
  const showApplyMessage = publication.state === OrdersState.PENDING;
  const showOfferView = publication.state === OrdersState.OFFERED_TO_BLOGGER;
  const showPublishForm = !showApplyMessage && !finished && !showOfferView;
  const waitReview = publication.state === OrdersState.PUBLICATION_WAIT_REVIEW;

  if (showOfferView) {
    return (
      <OrdersPublicationOffer
        publication={publication}
        campaign={campaign}
      />
    );
  }

  return (
    <div className="flex-1 mt-6">
      <Divider />

      <div className="mt-6">
        <Typography
          id={Anchors.ORDERS}
          variant="h5"
          fontWeight="bold"
        >
          {t('orderPublication.title', { defaultValue: 'Публікація' })}
        </Typography>

        <div className="mt-6 mb-3">
          <Divider />
        </div>

        {waitReview && (
          <div className="text-lg mt-6 mb-3 flex align-items font-bold">
            <AccessTimeIcon
              color="primary"
              className="mr-2 -mt-1 h-8 w-8"
            />
            {t('orderPublication.waitForReview', { defaultValue: 'Очікуємо фідбек рекламодавця' })}
          </div>
        )}

        {finished && (
          <OrdersPublicationFinished
            order={publication}
            campaign={campaign}
            onSubmitFeedback={() => refetch()}
          />
        )}

        {showApplyMessage && (
          <PanelSuccess
            title={t('orderPublication.alreadyApplied', { defaultValue: 'Заявка успішно подана' })}
            content={t('orderPublication.alreadyAppliedWait', {
              defaultValue:
                'Будь ласка, зачекайте на відповідь від рекламодавця. Ви також можете ознайомитись з іншими рекламними пропозиціями.',
            })}
            footerButton={
              <Button
                component={Link}
                to={AppRoutes.campaigns()}
                variant="outlined"
                fullWidth
                color="primary"
              >
                {t('orderPublication.returnToCampaigns', { defaultValue: 'Усі кампанії' })}
              </Button>
            }
          />
        )}

        {showPublishForm && (
          <OrdersPublicationForm
            publication={publication}
            campaign={campaign}
          />
        )}
      </div>
    </div>
  );
};
