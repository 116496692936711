import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';
import * as dayjs from 'dayjs';
import clsx from 'clsx';
import { ReactNode } from 'react';
import {
  ContentCard,
  ContentCardBody,
  ContentCardImage,
  ContentCardLeft,
  ContentCardRight,
  ContentCategories,
  ContentField,
  ContentText,
} from '../Content';
import { AppRoutes } from '../../configs/app-routes';
import { CHIPS_BY_PAYMENT_TYPE, CHIPS_BY_SOCIAL } from '../Chips';
import { messages } from '../../configs/messages';
import { CampaignViewTitle } from '../../modules/campaign-view/CampaignViewTitle';
import { CampaignEntity } from '../../types';

const MAX_WIDTH = 180;

type CampaignCardProps = {
  campaign: CampaignEntity;
  badges?: any[] | ReactNode | null;
  content: ReactNode;
  right?: boolean;
  finished?: boolean;
  isLink?: boolean;
  showDefaultHelpText?: boolean;
};

export const CampaignCard = ({
  campaign,
  badges,
  content,
  right,
  finished,
  isLink = true,
  showDefaultHelpText,
}: CampaignCardProps) => {
  const navigate = useNavigate();
  const { name, description, createdAt, socialTypes, paymentType } = campaign;

  const socialType = socialTypes[0].socialType.title;
  const categories = campaign?.categories.map((item) => item.category);

  const SocialChip = CHIPS_BY_SOCIAL[socialType];
  const PaymentBadge = CHIPS_BY_PAYMENT_TYPE[paymentType];

  const createdAtFormatted = dayjs(createdAt).locale('uk').format('DD MMMM, HH:mm');

  const { image } = campaign;
  const layoutImage = image;

  const descriptionEllipsed =
    description.length < MAX_WIDTH ? description : `${description.substring(0, MAX_WIDTH)}...`;

  return (
    <ContentCard
      className={`transition ${isLink ? 'cursor-pointer' : ''} shadow-sm ${finished ? 'opacity-50' : ''}`}
      dataTestId="campaign-item"
      onClick={isLink ? () => navigate(AppRoutes.campaignView(campaign.id)) : undefined}
    >
      {layoutImage && (
        <ContentCardLeft
          className="w-full sm:w-auto sm:min-w-[200px] sm:max-w-[200px] sm:mr-4 mr-0 mb-4 sm:mb-0"
          position="relative"
          onClick={!isLink ? () => navigate(AppRoutes.campaignView(campaign.id)) : undefined}
        >
          <ContentCardImage
            fullHeight
            borderRadius={0}
            image={layoutImage}
            className="cursor-pointer"
          />
        </ContentCardLeft>
      )}

      <ContentCardBody flex={1}>
        {name && name !== '' && (
          <>
            <ContentField display="flex">
              <CampaignViewTitle
                showDefaultHelpText={showDefaultHelpText}
                campaign={campaign}
                to={AppRoutes.campaignView(campaign.id)}
              />

              {badges && (
                <Box
                  ml="auto"
                  pl={2}
                  display="flex"
                >
                  {badges}
                </Box>
              )}
            </ContentField>

            <Box
              mt={-1}
              mb={1}
              display="flex"
            >
              <Typography
                color="grey"
                className="text-xs"
              >
                {createdAtFormatted}
              </Typography>
            </Box>
          </>
        )}

        <ContentText
          content={<PaymentBadge size="small" />}
          label="Тип співпраці:"
          boldLabel
        />

        <ContentText
          content={<SocialChip size="small" />}
          label="Платформа:"
          boldLabel
        />

        {Boolean(campaign.campaignType) && (
          <ContentText
            content={messages.campaignTypes[campaign.campaignType]}
            label="Тип кампанії:"
            boldLabel
          />
        )}

        {description && description !== '' && (
          <Typography>
            <div className={clsx('w-full break-words text-14')}>{descriptionEllipsed}</div>
          </Typography>
        )}

        {content}

        {categories && <ContentCategories categories={categories} />}
      </ContentCardBody>

      {right && <ContentCardRight>{right}</ContentCardRight>}
    </ContentCard>
  );
};
