import './styles/app-base.css';
import './styles/app-components.css';
import './styles/app-utilities.css';
import { createRoot } from 'react-dom/client';
import App from './app/App';
import * as serviceWorker from './serviceWorker';
import './messages';

import { setLocale } from 'yup';
import * as uaLocale from './configs/yup-ua-locale';

const container = document.getElementById('root')!;
const root = createRoot(container);

setLocale(uaLocale);

root.render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
if (process.env.REACT_APP_ENABLED_PWA) {
  serviceWorker.register({});
} else {
  serviceWorker.unregister();
}
