import styled from '@emotion/styled';
import { CheckboxStyled, CheckboxStyledDiv } from '../profile/ProfileInfoCheckbox';
import { profileInterestConfig } from '../profile/profileConfig';

const Root = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const ProfileInterestViewCheckboxes = ({ categories }) => {
  return (
    <Root>
      {profileInterestConfig
        .filter(({ value }) => categories && categories.includes(Number(value)))
        .map((props) => (
          <CheckboxStyledDiv className="active">
            <CheckboxStyled
              style={props.style}
              size={props.size}
              key={props.label}
            >
              {props.Icon && <props.Icon color="icon" className="max-w-[56px] max-h-[40px] w-full text-[40px]" />}
              <span>{props.label}</span>
            </CheckboxStyled>
          </CheckboxStyledDiv>
        ))}
    </Root>
  );
};
