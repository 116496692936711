import { NotificationOrdersCreated } from './components/NotificationOrdersCreated';
import { NotificationOfferFromOwner } from './components/NotificationOfferFromOwner';
import { NotificationOrderAccepted } from './components/NotificationOrderAccepted';
import { NotificationOrderDeclined } from './components/NotificationOrderDeclined';
import { NotificationOrderPublished } from './components/NotificationOrderPublished';
import { NotificationOrderFinished } from './components/NotificationOrderFinished';
import { NotificationOrderStatisticAttached } from './components/NotificationOrderStatisticAttached';

export const NotificationsTypes = {
  ordersCreated: 'orders.created',
  offerFromOwner: 'offer_from_owner',
  ordersAccepted: 'orders.accepted',
  ordersDeclined: 'orders.declined',
  ordersPublished: 'orders.published',
  ordersFinished: 'orders.finished',
  ordersStatisticAttached: 'orders.statistic_attached',
};
export const notificationMapping = {
  [NotificationsTypes.ordersCreated]: NotificationOrdersCreated,
  [NotificationsTypes.offerFromOwner]: NotificationOfferFromOwner,
  [NotificationsTypes.ordersAccepted]: NotificationOrderAccepted,
  [NotificationsTypes.ordersDeclined]: NotificationOrderDeclined,
  [NotificationsTypes.ordersPublished]: NotificationOrderPublished,
  [NotificationsTypes.ordersFinished]: NotificationOrderFinished,
  [NotificationsTypes.ordersStatisticAttached]: NotificationOrderStatisticAttached,
};

export const showNotificationGuard = (notification) => {
  if (notification.type === 'offer_from_owner' && notification.payload.declined) {
    return false;
  }

  return true;
};
