import { Rating } from '@mui/lab';
import Typography from '@mui/material/Typography';
import { BoxProps } from '@mui/material';
import { Username } from '../Username';
import { ContentCardImage, ContentCardLeft } from '../Content';
import { UserEntity } from '../../types';

type UserPanelProps = BoxProps & {
  user: UserEntity;
  size?: string | number;
  showUsername?: boolean;
  hideRating?: boolean;
};

const getFeedbackMessage = (count: number) => {
  if (count === 1) {
    return 'відгук';
  }
  if (count === 2) {
    return 'відгука';
  }
  if (count === 3 || count === 4) {
    return 'відгуки';
  }

  return 'відгуків';
};

export const UserPanel = ({
  user,
  size,
  showUsername = true,
  hideRating,
  ...props
}: UserPanelProps) => {
  return (
    <ContentCardLeft
      {...props}
      className={`text-center ${
        size === 'small' ? 'md:w-[100px]' : 'md:w-[140px]'
      } w-full md:mb-0 mb-4 md:mr-6 mr-0 md:block flex ${props.className} `}
    >
      <div className="w-1/2 md:w-full">
        <ContentCardImage image={user?.image} />
      </div>

      <div className="mt-2 w-1/2 md:w-full justify-center items-center flex flex-col">
        {showUsername && <Username user={user} />}

        {!hideRating ? (
          <>
            <div className="mt-2">
              <Rating
                size={size}
                readOnly
                value={user?.rating || 0}
              />
            </div>

            <div>
              <Typography
                whiteSpace="nowrap"
                variant={size === 'small' ? 'caption' : 'body2'}
                color="grey"
              >
                {user.rating || '0.0'} ({user.feedbackCount || 0}{' '}
                {getFeedbackMessage(user.feedbackCount)})
              </Typography>
            </div>
          </>
        ) : null}
      </div>
    </ContentCardLeft>
  );
};
