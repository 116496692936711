import { combineReducers } from '@reduxjs/toolkit';
import fuse from './fuse';
import user from './userSlice';
import socialsSlice from '../modules/socials/store/socialsSlice';
import { campaignInfluencerApi } from '../modules/campaigns-influencer/campaign-influencer-api';
import { reportProblemApi } from '../modules/report-problem/report-problem-api';
import { smartSearchApi } from '../modules/smart-search/smart-search-api';
import { notificationsApi } from '../modules/notifications/notifications-api';
import { campaignsOffers } from '../modules/campaign-view/redux/campaign-offer-api';
import { campaignViewApi } from '../modules/campaign-view/redux/campaign-view-api';
import { socialsApi } from '../modules/socials/socials-api';
import { campaignOrdersApi } from '../modules/orders/campaign-orders-api';
import { campaignFeedApi } from '../modules/campaigns-feed/campaign-feed-api';
import { chatApi } from '../modules/chat/chat-api';
import { profileApi } from '../modules/profile/profile-api';
import notificationsReducer from '../modules/notifications/notifications.slice';
import { feedbacksApi } from '../modules/feedbacks/feedbacks-api';
import { forgotPasswordApi } from '../modules/forgot-password/forgot-password-api';
import { creditsApi } from '../modules/credits/credits-api';
import { usersApi } from '../modules/users/users-api';
import { balanceApi } from '../modules/balance/balance-api';
import { paymentsApi } from '../modules/balance/payments-api';
import { userFeedbackApi } from '../modules/user-feedback/user-feedback-api';
import { userQuizApi } from '../modules/user-quiz/user-quiz-api';
import { userPaymentsApi } from '../modules/payments/payments-api';
import { campaignApi } from '../modules/campaign-view/redux/campaign-api';
import { notificationsPushApi } from '../modules/common/push-notifications/push-notification-api';

const createReducer = (asyncReducers) => (state, action) => {
  const combinedReducer = combineReducers({
    fuse,
    user,
    [campaignApi.reducerPath]: campaignApi.reducer,
    feedbacks: feedbacksApi.reducer,
    [campaignInfluencerApi.reducerPath]: campaignInfluencerApi.reducer,
    [reportProblemApi.reducerPath]: reportProblemApi.reducer,
    [smartSearchApi.reducerPath]: smartSearchApi.reducer,
    [notificationsApi.reducerPath]: notificationsApi.reducer,
    notifications: notificationsReducer,
    [socialsApi.reducerPath]: socialsApi.reducer,
    [campaignsOffers.reducerPath]: campaignsOffers.reducer,
    [campaignViewApi.reducerPath]: campaignViewApi.reducer,
    [campaignOrdersApi.reducerPath]: campaignOrdersApi.reducer,
    [profileApi.reducerPath]: profileApi.reducer,
    [campaignFeedApi.reducerPath]: campaignFeedApi.reducer,
    chat: chatApi.reducer,
    users: usersApi.reducer,
    [userFeedbackApi.reducerPath]: userFeedbackApi.reducer,
    [userQuizApi.reducerPath]: userQuizApi.reducer,
    socials: socialsSlice,
    balance: balanceApi.reducer,
    [forgotPasswordApi.reducerPath]: forgotPasswordApi.reducer,
    [creditsApi.reducerPath]: creditsApi.reducer,
    [paymentsApi.reducerPath]: paymentsApi.reducer,
    [userPaymentsApi.reducerPath]: userPaymentsApi.reducer,
    [notificationsPushApi.reducerPath]: notificationsPushApi.reducer,
    ...asyncReducers,
  });

  /*
	Reset the redux store when user logged out
	 */
  if (action.type === 'user/userLoggedOut') {
    // state = undefined;
  }

  return combinedReducer(state, action);
};

export default createReducer;
