import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../utils/axiosBaseQuery';
import { UserEntity } from '../../types';

export const profileEntityFormat = (profile: UserEntity) => {
  const result = { ...profile };

  if (profile?.brand?.data === null) {
    delete result.brand;
  }

  return result;
};

export const profileApi = createApi({
  reducerPath: 'profile-api',
  tagTypes: ['profile-api'],
  baseQuery: axiosBaseQuery({ baseUrl: '/api' }),
  endpoints: (builder) => ({
    fetch: builder.query({
      query: (id) => {
        return {
          url: `/profile/${id}`,
        };
      },
      transformResponse: profileEntityFormat,
    }),

    fetchMe: builder.query<UserEntity, void>({
      query: () => {
        return {
          url: `/profile/me`,
        };
      },
      transformResponse: profileEntityFormat,
    }),

    patch: builder.mutation({
      query: (body) => {
        return {
          url: `/profile/me`,
          method: 'PATCH',
          body: {
            ...body,
            categories: body?.categories.map(Number),
          },
        };
      },
    }),

    fetchCampaignsByState: builder.query({
      query: ({ id, state }) => {
        return {
          url: `/campaigns/by-profile/${id}?state=${state}`,
        };
      },
    }),

    hideAuthShowFeedbackForm: builder.mutation({
      query: () => {
        return {
          method: 'POST',
          url: `/profile/hide-auto-show-feedback-form`,
        };
      },
    }),
  }),
});

export const useProfileGetMeQuery = profileApi.endpoints.fetchMe.useQuery;
export const useProfileGetQuery = profileApi.endpoints.fetch.useQuery;
export const useProfilePatchMutation = profileApi.endpoints.patch.useMutation;
export const useProfileHideAuthShowFeedbackFromMutation =
  profileApi.endpoints.hideAuthShowFeedbackForm.useMutation;
export const useProfileFetchCampaignsByStateQuery =
  profileApi.endpoints.fetchCampaignsByState.useQuery;
