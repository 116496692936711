import { orange } from '@mui/material/colors';
import { lighten, styled } from '@mui/material/styles';
import { Controller, useFormContext } from 'react-hook-form';
import Box from '@mui/material/Box';
import { HiUpload } from 'react-icons/hi';
import { useUpload } from '../../modules/common/useUpload';

const Root = styled('div')(({ theme }) => ({
  '& .productImageFeaturedStar': {
    position: 'absolute',
    top: 0,
    right: 0,
    color: orange[400],
    opacity: 0,
  },

  '& .productImageUpload': {
    transitionProperty: 'box-shadow',
    transitionDuration: theme.transitions.duration.short,
    transitionTimingFunction: theme.transitions.easing.easeInOut,
  },

  '& .productImageItem': {
    transitionProperty: 'box-shadow',
    transitionDuration: theme.transitions.duration.short,
    transitionTimingFunction: theme.transitions.easing.easeInOut,
    '&:hover': {
      '& .productImageFeaturedStar': {
        opacity: 0.8,
      },
    },
    '&.featured': {
      pointerEvents: 'none',
      boxShadow: theme.shadows[3],
      '& .productImageFeaturedStar': {
        opacity: 1,
      },
      '&:hover .productImageFeaturedStar': {
        opacity: 1,
      },
    },
  },
}));

function FileImage(props) {
  const methods = useFormContext();
  const { control, watch } = methods;
  const { upload } = useUpload();

  const image = watch(props.name);
  const showImage = !!image;

  return (
    <Root className={props.className}>
      <div className="flex justify-center sm:justify-start flex-wrap -mx-2">
        <Controller
          name={props.name}
          control={control}
          render={({ field: { onChange, value } }) => (
            <Box
              sx={{
                backgroundImage: showImage ? `url("${image}")` : '',
                backgroundSize: 'contain',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundColor: (theme) =>
                  theme.palette.mode === 'light'
                    ? lighten(theme.palette.background.default, 0.4)
                    : lighten(theme.palette.background.default, 0.02),
              }}
              component="label"
              htmlFor="button-file"
              className="productImageUpload flex items-center justify-center relative w-32 h-32 rounded-16 mx-3 mb-6 overflow-hidden cursor-pointer shadow hover:shadow-lg"
            >
              <input
                accept="image/*"
                className="hidden"
                id="button-file"
                type="file"
                onChange={async (e) => {
                  const result = await upload([e.target.files[0]]);
                  onChange(result[0].url);
                }}
              />
              {!showImage && (
                <HiUpload
                  size={32}
                  color="action"
                />
              )}
            </Box>
          )}
        />
      </div>
    </Root>
  );
}

export default FileImage;
