import { ReactNode } from 'react';

export type CardProps = {
  children: ReactNode;
  className?: string;
};
export const Card = ({ children, className = '' }: CardProps) => (
  <div className={`${className} bg-white border border-gray-200 rounded-lg shadow py-4 px-4`}>
    {children}
  </div>
);
