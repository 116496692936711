import * as React from 'react';
import {ReactNode, useEffect, useState} from 'react';
import { useDispatch } from 'react-redux';
import { logoutUser, setUser } from '../../store/userSlice';
import jwtService from './services/jwtService';
import JwtService from "./services/jwtService/jwtService";

const AuthContext = React.createContext<{
  isAuthenticated: boolean;
  setIsAuthenticated: (val: boolean) => void;
  waitAuthCheck: boolean
}>({
  isAuthenticated: false,
  waitAuthCheck: true,
  setIsAuthenticated: () => null,
});

function AuthProvider({ children }: { children: ReactNode }) {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [waitAuthCheck, setWaitAuthCheck] = useState<boolean>(true);

  useEffect(() => {
    JwtService.init();
    setIsAuthenticated(JwtService.isAuthenticated());
    setWaitAuthCheck(false);
  }, []);

  return waitAuthCheck ? (
    <div />
  ) : (
    <AuthContext.Provider value={{ isAuthenticated, setIsAuthenticated, waitAuthCheck }}>{children}</AuthContext.Provider>
  );
}

function useAuth() {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider');
  }
  return context;
}

export { AuthProvider, useAuth };
