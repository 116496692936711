// eslint-disable-next-line import/no-named-as-default
import themesConfig from '../../components/themes-config';


const thendHeroOverrides = {
  MuiCssBaseline: {
    '@global': {
      body: {
        backgroundColor: '#F2F5F9',
      },
      '*, *::before, *::after': {
        boxSizing: 'content-box',
      },
      'strong, b': {
        fontWeight: 500,
      },
    },
  },
  MuiDivider: {
    root: {
      backgroundColor: '#E3E6F2',
    },
  },
  MuiSelect: {
    outlined: {
      paddingTop: 10,
      paddingBottom: 10,
    },
  },
  MuiMenu: {
    list: {
      padding: '0.5rem',
    },
    paper: {
      boxShadow: '0 2px 64px 0 rgba(53,66,91,0.11)',
      borderRadius: 12,
    },
  },
  MuiMenuItem: {
    root: {
      paddingLeft: '1rem',
      borderRadius: '8px',
      marginBottom: '3px',
      paddingRight: '1rem',
    },
  },
  MuiTypography: {
    colorTextSecondary: {
      color: '#8E93A8',
    },
  },
  MuiAlert: {
    root: {
      borderRadius: 8,
    },
  },
  MuiAppBar: {
    root: {
      overflow: 'visible',
    },
    colorDefault: {
      backgroundColor: '#fff',
      zIndex: 900,
      boxShadow: '0 2px 64px 0 rgb(53 66 91 / 11%)',
    },
  },
  MuiSvgIcon: {
    root: {
      verticalAlign: 'middle',
    },
    colorAction: {
      color: '#2953C0',
      opacity: 0.5,
    },
    fontSizeSmall: {
      fontSize: 20,
    },
  },
  MuiLinearProgress: {
    root: {
      height: 8,
      // backgroundColor: $b.greys.color4,
      borderRadius: 20,
    },
    colorSecondary: {
      borderRadius: 20,
      // backgroundColor: $b.greys.color4
    },
    barColorSecondary: {
      borderRadius: 20,
      // backgroundColor: $b.primary.main
    },
  },
  MuiGrid: {
    root: {},
  },
  MuiPaper: {
    rounded: {
      borderRadius: 20,
    },
    elevation16: {
      boxShadow: '0 21px 64px -20px #d7e1ea',
      '@media print': {
        boxShadow: 'none',
      },
    },
  },
  MuiCard: {
    root: {
      width: '100%',
    },
  },
  MuiCardHeader: {
    root: {
      paddingLeft: '2rem',
      paddingTop: '1.6rem',
      paddingBottom: 0,
      marginBottom: '-1rem',
      position: 'relative',
      '@media (max-width:1299.95px)': {
        paddingLeft: '1rem',
        marginBottom: '0rem',
        paddingTop: '1rem',
      },
    },
    action: {
      position: 'absolute',
      right: '1rem',
      top: '1rem',
    },
  },
  MuiCardContent: {
    root: {
      padding: '2rem',
      '@media (max-width:1299.95px)': {
        padding: '1rem',
        marginTop: '0rem',
      },
      '&:last-child': {
        paddingBottom: null,
      },
    },
  },
  MuiListItem: {
    root: {},
    button: {
      '&:hover': {},
    },
  },
  MuiButton: {
    root: {
      borderRadius: 8,
    },
    label: {
      textTransform: 'none',
      letterSpacing: '0.3px ',
    },
    sizeSmall: {
      fontSize: 12,
      padding: '2px 8px',
    },
    sizeLarge: {
      fontSize: '1rem',
      lineHeight: '2rem',
    },
    contained: {
      borderRadius: 8,
      boxShadow: 'none',
      // backgroundColor: Object($m.a)($b.primary.main, .03),
      '&:hover': {
        boxShadow: '0 0px 0px 0px transparent',
        backgroundColor: '#E2E8F0',
      },
      '&:active': {
        boxShadow: '0 0px 0px 0px #FC3F38',
        // backgroundColor: $b.primary.main,
        color: '#fff',
      },
      '& .MuiButton-label': {
        display: 'flex',
        alignContent: 'center',
        alignItems: 'center',
      },
    },
    containedSizeSmall: {
      fontSize: '0.8125rem',
      borderRadius: 6,
    },
    containedPrimary: {
      color: '#fff',
      '&:visited': {
        color: '#fff',
      },
    },
    containedSecondary: {
      backgroundImage: 'linear-gradient(270deg, #FF7D41 0%, #FC3F38 100%)',
      boxShadow: '0 8px 32px -16px #FC3F38',
      color: '#fff',
      '&:hover': {
        boxShadow: '0 0px 0px 0px #FC3F38',
      },
      '& .MuiButton-label': {
        display: 'flex',
        alignContent: 'center',
        alignItems: 'center',
        color: '#fff',
      },
      '&:visited': {
        color: '#fff',
      },
    },
    outlinedPrimary: {
      border: '1px solid',
      borderRadius: 8,
      '&:hover': {
        border: '1px solid',
      },
    },
    outlined: {
      borderColor: '#E4E5ED',
      '&:hover': {
        background: 'transparent !important',
        // border: "1px solid ".concat($b.primary.main)
      },
    },
  },
  MuiAvatar: {
    colorDefault: {
      backgroundColor: '#F0F4F8',
      boxShadow: '0 0 1px rgba(53,66,91,0.1), 0 8px 22px -6px rgba(53,66,91,0.1)',
    },
    circle: {
      backgroundColor: '#F0F4F8',
      boxShadow: '0 0 1px rgba(53,66,91,0.1), 0 8px 22px -6px rgba(53,66,91,0.1)',
      // border: "3px solid ".concat($b.greys.color4)
    },
  },
  MuiButtonBase: {
    root: {},
  },
  MuiInputBase: {
    input: {
      padding: '6px 0 6px',
      '&::placeholder': {
        fontSize: 14,
        marginTop: 2,
      },
    },
  },
  MuiAutocomplete: {
    popperDisablePortal: {
      position: 'relative',
    },
  },
  MuiListSubheader: {
    root: {
      backgroundColor: '#FAFBFD',
      fontSize: 10,
      lineHeight: 16,
      fontWeight: 400,
      color: '#8E93A8',
    },
    sticky: {
      marginTop: '0.5rem',
      marginBottom: '0.5rem',
      lineHeight: '1rem',
    },
    gutters: {
      paddingLeft: 0,
    },
  },
  MuiFormControl: {
    marginDense: {
      marginTop: 0,
    },
  },
  MuiFormLabel: {
    root: {
      color: '#72809D',
    },
  },
  MuiFormHelperText: {
    contained: {
      marginLeft: 0,
    },
  },
  MuiInputLabel: {
    root: {
      fontWeight: 600,
    },
    // asterisk: {
    //   // color: $b.error.main,
    //   fontWeight: 500
    // },
    outlined: {
      marginBottom: '0.4rem',
      fontWeight: 500,
      position: 'relative',
      zIndex: 'inherit',
      '&$marginDense': {
        transform: 'none !important',
        fontSize: 14,
        marginBottom: 8,
        fontWeight: 400,
      },
    },
    filled: {
      marginBottom: 4,
      '&$marginDense': {
        transform: 'none !important',
        fontSize: 14,
        marginBottom: 8,
        fontWeight: 400,
      },
    },
    shrink: {
      transform: 'none ! important',
    },
    formControl: {
      position: 'relative',
      transform: 'none',
    },
  },
  MuiFilledInput: {
    root: {
      borderTopLeftRadius: 8,
      borderTopRightRadius: 8,
      borderRadius: 8,
      backgroundColor: '#F3F6FC',
      '&:hover': {
        backgroundColor: '#F3F6FC',
      },
      '&:focus': {
        backgroundColor: '#F3F6FC',
      },
      '&:active': {
        backgroundColor: '#F3F6FC',
      },
      '&$focused': {
        backgroundColor: '#F3F6FC',
        // // boxShadow: "0 0 0 1px ".concat(Object($m.a)($b.primary.main, .2))
      },
    },
    input: {
      paddingTop: 12,
      paddingBottom: 12,
    },
    inputMarginDense: {
      paddingTop: 12,
      paddingBottom: 12,
    },
  },
  MuiOutlinedInput: {
    notchedOutline: {
      '&:hover': {},
    },
    root: {
      backgroundColor: '#fff',
      borderRadius: 8,
      '& $notchedOutline': {
        borderColor: '#E4E5ED',
      },
      '&:hover $notchedOutline': {
        borderColor: '#2953C0',
      },
    },
    adornedEnd: {
      paddingRight: 4,
      '@media (max-width:1280.95px)': {
        paddingRight: 0,
      },
    },
  },
  MuiInputAdornment: {
    filled: {
      marginTop: '0 !important',
    },
  },
  MuiChip: {
    root: {
      // backgroundColor: $b.greys.color4,
      borderRadius: 8,
      height: 'auto',
    },
    outlined: {
      // borderColor: $b.greys.color7
    },
    sizeSmall: {
      fontWeight: 500,
      padding: '4px 8px',
      '& $labelSmall': {
        padding: 0,
        fontSize: 13,
        lineHeight: '16px',
      },
      '& $iconSmall': {
        marginRight: 4,
      },
    },
    label: {
      whiteSpace: 'normal',
      padding: 8,
    },
  },
  MuiPopover: {
    paper: {
      borderRadius: 8,
      boxShadow: '0 2px 64px 0 rgba(53,66,91,0.2)',
    },
  },
  PrivateNotchedOutline: {
    root: {
      borderRadius: 8,
      top: 0,
    },
    legend: {
      width: '0px !important',
      visibility: 'visible',
      backgroundColor: 'red',
      '& span': {
        display: 'none',
      },
    },
    legendLabelled: {
      visibility: 'visible',
      display: 'none',
    },
  },
  MuiTooltip: {
    tooltip: {
      // color: $b.white,
      backgroundColor: 'rgba(12,31,74,0.90)',
      fontSize: 11,
    },
    popper: {
      marginTop: -10,
    },
  },
  MuiToolbar: {
    regular: {
      minHeight: 72,
    },
  },
  MuiTabScrollButton: {
    root: {
      width: 20,
    },
  },
  MuiContainer: {
    root: {
      paddingLeft: 8,
      paddingRight: 8,
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      '@media (max-width:1560.95px)': {},
      '@media (max-width:1280.95px)': {
        paddingLeft: 16,
        paddingRight: 16,
      },
    },
  },
  MuiDialog: {
    root: {},
    paper: {
      marginLeft: 0,
      marginRight: 0,
      padding: '2rem 2rem',
    },
  },
  MuiSwitch: {
    root: {
      padding: 0,
      width: 38,
      height: 24,
    },
    track: {
      borderRadius: 50,
      opacity: 1,
      // backgroundColor: $b.greys.color3
    },
    thumb: {
      width: 18,
      height: 18,
      boxShadow: 'none',
    },
    colorPrimary: {
      '&.Mui-checked': {
        // color: $b.white,
        '& + .MuiSwitch-track': {
          opacity: 1,
        },
      },
    },
    switchBase: {
      padding: 3,
      '&.Mui-checked': {
        transform: 'translateX(14px)',
      },
    },
  },
};



const defaultTheme = {
  palette: {
    divider: '#e2e8f0',

    mode: 'light',
    text: {
      primary: 'rgb(17, 24, 39)',
      secondary: 'rgb(107, 114, 128)',
      disabled: 'rgb(149, 156, 169)',
    },
    common: {
      // black: 'rgb(17, 24, 39)',
      // white: 'rgb(255, 255, 255)',
    },
    primary: {
      light: '#100d73',
      main: '#252f3e',
      dark: '#0d121b',
      contrastDefaultColor: 'light',
    },
    secondary: {
      light: '#bdf2fa',
      main: '#22d3ee',
      dark: '#0cb7e2',
    },
    background: {
      paper: '#FFFFFF',
      default: '#0d121b',
    },
    error: {
      light: '#ffcdd2',
      main: '#f44336',
      dark: '#b71c1c',
    },
    warning: {
      50: '#FFF9EB',
      100: '#FFF3C1',
      200: '#FFECA1',
      300: '#FFDC48',
      400: '#F4C000',
      500: '#DEA500',
      600: '#D18E00',
      700: '#AB6800',
      800: '#8C5800',
      900: '#5A3600',
      contrastText: 'rgba(0, 0, 0, 0.87)',
      dark: '#AB6800',
      light: '#FFDC48',
      main: '#DEA500',
    },
  },
};

/**
 * SETTINGS DEFAULTS
 */
export const defaultSettings = {
  customScrollbars: true,
  direction: 'ltr',
};

/**
 * THEME DEFAULTS
 */
export const defaultThemeOptions = {
  typography: {
    // fontFamily: ['Inter var', 'Roboto', '"Helvetica"', 'Arial', 'sans-serif'].join(','),
    fontFamily: ['Open Sans', 'Roboto', '"Helvetica"', 'Arial', 'sans-serif'].join(','),
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
  },
  components: {
    MuiNativeSelect: {
      styleOverrides: {
        select: {
          lineHeight: '1.3',
        },
      },
    },
    MuiDateTimePicker: {
      defaultProps: {
        PopperProps: { className: 'z-9999' },
      },
    },
    MuiAppBar: {
      defaultProps: {
        enableColorOnDark: true,
      },
      styleOverrides: {
        root: {
          backgroundImage: 'none',
        },
      },
    },

    MuiIconButton: {
      styleOverrides: {
        root: {
          borderRadius: 2,
        },
      },
    },

    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },

    MuiPaperBase: {
      defaultProps: {
        borderRadius: 4,
      },
    },

    MuiCard: {
      styleOverrides: {
        root: {
          overflow: 'visible',
          borderWidth: 2,
          borderRadius: 8,
        },
      },
    },

    MuiButton: {
      defaultProps: {
        variant: 'text',
        color: 'inherit',
      },
      styleOverrides: {
        root: {
          textTransform: 'none',
          lineHeight: 1.3,
          // lineHeight: 1,
        },
        sizeMedium: {
          borderRadius: 8,
          // borderRadius: 20,
          // height: 40,
          minHeight: 48,
          // maxHeight: 40,
        },
        sizeSmall: {
          borderRadius: 8,
          paddingTop: 8,
          paddingBottom: 8,
          minHeight: 36,
          // borderRadius: '15px',
        },
        sizeLarge: {
          borderRadius: 8,
          minHeight: 56,
          // borderRadius: '28px',
        },
        contained: {
          boxShadow: 'none',
          '&:hover, &:focus': {
            boxShadow: 'none',
          },
        },
        outlined: {
          borderWidth: 2,
          borderRadius: 8,
          // borderColor: '#100d73',

          '&:hover': {
            borderWidth: 2,
          },
        },
      },
    },
    MuiButtonGroup: {
      defaultProps: {
        color: 'primary',
      },
      styleOverrides: {
        contained: {
          borderRadius: 18,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
    MuiDialog: {
      defaultProps: {
        // variant: 'outlined',
      },
      styleOverrides: {
        paper: {
          borderRadius: 4,
          margin: 16,
          minWidth: '320px',
        },
      },
    },
    MuiPaper: {
      defaultProps: {
        variant: 'outlined',
      },
      styleOverrides: {
        root: {
          backgroundImage: 'none',
        },
        rounded: {
          borderRadius: 8,
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          borderRadius: 8,
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        color: 'secondary',
        size: 'large'
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        asterisk: {
          color: 'red',
        },
      },
    },

    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginLeft: 0,
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        filledInfo: {
          background: themesConfig.default.palette.primary.main,
        },
      },
    },
    MuiInputLabel: {
      defaultProps: {
        color: 'secondary',
      },
    },
    MuiSelect: {
      defaultProps: {
        color: 'secondary',
      },
    },
    MuiOutlinedInput: {
      defaultProps: {
        color: 'secondary',
      },
      styleOverrides: {
        root: {
          borderRadius: 8,

          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: themesConfig.default.palette.secondary.main,
          },

          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            // borderWidth: 1
          },
        },

        notchedOutline: {
          borderWidth: 1,
          transition: '.15s',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          minHeight: 38,
          lineHeight: 1.35,
          fontSize: '0.85rem',
        },
      },
    },

    MuiFilledInput: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          '&:before, &:after': {
            display: 'none',
          },
        },
      },
    },
    MuiSlider: {
      defaultProps: {
        color: 'secondary',
      },
    },
    MuiCheckbox: {
      defaultProps: {
        color: 'secondary',
        borderWidth: 1,
      },
    },
    MuiRadio: {
      defaultProps: {
        color: 'secondary',
      },
    },

    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: '1px solid #e2e8f0',
        },
      },
    },

    MuiSwitch: {
      defaultProps: {
        color: 'secondary',
      },

      styleOverrides: {
        marginTop: 0,
      },
    },
    MuiTypography: {
      styleOverrides: {
        // root: {
        // fontSize: '0.85rem',
        // },
      },
      variants: [
        {
          props: { color: 'text.secondary' },
          style: {
            color: 'text.secondary',
          },
        },
      ],
    },

    MuiDivider: {
      styleOverrides: {
        root: {
          borderBottomWidth: 1,
        },
      },
    },

    MuiPagination: {},

    MuiPaginationItem: {
      styleOverrides: {
        root: {
          borderRadius: 4,
          height: 40,
          minWidth: 40,

          '&.Mui-selected': {
            borderColor: themesConfig.default.palette.primary.main,
            borderWidth: 2,
            backgroundColor: themesConfig.default.palette.primary.main,
            color: 'white',
            // borderColor: '#000',
          },
        },

        outlined: {
          borderWidth: 2,
          borderColor: themesConfig.default.palette.primary.main,
          fontWeight: 500,
        },

        selected: {
          borderColor: '#000',
        },
      },
    },

    MuiChip: {
      defaultProps: {
        color: 'secondary',
      },
      styleOverrides: {
        root: {
          borderRadius: 4,
          borderStyle: 'solid',
          padding: '8px 6px',
          fontSize: '.8rem',
          height: 'auto',
          borderWidth: 2,
        },

        contained: {
          border: `2px solid ${themesConfig.default.palette.primary.main}`,
          fontWeight: 600,
        },

        colorDefault: {
          backgroundColor: 'transparent',
          borderColor: defaultTheme.palette.divider,
          fontWeight: 600,
        },
      },
    },
  },
};
