import { createApi } from '@reduxjs/toolkit/query/react';
import { campaignCreateFormatData } from '../campaign-view/redux/campaign-api';
import { axiosBaseQueryWithMeta } from '../../utils/axios-base-query-with-meta';

export const usersApi = createApi({
  reducerPath: 'users',
  tagTypes: ['users'],
  baseQuery: axiosBaseQueryWithMeta({ baseUrl: '/api' }),
  endpoints: (builder) => ({
    fetch: builder.query({
      query: (query) => ({
        url: `/users/list`,
        params: query,
      }),
    }),

    proposeCampaigns: builder.query({
      query: () => ({
        url: `/campaigns/owner/available-to-propose`,
      }),
    }),

    createToBlogger: builder.mutation({
      query: (dto) => {
        return {
          url: `/orders/create-to-blogger`,
          body: campaignCreateFormatData(dto),
          method: 'POST',
        };
      },
    }),

    createToBloggerByCampaignId: builder.mutation({
      query: (dto) => {
        console.log('dto', dto);
        return {
          url: `/orders/propose-campaign-to-blogger/${dto.id}`,
          body: dto,
          method: 'POST',
        };
      },
    }),
  }),
});

export const useUsersFetchQuery = usersApi.endpoints.fetch.useQuery;
export const useProposeToCampaignsFetchQuery = usersApi.endpoints.proposeCampaigns.useQuery;

export const useUsersCreateOrderToBlogger = usersApi.endpoints.createToBlogger.useMutation;

export const useUsersCreateOrderToBloggerByCampaignId =
  usersApi.endpoints.createToBloggerByCampaignId.useMutation;
