import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

export const socialConnect = createAsyncThunk(
  'socials/connect',
  async (dto, { dispatch, getState, rejectWithValue }) => {
    const response = await axios.post(`/api/socials`, dto);
    try {
      const data = await response.data;
      return data;
    } catch (err) {
      console.log("ERR")
      return rejectWithValue(err);
    }
  }
);

export const socialInstagramConnect = createAsyncThunk(
  'socials/connect',
  async (dto, { dispatch, getState, rejectWithValue }) => {
    const response = await axios.post(`/api/socials/instagram/connect`, dto);
    try {
      const data = await response.data;
      return data;
    } catch (err) {
      console.log("ERR", err)
      return rejectWithValue(err);
    }
  }
);

export const socialDisconnect = createAsyncThunk(
  'socials/disconnect',
  async (id, { dispatch, getState }) => {
    const response = await axios.post(`/api/socials/${id}/disconnect`);
    const data = await response.data;
    return data;
  }
);

const socialsSlice = createSlice({
  name: 'socials',
  initialState: {
    data: undefined,
    state: 'idle',
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(socialConnect.fulfilled, (state, action) => ({
      ...state,
      data: action.payload,
    }));
  },
});

export default socialsSlice.reducer;
