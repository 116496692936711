import { CampaignState } from '../../types';

export const messages = {
  rate: 'Оцінка:',

  socials: {
    title: 'Соціальні мережі:',
  },

  showStatistic: 'Переглянути статистику',
  hideStatistic: 'Приховати статистику',

  details: {
    portfolio: 'Приклади колаборацій:',
    categories: 'Категорії:',
    influencers: 'Улюблені інфлюенсери:',
    brands: 'Улюблені бренди:',
    hobbies: 'Хоббі:',
    title: 'Біо:',
  },

  feedbacks: {
    notFound: 'На даний момент відгуки відсутні',
  },

  campaigns: {
    [CampaignState.PENDING]: 'Діючі кампанії',
    [CampaignState.FINISHED]: 'Завершені брендом кампанії',
  },
};
