import EditorJS from '@editorjs/editorjs';
import { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { mainTheme } from '../theme';

console.log(mainTheme);

const LiveEditorStyled = styled.div`
  & > div {
    font-family: Open Sans, Roboto, 'Helvetica', Arial, sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.4375em;
    color: rgb(17, 24, 39);
    box-sizing: border-box;
    position: relative;
    width: 100%;
    min-height: 150px;
    padding: 8.5px 14px;
    border-radius: 2px;
    border-color: rgba(0, 0, 0, 0.23);
    border-width: 1px;
    transition: 0.15s;
    border-style: solid;
    transition-duration: 0.15s;

    &:hover {
      border-color: ${mainTheme.palette.secondary.main};
    }
    &.focused {
      border-width: 2px;
      border-color: ${mainTheme.palette.secondary.main};
    }
  }
`;

export const LiveEditor = () => {
  const [focused, setFocused] = useState(false);

  useEffect(() => {
    const editor = new EditorJS({
      /**
       * Id of Element that should contain the Editor
       */
      holder: 'editorjs',
      onChange(api, event) {
        console.log('api', api, event);
      },
      minHeight: 40,
    });

    document.getElementById('editorjs').addEventListener('focusin', () => setFocused(true));
    document.getElementById('editorjs').addEventListener('focusout', () => setFocused(false));
  }, []);

  return (
    <LiveEditorStyled style={{ width: '100%', minHeight: 150 }}>
      <div
        id="editorjs"
        className={`w-full ${focused ? 'focused' : ''}`}
      />
    </LiveEditorStyled>
  );
};
