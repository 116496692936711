import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../utils/axiosBaseQuery';

export const campaignInfluencerApi = createApi({
  reducerPath: 'influencer-orders',
  tagTypes: ['influencer-orders'],
  baseQuery: axiosBaseQuery({ baseUrl: '/api/orders' }),
  endpoints: (builder) => ({
    get: builder.query({
      query: (query) => ({
        url: '/',
        params: query,
      }),
    }),
  }),
});

// export const useFetchInfluencerCampaigns = influencer-ordersApi.useGetQuery;
export const useGetQuery = campaignInfluencerApi.endpoints.get.useQuery;
// export const useFetchInfluencerCampaigns = influencer-ordersApi.endpoints.getInfluencerCampaigns.;
// influencer-ordersApi.endpoints.getInfluencerCampaigns.useQuery;
// export const { u } = influencer-ordersApi
