import Provider from 'react-redux/es/components/Provider';
import { StyledEngineProvider } from '@mui/material/styles';
import store from '../store';

const withAppProviders = (Component) => (props) => {
  const WrapperComponent = () => (
    <Provider store={store}>
      <StyledEngineProvider injectFirst>
        <Component {...props} />
      </StyledEngineProvider>
    </Provider>
  );

  return WrapperComponent;
};

export default withAppProviders;
