export const problems = [
  'Обрати',
  'Проблема з оплатою',
  'Проблема з брендом',
  'Проблема з блогером',
  'Проблема з кампанією',
  'Проблема з платформою',
  'Інше',
].map((label, index) => ({
  label,
  value: index === 0 ? 0 : label,
}));
