import { ThemeProvider } from '@mui/material/styles';
import { memo } from 'react';
import NavbarStyle1 from './NavbarStyle1';
import themesConfig from '../themes-config';
import { generateMuiTheme } from '../theme';

const navbarTheme = generateMuiTheme(themesConfig.defaultDark);

function NavbarWrapperLayout1() {
  return (
    <ThemeProvider theme={navbarTheme}>
      <NavbarStyle1 />
    </ThemeProvider>
  );
}

export default memo(NavbarWrapperLayout1);
