import SignUpPage from './SignUpPage';
import authRoles from '../auth/authRoles';
import {AppRoutes} from "../../configs/app-routes";
import {SignUpBloggerPage} from "./SignUpBlogger";

const SignUpConfig = {
  settings: {
    layout: {
      config: {
        navbar: {
          display: false,
        },
        toolbar: {
          display: false,
        },
        footer: {
          display: false,
        },
        leftSidePanel: {
          display: false,
        },
        rightSidePanel: {
          display: false,
        },
      },
    },
  },
  auth: authRoles.onlyGuest,
  routes: [
    {
      path: AppRoutes.signUp(),
      element: <SignUpPage />,
    },
  ],
};

export default SignUpConfig;
