import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { messages } from './messages';
import { ContentCategories } from '../../components/Content';
import { useProfileGetQuery } from '../profile/profile-api';

const buildCategories = (arr) =>
  arr.map((title) => ({
    id: title,
    title,
  }));
export const ProfileViewInterests = () => {
  const { id } = useParams();
  const { data: profile } = useProfileGetQuery(id);
  const { brands, influencers, hobbies } = profile;

  const brandsFormatted = useMemo(() => buildCategories(brands || []), [brands]);
  const influencersFormatted = useMemo(() => buildCategories(influencers || []), [influencers]);
  const hobbiesFormatted = useMemo(() => buildCategories(hobbies || []), [hobbies]);

  return (
    <>
      <Box
        display="flex"
        flexWrap="wrap"
      >
        {Boolean(brandsFormatted.length) && (
          <Box className="w-full ">
            <Typography
              className="mt-2"
              variant="subtitle2"
              fontWeight="bold"
            >
              {messages.details.brands}
            </Typography>

            <Box
              maxWidth={700}
              className="-mt-2"
            >
              <ContentCategories categories={brandsFormatted} />
            </Box>
          </Box>
        )}

        {Boolean(influencersFormatted.length) && (
          <Box className="w-full ">
            <Typography
              className="mt-2"
              variant="subtitle2"
              fontWeight="bold"
            >
              {messages.details.influencers}
            </Typography>

            <Box
              maxWidth={700}
              className="-mt-2"
            >
              <ContentCategories categories={influencersFormatted} />
            </Box>
          </Box>
        )}

        {Boolean(hobbiesFormatted.length) && (
          <Box className="w-full ">
            <Typography
              className="mt-2"
              variant="subtitle2"
              fontWeight="bold"
            >
              {messages.details.hobbies}
            </Typography>

            <Box
              maxWidth={700}
              className="-mt-2"
            >
              <ContentCategories categories={hobbiesFormatted} />
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
};
