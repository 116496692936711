import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import JwtService from '../auth/services/jwtService';
import { configuration } from '../../configs/configuration';
import { AppRoutes } from '../../configs/app-routes';
import { useAuth } from '../auth/AuthContext';

function SignOutPage() {
  const { setIsAuthenticated } = useAuth();
  const { t } = useTranslation();

  useEffect(() => {
    setTimeout(() => {
      JwtService.logout();
      setIsAuthenticated(false);
      setTimeout(() => {
        window.location.href = configuration.basename + AppRoutes.signIn();
      }, 200);
    }, 1000);
  }, []);

  return (
    <div className="flex flex-col flex-auto items-center justify-center min-w-0 p-12">
      <Paper className="w-full sm:w-auto py-7 px-4 sm:p-12 rounded-lg">
        <div className="w-full max-w-320 sm:w-320 mx-auto sm:mx-0">
          <img
            className="w-12 mx-auto rounded-lg"
            src={configuration.global.logo}
            alt="logo"
          />

          <Typography className="mt-6 text-xl font-extrabold tracking-tight leading-tight text-center">
            {t('signOut.title', { defaultValue: 'Ви вийшли з облікового запису' })}
          </Typography>
        </div>
      </Paper>
    </div>
  );
}

export default SignOutPage;
