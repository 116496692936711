import { useCallback } from 'react';
import axios from 'axios';
import { configuration } from '../../configs/configuration';

const imageMapIds = {};

export const useUpload = () => {
  const upload = useCallback(async (files) => {
    const promises = [];

    files.forEach((file, index) => {
      const fd = new FormData();
      fd.append(`files`, file);
      promises.push(
        axios.post('/upload', fd, { headers: { 'Content-type': 'Multipart/form-data' } })
      );
    });

    const res = await Promise.all(promises);
    const urls = res.map(({ data: file }) => {
      imageMapIds[configuration.s3.bucket + file.filenameDisk] = file.id;

      return {
        id: file.id,
        url: configuration.s3.bucket + file.filenameDisk,
      };
    });

    return urls;
  }, []);

  const remove = useCallback(
    async (url) => {
      const id = imageMapIds[url];

      if (id) {
        return axios.delete(`/upload/remove/${id}`);
      }
    },
    [imageMapIds]
  );

  return {
    upload,
    remove,
  };
};
