import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { socialConnect, socialInstagramConnect } from './store/socialsSlice';
import { useToasts } from '../../components/Toasts';
import { useProfile } from '../profile/useProfile';
import { messages } from './messages';
import { useSocialDisconnectMutation, useSocialUpdateMutation } from './socials-api';
import { SocialTypes } from '../../configs/social-types';
import { referral } from '../../utils/referral';

export const useSocials = () => {
  const { error, success } = useToasts();
  const { load: loadProfile } = useProfile();
  const dispatch = useDispatch();
  const [updateMutation] = useSocialUpdateMutation();
  const [disconnectMutation] = useSocialDisconnectMutation();

  const handleError = (err) => {
    error(err.message);
  };

  const trigger = (type) => {
    window.gtag('event', 'connect_social', { type });
    referral.emit('connect_social');
  };

  const connect = useCallback(
    (dto) => {
      trigger(dto.socialType);
      return dispatch(socialConnect(dto))
        .unwrap()
        .then((res) => {
          referral.emit('connect_social_finished');
          success(messages.toastPlatformAdded);
          loadProfile();
          return res;
        })
        .catch(handleError);
    },
    [dispatch, error, loadProfile]
  );

  const connectInstagram = useCallback(
    (dto) => {
      trigger(SocialTypes.INSTA);

      return dispatch(socialInstagramConnect(dto))
        .unwrap()
        .then((res) => {
          success(messages.toastPlatformAdded);
          loadProfile();
          referral.emit('connect_social_finished');

          return res;
        });
    },
    [dispatch, error, loadProfile]
  );

  const disconnect = useCallback(
    (id) => {
      return disconnectMutation(id)
        .unwrap()
        .then(() => {
          success(messages.toastPlatformDisabled);

          referral.emit('disconnect_social');
          loadProfile();
        })
        .catch(handleError);
    },
    [dispatch, error, loadProfile]
  );

  const update = useCallback(
    ({ id, dto }) => {
      return updateMutation({ id, dto })
        .unwrap()
        .then(() => {
          success(messages.toastPlatformUpdated);
          loadProfile();
        })
        .catch(handleError);
    },
    [error, loadProfile, updateMutation]
  );

  return {
    connect,
    connectInstagram,
    disconnect,
    update,
    loadProfile,
  };
};
