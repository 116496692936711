import { UIMatch, useMatches } from 'react-router-dom';
import { ReactNode } from 'react';
import { Meta } from '../../modules/meta/Meta';

export type RouteHandle = {
  crumb?: () => ReactNode;
  title: () => string;
};

export const RoutesTitleHandler = () => {
  const matches = useMatches() as UIMatch<unknown, RouteHandle>[];

  const crumbLinks = matches.filter((match) => Boolean(match.handle?.title));

  if (!crumbLinks.length) {
    return null;
  }

  const route = crumbLinks[crumbLinks.length - 1];

  if (!route) {
    return null;
  }

  return <Meta title={route.handle.title()} />;
};
