import { ReactNode, useState } from 'react';
import { messages } from '../messages';
import { SocialEntity } from '../../../types';
import { SocialConnectView } from '../../socials-connect/components/SocialsConnectView';
import { SocialsImages } from '../components/SocialsImages';
import { Button } from '../../../components/Button';
import { ProfileViewCharts } from '../../profile-view/ProfileViewCharts';
import { ContentText } from '../../../components/Content';
import { Number } from '../../../components/Number/Number';

export type SocialInstagramConnectViewProps = {
  social: SocialEntity;
  beforeImagesBlock?: ReactNode;
};
export const SocialInstagramConnectView = ({
  social: data,
  beforeImagesBlock,
}: SocialInstagramConnectViewProps) => {
  const [showStatistic, setShowStatistic] = useState(false);
  const json = data?.json;

  const generalStat = json && json[0];

  if (!generalStat) {
    return null;
  }

  return (
    <>
      <SocialConnectView
        social={data}
        isShowImage={false}
        isStyleGrid={false}
        content={
          <>
            <ContentText
              label={messages.mediaCount}
              content={<Number amount={generalStat?.mediaCount} />}
            />
          </>
        }
      />

      <div className="max-w-6xl mt-3">
        <SocialsImages
          beforeImagesBlock={beforeImagesBlock}
          rowOrient
          social={data}
          name="images"
        />
      </div>

      {json && (
        <>
          <Button
            className="mt-4"
            variant="contained"
            color="primary"
            size="medium"
            onClick={() => setShowStatistic(!showStatistic)}
          >
            {!showStatistic ? messages.showStatistic : messages.hideStatistic}
          </Button>

          {showStatistic && <ProfileViewCharts data={json} />}
        </>
      )}
    </>
  );
};
