import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../utils/axiosBaseQuery';
import type { UserQuizDto } from './UserQuiz';

export const userQuizApi = createApi({
  reducerPath: 'user-quiz',
  baseQuery: axiosBaseQuery({ baseUrl: '/api/customer-feedbacks' }),
  endpoints: (builder) => ({
    create: builder.mutation({
      query: (fd: UserQuizDto) => {
        return {
          url: ``,
          method: 'POST',
          body: fd,
        };
      },
    }),
  }),
});

export const useCreateMutation = userQuizApi.endpoints.create.useMutation;
