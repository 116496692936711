import { lighten, styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import clsx from 'clsx';
import { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import Paper from '@mui/material/Paper';
import { HiOutlinePaperAirplane } from 'react-icons/hi';
import { useParams } from 'react-router-dom';
import { TextField } from '@mui/material';
import Divider from '@mui/material/Divider';
import relativeTime from 'dayjs/plugin/relativeTime';
import dayjs from 'dayjs';
import { useInterval } from 'react-use';
import { FaRegSadTear } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { useUser } from '../common/useUser';
import {
  useChatMessagesQuery,
  useChatReadMutation,
  useChatSendMutation,
  useContactsUnreadQuery,
} from './chat-api';
import { useCampaignOrdersFetchOneQuery } from '../orders/campaign-orders-api';
import { OrdersState, Roles } from '../../configs/configuration';
import { Meta } from '../meta/Meta';
import { TITLES } from '../../configs/titles';
import { HeaderBack } from '../../components/HeaderBack';
import { AppRoutes } from '../../configs/app-routes';
import ChatAvatar from './ChatAvatar';
import { Username } from '../../components/Username';
import { CampaignViewTitle } from '../campaign-view/CampaignViewTitle';
import { chatFormat } from './chat-format';
import { NotFoundPanel } from '../../components/NotFoundPanel';
import { CHAT_TITLE_MAP } from './constants';
import { Panel } from '../../components/Panel';

require('dayjs/locale/uk');

dayjs.extend(relativeTime);

const StyledMessageRow = styled('div')(({ theme }) => ({
  '&.contact': {
    '& .bubble': {
      backgroundColor: theme.palette.secondary.light,
      color: theme.palette.secondary.contrastText,
      borderTopLeftRadius: 5,
      borderBottomLeftRadius: 5,
      borderTopRightRadius: 20,
      borderBottomRightRadius: 20,
      '& .time': {
        marginLeft: 12,
      },
    },
    '&.first-of-group': {
      '& .bubble': {
        borderTopLeftRadius: 20,
      },
    },
    '&.last-of-group': {
      '& .bubble': {
        borderBottomLeftRadius: 20,
      },
    },
  },
  '&.me': {
    paddingLeft: 40,

    '& .bubble': {
      marginLeft: 'auto',
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.contrastText,
      borderTopLeftRadius: 20,
      borderBottomLeftRadius: 20,
      borderTopRightRadius: 5,
      borderBottomRightRadius: 5,
      '& .time': {
        justifyContent: 'flex-end',
        right: 0,
        marginRight: 12,
      },
    },
    '&.first-of-group': {
      '& .bubble': {
        borderTopRightRadius: 20,
      },
    },

    '&.last-of-group': {
      '& .bubble': {
        borderBottomRightRadius: 20,
      },
    },
  },
  '&.contact + .me, &.me + .contact': {
    paddingTop: 20,
    marginTop: 20,
  },
  '&.first-of-group': {
    '& .bubble': {
      borderTopLeftRadius: 20,
      paddingTop: 13,
    },
  },
  '&.last-of-group': {
    '& .bubble': {
      borderBottomLeftRadius: 20,
      paddingBottom: 13,
      '& .time': {
        display: 'flex',
      },
    },
  },
}));

export const CHAT_REFETCH_INTERVAL = 20000;

function Chat() {
  const { user } = useUser();
  const { t } = useTranslation();
  const routeParams = useParams();
  const orderId = routeParams.id;
  const { data: chat, refetch } = useChatMessagesQuery({ id: orderId });
  const chatRef = useRef(null);
  const [message, setMessage] = useState('');
  const [send] = useChatSendMutation();
  const { data: order } = useCampaignOrdersFetchOneQuery(orderId);
  const [read] = useChatReadMutation();
  const { data: { data: unreadMessages } = {} } = useContactsUnreadQuery();
  const campaignHelpText = CHAT_TITLE_MAP[order?.state];

  useEffect(() => {
    if (order?.id && unreadMessages && unreadMessages.includes(order.id)) {
      read(order?.id);
    }
  }, [read, order, unreadMessages]);

  useInterval(() => {
    refetch();
  }, CHAT_REFETCH_INTERVAL);

  useLayoutEffect(() => {
    if (chat) {
      setTimeout(() => {
        if (!chatRef.current) {
          return;
        }

        chatRef.current.scrollTop = 99999;
      }, 120);
    }
  }, [chat, chatRef.current]);

  const isFirstMessageOfGroup = (item, i) =>
    i === 0 || (chat[i - 1] && chat[i - 1].contactId !== item.contactId);

  const isLastMessageOfGroup = (item, i) =>
    i === chat.length - 1 || (chat[i + 1] && chat[i + 1].contactId !== item.contactId);

  const onInputChange = (ev) => setMessage(ev.target.value);

  const handleMessageSubmit = (ev) => {
    const userTo = user?.role === Roles.BRAND ? order?.blogger?.id : order?.user?.id;

    if (ev) {
      ev.preventDefault();
    }
    if (message === '') {
      return;
    }

    send({
      message,
      orderId,
      userTo,
    }).then(() => {
      setMessage('');
    });
  };

  const formattedMessages = useMemo(() => {
    return chat?.map((item) => ({
      ...item,
      message: chatFormat(item.message),
    }));
  }, [chat]);

  if (!user || !chat) {
    return null;
  }

  const disabled = order?.state === OrdersState.FINISHED || order?.state === OrdersState.DECLINED;
  const orderUserToShow = user.role === Roles.USER ? order?.user : order?.blogger;

  return (
    <div className="flex-1 relative min-h-[500px] h-full m-0 p-0">
      <Meta title={TITLES.chats()} />

      <HeaderBack
        message={t('chat.backMessage', { defaultValue: 'Усі повідомлення' })}
        to={AppRoutes.chats()}
      />

      <Panel isNoPadding>
        {order && (
          <div className="px-4 py-4">
            <CampaignViewTitle
              campaign={order?.campaign}
              to={AppRoutes.campaignView(order?.campaign.id)}
              helpText={campaignHelpText}
              showDefaultHelpText={false}
            />
            {/* <Typography className="font-black text-20 ">{order?.campaign.name}</Typography> */}

            <div className="mt-2 flex items-center">
              <ChatAvatar
                user={orderUserToShow}
                size={32}
              />
              <div className="ml-2">
                <Username user={orderUserToShow} />
              </div>
            </div>
          </div>
        )}

        <Divider />

        <div className="flex flex-auto min-h-0 w-full relative lg:max-h-[calc(100vh-260px)] min-h-[calc(100vh-310px)] md:min-h-[calc(100vh-270px)] max-h-[calc(100vh-210px)]">
          <div className="flex flex-1 z-10 flex-col relative">
            <div
              ref={chatRef}
              className="flex flex-1 flex-col overflow-y-auto"
            >
              {chat?.length ? (
                <div className="flex flex-col pt-4 px-4 pb-12">
                  {formattedMessages.map((item, i) => (
                    <StyledMessageRow
                      key={i}
                      className={clsx(
                        'flex flex-col grow-0 shrink-0 items-start justify-end relative px-4 pb-4',
                        item.userFrom === user.id ? 'me' : 'contact',
                        { 'first-of-group': isFirstMessageOfGroup(item, i) },
                        { 'last-of-group': isLastMessageOfGroup(item, i) },
                        i + 1 === chat.length && 'mb-120'
                      )}
                    >
                      <div className="bubble flex relative items-center justify-center p-3 max-w-full">
                        <div
                          className="leading-tight whitespace-pre-wrap"
                          dangerouslySetInnerHTML={{ __html: item.message }}
                        />
                        <Typography
                          className="time absolute hidden w-full text-11 mt-2 -mb-6 ltr:left-0 rtl:right-0 bottom-0 whitespace-nowrap"
                          color="text.secondary"
                        >
                          {dayjs(item.createdAt).locale('uk').from()}
                        </Typography>
                      </div>
                    </StyledMessageRow>
                  ))}
                </div>
              ) : (
                <div className="h-[calc(100%-115px)] flex items-center justify-center text-lg">
                  <NotFoundPanel
                    title={t('chat.notFoundTitle', { defaultValue: 'Повідомлень не знайдено' })}
                    Icon={FaRegSadTear}
                  />
                </div>
              )}
            </div>

            {chat && (
              <Paper
                square
                variant="outlined"
                component="form"
                onSubmit={handleMessageSubmit}
                className="absolute border-t-1 bottom-0 right-0 left-0 py-4 px-4 relative md:min-h-auto"
                sx={{
                  marginRight: '-1px',
                  marginLeft: '-1px',
                  marginBottom: '-1px',
                  backgroundColor: (theme) =>
                    theme.palette.mode === 'light'
                      ? lighten(theme.palette.background.default, 0.4)
                      : lighten(theme.palette.background.default, 0.02),
                }}
              >
                {disabled && (
                  <div className="absolute top-0 flex items-center rounded-lg z-[100] justify-center right-0 left-0 bottom-0 bg-white text-lg font-bold">
                    <NotFoundPanel
                      title={t('chat.finishedTitle', {
                        defaultValue: 'Вітаємо, співпрацю завершено!',
                      })}
                      content={t('chat.finished', {
                        defaultValue:
                          'Ваш доступ до чату було обмежено.\\nЯкщо у вас є питання чи потреба у допомозі, будь ласка, зверніться до саппорту★',
                      })}
                    />
                  </div>
                )}

                <Box
                  display="flex"
                  alignItems="center"
                >
                  <TextField
                    autoFocus={false}
                    id="message-input"
                    multiline
                    disabled={disabled}
                    rows={3}
                    className="flex-1 flex grow shrink-0 mr-4"
                    placeholder={t('chat.messagePlaceholder', { defaultValue: 'Повідомлення...' })}
                    onChange={onInputChange}
                    onKeyDown={(event) => {
                      if (event.key === 'Enter') {
                        event.preventDefault();
                      }
                    }}
                    value={message}
                    sx={{ backgroundColor: 'background.paper' }}
                  />
                  <IconButton
                    className=""
                    disabled={disabled}
                    type="submit"
                    size="large"
                  >
                    <HiOutlinePaperAirplane
                      className="rotate-90"
                      color="action"
                    />
                  </IconButton>
                </Box>
              </Paper>
            )}
          </div>
        </div>
      </Panel>
    </div>
  );
}

export default Chat;
