import { Dialog } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { ImCheckmark } from 'react-icons/im';

export const DialogConfirmNew = ({
  title,
  onClose,
  iconHide,
  buttonText = 'Close',
  icon,
  buttons,
  maxWidth,
  content,
  ...rest
}) => (
  <Dialog
    {...rest}
    onClose={onClose}
    PaperProps={{
      variant: 'elevation',
      className: 'px-10 py-8',
    }}
  >
    <Box maxWidth={maxWidth}>
      {!iconHide && (
        <Box
          display="flex"
          justifyContent="center"
        >
          {icon || (
            <ImCheckmark
              style={{ fontSize: 120 }}
              className="text-green-600"
            />
          )}
        </Box>
      )}

      <div className="flex flex-shrink-0 items-center justify-between rounded-t-md md:py-5 py-4 dark:border-opacity-50">
        <div className="text-lg font-medium leading-normal justify-center text-center text-neutral-800 mr-4">
          {title || 'title'}
        </div>
      </div>

      {content && (
        <div className="relative justify-center text-center flex-auto md:py-5 py-4">
          <Typography
            whiteSpace="pre-wrap"
            dangerouslySetInnerHTML={{ __html: content }}
          />
        </div>
      )}

      <div className="flex flex-shrink-0 flex-wrap items-center justify-around justify-center rounded-b-md md:pt-5 pt-4">
        {buttons || (
          <Box minWidth={120}>
            <Button
              fullWidth
              type="button"
              variant="contained"
              color="success"
              onClick={onClose}
            >
              {buttonText}
            </Button>
          </Box>
        )}
      </div>
    </Box>
  </Dialog>
);
