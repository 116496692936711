import { PaymentsPaid } from './PaymentsPaid';
import { PaymentsUnpaid } from './PaymentsUnpaid';

export const Payments = () => {
  return (
    <>
      <PaymentsUnpaid />
      <PaymentsPaid />
    </>
  );
};
