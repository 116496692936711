import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import { AppRoutes } from '../../../configs/app-routes';
import { NotificationBase } from './NotificationBase';

export const NotificationOrderStatisticAttached = ({ payload, onClick, ...rest }) => {
  return (
    <NotificationBase
      {...rest}
      title="Блогер додав статистику"
      content={
        <>Блогер додав статисту публікації до кампанії{' '}
          <Link
            href={AppRoutes.campaignView(payload.campaign.id)}
            rel="noreferrer"
            className="link"
          >
            {payload.campaign.name}
          </Link>{' '}
        </>
      }
      Buttons={
        <>
          <Button
            size="small"
            color="primary"
            variant="contained"
            className="mb-1 mt-2"
            component={Link}
            onClick={onClick}
            to={AppRoutes.campaignView(payload.campaign.id)}
          >
            Переглянути кампанію
          </Button>
        </>
      }
    />
  );
};
