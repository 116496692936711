import { ReactNode } from 'react';

export type CardStatisticProps = {
  children: ReactNode;
  title: ReactNode;
  className?: string;
};
export const CardChart = ({ title, children, className }: CardStatisticProps) => (
  <div className={`${className} bg-white md:border md:border-gray-200 md:rounded-lg md:shadow md:py-4 md:px-4 h-max`}>
    <div className="mb-4 mt-2 title-3">{title}</div>
    {children}
  </div>
);
