import { Typography } from '@mui/material';
import { usePaymentsFetch, PaymentsState } from './payments-api';
import { PaymentsTable } from './components/PaymentsTable';
import { messages as m } from './messages';

export const PaymentsPaid = () => {
  const { data: { data: paymentsPaidRows = [] } = {} } = usePaymentsFetch({
    state: PaymentsState.PAID,
  });

  return (
    <div className="mt-6">
      <Typography
        className="mb-6"
        variant="h5"
      >
        {m.paidTitle}
      </Typography>
      <PaymentsTable
        rows={paymentsPaidRows}
        emptyMessage={m.emptyPaid}
      />
    </div>
  );
};
