import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import CampaignFormItem from './CampaignFormItem';
import { campaignFields, campaignRequirementsFields } from '../../campaign-view/campaignConfig';
import { i18n } from '../../../i18n';

const requirementsRules = i18n.t('campaign.requirementsTZ', {
  defaultValue: `Опис продукту чи послуги:
- Посилання на ресурси, пов'язані з продуктом (брендбук)
- Правильна вимова/написання назви продукту та бренду
- Зображення
- Вимоги до візуалу, тексту та основні тези
- Терміни виконання. Позначте дату та час публікації. Штрафи - за невиконання
`,
});

function CampaignRequirements(props: { socialTypes: any[]; showFollowersCount: boolean }) {
  const { socialTypes, showFollowersCount = true } = props;
  const { t } = useTranslation();

  const formConfig = () => {
    const result = {
      // [campaignFields.followersCount]: {
      //   label: t('campaign.requirementsFollowersCount', { defaultValue: m.followersCount }),
      //   name: campaignFields.followersCount,
      //   variant: 'textfield',
      //   props: {
      //     step: 10,
      //     type: 'number',
      //   },
      //   // description: t('campaign.requirementsDesc.mentions', { defaultValue: m.desc.mentions }),
      //   // placeholder: '@ВашБренд',
      //   // props: {
      //   //   freeSolo: true,
      //   //   placeholder: '@ВашБренд',
      //   // },
      // },
      [campaignFields.followersCount]: {
        label: t('campaign.requirementsFollowersCount', {
          defaultValue: 'Мінімальна кількість фоловерів:',
        }),
        name: campaignFields.followersCount,
        variant: 'select',
        props: {
          items: [
            { label: '-', value: 0 },
            { label: '1000', value: 1000 },
            { label: '10 тисяч', value: 10000 },
            { label: '100 тисяч', value: 100000 },
            { label: '500 тисяч', value: 500000 },
            { label: '1 мільйон', value: 1000000 },
          ],
        },
        // props: {
        //   step: 10,
        //   type: 'number',
        // },
        // description: t('campaign.requirementsDesc.mentions', { defaultValue: m.desc.mentions }),
        // placeholder: '@ВашБренд',
        // props: {
        //   freeSolo: true,
        //   placeholder: '@ВашБренд',
        // },
      },
      [campaignRequirementsFields.mentions]: {
        label: t('campaign.requirementsRequirementsMentions', {
          defaultValue: 'Згадки:',
        }),
        name: `${campaignFields.requirements}.${campaignRequirementsFields.mentions}`,
        variant: 'autocomplete',
        list: [],
        description: t('campaign.requirementsDesc.mentions', { defaultValue: 'Ваші платформи' }),
        placeholder: '@ВашБренд',
        props: {
          freeSolo: true,
          limitTags: 20,
          placeholder: '@ВашБренд',
        },
      },
      [campaignRequirementsFields.keywords]: {
        label: t('campaign.requirementsRequirementsKeywords', {
          defaultValue: '#Хештеги:',
        }),
        name: `${campaignFields.requirements}.${campaignRequirementsFields.keywords}`,
        variant: 'autocomplete',
        list: [],
        placeholder: '#Хештег',
        props: {
          freeSolo: true,
          limitTags: 20,
        },
      },
      // [campaignRequirementsFields.hashtags]: {
      //   label: t('campaign.requirementsRequirementsHashtags', { defaultValue: m.requirementsHashtags }),
      //   name: `$campaignFields.requirementsname}.${campaignRequirementsFields.hashtags}`,
      //   variant: "categories",
      // },

      [campaignRequirementsFields.rules]: {
        label: t('campaign.requirementsRequirementsRules', { defaultValue: 'Технічне завдання:' }),
        name: `${campaignFields.requirements}.${campaignRequirementsFields.rules}`,
        variant: 'textarea',
        description: t('campaign.requirementsDesc.requirementsRules', {
          defaultValue: requirementsRules,
        }),
        required: true,
        props: {
          rows: 15,
        },
      },

      [campaignRequirementsFields.notOther_brands]: {
        label: t('campaign.requirementsRequirementsNotOther_brands', {
          defaultValue: 'Не показувати інші продукти в рекламній публікації:',
        }),
        name: `${campaignFields.requirements}.${campaignRequirementsFields.notOther_brands}`,
        variant: 'switcher',
        props: {
          items: [
            { label: t('campaign.requirementsYes', { defaultValue: 'Так' }), value: true },
            { label: t('campaign.requirementsNo', { defaultValue: 'Ні' }), value: false },
          ],
        },
      },

      [campaignRequirementsFields.attachStatistic]: {
        label: t('campaign.requirementsAttachStatistic', {
          defaultValue:
            'Чи необхідно додати скріншоти аналітики після успішного завершення кампанії?',
        }),
        name: `${campaignFields.requirements}.${campaignRequirementsFields.attachStatistic}`,
        variant: 'switcher',
        props: {
          items: [
            { label: t('campaign.requirementsYes', { defaultValue: 'Так' }), value: true },
            { label: t('campaign.requirementsNo', { defaultValue: 'Ні' }), value: false },
          ],
        },
      },
    };

    if (!showFollowersCount) {
      delete result[campaignFields.followersCount];
    }

    return result;
  };

  const config = useMemo(() => {
    const result = [];
    socialTypes &&
      socialTypes.forEach((socialType) => {
        result.push(formConfig(`${campaignFields.requirements}.${socialType}`));
      });

    return result;
  }, [socialTypes]);

  const socialConfig = config ? config[0] : [];
  const form = useFormContext();

  return (
    <div>
      {socialConfig &&
        Object.keys(socialConfig).map((key) => {
          const item = socialConfig[key];

          if (item.Component) {
            return (
              <item.Component
                {...item}
                key={item.name}
              />
            );
          }

          return (
            <CampaignFormItem
              size="medium"
              orient="horizontal"
              {...item}
              errors={form.formState.errors}
              key={item.name}
            />
          );
        })}
    </div>
  );
}

export default CampaignRequirements;
