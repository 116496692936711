import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useParams } from 'react-router-dom';
import Divider from '@mui/material/Divider';
import * as dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { ContentText, ContentCardBody } from '../../components/Content';
import { UserPanel } from '../../components/UserPanel';
import { ProfileViewFeedbacks } from './ProfileViewFeedbacks';
import { ProfileViewSocials } from './ProfileViewSocials';
import { ProfileViewInterests } from './ProfileViewDetails';
import { useProfileGetQuery } from '../profile/profile-api';
import { Roles } from '../../configs/configuration';
import { ProfileInterestViewCheckboxes } from './ProfileInterestViewCheckboxes';
import { ProfileViewCampaigns } from './ProfileViewCampaigns';
import { CampaignState } from '../../types';
import { ProfilePriceList } from './ProfilePriceList';

const ages = ['18-25', '26-35', '36+'];
const ageByBirthdate = (date) => {
  const diff = dayjs().diff(date, 'years');

  if (diff < 25) return ages[0];
  if (diff < 35) return ages[1];
  return ages[2];
};

export const ProfileView = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const query = useProfileGetQuery(id);
  const { data: profile } = query;

  if (!profile) {
    return null;
  }

  const isBrand = profile.role === Roles.BRAND;
  const isUser = !isBrand;
  const categories = profile?.categories?.map((item) => Number(item.category.id));
  const showSocials = !isBrand && Boolean(profile.socials);

  const { portfolio } = profile;

  return (
    <>
      <Card
        className="md:p-6 p-4"
        variant="outlined"
      >
        <div className="flex md:flex-row flex-col">
          <UserPanel
            className="md:max-w-[200px] md:min-w-[200px] md:w-1/3"
            user={profile}
          />

          <ContentCardBody className="flex-1 flex-wrap flex mt-4 md:mt-0">
            <div className="lg:w-1/2 min-w-[50%] lg:pr-4 mb-4 lg:mb-0">
              <Box mb={2}>
                <Typography
                  variant="h6"
                  className="font-black"
                >
                  {profile.firstName} {profile.lastName}
                </Typography>
              </Box>

              {profile.birthday && !isBrand && (
                <ContentText
                  boldLabel
                  label={t('profileView.age', { defaultValue: 'Вік:' })}
                  content={ageByBirthdate(profile.birthday)}
                />
              )}

              {profile.brandLinks && isBrand && (
                <ContentText
                  boldLabel
                  orientRow
                  label={t('profileView.brandLink', { defaultValue: 'Посилання на бренд:' })}
                  content={
                    <>
                      {profile.brandLinks.map((link) => (
                        <div key={link.value}>
                          <a
                            href={link.value}
                            target="_blank"
                            className="link inline-block break-all"
                            rel="noreferrer"
                          >
                            {link.value}
                          </a>
                        </div>
                      ))}
                    </>
                  }
                />
              )}

              {isUser && profile.city && (
                <ContentText
                  boldLabel
                  label={t('profileView.city', { defaultValue: 'Місто:' })}
                  content={profile.city}
                />
              )}

              <Box maxWidth={600}>
                <ContentText
                  orientRow
                  boldLabel
                  label={t('profileView.about', { defaultValue: 'Біо:' })}
                  content={profile.about}
                />
              </Box>

              {isUser && Boolean(portfolio && portfolio[0]?.brand) && (
                <Box className="w-full">
                  <Typography
                    className="mt-2"
                    variant="subtitle2"
                    fontWeight="bold"
                  >
                    {t('profileView.detailsPortfolio', { defaultValue: 'Приклади колаборацій:' })}
                  </Typography>

                  <Box maxWidth={700}>
                    {portfolio.map((item) =>
                      item.link ? (
                        <Box
                          key={item.link}
                          mb={1}
                        >
                          {item.brand}:{' '}
                          <a
                            href={item.link}
                            target="_blank"
                            className="link"
                            rel="noreferrer"
                          >
                            {item.link}
                          </a>
                        </Box>
                      ) : null
                    )}
                  </Box>
                </Box>
              )}
            </div>

            <div className="w-full lg:w-1/2 ml-auto">
              {Boolean(categories && categories.length) && (
                <div className="mt-4 lg:mt-0">
                  <div className="font-bold text-lg">
                    {t('profileView.detailsCategories', { defaultValue: 'Категорії:' })}
                  </div>

                  <Box
                    maxWidth={700}
                    mt={1}
                    display="flex"
                    flexWrap="wrap"
                  >
                    <ProfileInterestViewCheckboxes
                      categories={categories}
                      readonly
                    />
                  </Box>
                </div>
              )}

              <ProfileViewInterests />
            </div>

            {!isUser && (
              <div className="mt-4">
                {profile?.id && (
                  <>
                    <ProfileViewCampaigns
                      id={profile?.id}
                      state={CampaignState.FINISHED}
                    />
                    <ProfileViewCampaigns
                      id={profile?.id}
                      state={CampaignState.PENDING}
                    />
                  </>
                )}
              </div>
            )}
          </ContentCardBody>
        </div>

        <div className="mt-4 md:mt-6">
          {isUser && profile.prices && <ProfilePriceList prices={profile.prices} />}
        </div>

        <div className="mt-4 md:mt-6">
          {showSocials && (
            <>
              <Box
                mt={3}
                mb={2}
              >
                <Divider />
              </Box>

              <ProfileViewSocials profile={profile} />
            </>
          )}

          <Box
            mt={3}
            mb={2}
          >
            <Divider />
          </Box>

          <Box mt={3}>
            <div className="mb-4 title-3">
              {t('profileView.reviews', { defaultValue: 'Відгуки:' })}
            </div>

            {profile && <ProfileViewFeedbacks user={profile} />}
          </Box>
        </div>
      </Card>
    </>
  );
};
