import { createSlice } from '@reduxjs/toolkit';

const notificationsSlice = createSlice({
  name: 'notification/state',
  initialState: false,
  reducers: {
    toggleNotificationPanel: (state, action) => !state,
    openNotificationPanel: (state, action) => true,
    closeNotificationPanel: (state, action) => false,
  },
});

export const { toggleNotificationPanel, openNotificationPanel, closeNotificationPanel } =
  notificationsSlice.actions;

export const selectNotificationPanelState = ({ notifications }) => notifications;

export default notificationsSlice.reducer;
