import { messages } from '../socials/messages';
import { Number } from '../../components/Number/Number';
import { CardStatistic } from '../../components/CardStatistic';

export const ProfileViewInstagramStat = ({ data, title }) => {
  const egRate = (((data.likes || 1) + (data.comments || 0)) / data.reach) * 100;
  return (
    <div>
      <h2 className="mt-8 title-2">{title}</h2>
      <div className="grid grid-cols-2 md:grid-cols-3 gap-3 mt-4">
        {Object.keys(data).map((key) => {
          const value = data[key];

          if (!messages.instagram.fiels[key]) {
            return null;
          }

          return (
            <CardStatistic
              title={messages.instagram.fiels[key]}
              value={<Number amount={value} />}
            />
          );
        })}

        {egRate && (
          <CardStatistic
            title="Engagement Rate:"
            value={`${egRate.toFixed(2)}%`}
          />
        )}
      </div>
    </div>
  );
};
