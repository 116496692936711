import differenceBy from "lodash/differenceBy";

export const formatCreateDto = (_fd) => {
  const fd = { ..._fd };
  fd.images = {
    create: fd.images.map((item) => ({
      socials_id: '+',
      directus_files_id: { id: item.id },
    })),
  };

  return fd;
};

export const formatUpdateDto = (currentDto, prevDto) => {
  const diff1 = differenceBy(currentDto.images, prevDto.images, (item) => item.id);
  const diff2 = differenceBy(prevDto.images, currentDto.images, (item) => item.id);
  const diff = [...diff1, ...diff2];

  const addedImages = [];
  const removedImages = [];

  const findById = (arr, id) => arr.find((item) => item.id === id);

  diff.forEach((item) => {
    const foundedInPrev = findById(prevDto.images, item.id);
    const foundedInCurrent = findById(currentDto.images, item.id);

    if (foundedInCurrent && !foundedInPrev) {
      return addedImages.push(item);
    }

    if (!foundedInCurrent && foundedInPrev) {
      return removedImages.push(item);
    }
  });

  return {
    ...currentDto,
    images: {
      create: addedImages.map((item) => ({
        socials_id: currentDto.id,
        directus_files_id: { id: item.id },
      })),

      delete: removedImages.map((item) => item.innerId),
    },
  };
};