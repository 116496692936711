import clsx from 'clsx';
import { notificationMapping, showNotificationGuard } from './mapping';

function NotificationCard(props) {
  const { item, className, onClose } = props;
  const variant = item?.variant || '';

  const Component = notificationMapping[item.type];
  const showNotification = showNotificationGuard(item);

  if (!showNotification) {
    return null;
  }

  return (
    <div
      className={clsx(
        'flex items-center relative w-full min-h-12 ',
        variant === 'success' && 'bg-green-600 text-white',
        variant === 'info' && 'bg-blue-700 text-white',
        variant === 'error' && 'bg-red-600 text-white',
        variant === 'warning' && 'bg-orange-600 text-white',
        className
      )}
    >
      {item.image && (
        <img
          className="shrink-0 w-8 h-8 mr-3 rounded-full overflow-hidden object-cover object-center"
          src={item.image}
          alt="Notification"
        />
      )}

      <Component
        id={item.id}
        onClick={onClose}
        {...item}
      />

      {item.children}
    </div>
  );
}

export default NotificationCard;
