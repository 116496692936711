import { useFieldArray, useFormContext } from 'react-hook-form';
import { Box } from '@mui/system';
import { IconButton } from '@mui/material';
import IconDelete from '@mui/icons-material/Delete';
import { useTranslation } from 'react-i18next';
import { ProfileCard } from '../Profile.styles';
import CampaignFormItem from '../../campaign-create/components/CampaignFormItem';
import { Button } from '../../../components/Button';

export const ProfilePortfolio = () => {
  const { t } = useTranslation();
  const { control, formState } = useFormContext();

  const { fields, append, remove } = useFieldArray({ control, name: 'portfolio' });

  return (
    <ProfileCard
      title={t('profile.portfolioTitle', { defaultValue: 'Портфоліо' })}
      subtitle={t('profile.portfolioSubtitle', {
        defaultValue: 'Надайте приклади Ваших робіт або колаборацій з брендами',
      })}
    >
      {fields.map((_, index) => (
        <div
          className="flex flex-1 mb-4 items-center"
          key={index}
        >
          <div className="max-w-[350px]">
            <CampaignFormItem
              name={`portfolio[${index}].brand`}
              orient="vertical"
              size="small"
              label={t('profile.portfolioBrand', { defaultValue: 'Бренд' })}
              control={control}
              errors={formState.errors}
            />
          </div>

          <div className="ml-4 flex-1">
            <CampaignFormItem
              name={`portfolio[${index}].link`}
              label={t('profile.portfolioLink', { defaultValue: 'Посилання' })}
              orient="vertical"
              size="small"
              control={control}
              errors={formState.errors}
            />
          </div>

          {index !== 0 && (
            <Box
              ml={2}
              mt={1}
            >
              <IconButton onClick={() => remove(index)}>
                <IconDelete />
              </IconButton>
            </Box>
          )}
        </div>
      ))}

      <div>
        <Button
          type="button"
          variant="contained"
          color="primary"
          onClick={() => append({})}
        >
          {t('profile.portfolioAdd', { defaultValue: 'Додати колаборацію' })}
        </Button>
      </div>
    </ProfileCard>
  );
};
