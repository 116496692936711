import { Link } from 'react-router-dom';
import { useProfileFetchCampaignsByStateQuery } from '../profile/profile-api';
import { messages } from './messages';
import { CampaignState } from '../../types';
import { AppRoutes } from '../../configs/app-routes';

type ProfileViewCampaignsProps = {
  id: string;
  state: CampaignState;
};
export const ProfileViewCampaigns = ({ id, state }: ProfileViewCampaignsProps) => {
  const { data: { data = [] } = {} } = useProfileFetchCampaignsByStateQuery({ id, state });

  if (!data.length) {
    return null;
  }

  return (
    <div className="mt-4 md:mt-0 mb-6">
      <div className="font-bold text-lg mb-2">{messages.campaigns[state]!}:</div>
      <ul>
        {data.map((campaign, index) => (
          <li key={campaign.id}>
            <Link
              className="link"
              to={AppRoutes.campaignView(campaign.id)}
            >
              {index + 1}. {campaign.name}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};
