import * as yup from 'yup';
import { SocialTypes } from '../../configs/social-types';
import { messages } from '../../messages';

const m = messages.socials;

export const getSocialManualConnectByUsernameSchema = (socialType: SocialTypes) =>
  yup.object().shape({
    username: yup.string().required(),
    // followersCount: yup
    //   .number()
    //   .min(
    //     FOLLOWERS_COUNT_BY_SOCIAL_TYPE[socialType],
    //     m.errorMin(FOLLOWERS_COUNT_BY_SOCIAL_TYPE[socialType])
    //   ),
  });
