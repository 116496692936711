export const messages = {
  title: 'Ваш відгук про influencer.com.ua',
  questions: {
    platform: 'Оцініть платформу:',
    interface: 'Чи рекомендували б ви influencer.com.ua своїм колегам?',
    additional: 'Чого не вистачає?',
    placeholder: 'Залиште ваше повідомлення або пропозицію',
  },
  sendButton: 'Відправити',
  feedbackButton: 'Залишите відгук?',
  successMessage: 'Дякуємо!\nВаша думка про нас - важлива!',
  errorMessage: "Упс, проблемка :( спробуйте ще раз або звʼяжіться зі службою підтримки",
};
