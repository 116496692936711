import ChairIcon from '@mui/icons-material/Chair';
import FastfoodIcon from '@mui/icons-material/Fastfood';
import SportsSoccerIcon from '@mui/icons-material/SportsSoccer';
import AbcIcon from '@mui/icons-material/Abc';
import DiamondIcon from '@mui/icons-material/Diamond';
import { TbHealthRecognition, TbHome, TbSettings } from 'react-icons/tb';
import { GiKidSlide } from 'react-icons/gi';
import {
  MdPets,
  MdCastForEducation,
  MdOutlineTravelExplore,
  MdBusinessCenter,
} from 'react-icons/md';
import { Roles } from './configuration';
import { ReactComponent as BeautyIcon } from '../icons/beauty.svg';
import { ReactComponent as SelfieStickPhotography } from '../icons/selfie-stick-photography.svg';
import { FaGift } from "react-icons/fa6"
import { FaHandHoldingMedical } from "react-icons/fa";
import { PiChatCircleDots } from "react-icons/pi"
import { RiPassExpiredFill } from "react-icons/ri"

export const categoriesConfig = [
  { label: "Б'юті", id: 'beauty', strapiId: 4, Icon: BeautyIcon },
  { label: 'Бізнес', id: 'business', strapiId: 16, Icon: MdBusinessCenter },
  { label: 'Мода', id: 'fashion', strapiId: 5, Icon: DiamondIcon },
  { label: 'Фітнес', id: 'fitness', strapiId: 6, Icon: SportsSoccerIcon },
  { label: "Здоров'я", id: 'health_wellness', strapiId: 7, Icon: FaHandHoldingMedical },
  { label: 'Дім/Дизайн', id: 'home_design', strapiId: 8, Icon: TbHome },
  { label: "Діти/Сім'я", id: 'kids_family', strapiId: 9, Icon: GiKidSlide },
  { label: 'Домашні тварини', id: 'pets', strapiId: 10, Icon: MdPets },
  { label: 'Поради/Освіта', id: 'advice_education', strapiId: 11, Icon: MdCastForEducation },
  { label: 'Техніка', id: 'tech', strapiId: 12, Icon: TbSettings },
  { label: 'Подорожі', id: 'travel', strapiId: 13, Icon: MdOutlineTravelExplore },
  { label: 'Їжа/Напої', id: 'food_drink', strapiId: 15, Icon: FastfoodIcon },
  {
    label: 'Особисті блоги',
    id: 'personal_blog',
    strapiId: 18,
    Icon: SelfieStickPhotography,
    role: Roles.BRAND,
  },
  { label: 'Гів', id: 'giveaway', strapiId: 19, Icon: FaGift, role: Roles.BRAND },
  { label: 'Інше', id: 'other', strapiId: 14, Icon: PiChatCircleDots, role: Roles.BRAND },
  { label: '18+', id: '18_plus', strapiId: 17, Icon: RiPassExpiredFill },
];

export const categoriesForBlogger = categoriesConfig.filter(
  (category) => !category.role || category.role === Roles.USER
);
export const categoriesForBrand = categoriesConfig.filter(
  (category) => !category.role || category.role === Roles.BRAND
);
