import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { DialogConfirm } from '../../components/DialogConfirm';

export type CampaignViewPauseProps = {
  onSubmit: () => void;
  onClose: () => void;
  open: boolean;
};

export const CampaignViewPause = (props: CampaignViewPauseProps) => {
  const { t } = useTranslation();
  return (
    <DialogConfirm
      title={t('campaignViewPause.title', {
        defaultValue: 'Ви впевнені, що хочете призупинити кампанію?',
      })}
      iconHide
      maxWidth={520}
      content={t('campaignViewPause.content', {
        defaultValue: `Якщо ви зупиняєте кампанію:
- Публікації які в роботі = не скасовуються та будуть опубліковані згідно з вказівками у технічному завданні
- Ви можете в будь-який момент відновити пошук блогерів та продовжити роботу`,
      })}
      {...props}
      buttons={
        <>
          <div className="min-w-[120px]">
            <Button
              fullWidth
              type="button"
              variant="outlined"
              color="primary"
              onClick={props.onClose}
            >
              {t('campaignViewPause.no', { defaultValue: 'Зупинити' })}
            </Button>
          </div>

          <div className="min-w-[120px] ml-4">
            <Button
              fullWidth
              type="button"
              variant="contained"
              color="primary"
              onClick={props.onSubmit}
            >
              {t('campaignViewPause.yes', { defaultValue: 'Скасувати' })}
            </Button>
          </div>
        </>
      }
    />
  );
};
