import { UsersList } from './UsersList';
import UsersFilters from './UsersFilters';
import { Meta } from '../meta/Meta';
import { TITLES } from '../../configs/titles';

export const Users = () => (
  <>
    <Meta title={TITLES.users()} />

    <div className="flex flex-wrap">
      <div className="flex-1 order-[1] mt-6 lg:mt-0">
        <UsersList />
      </div>

      <UsersFilters />
    </div>
  </>
);
