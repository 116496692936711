import { useFormContext } from 'react-hook-form';
import { SocialTypes } from '../../configs/social-types';
import { Checkbox } from '../../components/Checkbox/Checkbox';
import { ChipInstagram, ChipTiktok, ChipYoutube } from '../../components/Chips';

export const Fields = {
  type: 'type',
  socialTypes: 'socialTypes',
  categories: 'categories',
};

function CampaignsFeedSocials({ activeIds = [] }: { activeIds: string[] }) {
  const { register } = useFormContext();

  const registeredYoutube = register('socialTypes');
  const registeredInstagram = register('socialTypes');
  const registeredTiktok = register('socialTypes');

  return (
    <>
      <label className="block mb-2">
        <Checkbox
          checked={activeIds.includes(SocialTypes.INSTA)}
          value={SocialTypes.INSTA}
          inputProps={registeredInstagram}
        />
        <ChipInstagram />
      </label>

      <label className="block mb-2">
        <Checkbox
          checked={activeIds.includes(SocialTypes.TIKTOK)}
          value={SocialTypes.TIKTOK}
          inputProps={registeredTiktok}
        />
        <ChipTiktok />
      </label>

      <label className="block mb-2">
        <Checkbox
          checked={activeIds.includes(SocialTypes.YOUTUBE)}
          value={SocialTypes.YOUTUBE}
          inputProps={registeredYoutube}
        />
        <ChipYoutube />
      </label>

    </>
  );
}

export default CampaignsFeedSocials;
