export const SERVER_ENDPOINT_REDIRECT =
  'https://influencer.com.ua/app/profile/socials/tiktok/remote-connect';
export const CLIENT_KEY = 'awvbkqmkkq6a6exo';
export class TiktokService {
  static oauth() {
    let url = 'https://www.tiktok.com/v2/auth/authorize/';

    // the following params need to be in `application/x-www-form-urlencoded` format.
    url += `?client_key=${CLIENT_KEY}`;
    url += '&scope=user.info.profile,user.info.stats';
    // url += '&scope=user.info.profile';
    url += '&response_type=code';
    url += `&redirect_uri=${SERVER_ENDPOINT_REDIRECT}`;

    window.open(url, '_blank');
  }
}
