import { deserialize as jsonApiDeserialize } from 'json-api-deserialize';

export const deserialize = (object) => {
  // eslint-disable-next-line consistent-return
  const fn = (item) => {
    if (typeof item === 'object' && item !== null) {
      // console.log("item", item, item.data === null);

      if (item.data) {
        return deserialize({ ...item });
      }

      if (item.data === null) {
        return undefined;
      }
    } else {
      return item;
    }
  };

  if (typeof object === 'object' && object !== null) {
    const formattedObject = jsonApiDeserialize(object);
    return formattedObject;
  }
  return object;
};
