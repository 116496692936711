import { useMemo } from 'react';
import { BarChart } from './BarChart';
import { LineChart } from './LineChart';
import { ChartDataItem } from '../types';
import { formatDataForChart, formatFlatData } from './overall-analytic';
import { messages } from '../../../messages';
import { ContentText } from '../../../components/Content';
import { Number } from '../../../components/Number/Number';
import { ChartBarAgeDemographics } from '../ChartBarAgeDemographics';
import { adaptAgeDemographyYoutube } from '../adapters';

const m = messages.socials;

const CONFIG_STAT = {
  views: {
    fn: (val: number) => Math.floor(val / 90),
    Component: ({ amount }: { amount: number }) => <Number amount={amount} />,
  },
  comments: {
    fn: (val: number) => Math.floor(val / 90),
    Component: ({ amount }: { amount: number }) => <Number amount={amount} />,
  },
  likes: {
    fn: (val: number) => Math.floor(val / 90),
    Component: ({ amount }: { amount: number }) => <Number amount={amount} />,
  },
  estimatedMinutesWatched: {
    fn: (val: number) => Math.floor(val / 90),
    Component: ({ amount }: { amount: number }) => <Number amount={amount} />,
  },
  // averageViewDuration: {
  //   fn: (val: number) => val,
  //   Component: ({ amount }: { amount: number }) => (
  //     <>
  //       <Number amount={amount} /> сек.
  //     </>
  //   ),
  // },
};

export type YoutubeChartData = {
  analytic: {
    columnHeaders: Array<{
      name: string;
    }>;

    rows: any[];
  };
};

export type YoutubeChartNormalizedData = {
  views: ChartDataItem[];
  estimatedMinutesWatched: ChartDataItem[];
  averageViewDuration: ChartDataItem[];
  averageViewPercentage: ChartDataItem[];
  subscribersGained: ChartDataItem[];
};

export const YoutubeCharts = ({ data }: { data: YoutubeChartData }) => {
  const { analytic } = data;

  const baseStatistic = data?.baseStatistic;
  const formattedBaseStatistic = baseStatistic && Object.entries(formatFlatData(baseStatistic));

  const normalizedAnalytic = useMemo(() => formatDataForChart(analytic), [analytic]);

  const ageDemographics = data?.ageDemographic.rows;
  const ageDemographicsAdapted = ageDemographics && adaptAgeDemographyYoutube(ageDemographics);

  return (
    <div className="card rounded-0 shadow-sm">
      <div className="title-4 mb-4 mt-4">{m.statistics.statForMonth}</div>

      <div className="grid md:grid-cols-2 grid-cols-1">
        {formattedBaseStatistic.map(([key, value]) => {
          const config = CONFIG_STAT[key];

          if (!config) {
            return null;
          }

          const { Component } = config;

          return (
            <ContentText
              key={key}
              label={`${m.statistics.youtube[key]}:`}
              content={<Component amount={config.fn(value)} />}
            />
          );
        })}
      </div>

      <div className="grid grid-cols-1 gap-4 mt-8 max-w-[800px]">
        <ChartBarAgeDemographics
          title="Розподіл глядачів по віку та статі за останні 90 днів:"
          data={ageDemographicsAdapted}
          showUndefinedGender={false}
        />

        <BarChart
          title={m.statistics.youtubeCharts.followers.title}
          datasetLabel={m.statistics.youtubeCharts.followers.label}
          data={normalizedAnalytic.subscribersGained}
        />

        <LineChart
          title={m.statistics.youtubeCharts.views.title}
          datasetLabel={m.statistics.youtubeCharts.views.label}
          data={normalizedAnalytic.views}
        />
      </div>
    </div>
  );
};
