import { Fragment, useMemo } from 'react';
import Box from '@mui/material/Box';
import * as yup from 'yup';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import Divider from '@mui/material/Divider';
import { useTranslation } from 'react-i18next';
import CampaignFormItem from '../campaign-create/components/CampaignFormItem';
import { useUser } from '../common/useUser';
import { SocialTypes, socialTypesConfigNormalized } from '../../configs/social-types';
import { messages } from '../../messages';
import { useSocialFetchMeQuery } from '../socials/socials-api';
import { AppRoutes } from '../../configs/app-routes';
import { configuration, PaymentType } from '../../configs/configuration';
import { useToasts } from '../../components/Toasts';
import { SocialLink } from '../../components/SocialLink/SocialLink';
import { useCampaignOrdersCreateMutation } from '../orders/campaign-orders-api';
import { useCreditsFetch } from '../credits/credits-api';
import { CampaignNoCredits } from './CampaignNoCredits';
import { getRegionNameByCode } from '../common/cities';
import { CampaignNotEnoughFollowers } from './CampaignNotEnoughFollowers';
import { Button } from '../../components/Button';

const getSchema = () =>
  yup.object().shape({
    requirements: yup.boolean().required(),
  });

const createAddressString = ({ address }) => {
  if (!address) {
    return '';
  }

  const { city } = address;
  const region = getRegionNameByCode(Number(address.region))?.label;
  const stringValues = [
    region,
    city,
    address?.location,
    address?.index,
    address?.newPost && `відділення Нової пошти: ${address?.newPost}`,
  ].filter(Boolean);

  return stringValues.join(', ');
};

function CampaignApply(props) {
  const { campaign, onClose, onSubmit, socialType } = props;
  const { t } = useTranslation();
  const { paymentType } = campaign;
  const [create] = useCampaignOrdersCreateMutation();
  const { user } = useUser();
  const { error } = useToasts();
  const { data: { data: socials } = {} } = useSocialFetchMeQuery();
  const { data = {}, isSuccess: isCreditsLoaded } = useCreditsFetch();
  const schema = useMemo(getSchema, []);

  const hasCredits = Boolean(data?.count);

  const showAddress =
    paymentType === PaymentType.BARTER || paymentType === PaymentType.BARTER_MONEY;

  const { address, city } = user;
  const { socialTypes } = campaign;
  const isFeedback = socialType.title === SocialTypes.FEEDBACK;

  const defaultValues = useMemo(() => {
    if (showAddress) {
      return {
        address: createAddressString({ city, address }),
      };
    }

    return {};
  }, [address, showAddress, city]);

  const methods = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });

  const disabled = !methods.formState.isValid;

  const social = useMemo(() => {
    return (
      socials &&
      socials.find((item) => {
        return item.socialType === socialType.title;
      })
    );
  }, [socialType, socials]);

  const submit = (fd) => {
    fd.ownerId = campaign.user.id;
    fd.campaignId = campaign.id;
    fd.campaignOwnerUserId = fd.ownerId;

    create(fd)
      .unwrap()
      .then(() => {
        onSubmit();
      })
      .catch(() => {
        error(messages.error);
        if (onClose) {
          onClose();
        }
      });
  };

  const configIcon = socialTypesConfigNormalized[socialType.title];
  const notEnoughFollowers = campaign.minFollowersCount > social?.followersCount;

  if (isCreditsLoaded && !hasCredits) {
    return <CampaignNoCredits />;
  }

  if (notEnoughFollowers) {
    return <CampaignNotEnoughFollowers />;
  }

  if (!social && !isFeedback) {
    return (
      <Box
        textAlign="center"
        alignItems="center"
      >
        <Box mt={4}>
          <Divider />
        </Box>

        <Box
          mb={3}
          mt={6}
        >
          <configIcon.Icon style={{ fontSize: 120 }} />
        </Box>

        <Typography
          variant="h5"
          fontWeight={500}
        >
          {t('campaignApply.notFoundSocialTitle', { defaultValue: 'У вас не підключено' })}{' '}
          {configIcon.title}
        </Typography>

        <Box
          width={200}
          mt={4}
          mx="auto"
        >
          <Button
            className="whitespace-nowrap mx-4"
            variant="contained"
            color="primary"
            type="submit"
            fullWidth
            component={Link}
            to={AppRoutes.profileSocials()}
          >
            {t('campaignApply.notFoundSocialTitle', { defaultValue: 'Підключити ' })}{' '}
            {configIcon.title}
          </Button>
        </Box>
      </Box>
    );
  }

  return (
    <>
      <Box mt={4}>
        <Divider />
      </Box>

      <FormProvider {...methods}>
        <Box
          mt={4}
          component="form"
          className="max-w-xl"
          onSubmit={methods.handleSubmit(submit)}
        >
          <Box mb={1}>
            <Typography variant="h6">
              {t('campaignApply.notFoundSocialTitle', {
                defaultValue: t('campaignApply.title', {
                  defaultValue: 'Відгукнутися на кампанію:',
                }),
              })}
            </Typography>
          </Box>

          {!isFeedback && (
            <Box mt={1}>
              {socialTypes.map(({ id }) => (
                <Fragment key={id}>
                  {t('campaignApply.accountName', { defaultValue: 'Ваш акаунт:' })}

                  {configuration.ENABLED_SHOW_USERNAME && <SocialLink social={social} />}
                </Fragment>
              ))}
            </Box>
          )}

          {showAddress && (
            <CampaignFormItem
              name="address"
              variant="textarea"
              control={methods.control}
              errors={methods.formState.errors}
              label={t('campaignApply.address', { defaultValue: 'Адреса доставки:' })}
            />
          )}

          <div className="mt-2">
            <CampaignFormItem
              name="details"
              variant="textarea"
              control={methods.control}
              errors={methods.formState.errors}
              label={t('campaignApply.details', { defaultValue: 'Повідомлення бренду:' })}
            />
          </div>

          <CampaignFormItem
            name="requirements"
            variant="checkbox"
            size="big"
            value="1"
            control={methods.control}
            errors={methods.formState.errors}
            label={
              <span
                dangerouslySetInnerHTML={{
                  __html: t('campaignApply.requirements', {
                    defaultValue: `Ви ознайомились з вимогами кампанії, <a class="hover:underline text-blue-600" href=${configuration.global.termsOfServiceUrl}>сервісу</a> та згодні з ними`,
                  }),
                }}
              />
            }
          />

          <Box
            mt={2}
            maxWidth={120}
          >
            <Button
              disabled={disabled}
              gaTrigger="campaign_apply"
              dataTestId="campaign-apply-btn"
              className="whitespace-nowrap"
              variant="contained"
              color="primary"
              type="submit"
              fullWidth
            >
              {t('campaignApply.submit', { defaultValue: 'Відправити' })}
            </Button>
          </Box>
        </Box>
      </FormProvider>
    </>
  );
}

export default CampaignApply;
