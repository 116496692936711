import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import Paper from '@mui/material/Paper';
import FormHelperText from '@mui/material/FormHelperText';
import { useMemo } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import jwtService from '../auth/services/jwtService';
import { SignUpSwitcher } from './SignUpSwitcher';
import { configuration, Roles } from '../../configs/configuration';
import CampaignFormItem from '../campaign-create/components/CampaignFormItem';
import { Link } from '../../components/Link';
import { Meta } from '../meta/Meta';
import { TITLES } from '../../configs/titles';
import { useAuth } from '../auth/AuthContext';
import { AppRoutes } from '../../configs/app-routes';
import { i18n } from '../../i18n';

/**
 * Form Validation Schema
 */
export const SIGN_UP_PASSWORD_REGEX = '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$';

const getSchema = () =>
  yup.object().shape({
    firstName: yup.string().required(),
    email: yup.string().email().required(i18n.t('signUp.emailRequired', 'Ви повинні ввести email')),
    password: yup
      .string()
      .matches(
        SIGN_UP_PASSWORD_REGEX as any,
        i18n.t(
          'signUp.passwordMatch',
          'Пароль повинен містити мінімум 8 символів, серед яких хоч одну цифру, спецсимвол, велику та маленьку літери'
        )
      )
      .required(i18n.t('signUp.passwordRequired', 'Будь ласка, введіть ваш пароль.'))
      .min(
        8,
        i18n.t(
          'signUp.passwordMin',
          'Занадто короткий пароль. Пароль повинен містити від 4 символів'
        )
      ),
    passwordConfirm: yup
      .string()
      .oneOf(
        [yup.ref('password'), null],
        i18n.t('signUp.passwordConfirmMatch', 'Паролі мають співпадати')
      ),
    acceptTermsConditions: yup
      .boolean()
      .oneOf([true], i18n.t('signUp.acceptTermsConditions', 'Умови повинні бути прийняті')),
  });

const defaultValues = {
  email: '',
  password: '',
  passwordConfirm: '',
  firstName: '',
  acceptTermsConditions: false,
  isBrand: false,
};

export const PaperStyled = styled(Paper)`
  @media (max-width: 640px) {
    border: none;
    box-shadow: none;
  }
`;

export type SignUpFd = {
  firstName: string;
  password: string;
  email: string;
  isBrand: boolean;
};
function SignUpPage() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const schema = useMemo(getSchema, []);
  const { control, watch, formState, setError, handleSubmit, getValues, reset } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });
  const { setIsAuthenticated } = useAuth();

  const { isValid, errors } = formState;
  const showBrandName = watch('isBrand');
  function onSubmit({ firstName, password, email, isBrand }: SignUpFd) {
    jwtService
      .createUser({
        firstName,
        password,
        email,
        isBrand,
      })
      .then((user) => {
        setIsAuthenticated(true);
        navigate(
          user.role === Roles.USER ? AppRoutes.signUpBlogger() : configuration.global.rootForOwner
        );

        window.gtag('event', 'sign_up', { role: isBrand ? 'brand' : 'user' });
      })
      .catch((_errors) => {
        if (_errors?.response?.status === 409) {
          setError('email', {
            message: t('signUp.errors.signUpConflict', {
              defaultValue: 'Акаунт із таким email вже існує',
            }),
          });
        } else {
          setError('email', {
            message: t('signUp.errors.base', { defaultValue: 'Щось пішло не так...' }),
          });
        }
      });
  }

  return (
    <div className="flex py-10 flex-col flex-auto items-center sm:justify-center min-w-0 m-3 my-8 ">
      <Meta title={TITLES.signUp()} />
      <PaperStyled className="w-full rounded-0 p-4 sm:p-8 rounded-lg max-w-md shadow">
        <div className="w-full max-w-320 sm:w-320 mx-auto sm:mx-0">
          <a href="/">
            <img
              className="mx-auto h-24 rounded-lg w-auto"
              src={configuration.global.logo}
              alt="logo"
            />
          </a>

          <Typography className="mt-10 text-center text-xl font-bold leading-9 tracking-tight text-gray-900">
            {t('signUp.title', { defaultValue: 'Реєстрація' })}
          </Typography>

          <form
            name="registerForm"
            noValidate
            className="flex flex-col justify-center w-full mt-6"
            onSubmit={handleSubmit(onSubmit)}
          >
            <CampaignFormItem
              name="firstName"
              dataTestId="firstName"
              control={control}
              label={
                showBrandName
                  ? t('signUp.brandName', { defaultValue: 'Назва бренду' })
                  : t('signUp.name', { defaultValue: "Ім'я" })
              }
              errors={errors}
              size="medium"
            />

            <CampaignFormItem
              name="email"
              dataTestId="email"
              control={control}
              label={t('signUp.email', { defaultValue: 'Email' })}
              errors={errors}
              size="medium"
            />

            <CampaignFormItem
              name="password"
              type="password"
              control={control}
              label={t('signUp.password', { defaultValue: 'Пароль' })}
              errors={errors}
              size="medium"
            />

            <CampaignFormItem
              name="passwordConfirm"
              type="password"
              control={control}
              label={t('signUp.passwordConfirm', { defaultValue: 'Підтвердіть пароль' })}
              errors={errors}
              size="medium"
            />

            <div className="mt-4">
              <SignUpSwitcher control={control} />
            </div>

            <Controller
              name="acceptTermsConditions"
              control={control}
              render={({ field }) => (
                <FormControl
                  className="items-center"
                  error={!!errors.acceptTermsConditions}
                >
                  <FormControlLabel
                    label={
                      <div
                        dangerouslySetInnerHTML={{
                          __html: t('signUp.acceptTermsConditions', {
                            defaultValue: `Я згоден з <a class="font-semibold text-indigo-600 hover:text-indigo-500" href="${configuration.global.termsOfServiceUrl}" target="_blank">Умовами надання послуг</a> та <a class="font-semibold text-indigo-600 hover:text-indigo-500" href="${configuration.global.privacyPolicyUrl}" target="_blank">Політикою конфіденційності</a>`,
                          }),
                        }}
                      />
                    }
                    control={
                      <Checkbox
                        size="medium"
                        {...field}
                      />
                    }
                  />
                  <FormHelperText>{errors?.acceptTermsConditions?.message}</FormHelperText>
                </FormControl>
              )}
            />

            <Button
              variant="contained"
              color="secondary"
              className="w-full mt-4"
              aria-label="Register"
              disabled={!isValid}
              type="submit"
            >
              {t('signUp.create', { defaultValue: 'Створити акаунт' })}
            </Button>

            <div className="w-full mt-10 my-auto justify-center text-sm text-gray-500 flex items-baseline font-medium">
              {t('signUp.alreadyHaveAccount', { defaultValue: 'Вже маєте акаунт?' })}
              <Link
                className="ml-1"
                to="/sign-in"
              >
                {t('signUp.signIn', { defaultValue: 'Увійти' })}
              </Link>
            </div>
          </form>
        </div>
      </PaperStyled>
    </div>
  );
}

export default SignUpPage;
