import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Colors,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import dayjs from 'dayjs';
import { CardChart } from '../../components/CardChart';

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Colors
);

const randomNum = () => Math.floor(Math.random() * (235 - 52 + 1) + 52);
const randomRGB = () => `rgb(${randomNum()}, ${randomNum()}, ${randomNum()})`;

// const labels = Utils.months({ count: 7 });

export const FollowersCountChart = ({ data: _data }) => {
  if (!_data) {
    return null;
  }

  const labels = _data.values.map((item) => dayjs(item.endTime).format('D.MM'));
  const values = _data.values.map((item) => item.value);

  const data = {
    labels,
    options: {
      plugins: {
        colors: {
          enabled: true,
        },
      },
      tooltips: {
        enabled: true,
        mode: 'single',
        callbacks: {
          label(tooltipItems, data) {
            return `${tooltipItems.yLabel} %`;
          },
        },
      },
    },
    datasets: [
      {
        label: 'К-ть фоловерів',
        data: values,
        // backgroundColor: new Array(values.length).fill(null).map(randomRGB),
        // backgroundColor: [
        //   'rgba(255, 99, 132, 0.2)',
        //   'rgba(255, 159, 64, 0.2)',
        //   'rgba(255, 205, 86, 0.2)',
        //   'rgba(75, 192, 192, 0.2)',
        //   'rgba(54, 162, 235, 0.2)',
        //   'rgba(153, 102, 255, 0.2)',
        //   'rgba(201, 203, 207, 0.2)',
        // ],
        // borderColor: [
        //   'rgb(255, 99, 132)',
        //   'rgb(255, 159, 64)',
        //   'rgb(255, 205, 86)',
        //   'rgb(75, 192, 192)',
        //   'rgb(54, 162, 235)',
        //   'rgb(153, 102, 255)',
        //   'rgb(201, 203, 207)',
        // ],
        borderWidth: 1,
      },
    ],
  };
  return (
    <CardChart title="Кількість фоловерів за 28 днів:">
      <Bar
        datasetIdKey="id"
        data={data}
      />
    </CardChart>
  );
};
