import { createContext, ReactNode, useContext, useMemo, useState } from 'react';
import { ReportProblem } from '../report-problem';
import { CampaignViewStop } from '../campaign-view/CampaignViewStop';
import { CampaignViewPause } from '../campaign-view/CampaignViewPause';
import { CampaignViewRerun } from '../campaign-view/CampaignViewRerun';
import { messages } from '../../messages';
import { useToasts } from '../../components/Toasts';
import {
  useCampaignViewPauseMutation,
  useCampaignViewRerunMutation,
  useCampaignViewStopMutation,
} from '../campaign-view/redux/campaign-view-api';

const m = messages.campaigns;

export type CampaignOwnerContextType = {
  showStop: (campaignId: string) => void;
  showPause: (campaignId: string) => void;
  showRerun: (campaignId: string) => void;
  showReport: (campaignId: string) => void;
};
export const CampaignsOwnerControlContext = createContext<CampaignOwnerContextType>({} as any);

export type CampaignsOwnerProviderProps = {
  children: ReactNode;
};
export const CampaignsOwnerControlProvider = ({ children }: CampaignsOwnerProviderProps) => {
  const { error, success } = useToasts();
  // const { } =
  const [stop] = useCampaignViewStopMutation();
  const [pause] = useCampaignViewPauseMutation();
  const [rerun] = useCampaignViewRerunMutation();

  const [showStop, setShowStop] = useState<string | undefined>();
  const [showPause, setShowPause] = useState<string | undefined>();
  const [showRerun, setShowRerun] = useState<string | undefined>();
  const [showReport, setShowReport] = useState<string | undefined>();

  const handleStop = () => {
    stop(showStop)
      .unwrap()
      .then(() => {
        setShowStop(undefined);
        success(m.stopConfirmation.success);
      })
      .catch((err) => {
        if (err.status === 409) {
          error(m.stopConfirmation.ordersError);
        } else {
          error(messages.error);
        }
      });
  };

  const handlePause = () => {
    pause(showPause)
      .unwrap()

      .then(() => {
        setShowPause(undefined);
        success(m.pauseConfirmation.success);
      })
      .catch(() => {
        error(messages.error);
      });
  };

  const handleRerun = () => {
    rerun(showRerun)
      .unwrap()

      .then(() => {
        setShowRerun(undefined);
        success(m.rerunConfirmation.success);
      })
      .catch(() => {
        error(messages.error);
      });
  };

  const context = useMemo(
    () => ({
      showStop: setShowStop,
      showPause: setShowPause,
      showRerun: setShowRerun,
      showReport: setShowReport,
    }),
    [setShowStop, setShowPause, setShowRerun, setShowReport]
  );

  return (
    <CampaignsOwnerControlContext.Provider value={context}>
      <ReportProblem
        key={showReport?.campaignId || 1}
        open={Boolean(showReport)}
        meta={showReport}
        onClose={() => setShowReport(undefined)}
      />

      <CampaignViewStop
        open={showStop}
        onClose={() => setShowStop(undefined)}
        onSubmit={handleStop}
      />

      <CampaignViewPause
        open={showPause}
        onClose={() => setShowPause(undefined)}
        onSubmit={handlePause}
      />

      <CampaignViewRerun
        open={showRerun}
        onClose={() => setShowRerun(undefined)}
        onSubmit={handleRerun}
      />

      {children}
    </CampaignsOwnerControlContext.Provider>
  );
};

export const useCampaignsOwnerControlProvider = () => useContext(CampaignsOwnerControlContext);
