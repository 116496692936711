import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../components/Button';
import { Meta } from '../../meta/Meta';
import { TITLES } from '../../../configs/titles';
import { PanelHeader } from '../../../components/PanelHeader';
import { AppRoutes } from '../../../configs/app-routes';

export const ProfileSecurity = () => {
  const { t } = useTranslation();

  return (
    <Box
      component={Card}
      minHeight={420}
      mb="auto"
      mt={0}
      variant="outlined"
      padding={4}
      display="flex"
    >
      <Meta title={TITLES.profileSecurity()} />

      <div className="flex flex-col flex-1 md:ltr:pr-32 md:rtl:pl-32">
        <PanelHeader
          title={t('security.title', { defaultValue: 'Безпека' })}
          subtitle=""
        />

        <div className="mb-4 text-18 font-black">
          {t('security.changePassword', { defaultValue: 'Зміна паролю:' })}
        </div>
        <div className="mb-4 text-14 max-w-[500px] whitespace-pre-wrap">
          {t('security.goToChangeDescription', {
            defaultValue:
              'Щоб змінити пароль, скористайтеся опцією "Відновлення паролю".\nВам буде відправлено листа з інструкціями щодо зміни паролю.',
          })}
        </div>

        <div>
          <Button
            color="primary"
            variant="contained"
            size="large"
            component={Link}
            to={AppRoutes.forgotPassword()}
          >
            {t('security.goToChangePassword', {
              defaultValue: 'Перейти до відновлення паролю',
            })}
          </Button>
        </div>

        <Divider className="my-8" />

        <div className="mb-4 text-18 font-black">
          {t('security.changeEmail', { defaultValue: 'Зміна електронної пошти:' })}
        </div>
        <div
          className="mb-4 text-14 max-w-[500px] whitespace-pre-wrap"
          dangerouslySetInnerHTML={{
            __html: t('security.changeEmailDescription', {
              defaultValue:
                'Для зміни, надішліть листа на <a href="mailto:support@influencer.com.ua" class="link">support@influencer.com.ua</a>  \n' +
                '\n' +
                'Примітка: Щоб внести зміни, надішліть листа з пошти, котра привʼязана до вашого облікового запису в influencer.ua\n' +
                '\n' +
                '<b>Вкажіть тему листа: Зміна email 📩</b> \n' +
                '\n' +
                'Якщо ви не маєте доступу до своєї пошти, зверніться до нашого саппорту. Ми готові вам допомогти відновити доступ, якщо ви можете підтвердити свою особу через соціальні мережі або іншим методом верифікації.',
            }),
          }}
        />
      </div>
    </Box>
  );
};
