import { useSocials } from '../useSocials';
import { messages } from '../../../messages';
import { SocialView } from '../components/SocialView';
import { TiktokConnectButton } from './TiktokConnectButton';
import { SocialEntity } from '../../../types';
import { ContentText } from '../../../components/Content';
import { Number } from '../../../components/Number/Number';

const m = messages.socials;
export const SocialTiktokView = ({
  data,
  edit,
  isStyleGrid,
}: {
  data: SocialEntity;
  edit?: boolean;
  isStyleGrid: boolean;
}) => {
  const { disconnect } = useSocials();
  const { json } = data;

  return (
    <SocialView
      social={data}
      control
      isStyleGrid={isStyleGrid}
      content={
        <>
          {json?.likesCount && (
            <ContentText
              label={messages.socials.statistics.tiktok.likes}
              content={<Number amount={json?.likesCount} />}
            />
          )}

          {json?.videoCount && (
            <ContentText
              label={messages.socials.statistics.tiktok.media}
              content={<Number amount={json?.videoCount} />}
            />
          )}
        </>
      }
      controlButton={
        <TiktokConnectButton
          edit={edit}
          socialId={data?.id}
        />
      }
      onDisconnect={disconnect}
      images={false}
      imagesTitle={m.imagesTitle}
      imagesDescription={m.images.youtubeDescription}
    />
  );
};
