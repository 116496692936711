import { configuration, PaymentType, Roles } from './configs/configuration';
import { NotificationsTypes } from './modules/notifications/mapping';
import { i18n } from './i18n';

export const breadcrumbs = {
  profile: i18n.t('breadcrumbs.profile', { defaultValue: 'Мій профіль' }),
  profileSocials: i18n.t('breadcrumbs.profileSocials', { defaultValue: 'Платформи' }),
  profileSecurity: i18n.t('breadcrumbs.profileSecurity', { defaultValue: 'Безпека' }),
  profileSocialsConnectInstagram: i18n.t('breadcrumbs.profileSocialsConnectInstagram', {
    defaultValue: 'Підключити Instagram',
  }),
  profileSocialsConnectYoutube: i18n.t('breadcrumbs.profileSocialsConnectYoutube', {
    defaultValue: 'Підключити Youtube',
  }),
  profileSocialsConnectTiktok: i18n.t('breadcrumbs.profileSocialsConnectTiktok', {
    defaultValue: 'Підключити TikTok',
  }),
  profileSocialsEdit: i18n.t('breadcrumbs.profileSocialsEdit', {
    defaultValue: 'Редагувати платформу',
  }),
};

export const messages = {
  roles: {
    [Roles.BRAND]: 'Бренд',
    [Roles.USER]: 'Інфлюенсер',
  },

  error: i18n.t('common.error', {
    defaultValue: 'Упс, проблемка 🙁 спробуйте ще раз або звʼяжіться зі службою підтримки',
  }),
  createError: i18n.t('common.createError', {
    defaultValue: 'Упс, проблемка 🙁 спробуйте ще раз або звʼяжіться зі службою підтримки',
  }),

  form: {
    linkValidation: i18n.t('common.linkValidation', { defaultValue: 'Введіть коректне посилання' }),
    required: i18n.t('common.required', { defaultValue: "Поле обов'язкове для заповнення" }),
    passwordConfirm: i18n.t('common.passwordConfirm', { defaultValue: 'Паролі не співпадають' }),
    max: i18n.t('common.max', { defaultValue: 'У полі доступно максимально 255 символів' }),
  },

  profile: {
    portfolio: {
      title: 'Портфоліо',
      subtitle: 'Надайте приклади Ваших робіт або колаборацій з брендами',
      brand: 'Бренд',
      link: 'Посилання',
      add: 'Додати колаборацію',
    },
  },

  forgotPassword: {
    title: 'Відновлення паролю',
    email: 'Email',
    submit: 'Відправити',
    changePassword: 'Змінити пароль',
    success: 'Пароль успішно змінено',
    finished:
      'Вам на пошту прийде посилання на відновлення паролю протягом 5 хвилин. Перейдіть по ній і відновіть обліковий запис.',
    passwordConfirm: 'Повторіть пароль',
    password: 'Пароль',
  },

  confirmation: {
    content: 'Цю дію не можна буде відмінити',
    yes: 'Так',
    no: 'Ні',
  },

  paymentTypes: {
    [PaymentType.BARTER]: 'Бартер',
    [PaymentType.MONEY]: 'Оплата',
    [PaymentType.BARTER_MONEY]: 'Продукт та оплата',
  },

  campaignCreate: {
    name: 'Назва рекламної кампанії:',
    back: 'Усі кампанії',
    categories: 'Категорія:',
    campaignType: 'Тип кампанії:',
    publicationCount: 'Кількість завершених кампаній:',
    dateStart: 'Початок дати:',
    dateEnd: 'Дата закінчення кампанії:',
    description: 'Опис:',
    shortDescription: 'Короткий опис:',
    requirements: 'Вимоги:',
    socialTypes: 'Платформа:',
    paymentType: 'Тип співпраці:',
    paymentTypeBarter: 'Бартер',
    paymentTypeMoney: 'Оплата',
    paymentTypeBarterMoney: 'Бартер з доплатою',
    settings: 'Налаштування:',
    followersCount: 'Мінімальна кількість фоловерів:',
    questionFromBrand: 'Чи хотіли б ви навчитися чомусь у блогера?',
    yes: 'Так',
    no: 'Ні',
    termsAndConditions: `Я прочитав(-ла) наведені <a href="${configuration.global.termsOfServiceUrl}" class="text-blue-600 hover:underline" target="_blank">умови</a> та приймаю їх:`,
    termsLink: 'Умови використання:',

    createSuccess:
      'Вітаємо, кампанію успішно створено. Вас автоматично перенаправлять на сторінку кампанії.',
    editSuccess: 'Зміни успішно збережено',
    createError:
      "Під час створення виникла помилка. Спробуйте ще раз або зв'яжіться зі службою підтримки",

    defaultShowInSearch: 'Показати в стрічці для всіх:',

    createToUser: 'Ви створюєте цю пропозицію для користувача: ',
    createToUserDescription:
      'Початково ця кампанія не буде відображена у загальній стрічці для всіх користувачів.\nЩоб зробити її видимою, відзначте опцію "Показати в стрічці"',
    desc: {
      price: 'Розрахунок здійснюватиметься на основі ціни з комісією.',
      link: 'Якщо необхідно, прикріпіть посилання на товар',
      description: 'Короткий опис бренду та кампанії, яку Ви збираєтесь промоутити',
      mentions: 'Ваші платформи',

      requirementsRules: `Опис продукту чи послуги:
- Посилання на ресурси, пов'язані з продуктом (брендбук)
- Правильна вимова/написання назви продукту та бренду
- Зображення
- Вимоги до візуалу, тексту та основні тези
- Терміни виконання. Позначте дату та час публікації. Штрафи - за невиконання
`,
    },

    price: 'Бюджет:',
    link: 'Посилання на продукт:',

    notFound: {
      title: 'Такої кампанії не існує',
      link: 'На головну',
    },

    form: {
      campaignMin: '',
      baseRequired: 'Поле має бути заповненим',
      required: {
        name: 'Ви повинні ввести назву товару',
        description: 'Ви повинні ввести назву товару',
      },
    },

    productDescription: 'Опис:',
    productImages: 'Зображення:',
    productPrice: 'Ціна (грн):',
    productTitle: 'Назва:',

    requirementsMentions: 'Згадки:',
    requirementsKeywords: '#Хештеги:',
    requirementsTutorialDo: 'Tutorial_do:',
    requirementsTutorialDont: 'Tutorial_dont:',
    requirementsHashtags: 'Хештеги:',
    requirementsPublishStart: 'PublishStart:',
    requirementsPublishEnd: 'PublishEnd:',
    requirementsRules: 'Технічне завдання:',

    requirementsMoodboard: 'Мудборд:',
    requirementsNotCopyright: 'Не копірайт:',
    requirementsNotOther_brands: 'Не показувати інші продукти в рекламній публікації:',
    attachStatistic: 'Чи необхідно додати скріншоти аналітики після успішного завершення кампанії?',
    requirementsOther: 'Інше:',

    append: 'Додати',
    remove: 'Видалити',

    view: {
      mentions: 'Згадки:',
      backToCampaigns: 'Усі кампанії',
      minFollowersCount: 'Мінімальна кількість фоловерів',
      keywords: 'Ключові слова:',
      tutorialDo: 'Tutorial_do:',
      tutorialDont: 'Tutorial_dont:',
      hashtags: 'Hashtags:',
      publishStart: 'PublishStart:',
      publishEnd: 'Кінець публікації:',
      rules: 'Технічне завдання:',
      moodboard: 'Мудборд:',
      notCopyright: 'НЕ копірайт',
      notOther_brands: 'НЕ використовувати інші бренди під час публікації',
      attachStatistic: 'Прикріпіть статистику успішної публікації, після проведення кампанії',
      other: 'Інше:',
      goToCampaignsPage: 'Перейти на сторінку кампаній',
      campaignType: 'Формат реклами:',
      view: 'Ціна:',
      apply: 'Застосувати',
      edit: 'Зберегти',
      stop: 'Стоп',
      requirements: 'Вимоги до публікації',
      notFound: 'Кампанія не створена або видалена',
    },

    terms: 'Умови використання',
    newCampaign: 'Нова кампанія',
    newCampaignDetail: 'Детальна інформація про кампанію',
    baseInfo: 'Інформація про базу',
    newCampaignRequirements: 'Вимоги до блогера:',
    newCampaignRequirementsDescription: 'Детальне технічне завдання',
    image: 'Обкладинка:',
    images: 'Мудборд:',
    edit: 'Редагувати',
    save: 'Cтворити кампанію',

    types: {
      instagram_stories: 'instagram_stories',
      instagram_reels: 'instagram_reels',
      instagram_carousel: 'instagram_carousel',
      post: 'post',
      native_integration: 'native_integration',
      ambasador: 'ambasador',
      youtube_ad: 'youtube_ad',
      youtube_review: 'youtube_review',
      youtube_mention: 'youtube_mention',
      tiktok_video: 'tiktok_video',
      tiktok_sound: 'tiktok_sound',
      common_feedback: 'common_feedback',
    },
  },

  campaignTypes: {
    instagram_stories: 'Instagram сторіз',
    instagram_reels: 'Instagram reels',
    instagram_carousel: 'Instagram карусель',
    post: 'Пост',
    native_integration: 'Нативна інтеграція',
    ambasador: 'Бренд - амбасадор',
    youtube_ad: 'YouTube огляд',
    youtube_review: 'YouTube відгук',
    youtube_mention: 'YouTube згадка',
    tiktok_video: 'TikTok відео',
    tiktok_sound: 'TikTok музика',
    common_feedback: 'Відгук',
  },

  campaignsInfluencer: {
    title: 'Мої заявки',
    type: 'Тип кампанії:',
    platform: 'Платформа:',
    showFinished: 'Показати завершені:',
    showDeclined: 'Показати відхилені:',

    states: {
      processing: 'Виконується',
      pending: 'Виконується',
      finished: 'Завершено',
      campaignFinished: 'Кампанія завершена',
      rejected: 'Відмовлено',
      waitAnswer: 'Очікуйте відповідь',
      waitFeedback: 'Очікуйте фідбек',
      offerFromBlogger: 'Пропозиція від бренду',
    },

    buttons: {
      see: 'Переглянути публікацію',
      showFeedback: 'Залишити відгук бренду',
      publish: 'Зробити публікацію',
      connect: "Зв'язатися із замовником",
      problem: 'Повідомити про проблему',
    },

    notFound: {
      title: 'Кампаній не знайдено',
      linkText: 'Створити нову кампанію',
    },
  },

  reportProblem: {
    title: 'Повідомити про проблему',
    fieldLabel: 'Тип проблеми',
    descriptionLabel: 'Щось пішло не так?',
    descriptionPlaceholder: 'Опишіть ситуацію',
    email: 'Email (для зворотнього звʼязку)',
    send: 'Відправити',
    info: 'Ми аналізуємо запити протягом тижня, будь ласка перевіряйте вашу електронну пошту!',
    success: 'Дякуємо за Ваш запит! Ми звʼяжемось з Вами найближчим час!',
    error: "Під час відправлення сталася помилка. Спробуйте ще раз або зв'яжіться з підтримкою.",
  },

  publications: {
    dialogTitle: 'Публікацію успішно відправлено',
    title: 'Публікація',
    waitForReview: 'Очікуємо фідбек рекламодавця',
    orderFinished: 'Публікацію затверджено',
    orderFinishedFeedback: 'Додати відгук',
    orderFinishedDescription:
      'Ваша публікація успішно завершена! Ви можете залишити відгук про співпрацю з брендом або переглянути інші кампанії.',
    alreadyApplied: 'Заявка успішно подана',
    alreadyAppliedWait:
      'Будь ласка, зачекайте на відповідь від рекламодавця. Ви також можете ознайомитись з іншими рекламними пропозиціями.',
    send: 'Відправити',
    buttonChat: 'Повідомлення',
    returnToCampaigns: 'Усі кампанії',

    price: 'Ціна:',
    changePrice: 'Змінити ціну за публікацію',
    publicationsLink: 'Посилання на публікацію:',
    publicationsMessage: 'Повідомлення:',
    publicationsAddLink: 'Додати посилання',
    publicationStatistic: 'Будь ласка, надайте статистику вашої рекламної публікації:',

    offer: {
      title: 'Ви отримали пропозицію на проведення кампанії:',
      accept: 'Прийняти',
      decline: 'Відмовитись',

      acceptConfirmation: 'Ви впевнені, що хочете прийняти пропозицію?',
      acceptConfirmationSuccess: 'Пропозиція успішно була прийнята',

      declineConfirmation: 'Ви впевнені, що хочете відхилити пропозицію?',
      declineConfirmationSuccess: 'Пропозиція була успішно відхилена',
    },

    success: {
      title: 'Вашу публікацію успішно надіслано',
      content:
        'Зачекайте, доки бренд перегляне вашу публікацію і підтвердить її виконання або надішле правки',
      close: 'Закрити',
    },

    successStatisticAttached: {
      title: 'Вашу публікацію успішно надіслано',
      close: 'Закрити',
    },

    successStatistic: 'Статистику успішно надіслано',
  },

  feed: {
    badges: {
      barter: 'Бартер',
      money: 'Оплата',
      barterMoney: 'Продукт + оплата',
    },
  },

  smartSearch: {
    title: 'Рекомендації блогерів',
    subtitle:
      'Ви можете надіслати пропозиції щодо співпраці блогерам, які знаходяться у нашій добірці. Не забувайте про важливість взаємовигідної співпраці та відкритості до їхніх ідей та пропозицій.',
    name: "Ім'я:",
    city: 'Локація:',
    countOfCampaigns: 'Кількість угод на платформі:',
    categories: 'Категорії:',
    offer: 'Запропонувати блогеру кампанію',
    offered: 'Ви вже запропонували кампанію',
    next: 'Перейти до кампанії',

    notFound: {
      title: 'Блогеров',
      message: 'Рекомендацій не знайдено',
    },

    offerDialog: {
      title: 'Запропонувати блогеру кампанію',
      subtitle:
        'Натискаючи "Запропонувати" блогеру прийде сповіщення і він може Відмовитись від угоди або прийняти її',
      message: 'Повідомлення',
      submit: 'Запропонувати',
      success: 'Ми надіслали вашу пропозицію.',
    },
  },

  notifications: {
    title: 'Cповіщення',
    titleLong: 'Центр сповіщень',
    allNotifications: 'Переглянути всі сповіщення',
    notFound: 'Немає давніших сповіщень',
    seeProposition: 'Переглянути пропозицію',

    [NotificationsTypes.ordersCreated]: {
      title: 'Ви отримали новий запит',
      content: () => ``,
    },
  },

  campaigns: {
    title: 'Усі Кампанії',
    payment: {
      paidTitle: 'Публікація успішно оплачена',
      paidGoToCampaign: 'Перейти до кампанії',
      title: 'Оплата',
      name: 'Назва кампанії:',
      budget: 'Бюджет:',
      toPaid: 'До оплати:',
      submit: 'Оплатити',
      alreadyPaid: 'Цю кампанію вже оплачено.',
      notPermitted: 'Ви не маєте доступу до цієї кампанії',
      rules: 'Умови:',
      success: 'Оплата пройшла успішно',
      description: `Гроші повернутися автоматично до вас на рахунок якщо:
- ви не знайдете відповідного блогера
- ви видалите/скасуєте кампанію, не розпочавши роботу з блогерами`,
    },

    stopConfirmation: {
      title: 'Ви впевнені що хочете завершити кампанію?',
      yes: 'Завершити',
      no: 'Скасувати',
      success: 'Компанію успішно завершено',
      ordersError: 'Помилка! Блогер не завершив кампанію.',
      content: `Якщо ви завершити кампанію:
- Ви не можете скасувати цю дію
- Кампанія більше не буде відображатися в пошуку і ви не зможете її заново запустити
- Ви <b>не можете завершити</b> кампанію, якщо вже почали співпрацю з блогером`,
    },

    rerunConfirmation: {
      title: 'Ви впевнені що хочете повернутись до пошуку?',
      yes: 'Так',
      no: 'Ні',
      success: 'Компанію успішно повернулась до пошуку',
      content: 'Кампанія знову повернуться в пошук та блогери зможуть вам надсилати пропозиції.',
    },

    pauseConfirmation: {
      title: 'Ви впевнені, що хочете призупинити кампанію?',
      content: `Якщо ви зупиняєте кампанію:
- Публікації які в роботі = не скасовуються та будуть опубліковані згідно з вказівками у технічному завданні
- Ви можете в будь-який момент відновити пошук блогерів та продовжити роботу`,
      yes: 'Зупинити',
      no: 'Скасувати',
      success: 'Компанію успішно зупинено',
    },

    name: 'Назва рекламної кампанії:',
    categories: 'Категорія:',
    campaignType: 'Тип кампанії:',
    dateStart: 'Початок дати:',
    dateEnd: 'Дата закінчення:',
    description: 'Опис:',
    shortDescription: 'Короткий опис:',
    requirements: 'Вимоги:',
    applied: 'В роботі',
    socialTypes: 'Платформа:',
    paymentType: 'Тип співпраці:',
    paymentTypeBarter: 'Бартер',
    paymentTypeMoney: 'Оплата',
    paymentTypeBarterMoney: 'Бартер з доплатою',
    settings: 'Налаштування:',
    image: 'Зображення:',
    followersCount: 'Мінімальна кількість фоловерів:',
    questionFromBrand: 'Чи хотіли б ви навчитися чомусь у блогера?',
    yes: 'Так',
    no: 'Ні',
    termsAndConditions: `Я прочитав(-ла) наведені <a href="${configuration.global.termsOfServiceUrl}" class="text-blue-600 hover:underline" target="_blank">умови</a> та приймаю їх:`,
    termsLink: 'Умови використання:',

    createSuccess: 'Рекламу успішно створено. Через 5 секунд вас буде перенаправлено на рекламу',
    createError:
      "Під час створення виникла помилка. Спробуйте ще раз або зв'яжіться зі службою підтримки",

    desc: {
      price: 'Розрахунок здійснюватиметься на основі ціни з комісією.',
      link: 'Якщо необхідно, прикріпіть посилання на товар',
      description: 'Короткий опис бренду та кампанії, яку Ви збираєтесь промоутити',
      mentions: 'Ваші платформи',

      requirementsRules: `Опис продукту чи послуги:
- Посилання на ресурси, пов'язані з продуктом (брендбук)
- Правильна вимова/написання назви продукту та бренду
- Зображення
- Вимоги до візуалу, тексту та основні тези
- Терміни виконання. Позначте дату та час публікації. Штрафи - за невиконання
`,
    },

    price: 'Орієнтовна ціна:',
    link: 'Посилання на продукт:',

    notFound: {
      title: 'Такої акції не існує',
    },

    form: {
      required: {
        name: 'Ви повинні ввести назву товару',
        description: 'Ви повинні ввести назву товару',
      },
    },

    productDescription: 'Опис:',
    productImages: 'Зображення:',
    productPrice: 'Ціна (грн):',
    productTitle: 'Назва:',

    requirementsMentions: 'Згадки:',
    requirementsKeywords: '#Хештеги:',
    requirementsTutorialDo: 'Tutorial_do:',
    requirementsTutorialDont: 'Tutorial_dont:',
    requirementsHashtags: 'Хештеги:',
    requirementsPublishStart: 'PublishStart:',
    requirementsPublishEnd: 'PublishEnd:',
    requirementsRules: 'ТЗ:',

    requirementsMoodboard: 'Мудборд:',
    requirementsNotCopyright: 'Не копірайт:',
    requirementsNotOther_brands: 'Не показувати інші продукти в рекламній публікації:',
    requirementsOther: 'Інше:',

    append: 'Додати',
    remove: 'Видалити',

    view: {
      mentions: 'Згадки:',
      paymentType: 'Тип співпраці:',
      platform: 'Платформа:',
      categories: 'Категорії:',
      description: 'Опис бренду та кампанії:',
      link: 'Посилання на продукт:',
      price: 'Бюджет:',
      keywords: 'Ключові слова:',
      tutorialDo: 'Tutorial_do:',
      tutorialDont: 'Tutorial_dont:',
      hashtags: 'Hashtags:',
      publishStart: 'PublishStart:',
      publishEnd: 'Фінальний термін подачі заявок:',
      rules: 'Правила:',
      moodboard: 'Мудборд:',
      notCopyright: 'Не копірайт',
      notOther_brands: 'Не інші бренди:',
      other: 'Інше:',
      goToCampaignsPage: 'Перейти на сторінку кампаній',
      campaignType: 'Формат реклами:',
      isShowInSearch: 'Зробити кампанію публічною:',
      author: 'Бренд:',
      createdAt: 'Дата створення:',
      view: 'Ціна:',
      apply: 'Зробити пропозицію на проведення кампанії',
      notEnoughFollowers: 'У вас недостатня кількість фоловерів для цієї кампанії',
      edit: 'Редагувати',
      stop: 'Завершити',
      reportProblem: 'Повідомити про проблему',
      pause: 'Зупинити пошук',
      rerun: 'Відновити пошук',
      requirements: 'Вимоги до блогеру',
      notFound: 'Кампанія не створена або видалена',
      stopped: 'завершена',
      paused: 'Пошук зупинено',
    },

    terms: 'Умови використання',
    newCampaign: 'Нова кампанія',
    newCampaignDetail: 'Детальна інформація про кампанію',
    baseInfo: 'Інформація про базу',
    newCampaignRequirements: 'Вимоги до блогера:',
    newCampaignRequirementsDescription: 'Детальне технічне завдання',
    images: 'Зображення:',
    edit: 'Редагувати',
    save: 'Cтворити кампанію',

    types: {
      instagram_stories: 'instagram_stories',
      instagram_reels: 'instagram_reels',
      instagram_carousel: 'instagram_carousel',
      post: 'post',
      native_integration: 'native_integration',
      ambasador: 'ambasador',
      youtube_ad: 'youtube_ad',
      youtube_review: 'youtube_review',
      youtube_mention: 'youtube_mention',
      tiktok_video: 'tiktok_video',
      tiktok_sound: 'tiktok_sound',
      common_feedback: 'common_feedback',
    },
  },

  orderCreate: {
    price: 'Запропонуйте ціну (в гривнях):',
    title: 'Відгукнутися на кампанію:',
    accountName: 'Ваш акаунт:',
    address: 'Адреса доставки:',
    phone: 'Телефон:',
    details: 'Повідомлення бренду:',
    submit: 'Відправити',
    requirements: `Ви ознайомились з вимогами кампанії, <a class="hover:underline text-blue-600" href=${configuration.global.termsOfServiceUrl}>сервісу</a> та згодні з ними`,
    success: {
      title: 'Пропозицію успішно надіслано',
      close: 'Закрити',
      content: 'Ви отримаєте сповіщення, як тільки бренд розгляне ваш запит',
    },

    notFoundSocial: {
      title: 'У вас не підключено',
      btn: 'Підключити ',
    },
  },

  campaignOrders: {
    status: 'Статус:',
    priceFromBlogger: 'Блогер пропонує ціну:',
    date: 'Дата створення:',
    followersCount: 'Кількість фоловерів:',
    account: 'Аккаунт:',
    rating: 'Рейтинг:',
    publicationsCount: 'Кількість завершених кампаній:',
    showMessageFromBlogger: 'Показати повідомлення від блогера',
    showAddressFromBlogger: 'Показати адресу блогера',
    hideMessageFromBlogger: 'Приховати повідомлення від блогера',
    hideAddressFromBlogger: 'Приховати адресу блогера',

    showImages: 'Показати статистику від блогера',
    hideImages: 'Приховати статистику від блогера',

    publication: 'Публікація від блогера',
    publicationLink: 'Посилання на публікацію:',
    publicationsMessage: 'Повідомлення:',
    letFeedback: 'Залишити відгук',
    finished: 'Завершено',
    waitForReview: 'Очікуємо рецензію',
    waitForPublication: 'Очікуємо публікацію',
    offeredToBlogger: 'Очікуємо відповідь від блогера',
    declined: 'Відмовлено',
    bloggerAcceptOffer: 'Блогер прийняв вашу пропозицію',
    pending: 'На розгляді',

    buttonStart: 'Почати роботу',
    buttonStatistic: 'Переглянути статистику',
    buttonDecline: 'Відмовитись',
    buttonApprove: 'Завершити співпрацю',
    buttonChat: 'Повідомлення',
    buttonReport: 'Повідомити про проблему',

    title: 'Пропозиції',
    navPending: 'Очікувані запити',
    navProcessing: 'Обробка заявок',
    navAll: 'Всі заявки',
    notFoundTitle: 'Заявки наразі відсутні',
    notFoundContent: 'Очікуйте нові запити від блогерів',

    success: {
      title: 'Ви успішно завершили співпрацю',
      content: `Вітаємо! Ваша співпраця з блогером успішно завершена.\nБудь ласка, залиште свій відгук. Якщо у вас виникли проблеми з блогером - звʼяжіться з саппортом.`,
      btn: 'Закрити',
    },

    acceptSuccess: {
      title: 'Ви успішно прийняли заявку від блогера',
      content: `Блогер отримає нотифікацію про заявку. Ви можете з ним зв'язатися через чат. Якщо блогер не вийде на зв'язок протягом 2-х днів - зв'яжіться з саппортом.`,
      btn: 'Закрити',
    },

    finish: {
      title: 'Публікацію успішно прийнято',
      content: `Блоггер отримає протягом 2-х днів оплату. Тепер ви можете залишити відгук про роботу з блогером`,
      btn: 'Закрити',
    },

    finishConfirmation: {
      title: `Ви впевнені, що хочете завершити співпрацю?`,
      content: `Зауважте! Цю дію неможливо скасувати.\nРекомендуємо зачекати, поки блогер додасть аналітику до кампанії, перед тим як її завершити.`,
      yes: 'Завершити',
      no: 'Скасувати',
    },

    startWorkConfirmation: {
      title: `Ви впевнені що хочете почати роботу з цим інфлюенсером?`,
      content: `Цю дію не можна скасувати.\nВас буде перенаправлено в чат для подальшої комунікації`,
      yes: 'Розпочати роботу',
      no: 'Скасувати',
      success: 'Йохууу, Ви успішно розпочали роботу з блогером! Зачекайте на відповідь ☆',
      decline: 'Пропозиція блогера відхилена',
    },

    declineConfirmation: {
      title: `Ви впевнені, що хочете відмовитись від пропозиції?`,
      content: `Зауважте, цю дію неможливо скасувати!`,
      no: 'Скасувати',
      yes: 'Відмовитись',
      success: 'О ні, співпраця з блогером була скасована',
    },
  },

  owner: {
    title: 'Мої кампанії',
    create: 'Створити нову кампанію',
    state: {
      all: 'Всі',
      pending: 'В процесі',
      finished: 'Завершені',
    },

    platform: 'Платформа:',
    type: 'Тип кампанії:',

    notFound: {
      title: 'Кампаній не знайдено',
      linkText: 'Створити нову кампанію',
    },

    stop: {
      title: 'Ви впевнені що хочете зупинити кампанію?',
      subtitle: 'Ви зможете відновити кампанію в будь-який момент',
      stop: 'Зупинити',
      cancel: 'Скасувати',
      success: 'Кампанія успішно зупинена',
    },

    rerun: {
      title: 'Ви впевнені що хочете запустити кампанію?',
      subtitle: 'Ви зможете відновити кампанію в будь-який момент',
      yes: 'Так',
      no: 'Ні',
      success: 'Кампанія успішно запущена',
    },
  },

  chat: {
    title: 'Повідомлення',
    backMessageCampaigns: 'Усі кампанії',
    backMessage: 'Усі повідомлення',
    notFoundMessages: 'Немає нових повідомлень',
    finishedTitle: 'Вітаємо, співпрацю завершено!',
    finished:
      'Ваш доступ до чату було обмежено.\nЯкщо у вас є питання чи потреба у допомозі, будь ласка, зверніться до саппорту★',
    orderFinished: 'співпрацю завершено',
    notFound: {
      title: 'Повідомлень не знайдено',
      contentBlogger: 'Дочекайтесь підтвердження співпраці від бренду',
      contentOwner:
        'Щоб мати можливість обмінюватися повідомленнями, розпочніть кампанію та отримайте пропозиції від блогерів',
      button: 'Усі кампанії',
    },
  },

  welcome: {
    title: `Вітаємо, Ви успішно стали частиною спільноти <a href="https://influencer.com.ua">INFLUENCER.COM.UA</a>`,
    contentBlogger: `Для того щоб розпочати користуватися платформою, потрібно:
- Заповнити свій профіль та вибрати категорії.
- Підключити свої соціальні мережі.

Додаткову інформацію ви можете знайти за цим посиланням: <a target="_blank" class="link" href="https://influencer.com.ua/content/how-start-to-work/">https://influencer.com.ua/content/how-start-to-work/</a>

Дякуємо, що Ви з нами!`,
    buttonProfile: 'Перейти в особистий кабінет',
    buttonSocials: 'Додати платформи',

    contentOwner: `Щоб почати роботу, вам необхідно створити рекламну кампанію. Після створення:

- Ваша кампанія з'явиться в результатах пошуку.
- Блогери отримають сповіщення про Вашу кампанію. 
- Ви зможете переглядати статистику, зв'язатися та почати роботу з блогерами, які зацікавляться Вашою кампанією. 

Детальніше про створення кампанії за посиланням: <a target="_blank" class="link" href="https://influencer.com.ua/content/how-start-to-work-brand/">https://influencer.com.ua/content/how-start-to-work-brand/</a>`,
    buttonCampaign: 'Перейти до створення кампаній',
  },

  footer: {
    about: 'Про нас',
    termsAndConditions: 'Умови використання',
    privatePolicy: 'Політика конфіденційності',
    faq: 'FAQs',
    problem: 'Повідомити про проблему',
    feedback: 'Залишити відгук',
  },

  credits: {
    title: 'Кредити',
    definition: 'Що таке кредит?',
    definitionList: `<p>Кредит - це одиниця виміру, яка використовується на платформі для здійснення кампаній.</p><p>Ви маєте 10 кредитів - за кожну заявку на проведення кампанії ви втрачаєте 1 кредит.</p>`,
    mainTitle: 'Як отримати кредит? ',
    mainDescription:
      '<ul class="mt-2"><li>1. За кожну успішно проведену кампанію ви отримуєте +1 кредит</li>' +
      '<li>2. Ваші кредити оновлюються на початку місяця</li>' +
      '<li>3. Якщо бренд відхилив заявку на співпрацю - кредит списується </li></ul>',
    creditsLeft: 'Кредитів залишилось:',

    notEnough: 'Недостатньо кредитів',
    notEnoughDescription: 'Додаткову інформацію про кредити можна знайти на сторінці "Кредити"',
    link: 'Переглянути кредити',
  },

  balance: {
    title: 'Гаманець:',
    currentBalance: 'Ваш поточний рахунок: ',
    withdrawal: 'Вивести кошти',
    withdrawalDescription: 'Виведення здійснюється протягом 1-2 робочих днів',
    topup: 'Ввести кошти',
    withdrawalComission: 'Комісія - 5%',
    topupComission: 'Комісія - 5%',
  },

  cards: {
    title: 'Підключені карти:',
    remove: 'Видалити:',
    addCard: 'Додати карту',
    success: 'Карту успішно додано',
    error: 'Під час додавання карти виникла помилка. Спробуйте ще раз або зверніться до саппорту',
    successRemove: 'Карту успішно видалено',
    addCardDescription:
      '<b>Безпека:</b> сервіс не зберігає ваші картки і не обробляє їх. Уся робота з картками відбувається на стороні платіжного сервісу <a href="" class="link" target="_blank">назва сервісу</a>',

    table: {
      notFoundTitle: 'Підключених карток не знайдено.',
      notFoundDescription: 'Додайте картку, для того що б отримувати оплати від роботодавців',
      cardNumber: 'Номер картки',
      defaultPaymentMethod: 'Метод оплати за замовчуванням',
      remove: 'Видалити',

      setDefault: 'Вибрати за замовчуванням',
      default: 'Обрано за замовчуванням',
    },
  },

  socials: {
    toastPlatformAdded: 'Платформу успішно додано',
    toastPlatformDisabled: 'Платформу успішно вимкнено',
    toastPlatformUpdated: 'Платформу успішно оновлено',
    showStatistic: 'Переглянути статистику',
    hideStatistic: 'Приховати статистику',
    back: 'Повернутись до соціальних мереж',
    connectForbiddenError: (verificationCode: string) =>
      `В описі вашого профілю не вказано ключове слово: ${verificationCode}`,
    connectCommonError: `Такого профілю не знайдено або виникла інша помилка. Спробуйте ще раз або зв'яжіться з підтримкою`,
    connectInstagramError: `
Здається, виникла помилка. Будь ласка, перевірте наступне:
- Наявність бізнес-сторінки на Facebook
- Наявність бізнес-акаунта в Instagram
- Переконайтеся, що бізнес-сторінка на Facebook прив'язана до облікового запису в Instagram (якщо потрібно, дивіться відео вище для інструкцій)`,
    connectFollowersCountError: `У вас недостатня кількість фоловерів`,

    statistics: {
      statForMonth: 'Статистика за останні 28 днів:',
      youtube: {
        views: 'Середня кількість переглядів',
        comments: 'Середня кількість коментарів',
        likes: 'Середня кількість лайків',
        dislikes: 'Середня кількість дизлайків',
        estimatedMinutesWatched: 'Середня кількість перегляду (хвилин)',
        averageViewDuration: 'Середній час перегляду',
        verification: (id: string) =>
          `<b>Важливо:</b> Для підключення Youtube вам потрібно в кінці вашого опису додати код: <b>${id}</b>. </br>Ви можете прибрати його після підключення.`,
      },

      tiktok: {
        likes: 'Кількість лайків:',
        media: 'Кількість медіа:',
        verification: (id: string) =>
          `<b>Важливо:</b> Для підключення Тікток вам потрібно в кінці вашого опису додати код: <b>${id}</b>. </br>Ви можете прибрати його після підключення.`,
      },

      youtubeCharts: {
        followers: {
          title: 'Кількість фоловерів за день:',
          label: 'Кількість фоловерів:',
        },
        views: {
          title: 'Кількість переглядів за добу:',
          label: 'Кількість переглядів:',
        },
      },
    },

    errorMin: (count) => `Кількість фоловерів має бути більшою за ${count}`,

    connectInstagram: 'Підключити через Facebook',
    cancel: 'Скасувати',

    view: {
      account: 'Аккаунт: ',
      followersCount: 'Кількість фоловерів: ',
    },

    instagram: {
      followersCount: 'Кількість фоловерів:',
      disconnect: 'Відключити',
      connecting: 'Підключення Instagram...',
    },

    tiktok: {
      editUsername: 'Ваш юзернейм (без @):',
      username: 'Вкажіть Ваш юзернейм (без @):',
      connect: 'Підключити',
      update: 'Зберегти',
      alert:
        'Наразі TikTok працює в ручному режимі. Ми знаходимося в процесі розробки можливості підключення облікового запису TikTok до нашої платформи.',
    },

    title: 'Мої платформи',
    subTitle:
      'Для отримання персоналізованих пропозицій, ми рекомендуємо Вам підключити свої соціальні мережі. Це допоможе знайти кампанії, які відповідають Вашим інтересам',
    important:
      'Важливо: Коли Ви підключаєте свій аккаунт до платформи, ми не отримуємо доступу до Вашого паролю. Ми не можемо змінювати Ваш аккаунт, публікувати контент, ставити лайки або коментувати від Вашого імені.',

    followersCheckmark: (count) => `✔️ Мінімум ${count} фоловерів`,

    imagesTitle: '3. Прикрепіть статистику та аналітику',

    images: {
      tiktokDescription: `Прикріпіть скріни аналітики за тиждень:
- лайки/перегляди/коментарі
- скрін ЦА (країни, стать, вік)`,
      youtubeDescription: `Прикріпіть скріни аналітики за тиждень:
- час перегляду, охоплення, конверсія, клікабельність, аудиторія - географічне положення, вік, стать
    `,
      instagramDescription: `Прикріпіть скріни історій за тиждень:
- аналітики переглядів сторіз;
- «Кліки по посиланню» - показує число кліків по прикріпленому вами посиланням в Історії.
- «Відповіді» - показує, скільки користувачів написали вам в Дірект з Історії або відреагували на неї емодзі.
- «Число торкань наклейок» - якщо ви прикріпили наклейку з ім'ям користувача Інстаграм (бренду), покаже кількість натиснень користувачів. Або якщо ви, наприклад, репоснули чужу Історію, покаже число переходів на неї. У статистиці буде відображено і ім'я користувача.

Допис:
- скрін аналітики допису

Reels:
- прикріпіть аналітику ваших рілз, охоплення за останні 14 днів

Інстаграм аналітика повинна оновлюватись раз у тиждень.`,
    },

    followersCount: '2. Вкажіть кількість фоловерів:',
    videoCount: 'Кількість відео:',
    viewsCount: 'Кількість переглядів:',

    connect: 'Підключити',
    connectTitle: 'підключити',
    editTitle: 'редагувати',
    edit: 'Редагувати',
    disconnect: 'Відключити',
    update: 'Редагувати',
  },

  usersList: {
    title: 'Cписок блогерів',
    subtitle:
      'Ви можете надіслати пропозиції щодо співпраці блогерам, які знаходяться у нашій добірці',
    name: "Ім'я:",
    city: 'Локація:',
    countOfCampaigns: 'Кількість угод на платформі:',
    categories: 'Категорії:',
    offer: 'Запропонувати блогеру кампанію',
    offered: 'Ви вже запропонували кампанію',
    next: 'Перейти до кампанії',

    notFound: {
      title: 'За даним пошуковим запитом блогерів не знайдено',
      message: 'Рекомендацій не знайдено',
    },

    offerDialog: {
      title: 'Запропонувати блогеру кампанію',
      subtitle:
        'Натискаючи "Запропонувати" блогеру прийде сповіщення і він може Відмовитись від угоди або прийняти її',
      message: 'Повідомлення',
      submit: 'Запропонувати',
      success: 'Ми надіслали вашу пропозицію.',
    },
  },

  userListPropose: {
    title: 'Запропонуйте блогеру кампанію',
    notFound: 'Упс, у Вас немає відповідних кампаній для співпраці з цим блогером',
    subtitle: 'Виберіть з поточних або створіть нову',
    create: 'Cтворити нову пропозицію',
    propose: 'Запропонувати поточну',
    success: 'Ваша пропозиція блогеру успішно надіслана.',
    errorConflict: 'Ви вже працюєте з цим інфлюенсером з цієї кампанії. Виберіть іншого блогера',
  },

  charts: {
    impressions: 'Покази (за 28 днів)',
    reach: 'Охоплені акаунти (за 28 днів)',
    accounts_engaged: 'Залучені облікові записи (за 28 днів)',
    saves: 'Збереження (за 28 днів)',
    shares: 'Репости (за 28 днів)',
    likes: 'Позначки "Подобається" (за 28 днів)',
    comments: 'Коментарі (за 28 днів)',
    profile_views: 'Відвідування профілю (за 28 днів)',
  },
};
