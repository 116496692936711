import { Box } from '@mui/material';
import Card from '@mui/material/Card';
import { useMemo, useState } from 'react';
import Pagination from '@mui/material/Pagination';
import { useTranslation } from 'react-i18next';
import CampaignsInfluencerListItem from './CampaignsInfluencerListItem';
import { NotFoundPanel } from '../../components/NotFoundPanel';
import { AppRoutes } from '../../configs/app-routes';
import { useGetQuery } from './campaign-influencer-api';
import { ReportProblem } from '../report-problem';
import { OrdersState } from '../../configs/configuration';
import { OrderEntity } from '../../types';
import { FeedbackForm } from '../feedbacks';
import { Loading } from '../../components/Loading';
import { usePaginationParams } from '../../hooks/usePaginationParams';

const SORT_ORDER_BY_STATE = {
  // [OrdersState.PUBLICATION_PROGRESS]: 1,
  // [OrdersState.PENDING]: 2,
  [OrdersState.DECLINED]: 4,
  [OrdersState.FINISHED]: 3,
};

function CampaignsInfluencerList() {
  const { t } = useTranslation();
  const { handlePageChange, query } = usePaginationParams();
  const { data: { data, meta } = {}, isLoading, refetch } = useGetQuery(query);

  const totalCount = meta?.filterCount;

  const [showReport, setShowReport] = useState();
  const [feedbackOrder, setFeedbackOrder] = useState<OrderEntity>(null);

  const showPagination = totalCount > query.limit;
  const countPagination = Math.ceil(totalCount / query.limit);

  const sorted = useMemo(
    // () => orderBy(data, (order) => SORT_ORDER_BY_STATE[order.state] || 0),
    () => data,
    [data]
  );

  return (
    <>
      <ReportProblem
        key={showReport?.campaignId || 1}
        open={Boolean(showReport)}
        meta={showReport}
        onClose={() => setShowReport(undefined)}
      />

      <FeedbackForm
        open={Boolean(feedbackOrder)}
        user={feedbackOrder?.user}
        onClose={() => setFeedbackOrder(null)}
        onSubmit={() => refetch()}
        campaign={feedbackOrder?.campaign}
        order={feedbackOrder}
        isBrand
      />

      {!data || data.length === 0 ? (
        <Card
          component={Box}
          flex="1"
          display="flex"
          alignItems="center"
          minHeight={600}
          variant="outlined"
        >
          {isLoading ? (
            <Loading />
          ) : (
            <NotFoundPanel
              title={t('campaigns-influencer.title', { defaultValue: 'Кампаній не знайдено' })}
              content={t('campaigns-influencer.content', {
                defaultValue: 'Знайти кампанію',
              })}
              linkText={t('campaigns-influencer.linkText', {
                defaultValue: 'Оберіть підходящий бренд та розпочніть роботу',
              })}
              link={AppRoutes.campaigns()}
            />
          )}
        </Card>
      ) : (
        <div className="">
          {sorted.map((order) => (
            <div
              className="mb-3"
              key={order.id}
            >
              <CampaignsInfluencerListItem
                order={order}
                onClickReport={setShowReport}
                refetch={refetch}
                onClickFeedback={() => setFeedbackOrder(order)}
              />
            </div>
          ))}
        </div>
      )}

      {showPagination && (
        <Box
          my={4}
          justifyContent="center"
          display="flex"
        >
          <Pagination
            rowsPerPage={query.limit}
            onChange={handlePageChange}
            onPageChange={handlePageChange}
            count={countPagination}
            variant="outlined"
            color="primary"
          />
        </Box>
      )}
    </>
  );
}

export default CampaignsInfluencerList;
