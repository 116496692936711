import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

export const usePaginationParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const limit = Number(searchParams.get('limit')) || 15;
  const offset = Number(searchParams.get('offset')) || 0;
  const query = useMemo(
    () => ({
      limit,
      offset,
    }),
    [limit, offset]
  );

  const handlePageChange = (event: any, page: number) => {
    searchParams.set('offset', ((page - 1) * limit).toString());
    setSearchParams(searchParams);
  };

  return {
    query,
    handlePageChange,
  };
};
