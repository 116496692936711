import { Dialog, DialogProps } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { ReactNode } from 'react';
import { ImCheckmark } from 'react-icons/im';
import Typography from '@mui/material/Typography';
import {DialogClose} from "../DialogClose";

export type DialogSuccessProps = DialogProps & {
  title: ReactNode;
  content?: ReactNode;
  buttonText: ReactNode;
  onClose: () => void;
};
export const DialogSuccess = ({
  title,
  onClose,
  content,
  buttonText = 'Close',
  ...rest
}: DialogSuccessProps) => (
  <Dialog
    {...rest}
    onClose={onClose}
  >
    <div className="max-w-[420px]">
      <DialogClose onClose={onClose} />

      <div className="flex flex-shrink-0 items-center justify-between rounded-t-md border-b border-neutral-100 border-opacity-100 md:p-5 p-4">
        <div className="text-xl font-black leading-normal text-neutral-800 mr-4">
          <span dangerouslySetInnerHTML={{ __html: title }} />
        </div>
      </div>

      <div className="relative flex-auto md:p-5 p-4">
        <Box
          display="flex"
          justifyContent="center"
          mt={2}
        >
          <Box
            component={ImCheckmark}
            style={{ fontSize: 120, color: '#100d73' }}
          />
        </Box>

        {content && (
          <Typography
            variant="caption"
            component="div"
            className="mt-6 font-medium text-center whitespace-pre-wrap mx-auto"
          >
            {content}
          </Typography>
        )}
      </div>

      <div className="flex flex-shrink-0 flex-wrap items-center justify-center rounded-b-md border-t border-neutral-100 border-opacity-100 md:p-5 p-4">
        <Box minWidth={160}>
          <Button
            fullWidth
            type="button"
            variant="contained"
            color="primary"
            onClick={onClose}
          >
            {buttonText}
          </Button>
        </Box>
      </div>
    </div>
  </Dialog>
);
