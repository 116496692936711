export const messages = {
  title: 'Авторизація',
  dontHaveAccount: 'Не маєте облікового запису?',
  signUp: 'Зареєструватися',
  email: 'Email',
  password: 'Пароль',
  rememberMe: "Запам'ятати мене",
  forgotPassword: 'Забули пароль?',
  signIn: 'Увійти',
  signInAccountBlocked:
    "Ваш обліковий запис було заблоковано. Зв'яжіться із сапортом для уточнення деталей.",

  form: {
    base: 'Неправильний email або пароль',
    email: {
      valid: 'Ви повинні ввести дійсний email',
      required: 'Ви повинні ввести email',
    },
    password: {
      valid: 'Занадто короткий пароль. Пароль повинен містити від 4 символів',
      required: 'Будь ласка, введіть ваш пароль.',
    },
  },
};
