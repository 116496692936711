import { SocialTypes } from '../../configs/social-types';
import { SocialEntity } from '../../types';
import { SocialsConnectRouteLayout } from './components/SocialsConnectRouteLayout';
import { SocialsConnectRemote } from './components/SocialsConnectRemote';
import { TiktokConnectButton } from '../socials/tiktok/TiktokConnectButton';
import { SocialTiktokConnectView } from '../socials/tiktok/SocialTiktokConnectView';

type SocialsConnectRouteProps = {
  edit?: boolean;
  social?: SocialEntity;
};

export const SocialsConnectRouteTiktok = ({ edit, social }: SocialsConnectRouteProps) => {
  const socialType = (social && social.socialType) || SocialTypes.TIKTOK;

  return (
    <SocialsConnectRouteLayout>
      <SocialsConnectRemote
        edit={edit}
        social={social}
        socialType={socialType}
        ButtonComponent={TiktokConnectButton}
        InfoComponent={SocialTiktokConnectView}
        connectDescription={
          <ul className="ml-2">
            <li className="mb-2">✔️ Публічний обліковий запис</li>
            <li className="mb-2">✔️ Від 1000 підписників</li>
            <li className="mb-2">✔️ ER - показник залученості або активна аудиторія вашої сторінки становить від 1%</li>
            <li className="mb-2">✔️ Автентичність аудиторії: відсутність фальшивих підписників</li>
            <li className="mb-2">✔️ Релевантний і послідовний контент</li>
          </ul>
        }
        beforeImagesBlock={
          <div className="max-w-[600px]">
            Прикріпіть скріни аналітики за тиждень
            <ul>
              <li>&#x2022; лайки/перегляди/коментарі;</li>
              <li>&#x2022; скрін ЦА (країни, стать, вік);</li>
            </ul>
          </div>
        }
      />
    </SocialsConnectRouteLayout>
  );
};
