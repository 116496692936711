import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import * as yup from 'yup';
import Paper from '@mui/material/Paper';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { configuration } from '../../configs/configuration';
import CampaignFormItem from '../campaign-create/components/CampaignFormItem';
import { messages } from '../../messages';
import { useForgotPasswordRecovery } from './forgot-password-api';
import { useToasts } from '../../components/Toasts';
import { AppRoutes } from '../../configs/app-routes';
import { i18n } from '../../i18n';
import { SIGN_UP_PASSWORD_REGEX } from '../sign-up/SignUpPage';

const getSchema = () =>
  yup.object().shape({
    password: yup
      .string()
      .matches(
        SIGN_UP_PASSWORD_REGEX as any,
        i18n.t(
          'forgotPassword.passwordMatch',
          'Пароль повинен містити мінімум 8 символів, серед яких хоч одну цифру, спецсимвол, велику та маленьку літери'
        )
      )
      .required(i18n.t('forgotPassword.passwordRequired', 'Будь ласка, введіть ваш пароль.'))
      .min(
        8,
        i18n.t(
          'forgotPassword.passwordMin',
          'Занадто короткий пароль. Пароль повинен містити від 4 символів'
        )
      ),

    passwordConfirm: yup.string().oneOf([yup.ref('password'), null], messages.form.passwordConfirm),
  });

const defaultValues = {
  email: '',
  password: '',
};

function ForgotPasswordRecovery() {
  const schema = useMemo(getSchema, []);
  const { t } = useTranslation();
  const { control, formState, handleSubmit } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });
  const { error, success } = useToasts();
  const navigate = useNavigate();

  const [recovery] = useForgotPasswordRecovery();
  const [params] = useSearchParams();

  const { isValid, errors } = formState;

  const onSubmit = async (fd: { password: string }) => {
    await recovery({
      token: params.get('token'),
      newPassword: fd.password,
    })
      .unwrap()
      .then(() => {
        success(t('forgotPasswordRecovery.success', { defaultValue: 'Пароль успішно змінено' }));
        setTimeout(() => {
          navigate(AppRoutes.signIn());
        }, 1000);
      })
      .catch(() => error(messages.error));
  };

  return (
    <div className="flex py-10 flex-col flex-auto items-center sm:justify-center min-w-0  ">
      <Paper className="w-full rounded-0 py-4 px-2 sm:p-8 rounded-lg max-w-md">
        <div className="w-full max-w-320 sm:w-320 mx-auto sm:mx-0">
          <img
            className="mx-auto h-24 rounded-lg w-auto"
            src={configuration.global.logo}
            alt="logo"
          />

          <Typography className="mt-10 text-center text-xl font-bold leading-9 tracking-tight text-gray-900">
            {t('forgotPasswordRecovery.title', { defaultValue: 'Відновлення паролю' })}
          </Typography>

          <form
            noValidate
            className="flex flex-col justify-center w-full mt-6"
            onSubmit={handleSubmit(onSubmit)}
          >
            <CampaignFormItem
              name="password"
              type="password"
              control={control}
              label={t('forgotPasswordRecovery.password', { defaultValue: 'Пароль' })}
              errors={errors}
              size="small"
            />

            <CampaignFormItem
              name="passwordConfirm"
              type="password"
              control={control}
              label={t('forgotPasswordRecovery.passwordConfirm', {
                defaultValue: 'Повторіть пароль',
              })}
              errors={errors}
              size="small"
            />

            <Button
              variant="contained"
              color="primary"
              className="w-full mt-4"
              aria-label="Register"
              disabled={!isValid}
              type="submit"
              size="small"
            >
              {t('forgotPasswordRecovery.changePassword', { defaultValue: 'Змінити пароль' })}
            </Button>
          </form>
        </div>
      </Paper>
    </div>
  );
}

export default ForgotPasswordRecovery;
