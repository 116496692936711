import { messages } from './messages';
import { SocialTypes } from './social-types';

export const campaignTypesList = {
  instagram_stories: {
    value: 'instagram_stories',
    label: messages.campaignTypes.instagram_stories,
  },
  instagram_reels: {
    value: 'instagram_reels',
    label: messages.campaignTypes.instagram_reels,
  },
  instagram_carousel: {
    value: 'instagram_carousel',
    label: messages.campaignTypes.instagram_carousel,
  },
  post: { value: 'post', label: messages.campaignTypes.post },
  native_integration: {
    value: 'native_integration',
    label: messages.campaignTypes.native_integration,
  },
  ambasador: { value: 'ambasador', label: messages.campaignTypes.ambasador },
  youtube_ad: { value: 'youtube_ad', label: messages.campaignTypes.youtube_ad },
  youtube_review: {
    value: 'youtube_review',
    label: messages.campaignTypes.youtube_review,
  },
  youtube_mention: {
    value: 'youtube_mention',
    label: messages.campaignTypes.youtube_mention,
  },
  tiktok_video: {
    value: 'tiktok_video',
    label: messages.campaignTypes.tiktok_video,
  },
  tiktok_sound: {
    value: 'tiktok_sound',
    label: messages.campaignTypes.tiktok_sound,
  },
  common_feedback: {
    value: 'common_feedback',
    label: messages.campaignTypes.common_feedback,
  },
};

export const campaignTypesTokens = {
  [SocialTypes.INSTA]: [
    'instagram_stories',
    'instagram_reels',
    'post',
    'instagram_carousel',
    'native_integration',
    'ambasador',
  ],
  [SocialTypes.YOUTUBE]: ['youtube_ad', 'youtube_review', 'youtube_mention'],
  [SocialTypes.TIKTOK]: ['tiktok_video', 'tiktok_sound'],
  [SocialTypes.FEEDBACK]: ['post', 'common_feedback'],
};
