import Typography from '@mui/material/Typography';
import CampaignFormItem from '../../campaign-create/components/CampaignFormItem';

export const ProfileFormItem = (props) => {
  const { edit, variant } = props;

  if (props.Component) {
    return <props.Component {...props} />;
  }

  if (edit) {
    return <CampaignFormItem {...props} />;
  }

  return <ProfileFormItemView {...props} />;
};

const ProfileFormItemView = (props) => {
  const { value, label, variant } = props;

  return (
    <div className="mb-24">
      <Typography className="font-semibold mb-4 text-15">{label}:</Typography>
      {variant === 'image' ? (
        <img
          src={value}
          width={80}
          alt="label"
        />
      ) : (
        <Typography>{value}</Typography>
      )}
    </div>
  );
};
