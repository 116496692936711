import { useState } from 'react';
import { useSocials } from '../useSocials';
import { ContentText } from '../../../components/Content';
import { messages } from '../messages';
import { SocialView } from '../components/SocialView';
import { InstagramConnectButton } from './InstagramConnectButton';
import { Number } from '../../../components/Number/Number';

export const SocialInstagramView = ({ data, control, controlUpdate }) => {
  const { disconnect } = useSocials();

  const json = data?.json;
  const generalStat = json && json[0];

  return (
    <>
      <SocialView
        social={data}
        control={control}
        isStyleGrid={false}
        onDisconnect={disconnect}
        images={false}
        controlButton={<InstagramConnectButton text={messages.instagram.update} id={data?.id} />}
        content={
          <>
            <ContentText
              label={messages.mediaCount}
              content={<Number amount={generalStat?.mediaCount} />}
            />
          </>
        }
        // imagesTitle={messages.imagesTitle}
        // imagesDescription={messages.images.instagramDescription}
      />
    </>
  );
};
