import { useEffect, useMemo, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { SocialTypes } from '../../configs/social-types';
import { SocialView } from '../socials/components/SocialView';
import { ProfileViewSocialImages } from './ProfileViewSocialImages';
import { Button } from '../../components/Button';
import { ProfileViewCharts } from './ProfileViewCharts';
import { SocialEntity, UserEntity } from '../../types';
import { configuration } from '../../configs/configuration';
import { YoutubeCharts } from '../charts/youtube/YoutubeCharts';

type ProfileViewSocialProps = {
  social: SocialEntity;
  isStyleGrid: boolean;
  defaultShow: boolean;
};

const getIsShowSocial = (social: SocialEntity, isEnabledRemoteConnection: boolean) =>
  !isEnabledRemoteConnection ? !!social : true;

const ProfileViewSocial = ({
  social,
  isStyleGrid,
  defaultShow = false,
}: ProfileViewSocialProps) => {
  const { t } = useTranslation();
  const [show, setShow] = useState(defaultShow);

  return (
    <>
      <SocialView
        isStyleGrid={isStyleGrid}
        control={false}
        social={social}
        images={false}
        content={
          <div>
            {(social.images && social.images.length) || social.json ? (
              <Button
                variant="contained"
                color="primary"
                onClick={() => setShow(!show)}
              >
                {!show
                  ? t('profileView.showStatistic', { defaultValue: 'Переглянути статистику' })
                  : t('profileView.hideStatistic', { defaultValue: 'Приховати статистику' })}
              </Button>
            ) : null}
          </div>
        }
      />

      {show && (
        <>
          <ProfileViewSocialImages social={social} />
          {social.json && social.socialType === SocialTypes.INSTA && (
            <ProfileViewCharts data={social.json} />
          )}
          {social.json && social.socialType === SocialTypes.YOUTUBE && (
            <YoutubeCharts data={social.json} />
          )}
        </>
      )}
    </>
  );
};

export const ProfileViewSocials = ({ profile }: { profile: UserEntity }) => {
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();
  const showStatistic = searchParams.get('showStatistic');

  const socials = profile?.socials;

  const socialsFormatted = useMemo(() => {
    const obj = {};

    if (!socials) {
      return null;
    }

    socials.forEach((item) => {
      obj[item.socialType] = item;
    });

    return obj;
  }, [socials]);

  useEffect(() => {
    if (showStatistic) {
      document.getElementById('statistic') &&
        document.getElementById('statistic').scrollIntoView({
          behavior: 'smooth',
        });
    }
  }, [showStatistic]);

  if (!profile || isEmpty(socialsFormatted)) {
    return null;
  }

  const youtube = socialsFormatted[SocialTypes.YOUTUBE];
  const instagram = socialsFormatted[SocialTypes.INSTA];
  const tiktok = socialsFormatted[SocialTypes.TIKTOK];

  return (
    <>
      <h3
        mt={3}
        className="title-3"
        id="statistic"
      >
        {t('profileView.socialsTitle', { defaultValue: 'Соціальні мережі:' })}
      </h3>

      {instagram &&
        getIsShowSocial(instagram, configuration.ENABLED_INSTAGRAM_REMOTE_CONNECTION) && (
          <div className="mt-5 w-full">
            <ProfileViewSocial
              social={instagram}
              defaultShow={showStatistic}
              isStyleGrid={false}
            />
          </div>
        )}

      {tiktok && getIsShowSocial(tiktok, configuration.ENABLED_TIKTOK_REMOTE_CONNECTION) && (
        <div className="mt-5 w-full">
          <ProfileViewSocial
            social={tiktok}
            defaultShow={showStatistic}
            isStyleGrid={false}
          />
        </div>
      )}

      <div className="flex flex-wrap">
        {youtube && getIsShowSocial(youtube, configuration.ENABLED_YOUTUBE_REMOTE_CONNECTION) && (
          <div className="mt-5 w-full">
            <ProfileViewSocial
              social={youtube}
              defaultShow={showStatistic}
              isStyleGrid={false}
            />
          </div>
        )}
      </div>
    </>
  );
};
