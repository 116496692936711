import Box from '@mui/material/Box';
import { useSearchParams } from 'react-router-dom';
import qs from 'query-string';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import CampaignsFeedListHorizontal from './CampaignsFeedListHorizontal';
import CampaignsFeedListFilters from './CampaignsFeedFilters';
import { useCampaignFeedGetQuery } from './campaign-feed-api';
import { Meta } from '../meta/Meta';
import { TITLES } from '../../configs/titles';
import { PageHeader } from '../../components/PageContainer/PageHeader';
import { usePaginationParams } from '../../hooks/usePaginationParams';
import { CampaignsFeedQuery } from './types';

function CampaignsFeedListApp() {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const {
    query: { limit, offset },
  } = usePaginationParams();

  const values = useMemo(() => {
    const query = qs.parse(searchParams.toString()) as CampaignsFeedQuery;

    if (query.categories && !Array.isArray(query.categories)) {
      query.categories = [Number(query.categories)];
    } else if (Array.isArray(query.categories)) {
      query.categories = query.categories.map(Number);
    }

    if (!query.categories) {
      query.categories = [];
    }

    query.limit = limit;
    query.offset = offset;

    return query;
  }, [searchParams, limit, offset]);

  const { data: { data, meta } = {} } = useCampaignFeedGetQuery(values);

  return (
    <>
      <div className="mb-4">
        <PageHeader title={t('campaigns.title', 'Усі Кампанії')} />
      </div>

      <Meta title={TITLES.campaignsRoot()} />

      <div className="flex flex-wrap">
        <div className="flex-1 order-[1] mt-6 lg:mt-0">
          <Box
            flex={1}
            display="flex"
            flexWrap="wrap"
          >
            <CampaignsFeedListHorizontal
              limit={limit}
              totalCount={meta?.filterCount}
              data={data}
            />
          </Box>
        </div>

        <CampaignsFeedListFilters />
      </div>
    </>
  );
}

export default CampaignsFeedListApp;
