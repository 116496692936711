import { ContentText } from '../../../components/Content';
import { messages } from '../../../messages';
import { formatFlatData } from '../../charts/youtube/overall-analytic';
import { Number } from '../../../components/Number/Number';
import { SocialEntity } from '../../../types';

const m = messages.socials;

const CONFIG_STAT = {
  views: {
    fn: (val: number) => Math.floor(val / 90),
    Component: ({ amount }: { amount: number }) => <Number amount={amount} />,
  },
  comments: {
    fn: (val: number) => Math.floor(val / 90),
    Component: ({ amount }: { amount: number }) => <Number amount={amount} />,
  },
  likes: {
    fn: (val: number) => Math.floor(val / 90),
    Component: ({ amount }: { amount: number }) => <Number amount={amount} />,
  },
  estimatedMinutesWatched: {
    fn: (val: number) => Math.floor(val / 90),
    Component: ({ amount }: { amount: number }) => <Number amount={amount} />,
  },
  averageViewDuration: {
    fn: (val: number) => val,
    Component: ({ amount }: { amount: number }) => (
      <>
        <Number amount={amount} /> сек.
      </>
    ),
  },
};

type SocialYoutubeViewProps = {
  data: SocialEntity;
};
export const SocialYoutubeConfigView = ({ data }: SocialYoutubeViewProps) => {
  const stat = data?.json && data?.json.items[0]?.statistics;
  const json = data?.json;
  const baseStatistic = json?.baseStatistic;
  const formattedBaseStatistic = baseStatistic && Object.entries(formatFlatData(baseStatistic));

  if (!stat) {
    return null;
  }

  return (
    <>
      <ContentText
        label={m.videoCount}
        content={<Number amount={stat.videoCount} />}
      />
      <ContentText
        label={m.viewsCount}
        content={<Number amount={stat.viewCount} />}
      />

      {false && formattedBaseStatistic.map(([key, value]) => {
        const config = CONFIG_STAT[key];

        if (!config) {
          return null;
        }

        const { Component } = config;

        return (
          <ContentText
            key={key}
            label={`${m.statistics.youtube[key]}:`}
            content={<Component amount={config.fn(value)} />}
          />
        );
      })}
    </>
  );
};
