import { Button, Divider, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import sortBy from 'lodash/sortBy';
import { useMemo } from 'react';
import {
  ContentCardBody,
  ContentCardRight,
  ContentCategories,
  ContentField,
  ContentText,
} from '../../components/Content';
import { messages } from '../../messages';
import { UserPanel } from '../../components/UserPanel';
import { AppRoutes } from '../../configs/app-routes';
import { SmartSearchSocial } from '../smart-search/SmartSearchInstagram';
import { CanView } from '../../components/CanView';
import { Roles } from '../../configs/configuration';
import { SocialTypes } from '../../configs/social-types';

const m = messages.smartSearch;

const sortValues = {
  [SocialTypes.YOUTUBE]: 1,
  [SocialTypes.TIKTOK]: 0,
  [SocialTypes.INSTA]: -1,
};

export const UserItem = ({ user, onClickOffer }) => {
  if (!user) {
    return null;
  }

  const { socials } = user;
  const sortedSocials = useMemo(
    () => sortBy(socials, (social) => sortValues[social.socialType] || 0),
    [socials]
  );

  return (
    <>
      <Divider />
      <div
        className="flex flex-1 flex-wrap mt-5 mb-4 px-6"
        key={user.id}
      >
        <UserPanel
          size="small"
          user={user}
          showUsername={false}
        />

        <ContentCardBody className="flex flex-wrap flex-1">
          <div className="flex-1">
            <div className="mb-2">
              <Typography
                variant="h6"
                component={RouterLink}
                to={AppRoutes.profileView(user.uuid)}
                className="hover:underline"
              >
                {user.firstName} {user.lastName}
              </Typography>
            </div>

            {user.city && (
              <ContentText
                label={m.city}
                content={user.city}
              />
            )}

            <ContentText
              label={m.countOfCampaigns}
              content={user.completedCampaignsCount || 0}
            />

            {sortedSocials.map((item) => (
              <SmartSearchSocial
                key={item.id}
                socialType={item.socialType}
                data={item}
              />
            ))}

            {user.categories && (
              <ContentCategories categories={user?.categories?.map(({ category }) => category)} />
            )}
          </div>

          <CanView role={Roles.BRAND}>
            <ContentCardRight>
              <ContentField>
                <Button
                  onClick={() => onClickOffer()}
                  fullWidth
                  variant="contained"
                  color="primary"
                >
                  {m.offer}
                </Button>
              </ContentField>
            </ContentCardRight>
          </CanView>
        </ContentCardBody>
      </div>
    </>
  );
};
