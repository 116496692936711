import { Bar } from 'react-chartjs-2';
import dayjs from 'dayjs';
import { ChartDataItem } from '../types';
import { CardChart } from '../../../components/CardChart';

// ChartJS.register(
//   ArcElement,
//   Tooltip,
//   Legend,
//   CategoryScale,
//   LinearScale,
//   PointElement,
//   LineElement,
//   BarElement,
//   Colors
// );

type BarChartProps = {
  data: ChartDataItem[];
  title: string;
  datasetLabel: string;
  dateFormat?: string;
};
export const BarChart = ({ data, title, datasetLabel, dateFormat = 'D.MM' }: BarChartProps) => {
  if (!data) {
    return null;
  }

  const labels = data.map((item) => dayjs(item.label).format('D.MM'));
  const values = data.map((item) => item.value);

  const config = {
    labels,
    options: {
      plugins: {
        colors: {
          enabled: true,
        },
      },
      tooltips: {
        enabled: true,
        mode: 'single',
        callbacks: {
          label(tooltipItems, data) {
            return `${tooltipItems.yLabel}`;
          },
        },
      },
    },
    datasets: [
      {
        label: datasetLabel,
        data: values,
        // backgroundColor: new Array(values.length).fill(null).map(randomRGB),
        // backgroundColor: [
        //   'rgba(255, 99, 132, 0.2)',
        //   'rgba(255, 159, 64, 0.2)',
        //   'rgba(255, 205, 86, 0.2)',
        //   'rgba(75, 192, 192, 0.2)',
        //   'rgba(54, 162, 235, 0.2)',
        //   'rgba(153, 102, 255, 0.2)',
        //   'rgba(201, 203, 207, 0.2)',
        // ],
        // borderColor: [
        //   'rgb(255, 99, 132)',
        //   'rgb(255, 159, 64)',
        //   'rgb(255, 205, 86)',
        //   'rgb(75, 192, 192)',
        //   'rgb(54, 162, 235)',
        //   'rgb(153, 102, 255)',
        //   'rgb(201, 203, 207)',
        // ],
        borderWidth: 1,
      },
    ],
  };
  return (
    <CardChart
      title={title}
      className="mt-6"
    >
      <Bar
        datasetIdKey="id"
        data={config}
      />
    </CardChart>
  );
};
