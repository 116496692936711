import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../utils/axiosBaseQuery';

export type ChatContactsUnreadResponse = {
  data: Array<number>;
};

export const chatApi = createApi({
  reducerPath: 'chat',
  tagTypes: ['ChatMessages', 'ChatUnreadMessages'],
  baseQuery: axiosBaseQuery({ baseUrl: '/api' }),
  endpoints: (builder) => ({
    contacts: builder.query({
      query: () => {
        return {
          url: `/messages/contacts`,
        };
      },
    }),

    contactsUnread: builder.query<ChatContactsUnreadResponse, void>({
      query: () => ({
        url: `/messages/contacts/unread`,
      }),
      providesTags: ['ChatUnreadMessages'],
    }),

    messages: builder.query({
      query: ({ id }) => ({
        url: `/messages/${id}`,
      }),
      providesTags: ['ChatMessages'],
    }),

    send: builder.mutation({
      query: (dto) => ({
        url: `/messages`,
        method: 'POST',
        body: dto,
      }),
      invalidatesTags: ['ChatMessages'],
    }),

    chatRead: builder.mutation({
      query: (orderId: string) => ({
        url: `/messages/${orderId}/read`,
        method: 'POST',
      }),
      invalidatesTags: ['ChatUnreadMessages'],
    }),
  }),
});

export const useChatContactsQuery = chatApi.endpoints.contacts.useQuery;
export const useChatMessagesQuery = chatApi.endpoints.messages.useQuery;
export const useChatSendMutation = chatApi.endpoints.send.useMutation;

export const { useContactsUnreadQuery, useChatReadMutation } = chatApi;
