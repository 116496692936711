import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../utils/axiosBaseQuery';

export type ReportProblemDto = {
  email: string;
  type: string;
  description: string;
  meta: Object;
  scope?: string;
};

export const reportProblemApi = createApi({
  reducerPath: 'reportPost',
  baseQuery: axiosBaseQuery({
    baseUrl: '/reports',
  }),
  tagTypes: ['reportPost'],
  endpoints: (build) => ({
    createReport: build.mutation({
      query: (dto: ReportProblemDto) => ({
        url: ``,
        method: 'POST',
        body: dto,
      }),

      invalidatesTags: ['reportPost'],
    }),
  }),
});

export const { useCreateReportMutation } = reportProblemApi;
