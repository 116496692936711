import { useFormContext } from 'react-hook-form';
import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { ReactNode } from 'react';
import CampaignImages from '../../campaign-create/components/CampaignImages';
import { SocialEntity } from '../../../types';
import { configuration } from '../../../configs/configuration';

export const socialsImagesGetDefaultImageValues = (images: any[]) => {
  return (
    images &&
    images.map((item) => ({
      ...item.directusFilesId,
      innerId: item.id,
      url: configuration.s3.bucket + item.directusFilesId.filenameDisk,
    }))
  );
};

type SocialsImagesProps = {
  name: string;
  description?: ReactNode;
  title?: string;
  social: SocialEntity;
  rowOrient?: boolean;
  beforeImagesBlock?: ReactNode;
};
export const SocialsImages = ({
  name,
  description,
  title,
  rowOrient,
  beforeImagesBlock,
}: SocialsImagesProps) => {
  const methods = useFormContext();
  const images = methods.watch(name) || [];
  const colOrient = !rowOrient;

  const handleRemove = (fileToRemove) => {
    if (fileToRemove) {
      const arr = images.filter((img) => img.id !== fileToRemove.id);
      methods.setValue(name, arr, { shouldDirty: true });
    }
  };

  const handleUpload = (images) => {
    // update({ id: social.id, dto: createImagesDto(social, images) });
  };

  return (
    <>
      {title && (
        <Box
          mt={1}
          mb={1}
        >
          {title}
        </Box>
      )}

      <div className={colOrient ? 'md:flex' : ''}>
        {beforeImagesBlock}

        <Typography
          dangerouslySetInnerHTML={{ __html: description }}
          whiteSpace="pre-wrap"
          variant="body2"
          color="grey"
          component="div"
          maxWidth={400}
          fontStyle={colOrient ? 'italic' : ''}
        />

        <div className={`${colOrient ? 'md:ml-4 md:mt-0' : ''}  mt-4 `}>
          <CampaignImages
            onUpload={handleUpload}
            onRemove={handleRemove}
            useId
            name={name}
          />
        </div>
      </div>
    </>
  );
};
