import { Button, Divider, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  ContentCardBody,
  ContentCardRight,
  ContentCategories,
  ContentField,
  ContentText,
} from '../../components/Content';
import { UserPanel } from '../../components/UserPanel';
import { SmartSearchSocial } from './SmartSearchInstagram';
import { AppRoutes } from '../../configs/app-routes';
import { UserEntity } from '../../types';
import { SocialTypes } from '../../configs/social-types';

export type SmartSearchItemProps = {
  user: UserEntity;
  onClickOffer: (id: number) => void;
  socialType: SocialTypes;
  selected: boolean;
};
export const SmartSearchItem = ({
  user,
  onClickOffer,
  socialType,
  selected,
}: SmartSearchItemProps) => {
  const { t } = useTranslation();

  console.log("user", user);

  if (!user) {
    return null;
  }

  const social = user.socials.find((item) => item.socialType === socialType);

  if (!social?.socialType || !socialType) {
    return null;
  }

  return (
    <>
      <Divider />

      <Box
        className="flex flex-1 lg:flex-row flex-col mt-5 mb-4 px-6"
        key={user.id}
      >
        <UserPanel
          size="small"
          user={user}
          showUsername={false}
        />

        <ContentCardBody className="flex flex-1 lg:flex-row flex-col">
          <div className="flex-1">
            <div className="mb-2">
              <Typography
                variant="h6"
                component={RouterLink}
                to={AppRoutes.profileView(user.uuid)}
                className="hover:underline"
              >
                {user.firstName} {user.lastName}
              </Typography>
            </div>

            {user.city && (
              <ContentText
                label={t('smartSearch.city', { defaultValue: 'Локація:' })}
                content={user.city}
              />
            )}

            <ContentText
              label={t('smartSearch.countOfCampaigns', {
                defaultValue: 'Кількість угод на платформі:',
              })}
              content={user.completedCampaignsCount || 0}
            />

            {user.socials &&
              user.socials.map((userSocial) => (
                <div
                  className="mt-2"
                  key={userSocial.socialType}
                >
                  <SmartSearchSocial
                    socialType={userSocial.socialType}
                    data={userSocial}
                  />
                </div>
              ))}

            {user.categories && (
              <ContentCategories categories={user?.categories?.map(({ category }) => category)} />
            )}
          </div>

          <ContentCardRight>
            <ContentField>
              {!selected ? (
                <Button
                  onClick={() => onClickOffer(user.id)}
                  fullWidth
                  variant="outlined"
                  color="primary"
                >
                  {t('smartSearch.select', { defaultValue: 'Вибрати блогера' })}
                </Button>
              ) : (
                <Button
                  onClick={() => onClickOffer(user.id)}
                  fullWidth
                  variant="contained"
                  color="primary"
                >
                  {t('smartSearch.unselect', { defaultValue: 'Відминити вибір' })}
                </Button>
              )}
            </ContentField>
          </ContentCardRight>
        </ContentCardBody>
      </Box>
    </>
  );
};
