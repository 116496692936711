import { useState } from 'react';
import { Gallery, Item } from 'react-photoswipe-gallery';
import { configuration } from '../../configs/configuration';
import { SocialEntity } from '../../types';

export const ProfileViewSocialImages = ({ social }: { social: SocialEntity }) => {
  const [imageSizes, setImageSizes] = useState({});

  const setImageSize = (image: string, { width, height }: { width: number; height: number }) => {
    setImageSizes({ ...imageSizes, [image]: { width, height } });
  };

  return (
    <div className="grid gap-4 grid-cols-2 mt-4">
      <Gallery>
        {social.images &&
          social.images.map(({ directusFilesId }) =>
            directusFilesId ? (
              <Item
                key={directusFilesId.filenameDisk}
                original={configuration.s3.bucket + directusFilesId.filenameDisk}
                width={imageSizes[directusFilesId] && imageSizes[directusFilesId].width}
                height={imageSizes[directusFilesId] && imageSizes[directusFilesId].height}
              >
                {({ ref, open }) => (
                  <div className="rounded-lg mr-3 p-3 mb-3 border-gray-300 border cursor-pointer w-full md:min-w-[200px]">
                    <img
                      alt="img"
                      src={configuration.s3.bucket + directusFilesId.filenameDisk}
                      onLoad={(img) => {
                        setImageSize(directusFilesId, {
                          width: img.target.naturalWidth,
                          height: img.target.naturalHeight,
                        });
                      }}
                      ref={ref}
                      onClick={open}
                      className="w-full"
                    />
                  </div>
                )}
              </Item>
            ) : null
          )}
      </Gallery>
    </div>
  );
};
