import { Box, Typography } from '@mui/material';
import styled from '@emotion/styled';
import Divider from '@mui/material/Divider';

export const ProfileCardEditStyled = styled.div`
  position: absolute;
  top: 12px;
  right: 12px;
`;

export const ProfileCard = ({ children, title, subtitle }) => {
  return (
    <div className="mb-6">
      {title && (
        <>
          <Typography className="text-20 truncate font-semibold">{title}</Typography>
          <Box maxWidth={460}>
            <Typography
              variant="caption"
              className="font-medium"
            >
              {subtitle}
            </Typography>
          </Box>

          <Box
            mt={3}
            mb={3}
          >
            <Divider />
          </Box>
        </>
      )}

      <Box maxWidth={700}>{children}</Box>
    </div>
  );
};

export const ProfileInfoFormTitle = (props) => (
  <Box mb={2}>
    <Typography
      {...props}
      variant="subtitle1"
    />
  </Box>
);

export const ProfileInfoFormLabel = (props) => (
  <Box mb={1}>
    <Typography
      {...props}
      variant="subtitle2"
    />
  </Box>
);
export const ProfileInfoFormSubTitle = (props) => (
  <Box
    mt={-1}
    mb={1}
  >
    <Typography
      {...props}
      color="grey"
      variant="caption"
    />
  </Box>
);
export const ProfileInfoFormField = (props) => (
  <Box
    {...props}
    my={2}
  />
);
