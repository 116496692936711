import axios, { AxiosError, AxiosRequestConfig } from 'axios';

interface AxiosBaseQuery extends AxiosRequestConfig {
  body?: any;
}

export const axiosBaseQueryWithMeta =
  ({ baseUrl } = { baseUrl: '' }) =>
  async ({ url, method, data: requestData, body, ...rest }: AxiosBaseQuery) => {
    try {
      const request = await axios({
        url: baseUrl + url,
        data: body || requestData,
        method,
        ...rest,
      });

      const { data } = request;

      return { data: { ...data, request } };
    } catch (axiosError) {
      const { response, message } = axiosError as AxiosError;

      return {
        error: {
          status: response?.status,
          data: response?.data || message,
        },
      };
    }
  };
