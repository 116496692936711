import { Divider, Link, Typography } from '@mui/material';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import { useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Card from '@mui/material/Card';
import Chip from '@mui/material/Chip';
import { useTranslation } from 'react-i18next';
import {
  ContentCardBody,
  ContentCardRight,
  ContentField,
  ContentText,
} from '../../components/Content';
import { configuration, OrdersState } from '../../configs/configuration';
import { DialogSuccess } from '../../components/DialogSuccess';
import { messages } from '../../messages';
import { UserPanel } from '../../components/UserPanel';
import { AppRoutes } from '../../configs/app-routes';
import {
  useCampaignOrdersAcceptMutation,
  useCampaignOrdersDeclineMutation,
  useCampaignOrdersFinishMutation,
} from './campaign-orders-api';
import { SocialLink } from '../../components/SocialLink';
import { Money } from '../../components/Money';
import { Button } from '../../components/Button';
import { OrderFinishConfirmation } from './OrderFinishConfirmation';
import { OrderStartWorkConfirmation } from './OrderStartWorkConfirmation';
import { convertToInternationalCurrencySystem } from '../../utils/convertToInternationalCurrencySystem';
import { OrderDeclineConfirmation } from './OrderDeclineConfirmation';
import { useToasts } from '../../components/Toasts';
import { OrderItemImages } from './OrderItemImages';
import { ReportProblem } from '../report-problem';
import { Date } from '../../components/Date';

const m = messages.campaignOrders;

const badgeConfigByState = {
  [OrdersState.FINISHED]: {
    content: m.finished,
    variant: 'primary',
  },
  [OrdersState.PUBLICATION_WAIT_REVIEW]: {
    content: m.waitForReview,
    variant: 'secondary',
  },
  [OrdersState.PENDING]: {
    content: m.pending,
    variant: 'primary',
  },
  [OrdersState.PUBLICATION_PROGRESS]: {
    content: m.waitForPublication,
    variant: 'primary',
  },
  [OrdersState.OFFERED_TO_BLOGGER]: {
    content: m.offeredToBlogger,
    variant: 'primary',
  },
  [OrdersState.DECLINED]: {
    content: m.declined,
    variant: 'default',
  },
};

const ORDER_ITEM_ACCEPT_NAVIGATE_TIMEOUT = 1000;

export const OrderItem = (props) => {
  const { order, onClickFeedback, onFinish } = props;
  const [accept] = useCampaignOrdersAcceptMutation(order.id);
  const [decline] = useCampaignOrdersDeclineMutation(order.id);
  const [finish] = useCampaignOrdersFinishMutation(order.id);
  const { t } = useTranslation();
  const { success, error } = useToasts();
  const navigate = useNavigate();
  const { blogger, campaign } = order;
  const [showSuccess, setShowSuccess] = useState(false);
  const [showFinishDialog, setShowFinishDialog] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [showImages, setShowImages] = useState(false);
  const [showAddress, setShowAddress] = useState(false);
  const [showFinishConfirmation, setShowFinishConfirmation] = useState(false);
  const [showStartWorkConfirmation, setShowStartWorkConfirmation] = useState(false);
  const [showDeclineWorkConfirmation, setShowDeclineWorkConfirmation] = useState(false);
  const [showReport, setShowReport] = useState<{ campaignId: string } | undefined>(false);

  const { state } = order;

  const finished = state === OrdersState.FINISHED;
  const showStartWorkControl = state === OrdersState.PENDING;
  const showWaitPublication = state === OrdersState.PUBLICATION_PROGRESS;
  const showFinish =
    state === OrdersState.PUBLICATION_WAIT_REVIEW || state === OrdersState.PUBLICATION_PROGRESS;
  const showPublicationDetails = showFinish || finished;
  const showRateControl = finished && !order.hasBrandFeedback;

  const badgeConfig = badgeConfigByState[state];
  let badgeContent = badgeConfig && badgeConfig.content;
  const badgeVariant = badgeConfig && badgeConfig.variant;

  const socialType = campaign?.socialTypes[0].socialType.title;
  const social = blogger?.socials.find((item) => item.socialType === socialType);

  const proposeToBlogger = Boolean(order.proposeToBlogger);

  if (proposeToBlogger && state === OrdersState.PENDING) {
    badgeContent = t('order.bloggerAcceptOffer', {
      defaultValue: 'Блогер прийняв вашу пропозицію',
    });
  }

  if (!blogger) {
    return null;
  }

  const handleAccept = () => {
    return accept(order.id)
      .unwrap()
      .then(() => {
        success(
          t('order.startWorkConfirmationSuccess', {
            defaultValue:
              'Йохууу, Ви успішно розпочали роботу з блогером! Зачекайте на відповідь ☆',
          })
        );
        setShowStartWorkConfirmation(false);
        setTimeout(() => {
          navigate(AppRoutes.chat(order.id));
        }, ORDER_ITEM_ACCEPT_NAVIGATE_TIMEOUT);
      })
      .catch(() => error(messages.error));
    // navigate(Routes.acceptOrderPayment(order.id));
  };

  const handleDecline = () => {
    decline(order.id)
      .unwrap()
      .then(() => {
        success(
          t('order.declineConfirmationSuccess', {
            defaultValue: 'О ні, співпраця з блогером була скасована',
          })
        );
        setShowDeclineWorkConfirmation(false);
      })
      .catch(() => error(messages.error));
  };

  const handleFinish = () => {
    finish(order.id).then(() => {
      setShowFinishConfirmation(false);
      setShowFinishDialog(true);
      onFinish();
    });
  };

  const showChatButton =
    order.state === OrdersState.PUBLICATION_WAIT_REVIEW ||
    order.state === OrdersState.PUBLICATION_PROGRESS;

  const showReportButton =
    order.state === OrdersState.PUBLICATION_PROGRESS ||
    order.state === OrdersState.PUBLICATION_WAIT_REVIEW ||
    order.state === OrdersState.FINISHED;

  return (
    <>
      <DialogSuccess
        open={showSuccess}
        onClose={() => setShowSuccess(false)}
        title={t('order.acceptSuccessTitle', {
          defaultValue: 'Ви успішно прийняли заявку від блогера',
        })}
        content={t('order.acceptSuccessContent', {
          defaultValue: `Блогер отримає нотифікацію про заявку. Ви можете з ним зв'язатися через чат. Якщо блогер не вийде на зв'язок протягом 2-х днів - зв'яжіться з саппортом.`,
        })}
        buttonText={t('order.acceptSuccessBtn', { defaultValue: 'Закрити' })}
      />

      <DialogSuccess
        open={showFinishDialog}
        onClose={() => {
          setShowFinishDialog(false);
          onClickFeedback();
        }}
        title={t('order.successTitle', { defaultValue: 'Ви успішно завершили співпрацю' })}
        content={t('order.successContent', {
          defaultValue: `Вітаємо! Ваша співпраця з блогером успішно завершена.\nБудь ласка, залиште свій відгук. Якщо у вас виникли проблеми з блогером - звʼяжіться з саппортом.`,
        })}
        buttonText={t('order.successBtn', { defaultValue: 'Закрити' })}
      />

      <ReportProblem
        open={Boolean(showReport)}
        meta={showReport}
        onClose={() => setShowReport(undefined)}
      />

      <OrderFinishConfirmation
        open={showFinishConfirmation}
        onClose={() => setShowFinishConfirmation(false)}
        onSubmit={handleFinish}
      />

      <OrderStartWorkConfirmation
        open={showStartWorkConfirmation}
        onClose={() => setShowStartWorkConfirmation(false)}
        onSubmit={handleAccept}
      />

      <OrderDeclineConfirmation
        open={showDeclineWorkConfirmation}
        onClose={() => {
          setShowDeclineWorkConfirmation(false);
        }}
        onSubmit={handleDecline}
      />

      <Divider />

      <div
        className="flex flex-1 mt-6 mb-4 flex-wrap"
        key={order.id}
      >
        <UserPanel
          size="small"
          user={blogger}
        />

        <ContentCardBody className="flex flex-1">
          <div className="flex-1">
            <div className="flex flex-1">
              {configuration.ENABLED_SHOW_USERNAME && (
                <ContentText
                  boldLabel
                  label={t('order.account', { defaultValue: 'Аккаунт:' })}
                  content={<SocialLink social={social} />}
                />
              )}
            </div>

            <ContentText
              boldLabel
              label={t('order.status', { defaultValue: 'Статус:' })}
              content={
                <Chip
                  variant="filled"
                  color={badgeVariant as any}
                  overlap="circular"
                  label={badgeContent}
                  style={{ whiteSpace: 'nowrap' }}
                />
              }
            />

            {Boolean(order.price) && (
              <ContentText
                boldLabel
                label={t('order.priceFromBlogger', { defaultValue: 'Блогер пропонує ціну:' })}
                content={<Money amount={order?.price} />}
              />
            )}

            {social && (
              <ContentText
                boldLabel
                label={t('order.followersCount', { defaultValue: 'Кількість фоловерів:' })}
                content={convertToInternationalCurrencySystem(social.followersCount)}
              />
            )}

            <ContentText
              boldLabel
              label={t('order.publicationsCount', {
                defaultValue: 'Кількість завершених кампаній:',
              })}
              content={blogger?.completedCampaignsCount || 0}
            />

            {order.details && (
              <ContentField>
                <Link onClick={() => setShowDetails(!showDetails)}>
                  {!showDetails
                    ? t('order.showMessageFromBlogger', {
                        defaultValue: 'Показати повідомлення від блогера',
                      })
                    : t('order.hideMessageFromBlogger', {
                        defaultValue: 'Приховати повідомлення від блогера',
                      })}
                </Link>
                {showDetails && (
                  <Card
                    variant="outlined"
                    className="p-4"
                  >
                    <Typography>{order?.details}</Typography>
                  </Card>
                )}
              </ContentField>
            )}

            {order.images && (
              <ContentField>
                <Link onClick={() => setShowImages(!showImages)}>
                  {!showImages
                    ? t('order.showImages', { defaultValue: 'Показати статистику від блогера' })
                    : t('order.hideImages', { defaultValue: 'Приховати статистику від блогера' })}
                </Link>
                {showImages && (
                  <Card
                    variant="outlined"
                    className="p-4 flex flex-wrap"
                  >
                    <OrderItemImages images={order?.images} />
                  </Card>
                )}
              </ContentField>
            )}

            {order.address && (
              <ContentField>
                <Link onClick={() => setShowAddress(!showAddress)}>
                  {!showAddress
                    ? t('order.showAddressFromBlogger', { defaultValue: 'Показати адресу блогера' })
                    : t('order.hideAddressFromBlogger', {
                        defaultValue: 'Приховати адресу блогера',
                      })}
                </Link>
                {showAddress && (
                  <Card
                    variant="outlined"
                    className="p-4"
                  >
                    <Typography>{order?.address}</Typography>
                  </Card>
                )}
              </ContentField>
            )}

            {showPublicationDetails && (
              <Box maxWidth={600}>
                <Accordion variant="outlined">
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography
                      variant="subtitle2"
                      fontWeight="bold"
                    >
                      {t('order.publication', { defaultValue: 'Публікація від блогера' })}
                    </Typography>
                  </AccordionSummary>

                  <AccordionDetails>
                    <ContentText
                      label={t('order.publicationLink', {
                        defaultValue: 'Посилання на публікацію:',
                      })}
                      content={
                        <>
                          {order.publicationLinks?.map((link) => (
                            <div key={link.value}>
                              -{' '}
                              <a
                                className="link"
                                key={link.value}
                                href={link.value}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {link.value}
                              </a>
                            </div>
                          ))}
                        </>
                      }
                    />
                    <ContentText
                      label={t('order.publicationsMessage', {
                        defaultValue: 'Повідомлення:',
                      })}
                      content={order.publicationsMessage}
                    />
                  </AccordionDetails>
                </Accordion>
              </Box>
            )}

            {Boolean(order.createdAt) && (
              <ContentText
                label={t('order.date', { defaultValue: 'Дата створення:' })}
                content={
                  <Date
                    date={order?.createdAt}
                    format="dateTime"
                  />
                }
              />
            )}
          </div>
        </ContentCardBody>

        <ContentCardRight className="empty:hidden ml-0 md:ml-6 mt-3 md:mt-0 w-full md:w-[200px]">
          {!finished && (
            <ContentField>
              <Button
                component={RouterLink}
                to={AppRoutes.profileViewStatistic(blogger.uuid)}
                fullWidth
                variant="contained"
                color="primary"
                gaTrigger="show_statistic_button"
              >
                {t('order.buttonStatistic', { defaultValue: 'Переглянути статистику' })}
              </Button>
            </ContentField>
          )}

          {showStartWorkControl && (
            <>
              <ContentField>
                <Button
                  onClick={() => setShowStartWorkConfirmation(true)}
                  fullWidth
                  variant="contained"
                  color="primary"
                >
                  {t('order.buttonStart', { defaultValue: 'Почати роботу' })}
                </Button>
              </ContentField>

              <ContentField>
                <Button
                  fullWidth
                  variant="outlined"
                  color="error"
                  onClick={() => {
                    setShowDeclineWorkConfirmation(true);
                  }}
                >
                  {t('order.buttonDecline', { defaultValue: 'Відмовитись' })}
                </Button>
              </ContentField>
            </>
          )}

          {showRateControl && (
            <ContentField>
              <Button
                style={{ whiteSpace: 'nowrap' }}
                onClick={() => onClickFeedback()}
                fullWidth
                variant="contained"
                color="primary"
              >
                {t('order.letFeedback', { defaultValue: 'Залишити відгук' })}
              </Button>
            </ContentField>
          )}

          {showFinish && (
            <ContentField>
              <Button
                onClick={() => setShowFinishConfirmation(true)}
                fullWidth
                variant="contained"
                color="primary"
              >
                {t('order.buttonApprove', { defaultValue: 'Завершити співпрацю' })}
              </Button>
            </ContentField>
          )}

          {showChatButton && (
            <ContentField>
              <Button
                fullWidth
                variant="outlined"
                color="warning"
                component={RouterLink}
                to={AppRoutes.chat(order.id)}
              >
                {t('order.buttonChat', { defaultValue: 'Повідомлення' })}
              </Button>
            </ContentField>
          )}

          {showReportButton && (
            <ContentField>
              <Button
                onClick={() =>
                  setShowReport({
                    campaignId: campaign.id,
                  })
                }
                variant="outlined"
                color="primary"
                fullWidth
              >
                {t('order.buttonReport', { defaultValue: 'Повідомити про проблему' })}
              </Button>
            </ContentField>
          )}
        </ContentCardRight>
      </div>
    </>
  );
};
