import { useState } from 'react';
import { FormProvider, useForm, Controller } from 'react-hook-form';
import { ImCheckmark } from 'react-icons/im';
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useCreateMutation } from './user-quiz-api';
import { Button } from '../../components/Button';
import { useToasts } from '../../components/Toasts';
import { Rates } from '../../components/Rates';
import { messages as m } from './messages';
import { USER_QUIZ_CLOSE_DIALOG_TIME } from './constants';

export type UserQuizDto = {
  ratePlatform: null | number;
  rateInterface: null | number;
  message: string;
  type: string;
};

type UserQuizProps = {
  open: boolean;
  onClose: () => void;
};

export const UserQuiz = ({ open, onClose }: UserQuizProps) => {
  const { error } = useToasts();
  const [post] = useCreateMutation();

  const [showSuccess, setShowSuccess] = useState(false);

  const form = useForm<UserQuizDto>({
    defaultValues: {
      ratePlatform: null,
      rateInterface: null,
      message: '',
      type: 'user_quiz',
    },
  });

  const submit = (fd: UserQuizDto) => {
    return post(fd)
      .unwrap()
      .then(() => {
        setShowSuccess(true);
        setTimeout(() => {
          onClose();
        }, USER_QUIZ_CLOSE_DIALOG_TIME);
      })
      .catch(() => {
        error(m.errorMessage);
      });
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
    >
      <FormProvider {...form}>
        <form
          className="max-w-[400px]"
          onSubmit={form.handleSubmit(submit)}
        >
          {showSuccess && (
            <div className="absolute top-0 left-0 right-0 bottom-0 bg-white z-10 flex items-center text-center flex-col justify-center">
              <ImCheckmark className="text-[120px] text-green-600" />
              <p className="mt-4 text-lg font-black whitespace-pre-wrap">{m.successMessage}</p>
            </div>
          )}
          <IconButton
            aria-label="close"
            onClick={onClose}
            className="absolute right-2 top-2 text-neutral-400 z-[11]"
          >
            <CloseIcon />
          </IconButton>
          <DialogTitle className="text-xl font-black pb-[8px] mr-4">{m.title}</DialogTitle>
          <DialogContent>
            <p className="mt-3">{m.questions.platform}</p>

            <div className="mt-3">
              <Rates name="ratePlatform" />
            </div>
            <p className="mt-3">{m.questions.interface}</p>
            <div className="mt-3">
              <Rates name="rateInterface" />
            </div>
            <div className="mt-3">
              <label
                htmlFor="message"
                className="block mb-2"
              >
                {m.questions.additional}
              </label>

              <Controller
                control={form.control}
                name="message"
                rules={{
                  required: true,
                }}
                render={({ field: { onChange } }) => (
                  <textarea
                    id="message"
                    onChange={onChange}
                    placeholder={m.questions.placeholder}
                    rows={4}
                    required
                    className="textarea"
                  />
                )}
              />
            </div>
          </DialogContent>
          <DialogActions className="justify-start px-6 pt-0 pb-4">
            <Button
              variant="contained"
              color="secondary"
              type="submit"
            >
              {m.sendButton}
            </Button>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  );
};
