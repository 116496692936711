import Box from '@mui/material/Box';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { Link, Outlet } from 'react-router-dom';
import { ReactNode, useMemo } from 'react';
import Button from '@mui/material/Button';
import { YoutubeConnectButton } from './youtube/YoutubeConnectButton';
import { InstagramConnectButton } from './instagram/InstagramConnectButton';
import { SocialTypes, socialTypesImages } from '../../configs/social-types';
import { useProfile } from '../profile/useProfile';
import { SocialAdd } from './components/SocialAdd';
import { messages } from '../../messages';
import { SocialYoutubeView } from './youtube/SocialYoutubeView';
import { SocialInstagramView } from './instagram/SocialInstagramView';
import { Meta } from '../meta/Meta';
import { TITLES } from '../../configs/titles';
import { CanView } from '../../components/CanView';
import { Roles } from '../../configs/configuration';
import { AppRoutes } from '../../configs/app-routes';
import { TiktokConnectButton } from './tiktok/TiktokConnectButton';
import { SocialTiktokView } from './tiktok/SocialTiktokView';
import { PanelHeader } from '../../components/PanelHeader';

const m = messages.socials;

const SocialWrapper = ({ children }: { children: ReactNode }) => (
  <Box mt={3}>
    {children}

    <Box my={3}>
      <Divider />
    </Box>
  </Box>
);

export const Socials = () => {
  const { profile } = useProfile();

  const socials = profile?.socials;

  const socialsFormatted = useMemo(() => {
    const obj = {};

    if (!socials) {
      return null;
    }

    socials.forEach((item) => {
      obj[item.socialType] = item;
    });

    return obj;
  }, [socials]);

  if (!profile || !socialsFormatted) {
    return null;
  }

  const youtube = socialsFormatted[SocialTypes.YOUTUBE];
  const instagram = socialsFormatted[SocialTypes.INSTA];
  const tiktok = socialsFormatted[SocialTypes.TIKTOK];

  return (
    <>
      <Meta title={TITLES.profileSocials()} />

      <Box
        component={Card}
        p={4}
        variant="outlined"
      >
        <Outlet />

        <PanelHeader
          title={m.title}
          subtitle={m.subTitle}
          hideDivider
        />
        {/* <div className="title-2 mb-2">{m.title}</div> */}
        {/* <Typography */}
        {/*  className="max-w-[700px]" */}
        {/*  variant="subtitle2" */}
        {/* > */}
        {/*  {m.subTitle} */}
        {/* </Typography> */}

        <Typography
          mt={1}
          variant="body2"
          color="grey"
          maxWidth={400}
          fontStyle="italic"
        >
          <ErrorOutlineIcon color="warning" /> {m.important}
        </Typography>

        {instagram && (
          <SocialWrapper>
            <SocialInstagramView
              title="Instagram"
              control
              data={instagram}
            />
          </SocialWrapper>
        )}

        {tiktok && (
          <SocialWrapper>
            <SocialTiktokView
              data={tiktok}
              isStyleGrid={false}
              title="TikTok"
              edit
              // control
              // data={tiktok}
            />
          </SocialWrapper>
        )}

        {youtube && (
          <SocialWrapper>
            <SocialYoutubeView
              title="YouTube"
              data={youtube}
              isStyleGrid={false}
            />
          </SocialWrapper>
        )}

        {!instagram && (
          <SocialAdd
            title="Instagram"
            image={socialTypesImages[SocialTypes.INSTA]}
            connectButton={<InstagramConnectButton />}
            body={
              <>
                <Typography variant="body2">{m.followersCheckmark(1000)}</Typography>
              </>
            }
          />
        )}

        {!tiktok && (
          <SocialAdd
            title="TikTok"
            edit
            image={socialTypesImages[SocialTypes.TIKTOK]}
            connectButton={<TiktokConnectButton text={messages.socials.connect} />}
            body={
              <>
                <Typography variant="body2">{m.followersCheckmark(1000)}</Typography>
              </>
            }
          />
        )}

        {!youtube && (
          <SocialAdd
            title="YouTube"
            image={socialTypesImages[SocialTypes.YOUTUBE]}
            connectButton={<YoutubeConnectButton />}
            body={
              <>
                <Typography variant="body2">{m.followersCheckmark(1000)}</Typography>
              </>
            }
          />
        )}

        <CanView role={Roles.USER}>
          <Button
            component={Link}
            to={AppRoutes.profileView(profile.uuid)}
            fullWidth
            type="button"
            variant="outlined"
            color="secondary"
            size="medium"
            className="w-[230px] mt-8"
          >
            Переглянути Ваш профайл
          </Button>
        </CanView>
      </Box>
    </>
  );
};
