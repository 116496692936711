import { ReactNode } from 'react';

type PageHeaderProps = {
  title: ReactNode;
  right?: ReactNode;
};
export const PageHeader = ({ title, right }: PageHeaderProps) => (
  <div className="flex w-full">
    <h3 className="title-2">{title}</h3>

    {right && <div className="hidden md:block ml-auto">{right}</div>}
  </div>
);
