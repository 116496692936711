import { ReactNode } from 'react';

type RateItemProps = {
  children: ReactNode;
  onClick: () => void;
  active?: boolean;
};
export const RateItem = ({ active, onClick, children }: RateItemProps) => (
  <button
    type="button"
    onClick={onClick}
    className={`${
      active ? 'bg-amber-400' : ''
    } flex h-12 w-16 cursor-pointer items-center justify-center rounded-md bg-amber-200 text-xl font-bold hover:bg-amber-400`}
  >
    {children}
  </button>
);
