import clsx from 'clsx';
import { memo } from 'react';
import Hidden from '@mui/material/Hidden';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import { LogoInverted } from './Logo';
import NavbarToggleButton from './NavbarToggleButton';
import Navigation from './Navigation';
import { configuration, Roles } from '../../configs/configuration';
import { useUser } from '../../modules/common/useUser';

const Root = styled.div(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  color: theme.palette.text.primary,
  // background: '#f8f8f8',
  // backgroundImage: 'linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 30%) 100%)',
  // boxShadow: '1px 0 0 #fff, 0 2px 64px 0 rgb(10 16 45 / 6%)',
}));

const StyledContent = styled.div(({ theme }) => ({
  overscrollBehavior: 'contain',
  overflowX: 'hidden',
  overflowY: 'auto',
  WebkitOverflowScrolling: 'touch',
  backgroundRepeat: 'no-repeat',
  backgroundSize: '100% 40px, 100% 10px',
  backgroundAttachment: 'local, scroll',
}));

function NavbarStyle1Content(props) {
  const { role } = useUser();

  return (
    <Root className={clsx('flex flex-auto flex-col overflow-hidden h-full', props.className)}>
      <div className="flex flex-row items-center shrink-0 h-12 md:h-16 px-2">
        <div className="flex flex-1 mx-2">
          <div className="w-10">
            <Link
              to={
                role === Roles.USER
                  ? configuration.global.rootForBlogger
                  : configuration.global.rootForOwner
              }
            >
              <LogoInverted />
            </Link>
          </div>
        </div>

        <Hidden lgUp>
          <NavbarToggleButton className="w-10 h-10 p-0" />
        </Hidden>
      </div>

      <StyledContent
        className="flex flex-1 flex-col min-h-0"
        option={{ suppressScrollX: true, wheelPropagation: false }}
      >
        <Navigation layout="vertical" />
      </StyledContent>
    </Root>
  );
}

export default memo(NavbarStyle1Content);
