export class Referral {
  async init() {
    const urlParams = new URLSearchParams(window.location.search);
    const referralId = urlParams.get('i_referral_id');
    const currentReferral = this.getReferral();

    if (!currentReferral && referralId) {
      this.setReferral(referralId);
    }
  }

  getReferral() {
    return localStorage.getItem('i_referral_id');
  }

  setReferral(value) {
    return localStorage.setItem('i_referral_id', value);
  }

  setUserId(userId) {
    return localStorage.setItem('i_referral_user_id', userId);
  }

  getUserId() {
    return localStorage.getItem('i_referral_user_id');
  }

  async emit(event) {
    const referralId = this.getReferral();
    const userId = this.getUserId();

    if (referralId) {
      await fetch('https://api.influencer.com.ua/api/referral/emit', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ event, referralId, userId }),
      });
    }
  }
}

export const referral = new Referral();
