import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { DialogConfirm } from '../../components/DialogConfirm';

type OrderFinishConfirmationProps = {
  onClose: () => void;
  onSubmit: () => void;
};
export const OrderFinishConfirmation = (props: OrderFinishConfirmationProps) => {
  const { t } = useTranslation();
  return (
    <DialogConfirm
      title={t('order.finishTitle', {
        defaultValue: `Ви впевнені, що хочете завершити співпрацю?`,
      })}
      iconHide
      maxWidth={520}
      content={t('order.finishContent', {
        defaultValue: `Зауважте! Цю дію неможливо скасувати.\nРекомендуємо зачекати, поки блогер додасть аналітику до кампанії, перед тим як її завершити.`,
      })}
      {...props}
      buttons={
        <>
          <Box minWidth={140}>
            <Button
              fullWidth
              type="button"
              variant="outlined"
              color="primary"
              onClick={props.onClose}
            >
              {t('order.finishNo', { defaultValue: 'Завершити' })}
            </Button>
          </Box>

          <Box
            minWidth={140}
            ml={2}
          >
            <Button
              fullWidth
              type="button"
              variant="contained"
              color="primary"
              onClick={props.onSubmit}
            >
              {t('order.finishYes', { defaultValue: 'Скасувати' })}
            </Button>
          </Box>
        </>
      }
    />
  );
};
