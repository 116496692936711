import Chip from '@mui/material/Chip';

import { messages } from '../../configs/messages';
import { CampaignViewControl } from '../campaign-view/CampaignViewControl';
import { CampaignCard } from '../../components/CampaignCard/CampaignCard';
import { CampaignEntity, CampaignState } from '../../types';

const badgeConfigByState = {
  [CampaignState.finished]: {
    content: messages.finished,
  },
  [CampaignState.stopped]: {
    content: messages.stopped,
  },
  [CampaignState.processing]: {
    content: messages.processing,
  },
  [CampaignState.pending]: {
    content: messages.pending,
  },
  [CampaignState.paused]: {
    content: messages.paused,
  },
};
const badgeColorByState = {
  [CampaignState.finished]: 'success',
  [CampaignState.stopped]: 'warning',
  [CampaignState.processing]: 'primary',
  [CampaignState.pending]: 'primary',
  [CampaignState.paused]: 'secondary',
};

export type CampaignsOwnerItemProps = {
  campaign: CampaignEntity;
};

function CampaignsOwnerItem({ campaign }: CampaignsOwnerItemProps) {
  const { state } = campaign;

  const badgeVariant = badgeColorByState[state];
  const badgeConfig = badgeConfigByState[state] || badgeConfigByState.pending;
  const badgeContent = badgeConfig && badgeConfig.content;

  const finishedState =
    campaign.state === CampaignState.FINISHED ||
    campaign.state === CampaignState.STOPPED ||
    campaign.state === CampaignState.PAUSED;

  return (
    <CampaignCard
      isLink
      finished={finishedState}
      campaign={campaign}
      badges={
        badgeContent && (
          <div className="ml-auto text-center">
            <Chip
              color={badgeVariant}
              overlap="circular"
              label={badgeContent}
              style={{ whiteSpace: 'nowrap' }}
            />
          </div>
        )
      }
      right={<CampaignViewControl campaign={campaign} />}
    />
  );
}

export default CampaignsOwnerItem;
