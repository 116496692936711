import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { Controller } from 'react-hook-form';
import Autocomplete from '@mui/material/Autocomplete';
import FormControlLabel from '@mui/material/FormControlLabel';
import styled from '@emotion/styled';
import isNil from 'lodash/isNil';
import get from 'lodash/get';
import { Box } from '@mui/system';
import Slider from '@mui/material/Slider';
import { CampaignFormDescription, CampaignFormSubtitle } from '../CampaignStyles';
import FileImage from '../../../components/FileImage/FileImage';
import { Checkbox } from '../../../components/Checkbox/Checkbox';
import { Switcher } from '../../../components/Switcher/Switcher';
import { LiveEditor } from '../../../components/LiveEditor/LiveEditor';

/**
 * Form Validation Schema
 */

export const CampaignFormItemContainer = styled.div<{ orient?: 'horizontal' }>`
  display: flex;
  flex: 1;
  flex-direction: column;

  select {
    line-height: 1.3;
  }

  ${({ orient }) =>
    orient === 'horizontal'
      ? `
        @media(min-width: 800px) {
            flex-direction: row;
        
            > label, > div:first-of-type {
              min-width: 300px;
              max-width: 300px;
              margin-right: 20px;
              margin-top: 16px;
            }
        }
        
        @media (max-width: 800px) {
            > label, > div:first-of-type {
            }
        }
`
      : ``}
`;

function CampaignFormItem(props): JSX.Element {
  const {
    name,
    control,
    errors,
    label,
    variant = 'textfield',
    placeholder,
    orient,
    value,
    description,
    list,
    required,
    props: _props = {},
    FieldComponent,
    AfterInputComponent,
    ...rest
  } = props;
  const error = errors && get(errors, name);
  const errorText = error && error.message;

  if (variant === 'textfield' || variant === 'textarea' || variant === 'date') {
    return (
      <CampaignFormItemContainer orient={orient}>
        {FieldComponent ? (
          <>
            <CampaignFormSubtitle
              required={required}
              htmlFor={name}
            >
              {label}
            </CampaignFormSubtitle>
            <FieldComponent
              id={name}
              {...rest}
              {..._props}
            />
          </>
        ) : (
          <Controller
            name={name}
            control={control}
            required={required}
            render={({ field, formState }) => (
              <>
                <Box>
                  <CampaignFormSubtitle
                    required={required}
                    htmlFor={name}
                  >
                    {label}
                  </CampaignFormSubtitle>

                  {description && (
                    <CampaignFormDescription dangerouslySetInnerHTML={{ __html: description }} />
                  )}
                </Box>

                <TextField
                  className={`mt-2 mb-4 ${variant === 'date' && 'max-w-[400px]'}`}
                  placeholder={placeholder}
                  error={error}
                  helperText={errorText}
                  type={variant === 'date' && 'date'}
                  id={name}
                  variant="outlined"
                  fullWidth
                  multiline={variant === 'textarea'}
                  rows={variant === 'textarea' ? 6 : 0}
                  inputProps={{
                    lang: 'uk',
                  }}
                  {...field}
                  {...rest}
                  {..._props}
                />
              </>
            )}
          />
        )}
      </CampaignFormItemContainer>
    );
  }

  if (variant === 'checkbox') {
    return (
      <CampaignFormItemContainer orient={orient}>
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <div>
              <FormControlLabel
                className=""
                control={
                  <Checkbox
                    {...field}
                    error={!!error}
                    placeholder={placeholder}
                    label={label}
                    id={name}
                    value={value}
                    checked={(field.value || []).includes(value)}
                    onChange={(e) => {
                      const { value } = e.target;
                      const arr = (Array.isArray(field.value) ? field.value : [field.value]).filter(
                        (val) => !isNil(val)
                      );

                      const set = new Set(arr);
                      if (set.has(value)) {
                        set.delete(value);
                      } else {
                        set.add(value);
                      }

                      console.log("SET", Array.from(set));

                      field.onChange(Array.from(set));
                    }}
                    {..._props}
                  />
                }
                label={label}
              />
            </div>
          )}
        />
      </CampaignFormItemContainer>
    );
  }

  if (variant === 'date') {
    return (
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => (
          <CampaignFormItemContainer orient={orient}>
            <CampaignFormSubtitle
              required={required}
              htmlFor={name}
            >
              {label}
            </CampaignFormSubtitle>
            <TextField
              id={name}
              inputProps={{
                lang: 'uk',
              }}
              type="date"
              value={value}
              onChange={(value) => {
                onChange(value.target.value);
              }}
              className="mt-2 mb-4 w-full"
              {..._props}
            />
          </CampaignFormItemContainer>
        )}
      />
    );
  }

  if (variant === 'autocomplete') {
    return (
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value, ...rest } }) => (
          <Autocomplete
            className="mt-2 mb-4"
            multiple
            options={list}
            value={value || []}
            filterSelectedOptions
            onChange={(event, newValue, _) => {
              if (_props.limitTags) {
                if (newValue.length > _props.limitTags) {
                  return;
                }
              }

              onChange(newValue);
            }}
            {..._props}
            limitTags={3}
            renderInput={(params) => (
              <CampaignFormItemContainer orient={orient}>
                <Box>
                  <CampaignFormSubtitle
                    required={required}
                    htmlFor={name}
                  >
                    {label}
                  </CampaignFormSubtitle>

                  {description && <CampaignFormDescription>{description}</CampaignFormDescription>}
                </Box>

                <TextField
                  placeholder={placeholder}
                  className="mt-2"
                  {...params}
                  {...rest}
                  id={name}
                  name={name}
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </CampaignFormItemContainer>
            )}
          />
        )}
      />
    );
  }

  if (variant === 'select') {
    return (
      <Controller
        name={name}
        control={control}
        defaultValue={[]}
        render={({ field: { onChange, value } }) => (
          <CampaignFormItemContainer orient={orient}>
            <Box>
              <CampaignFormSubtitle
                required={required}
                htmlFor={name}
              >
                {label}
              </CampaignFormSubtitle>

              {description && (
                <CampaignFormDescription dangerouslySetInnerHTML={{ __html: description }} />
              )}
            </Box>
            <Select
              className="mt-2 mb-4"
              placeholder={placeholder}
              error={!!error}
              helperText={errorText}
              id={name}
              variant="outlined"
              fullWidth
              onChange={(value, ...rest) => {
                onChange(value);
              }}
              value={value}
              {...rest}
              {..._props}
              native
              MenuProps={{
                PopoverClasses: {
                  PaperProps: {
                    variant: 'outlined',
                    square: true,
                  },
                },
              }}
            >
              {/* eslint-disable-next-line no-shadow */}
              {_props.items.map(({ label, value }) => (
                <option
                  key={value}
                  value={value}
                >
                  {label}
                </option>
              ))}
            </Select>
          </CampaignFormItemContainer>
        )}
      />
    );
  }

  if (variant === 'image') {
    return (
      <Controller
        name={name}
        control={control}
        render={() => (
          <CampaignFormItemContainer orient={orient}>
            <CampaignFormSubtitle
              required={required}
              htmlFor={name}
            >
              {label}
            </CampaignFormSubtitle>
            <FileImage
              className="mt-2"
              error={!!error}
              name={name}
              helperText={errorText}
              id={name}
              {...rest}
              {..._props}
            />
          </CampaignFormItemContainer>
        )}
      />
    );
  }

  if (variant === 'select') {
    return (
      <Controller
        name={name}
        control={control}
        defaultValue={[]}
        render={({ field: { onChange, value } }) => (
          <CampaignFormItemContainer orient={orient}>
            <CampaignFormSubtitle
              required={required}
              htmlFor={name}
            >
              {label}
            </CampaignFormSubtitle>
            <Select
              className="mt-2 mb-4"
              placeholder={placeholder}
              error={!!error}
              helperText={errorText}
              id={name}
              variant="outlined"
              fullWidth
              onChange={onChange}
              value={value}
              {...rest}
              {..._props}
            >
              {/* eslint-disable-next-line no-shadow */}
              {_props.items.map(({ label, value }) => (
                <MenuItem
                  key={value}
                  value={value}
                >
                  {label}
                </MenuItem>
              ))}
            </Select>
          </CampaignFormItemContainer>
        )}
      />
    );
  }

  if (variant === 'switcher') {
    return (
      <CampaignFormItemContainer
        className="mt-2 mb-4"
        orient={orient}
      >
        <CampaignFormSubtitle
          required={required}
          htmlFor={name}
        >
          {label}
        </CampaignFormSubtitle>
        <Switcher
          className="mt-2 mb-4"
          id={name}
          name={name}
          {...rest}
          {..._props}
        />
      </CampaignFormItemContainer>
    );
  }

  if (variant === 'range') {
    return (
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <CampaignFormItemContainer orient={orient}>
            <CampaignFormSubtitle
              required={required}
              htmlFor={name}
            >
              {label}
            </CampaignFormSubtitle>

            <Box
              component="span"
              mt={2}
              display="flex"
              alignItems="center"
              lineHeight="40px"
              flex={1}
              width="100%"
            >
              <Slider
                fullWidth
                id={name}
                name={name}
                value={field.value || 0}
                {...field}
                {...rest}
                {..._props}
              />
            </Box>
          </CampaignFormItemContainer>
        )}
      />
    );
  }

  if (variant === 'livetext') {
    return (
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <CampaignFormItemContainer orient={orient}>
            <CampaignFormSubtitle
              required={required}
              htmlFor={name}
            >
              {label}
            </CampaignFormSubtitle>

            <Box
              component="span"
              mt={2}
              display="flex"
              alignItems="center"
              lineHeight="40px"
              flex={1}
              width="100%"
            >
              <LiveEditor />
            </Box>
          </CampaignFormItemContainer>
        )}
      />
    );
  }
}

export default CampaignFormItem;
