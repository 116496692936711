import { MouseEventHandler, useMemo } from 'react';
import {
  Box,
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { PaymentEntity } from '../../payments-api';
import { PaymentsEmpty } from '../PaymentsEmpty';
import { Money } from '../../../../components/Money';
import { Date } from '../../../../components/Date';
import { getSelectedIds, getUserName } from './utils';
import { messages as m } from '../../messages';

export type PaymentsTableProps = {
  rows: PaymentEntity[];
  hasCheckbox?: boolean;
  onSelect?: (e: MouseEventHandler, id: number) => void;
  selectedRows?: PaymentEntity[];
  emptyMessage: string;
};

export function PaymentsTable({
  rows,
  selectedRows,
  onSelect,
  hasCheckbox = false,
  emptyMessage,
}: PaymentsTableProps) {
  const selectedIds = useMemo(() => new Set(getSelectedIds(selectedRows)), [selectedRows]);

  if (!rows || rows.length === 0) return <PaymentsEmpty message={emptyMessage} />;

  const handleSelect: PaymentsTableProps['onSelect'] = (e, id) => onSelect && onSelect(e, id);

  return (
    <Box className="my-6">
      <TableContainer component={Paper}>
        <Table
          className="min-w-[750px]"
          aria-labelledby="tableTitle"
          size="medium"
        >
          <TableHead>
            <TableRow>
              <TableCell
                padding="checkbox"
                className="pl-6"
              />
              <TableCell
                key="campaignName"
                className="w-[40%]"
              >
                {m.companyName}
              </TableCell>
              <TableCell
                key="userName"
                className="w-[25%]"
              >
                {m.userName}
              </TableCell>
              <TableCell
                key="createdDate"
                align="right"
                className="w-[15%]"
              >
                {m.publicationDate}
              </TableCell>
              <TableCell
                className="pr-6 w-[15%]"
                key="amount"
                align="right"
              >
                {m.cost}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => {
              const isItemSelected = selectedIds.has(row.id);
              const labelId = `table-checkbox-${row.id}`;
              const checkboxProps = {
                onClick: (e: MouseEventHandler) => handleSelect && handleSelect(e, row.id),
                role: 'checkbox',
                'aria-checked': isItemSelected,
                selected: isItemSelected,
                sx: { cursor: 'pointer' },
                tabIndex: -1,
              };

              return (
                <TableRow
                  hover
                  key={row.id}
                  {...(hasCheckbox ? checkboxProps : {})}
                >
                  <TableCell className="pl-6">
                    {hasCheckbox && (
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        inputProps={{
                          'aria-labelledby': labelId,
                        }}
                      />
                    )}
                  </TableCell>
                  <TableCell key={row.campaign.id}>{row.campaign.name}</TableCell>
                  <TableCell key={row.user.id}>{getUserName(row)}</TableCell>
                  <TableCell
                    key={row.dateCreated}
                    align="right"
                  >
                    <Date date={row.dateCreated} />
                  </TableCell>
                  <TableCell
                    className="pr-6"
                    key={row.amount}
                    align="right"
                  >
                    <Money amount={row.amount} />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
