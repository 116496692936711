import styled from '@emotion/styled';
import { useFormContext } from 'react-hook-form';
import { FormHelperText } from '@mui/material';
import { useMemo } from 'react';
import { ProfileInfoCheckbox } from './ProfileInfoCheckbox';
import { profileConfigFields } from './fields';
import { categoriesForBlogger, categoriesForBrand } from '../../configs/categories-config';
import { useUser } from '../common/useUser';
import { Roles } from '../../configs/configuration';

const Root = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export type ProfileInterestCheckboxesProps = {
  grid?: boolean;
};
export const ProfileInterestCheckboxes = ({ grid }: ProfileInterestCheckboxesProps) => {
  const {
    formState: { errors },
  } = useFormContext();
  const { role } = useUser();
  const error = errors[profileConfigFields.categories];
  const categoriesList = role === Roles.BRAND ? categoriesForBrand : categoriesForBlogger;
  const categoriesListFormatted = useMemo(
    () =>
      categoriesList.map((category) => ({
        ...category,
        value: category.strapiId,
      })),
    [categoriesList]
  );

  return (
    <Root>
      <Root className={grid ? 'grid grid-cols-3' : ''}>
        {categoriesListFormatted.map((item) => (
          <ProfileInfoCheckbox
            readonly
            {...item}
            key={item.value}
            name={profileConfigFields.categories}
          />
        ))}
      </Root>

      {error && (
        <FormHelperText
          className="-mt-1 block w-full"
          error
        >
          {error.message}
        </FormHelperText>
      )}
    </Root>
  );
};
