import { useEffect, useState } from 'react';
import { configuration } from '../../configs/configuration';
import { ReportProblem } from '../../modules/report-problem';
import { UserQuiz } from '../../modules/user-quiz';
import { useUserQuiz } from '../../modules/user-quiz/useUserQuiz';
import { i18n } from '../../i18n';

const links = [
  {
    href: configuration.global.about,
    target: '',
    title: i18n.t('footer.about', { defaultValue: 'Про нас' }),
  },
  {
    href: configuration.global.termsOfServiceUrl,
    target: '_blank',
    title: i18n.t('footer.termsAndConditions', { defaultValue: 'Умови використання' }),
  },
  {
    href: configuration.global.privacyPolicyUrl,
    target: '_blank',
    title: i18n.t('footer.privatePolicy', { defaultValue: 'Політика конфіденційності' }),
  },
  {
    href: configuration.global.faq,
    target: '',
    title: i18n.t('footer.faq', { defaultValue: 'FAQs' }),
  },
];

export const Footer = () => {
  const [showQuiz, setShowQuiz] = useState<boolean>(false);

  const { autoShowFeedbackForm, hide } = useUserQuiz();

  const [showProblem, setShowProblem] = useState(false);

  useEffect(() => {
    if (autoShowFeedbackForm) {
      setShowQuiz(true);
    }
  }, [autoShowFeedbackForm]);

  return (
    <div
      className="bg-white"
      style={{ borderTop: '2px solid #e2e8f0' }}
    >
      <ReportProblem
        open={showProblem}
        onClose={() => {
          setShowProblem(false);
        }}
      />

      <UserQuiz
        open={showQuiz}
        onClose={() => {
          setShowQuiz(false);
          hide();
        }}
      />

      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="flex flex-col items-start justify-between gap-y-12 pb-6 pt-6 lg:flex-row lg:items-center lg:py-6">
          <div className="md:flex items-center">
            <div className="flex items-center text-gray-900">
              <a href="/">
                <img
                  className="w-12 rounded"
                  src={configuration.global.logo}
                />
              </a>
            </div>
            <nav className="flex md:flex-row flex-col gap-8 md:ml-6 md:mt-0 mt-8 items-start">
              {links.map((item) => (
                <a
                  key={item.href}
                  className="relative -mx-3 -my-2 rounded-lg px-3 py-2 text-sm text-gray-700 transition-colors delay-150 hover:text-gray-900 hover:delay-[0ms]"
                  href={item.href}
                  target={item.target}
                  rel="noreferrer"
                >
                  <span className="relative z-10">{item.title}</span>
                </a>
              ))}

              <button
                type="button"
                className="relative -mx-3 -my-2 rounded-lg px-3 py-2 text-sm text-gray-700 transition-colors delay-150 hover:text-gray-900 hover:delay-[0ms]"
                onClick={() => setShowProblem(true)}
              >
                <span className="relative z-10">
                  {i18n.t('footer.problem', { defaultValue: 'Повідомити про проблему' })}
                </span>
              </button>

              <button
                type="button"
                className="relative -mx-3 -my-2 rounded-lg px-3 py-2 text-sm text-gray-700 transition-colors delay-150 hover:text-gray-900 hover:delay-[0ms]"
                onClick={() => setShowQuiz(true)}
              >
                <span className="relative z-10">
                  {i18n.t('footer.feedback', { defaultValue: 'Залишити відгук' })}
                </span>
              </button>
            </nav>
          </div>
        </div>

        <div className="flex flex-col border-t border-gray-200 pb-12 pt-8 md:justify-between md:pt-6">
          <p className="mt-6 text-sm text-gray-500 md:mt-0">
            © Copyright 2024. All rights reserved.
          </p>
        </div>
      </div>
    </div>
  );
};
