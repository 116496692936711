import { Dialog, DialogTitle } from '@mui/material';
import Box from '@mui/material/Box';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import CampaignFormItem from '../campaign-create/components/CampaignFormItem';
import { problems } from '../../configs/problems';
import { CampaignFormDescription } from '../campaign-create/CampaignStyles';
import { ReportProblemDto, useCreateReportMutation } from './report-problem-api';
import { useToasts } from '../../components/Toasts';
import { getReportProblemSchema } from './schema';
import { Button } from '../../components/Button';
import { DialogClose } from '../../components/DialogClose';

type ReportProblemProps = {
  onClose: () => void;
  meta?: object;
  open: boolean;
  scope?: string;
};

export const ReportProblem = ({ onClose, scope, meta = {}, open, ...rest }: ReportProblemProps) => {
  const [createReport, { isLoading: isUpdating }] = useCreateReportMutation();
  const { success, error } = useToasts();
  const { t } = useTranslation();
  const reportProblemSchema = useMemo(getReportProblemSchema, []);
  const methods = useForm<ReportProblemDto>({
    mode: 'onChange',
    defaultValues: {
      type: problems[0].value as any,
      scope,
    },
    resolver: yupResolver(reportProblemSchema),
  });

  useEffect(() => {
    methods.reset();
  }, [open]);

  const submit = (dto: ReportProblemDto) => {
    createReport({ ...dto, meta })
      .unwrap()
      .then(() => {
        success(
          t('reportProblem.success', {
            defaultValue: 'Дякуємо за Ваш запит! Ми звʼяжемось з Вами найближчим час!',
          })
        );
        onClose();
      })
      .catch(() => {
        error(
          t('reportProblem.error', {
            defaultValue:
              '"Під час відправлення сталася помилка. Спробуйте ще раз або зв\'яжіться з підтримкою.',
          })
        );
      });
  };

  return (
    <Dialog
      open={open}
      {...rest}
      onClose={onClose}
    >
      <FormProvider {...methods}>
        <form
          className="pt-4 pb-6 px-8 md:min-w-[400px] md:max-w-[400px] min-w-[320px] max-w[320px]"
          onSubmit={methods.handleSubmit(submit)}
        >
          <DialogTitle align="center">
            {t('reportProblem.title', { defaultValue: 'Повідомити про проблему' })}
          </DialogTitle>
          <DialogClose onClose={onClose} />

          <div className="mt-2">
            <CampaignFormItem
              name="email"
              label={t('reportProblem.email', { defaultValue: 'Email (для зворотнього звʼязку)' })}
              control={methods.control}
              errors={methods.formState.errors}
              size="small"
            />

            <CampaignFormItem
              name="type"
              variant="select"
              label={t('reportProblem.fieldLabel', { defaultValue: 'Тип проблеми' })}
              control={methods.control}
              errors={methods.formState.errors}
              size="small"
              props={{
                items: problems,
              }}
            />

            <CampaignFormItem
              name="description"
              variant="textarea"
              label={t('reportProblem.descriptionLabel', { defaultValue: 'Щось пішло не так?' })}
              placeholder={t('reportProblem.descriptionPlaceholder', {
                defaultValue: 'Опишіть ситуацію',
              })}
              control={methods.control}
              errors={methods.formState.errors}
              size="small"
              props={{
                rows: 6,
                max: 255,
              }}
            />

            <Box>
              <CampaignFormDescription>
                {t('reportProblem.info', {
                  defaultValue:
                    'Ми аналізуємо запити протягом тижня, будь ласка перевіряйте вашу електронну пошту!',
                })}
              </CampaignFormDescription>
            </Box>
          </div>

          <Box
            display="flex"
            justifyContent="center"
            mt={4}
          >
            <Box minWidth={120}>
              <Button
                fullWidth
                loading={isUpdating}
                type="submit"
                variant="contained"
                color="primary"
              >
                {t('reportProblem.send', { defaultValue: 'Відправити' })}
              </Button>
            </Box>
          </Box>
        </form>
      </FormProvider>
    </Dialog>
  );
};
