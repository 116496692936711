import { CircularProgress } from '@mui/material';

export function Loading() {
  return (
    <div className="flex flex-1 flex-col items-center justify-center p-24 min-h-[300]">
      <CircularProgress />
    </div>
  );
}

export default Loading;
