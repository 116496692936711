import { Box } from '@mui/material';
import Card from '@mui/material/Card';
import Pagination from '@mui/material/Pagination';
import { GiKnockedOutStars } from 'react-icons/gi';
import { useTranslation } from 'react-i18next';
import CampaignsFeedListItem from './CampaignsFeedListItem';
import { NotFoundPanel } from '../../components/NotFoundPanel';
import { AppRoutes } from '../../configs/app-routes';
import { useUser } from '../common/useUser';
import { Roles } from '../../configs/configuration';
import { usePaginationParams } from '../../hooks/usePaginationParams';

type CampaignsFeedListHorizontalProps = {
  data: any[];
  totalCount: number;
  limit: number;
};

function CampaignsFeedListHorizontal({
  data: filteredData,
  totalCount,
  limit,
}: CampaignsFeedListHorizontalProps) {
  const { t } = useTranslation();
  const { handlePageChange } = usePaginationParams();
  const { role } = useUser();

  const showPagination = totalCount > limit;
  const countPagination = Math.ceil(totalCount / limit);

  return !filteredData || filteredData.length === 0 ? (
    <Card
      component={Box}
      flex="1"
      className="w-full lg:mr-8"
      display="flex"
      minHeight={600}
      variant="outlined"
    >
      <NotFoundPanel
        Icon={GiKnockedOutStars}
        title={t('campaigns.notFoundTitle', {
          defaultValue: 'На жаль, нових кампаній не знайдено',
        })}
        content={t('campaigns.notFoundContent', {
          defaultValue: 'Спробуйте змінити фільтр або дочекайтесь оновлення',
        })}
        linkText={
          role === Roles.BRAND
            ? t('campaigns.notFoundButton', { defaultValue: 'Створити нову кампанію' })
            : ''
        }
        link={role === Roles.BRAND ? AppRoutes.campaignsCreate() : ''}
      />
    </Card>
  ) : (
    <div className="w-full lg:mr-8">
      {filteredData.map((campaign) => (
        <Box
          mb={3}
          key={campaign.id}
        >
          <CampaignsFeedListItem
            key={campaign.id}
            campaign={campaign}
          />
        </Box>
      ))}

      {showPagination && (
        <Box
          my={4}
          justifyContent="center"
          display="flex"
        >
          <Pagination
            rowsPerPage={limit}
            onChange={handlePageChange}
            onPageChange={handlePageChange}
            count={countPagination}
            variant="outlined"
            color="primary"
          />
        </Box>
      )}
    </div>
  );
}

export default CampaignsFeedListHorizontal;
