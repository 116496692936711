import { useTranslation } from 'react-i18next';
import { NotFoundPanel } from '../../components/NotFoundPanel';
import { AppRoutes } from '../../configs/app-routes';

export const CampaignNoCredits = () => {
  const { t } = useTranslation();
  return (
    <div className="pt-16 min-h-[300px]">
      <NotFoundPanel
        title={t('campaign.noCredits.notEnough', { defaultValue: 'Недостатньо кредитів' })}
        content={t('campaign.noCredits.notEnoughDescription', {
          defaultValue: 'Додаткову інформацію про кредити можна знайти на сторінці "Кредити"',
        })}
        linkText={t('campaign.noCredits.link', { defaultValue: 'Переглянути кредити' })}
        link={AppRoutes.credits()}
      />
    </div>
  );
};
