import { useCallback, useEffect } from 'react';
import { useMatches, useNavigate } from 'react-router-dom';
import { useUser } from './useUser';
import { AppRoutes } from '../../configs/app-routes';
import { useToasts } from '../../components/Toasts';
import {Roles} from "../../configs/configuration";

const SOCIAL_GUARD_TIME_TO_OPEN = 10000;
const SOCIAL_GUARD_TIME_TO_REDIRECT = SOCIAL_GUARD_TIME_TO_OPEN + 5000;

let timeoutValue: any;
let timeoutValue2: any;

export const UserSocialsGuard = () => {
  const { role, user, isLoaded } = useUser();
  const matches = useMatches();
  const navigate = useNavigate();

  const { info, close } = useToasts();
  const getIsSocialLink = useCallback(() => {
    return !!matches.find(({ pathname }) => pathname === AppRoutes.profile());
  }, [matches]);

  useEffect(() => {
    const isHasSocial = user.socials && user.socials.length;
    const isSocialLink = getIsSocialLink();

    if (isHasSocial || !isLoaded || role === Roles.BRAND) {
      return undefined;
    }

    if (!user.about && !isHasSocial) {
      navigate(AppRoutes.signUpBlogger());
    }

    if (!isSocialLink) {
      if (!timeoutValue) {
        timeoutValue = setTimeout(() => {
          info(
            'Щоб отримати повний доступ до платформи Вам потрібно підключити соціальні мережі. Через 10 секунд Вас автоматично перенаправить на сторінку «Соціальні мережі» ★',
            {
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'center',
              },
              disableOnClose: true,
              action: null,
            }
          );
        }, SOCIAL_GUARD_TIME_TO_OPEN);

        timeoutValue2 = setTimeout(() => {
          navigate(AppRoutes.profileSocials());
          close();
          timeoutValue = undefined;
        }, SOCIAL_GUARD_TIME_TO_REDIRECT);
      }
    }

    return () => {
      clearTimeout(timeoutValue);
      clearTimeout(timeoutValue2);
    };
  }, [role, matches, getIsSocialLink]);

  return null;
};
