import { Box } from '@mui/system';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AppRoutes } from '../../configs/app-routes';
import { Button } from '../../components/Button';

type WelcomeProps = {
  onClose: () => void;
}
export const WelcomeBlogger = ({ onClose }: WelcomeProps) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="flex flex-shrink-0 items-center justify-between rounded-t-md border-b border-neutral-100 border-opacity-100 md:p-5 p-4">
        <div className="text-xl font-medium leading-normal text-neutral-800  mr-4">
          <span
            dangerouslySetInnerHTML={{
              __html: t('welcome.title', {
                defaultValue:
                  'Вітаємо, Ви успішно стали частиною спільноти <a href="https://influencer.com.ua">INFLUENCER.COM.UA</a>',
              }),
            }}
          />
        </div>
      </div>

      <div className="relative flex-auto md:p-5 p-4">
        <Typography
          whiteSpace="pre-wrap"
          dangerouslySetInnerHTML={{
            __html: t(
              'welcome.contentBlogger',
              `Для того щоб розпочати користуватися платформою, потрібно:
- Заповнити свій профіль та вибрати категорії.
- Підключити свої соціальні мережі.

Додаткову інформацію ви можете знайти за цим посиланням: <a target="_blank" class="link" href="https://influencer.com.ua/content/how-start-to-work/">https://influencer.com.ua/content/how-start-to-work/</a>

Дякуємо, що Ви з нами!`
            ),
          }}
        />
      </div>

      <div className="flex flex-shrink-0 flex-wrap items-center justify-start rounded-b-md border-t border-neutral-100 border-opacity-100 md:p-5 p-4">
        <Box
          width={260}
          className="mr-4"
        >
          <Button
            className="w-full"
            color="primary"
            variant="contained"
            component={Link}
            to={AppRoutes.profile()}
            onClick={onClose}
            gaTrigger="welcome:blogger:profile"
          >
            {t('welcome.buttonProfile', 'Перейти в особистий кабінет')}
          </Button>
        </Box>

        <Box
          width={260}
          className="mt-2 md:mt-0"
        >
          <Button
            className="w-full"
            color="primary"
            variant="contained"
            component={Link}
            to={AppRoutes.profileSocials()}
            gaTrigger="welcome:blogger:socials"
            onClick={onClose}
          >
            {t('welcome.buttonSocials', 'Додати платформи')}
          </Button>
        </Box>
      </div>
    </>
  );
};
