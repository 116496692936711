import Button from '@mui/material/Button';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { useNavigate } from 'react-router-dom';
import { messages } from '../../../messages';
import { configuration } from '../../../configs/configuration';
import { AppRoutes } from '../../../configs/app-routes';
import { useSocialGetYoutubeLinkMutation } from '../socials-api';

export type YoutubeConnectProps = {
  remoteConnect?: boolean;
  edit?: boolean;
  id?: string;
};
export const YoutubeConnectButton = ({ remoteConnect, edit, id }: YoutubeConnectProps) => {
  const [getLink] = useSocialGetYoutubeLinkMutation();
  const navigate = useNavigate();

  const handleClick = async () => {
    if (configuration.ENABLED_YOUTUBE_REMOTE_CONNECTION && remoteConnect) {
      const link = await getLink({});
      window.location.href = link.data;
    }

    if (id) {
      navigate(AppRoutes.profileSocialsViewById(id));
    } else {
      navigate(AppRoutes.profileSocialsConnectYoutube());
    }
  };

  return (
    <Button
      startIcon={<YouTubeIcon className="w-[24px] h-[24px]" />}
      onClick={handleClick}
      size="medium"
      fullWidth
      variant="contained"
      color="secondary"
    >
      {edit ? messages.socials.edit : messages.socials.connect}
    </Button>
  );
};
