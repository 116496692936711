import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import Button from '@mui/material/Button';
import { Link, useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import isEmpty from 'lodash/isEmpty';
import Paper from '@mui/material/Paper';
import { useMemo } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import jwtService from '../auth/services/jwtService';
import { messages } from './messages';
import { configuration, Roles } from '../../configs/configuration';
import CampaignFormItem from '../campaign-create/components/CampaignFormItem';
import { AppRoutes } from '../../configs/app-routes';
import { useAuth } from '../auth/AuthContext';
import { SIGN_UP_PASSWORD_REGEX } from '../sign-up/SignUpPage';
import { i18n } from '../../i18n';

/**
 * Form Validation Schema
 */
const getSchema = () =>
  yup.object().shape({
    email: yup.string().email().required(i18n.t('signIn.emailRequired', 'Ви повинні ввести email')),
    password: yup
      .string()
      .matches(
        SIGN_UP_PASSWORD_REGEX,
        i18n.t(
          'signIn.passwordMatch',
          'Пароль повинен містити мінімум 8 символів, серед яких хоч одну цифру, спецсимвол, велику та маленьку літери'
        )
      )
      .required(i18n.t('signIn.passwordRequired', 'Будь ласка, введіть ваш пароль.'))
      .min(
        8,
        i18n.t(
          'signIn.passwordMin',
          'Занадто короткий пароль. Пароль повинен містити від 4 символів'
        )
      ),
  });

const defaultValues = {
  email: '',
  password: '',
  remember: true,
};

export const PaperStyled = styled(Paper)`
  @media (max-width: 640px) {
    border: none;
    box-shadow: none;
  }
`;

function SignInPage() {
  const schema = useMemo(getSchema, []);
  const { t } = useTranslation();
  const { control, formState, handleSubmit, setError, setValue } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });
  const navigate = useNavigate();
  const { setIsAuthenticated } = useAuth();

  const { isValid, dirtyFields, errors } = formState;

  function onSubmit({ email, password }) {
    jwtService
      .signInWithEmailAndPassword(email, password)
      .then((user) => {
        setIsAuthenticated(true);
        navigate(
          user.role === Roles.BRAND
            ? configuration.global.rootForOwner
            : configuration.global.rootForBlogger
        );
      })
      .catch((error) => {
        if (error && error.statusCode === 403) {
          setError('email', {
            message: t('signIn.signInAccountBlocked', {
              defaultValue: messages.signInAccountBlocked,
            }),
          });
        } else {
          setError('email', {
            message: i18n.t('signIn.emailError', 'Неправильний email або пароль'),
          });
        }
      });
  }

  return (
    <div className="flex flex-col flex-auto items-center sm:justify-center min-w-0 m-3 my-8">
      <PaperStyled className="w-full rounded-0 p-4 sm:p-8 rounded max-w-md shadow">
        <div className="w-full max-w-320 sm:w-320 mx-auto sm:mx-0">
          <img
            className="mx-auto h-24 rounded-lg w-auto"
            src={configuration.global.logo}
            alt="logo"
          />

          <h2 className="mt-10 text-center text-xl font-bold tracking-tight text-gray-900">
            {t('signIn.title', { defaultValue: 'Авторизація' })}
          </h2>

          <form
            name="loginForm"
            noValidate
            className="flex flex-col justify-center w-full mt-4"
            onSubmit={handleSubmit(onSubmit)}
          >
            <CampaignFormItem
              name="email"
              control={control}
              label={t('signIn.email', { defaultValue: 'Email' })}
              errors={errors}
              size="large"
            />

            <CampaignFormItem
              name="password"
              type="password"
              control={control}
              label={
                <div className="flex items-center justify-between">
                  {t('signIn.password', { defaultValue: 'Пароль' })}

                  <div className="text-sm">
                    <Link
                      className="font-semibold leading-6 ml-1 text-indigo-600 hover:text-indigo-500"
                      to={AppRoutes.forgotPassword()}
                    >
                      {t('signIn.forgotPassword', { defaultValue: 'Забули пароль?' })}
                    </Link>
                  </div>
                </div>
              }
              errors={errors}
              size="large"
            />

            <Button
              variant="contained"
              color="primary"
              className="w-full mt-4"
              aria-label="Sign in"
              disabled={isEmpty(dirtyFields) || !isValid}
              type="submit"
              size="medium"
            >
              {t('signIn.signIn', { defaultValue: 'Увійти' })}
            </Button>
          </form>

          <div className="mt-10 text-center justify-center text-sm text-gray-500 flex items-baseline font-medium">
            <div>{t('signIn.dontHaveAccount', { defaultValue: 'Не маєте облікового запису?' })} </div>
            <Link
              className="font-semibold leading-6 ml-1 text-indigo-600 hover:text-indigo-500"
              to="/sign-up"
            >
              {t('signIn.signUp', { defaultValue: 'Зареєструватися' })}
            </Link>
          </div>
        </div>
      </PaperStyled>
    </div>
  );
}

export default SignInPage;
