import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import { AppRoutes } from '../../../configs/app-routes';
import { NotificationBase } from './NotificationBase';

export const NotificationOrderFinished = ({ payload, onClick, ...rest }) => {
  return (
    <NotificationBase
      {...rest}
      title="Ваша публікація була прийнята"
      createdAt={rest.createdAt}
      content={
        <>
          Ви успішно виконали кампанію{' '}
          <Link
            className="link"
            to={AppRoutes.campaignView(payload.campaign.id)}
          >
            {payload.campaign.name}
          </Link>
        </>
      }
      Buttons={
        <>
          <Button
            size="small"
            color="primary"
            variant="contained"
            className="mb-1 mt-2"
            onClick={onClick}
            component={Link}
            to={AppRoutes.campaignView(payload.campaign.id)}
          >
            Переглянути кампанію
          </Button>
        </>
      }
    />
  );
};
