import { ReactNode } from 'react';
import styled from '@emotion/styled';
import { Footer } from '../components/Footer/Footer';
import {ProfileContextProvider} from "../modules/common/ProfileContext";

const Root = styled.div({
  '& .container': {
    width: '100%',
    margin: '0 auto',
  },
});

export const AuthRoutesLayoutNoGuard = ({ children }: { children: ReactNode }) => {
  return (
    <Root
      id="fuse-layout"
      className="w-full flex h-full flex-col flex-1"
    >
      <ProfileContextProvider>
        <div className="flex flex-auto min-w-0">
          <main
            id="fuse-main"
            className="flex flex-col flex-auto min-h-full min-w-0 relative z-10"
          >
            <div className="flex bg-white flex-col flex-auto min-h-0 relative z-10">{children}</div>

            <Footer />
          </main>
        </div>
      </ProfileContextProvider>
    </Root>
  );
};
