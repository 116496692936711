import {
  Button as MuiButton,
  CircularProgress,
  Box,
  ButtonProps as MuiButtonProps,
} from '@mui/material';

import styled from '@emotion/styled';

const Progress = styled(CircularProgress)`
  color: white;
`;

const colorsByVariant = {
  primary: { main: 'bg-blue-900', hover: 'bg-blue-950', active: 'bg-blue-950' },
  secondary: { main: 'bg-blue-900', hover: 'bg-blue-950', active: 'bg-blue-950' },
  success: { main: 'bg-green-600', hover: 'bg-green-700', active: 'bg-green-800' },
  warning: { main: 'bg-orange-500', hover: 'bg-orange-600', active: 'bg-orange-700' },
  error: { main: 'bg-red-600', hover: 'bg-red-700', active: 'bg-red-800' },
  default: { main: 'bg-blue-900', hover: 'bg-blue-950', active: 'bg-blue-950' },
};

export type ButtonProps = MuiButtonProps & {
  tailwind?: boolean;
  loading?: boolean;
  gaTrigger?: string;
  component?: any;
  to?: string;
};

const MuiStyledButton = (props: ButtonProps) => {
  return (
    <MuiButton
      {...props}
      disabled={props.disabled || props.loading}
    >
      {props.loading && (
        <Box
          display="inline-block"
          mr={2}
          mb="-2px"
        >
          <Progress
            color="inherit"
            size={18}
          />
        </Box>
      )}
      {props.children}
    </MuiButton>
  );
};

// const colorsByVariant
export const Button = (props: ButtonProps) => {
  const color = colorsByVariant[props.color || 'default'];

  let handleClick = props.onClick;

  if (props.gaTrigger) {
    handleClick = (event) => {
      console.log("EMIT GA", props.gaTrigger);
      window.gtag('event', props.gaTrigger);
      if (props.onClick) {
        props.onClick(event);
      }
    };
  }

  if (props.tailwind)
    return (
      <button
        {...props}
        className={`subscribe w-full px-8 py-8 transition font-semibold text-sm text-white shadow-sm rounded ${color.main} hover:${color.hover} active:${color.active}`}
        onClick={handleClick}
      >
        {props.loading && (
          <Box
            display="inline-block"
            mr={2}
            mb="-2px"
          >
            <Progress
              color="inherit"
              size={18}
            />
          </Box>
        )}
        {props.children}
      </button>
    );

  return (
    <MuiStyledButton
      {...props}
      size={props.size || 'small'}
      onClick={handleClick}
    />
  );
};
