import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../utils/axiosBaseQuery';

export const balanceApi = createApi({
  reducerPath: 'balance',
  tagTypes: ['balance'],
  baseQuery: axiosBaseQuery({ baseUrl: '/api/economy' }),
  endpoints: (builder) => ({
    fetch: builder.query({
      query: (campaignId) => ({
        url: `/me/balance`,
      }),
    }),
  }),
});

export const useBalanceFetch = balanceApi.endpoints.fetch.useQuery;
