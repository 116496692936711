import Typography from '@mui/material/Typography';

type PaymentsEmptyProps = {
  message: string;
};

export const PaymentsEmpty = ({ message }: PaymentsEmptyProps) => {
  return (
    <div className="flex flex-col flex-1 items-center justify-center h-full min-h-[100px]">
      <Typography
        color="text.secondary"
        variant="h5"
      >
        {message}
      </Typography>
    </div>
  );
};
