import SvgIcon from '@mui/material/SvgIcon';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import insta from './img/insta.png';
import tiktok from './img/tiktok.png';
import youtube from './img/youtube.png';
import { ReactComponent as TikTokIcon } from './img/logo-tiktok-svgrepo-com.svg';

export enum SocialTypes {
  TIKTOK = 'tiktok',
  INSTA = 'insta',
  // FEEDBACK = 'feedback',
  YOUTUBE = 'youtube',
}

export const socialTypesImages = {
  [SocialTypes.TIKTOK]: tiktok,
  [SocialTypes.INSTA]: insta,
  [SocialTypes.YOUTUBE]: youtube,
  // [SocialTypes.FEEDBACK]: feedback,
};

export const socialTypesIcons = {
  [SocialTypes.TIKTOK]: (props) => (
    <SvgIcon {...props}>
      <TikTokIcon />
    </SvgIcon>
  ),
  [SocialTypes.INSTA]: InstagramIcon,
  [SocialTypes.YOUTUBE]: YouTubeIcon,
  // [SocialTypes.FEEDBACK]: RateReviewIcon,
};

export const socialTypesConfig = [
  {
    key: SocialTypes.TIKTOK,
    image: socialTypesImages[SocialTypes.TIKTOK],
    title: 'TikTok',
    Icon: socialTypesIcons[SocialTypes.TIKTOK],
    color: 'primary',
  },
  {
    key: SocialTypes.INSTA,
    image: socialTypesImages[SocialTypes.INSTA],
    title: 'Instagram',
    Icon: socialTypesIcons[SocialTypes.INSTA],
    color: 'warning',
  },
  {
    key: SocialTypes.YOUTUBE,
    image: socialTypesImages[SocialTypes.YOUTUBE],
    title: 'YouTube',
    Icon: socialTypesIcons[SocialTypes.YOUTUBE],
    color: 'error',
  },
  // {
  //   key: SocialTypes.FEEDBACK,
  //   image: socialTypesImages[SocialTypes.FEEDBACK],
  //   title: 'Відгук',
  //   Icon: socialTypesIcons[SocialTypes.FEEDBACK],
  // },
];

export const socialTypesConfigNormalized = socialTypesConfig.reduce((acc, item) => {
  acc[item.key] = item;
  return acc;
}, {} as Record<SocialTypes, (typeof socialTypesConfig)[0]>);
