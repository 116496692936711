import dayjs from 'dayjs';
import 'dayjs/locale/uk';
import { useMemo } from 'react';

export type DateProps = {
  date: string;
  format?: 'short' | 'long' | 'dateTime' | string;
};

const types = {
  short: 'MM.YYYY',
  long: 'D.MM.YYYY',
  dateTime: 'DD MMMM, HH:mm',
};

export const Date = ({ date, format = 'long' }: DateProps) => {
  const formattedDate = useMemo(
    () =>
      dayjs(date)
        .locale('uk')
        .format(types[format] || format),
    [date, format]
  );
  return <>{formattedDate}</>;
};
