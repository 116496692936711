import { useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { SocialTypes } from '../../configs/social-types';
import { AppRoutes } from '../../configs/app-routes';
import { SocialsConnectRouteLayout } from './components/SocialsConnectRouteLayout';
import { SocialsConnectRemote } from './components/SocialsConnectRemote';
import { SocialInstagramConnectView } from '../socials/instagram/SocialInstagramConnectView';
import { InstagramConnectButton } from '../socials/instagram/InstagramConnectButton';
import { SocialEntity } from '../../types';
import { configuration } from '../../configs/configuration';

type SocialsConnectRouteProps = {
  edit?: boolean;
  social?: SocialEntity;
};

export const SocialsConnectRouteInstagram = ({ edit, social }: SocialsConnectRouteProps) => {
  const navigate = useNavigate();
  const redUri = `${configuration.webUrl}${AppRoutes.profileSocialsConnectInstagramWebhook()}`;

  return (
    <SocialsConnectRouteLayout>
      <SocialsConnectRemote
        social={social}
        edit={edit}
        socialType={SocialTypes.INSTA}
        ButtonComponent={InstagramConnectButton}
        InfoComponent={SocialInstagramConnectView}
        onSubmit={() => navigate(AppRoutes.profileSocials())}
        connectDescription={
          <div>
            <ul className="ml-2">
              <li className="mb-2">✔️ Бізнес-акаунт Instagram</li>
              <li className="mb-2">✔️ 1000+ підписників</li>
              <li className="mb-2">✔️ Рівень залученості більше ніж 1%</li>
              <li className="mb-2">✔️ Принаймні 30% місцевої аудиторії</li>
              <li className="mb-2">
                ✔️ Автентичність аудиторії: відсутність фальшивих підписників
              </li>
              <li className="mb-2">✔️ Релевантний і послідовний контент</li>
            </ul>

            <div className="mt-6 max-w-[590px]">
              <b className="mb-2 block">Важливо! </b>
              Щоб підключити Instagram до платформи, Ваш аккаунт має відповідати цим вимогам:
              <ul className="mt-2">
                <li className="mb-2">
                  - Обліковий запис повинен бути типів: <b>бізнес або автор</b> в Instagram;
                </li>
                <li className="mb-2">
                  - Ви маєте <b>бізнес сторінку</b> на Facebook;
                </li>
                <li className="mb-2">
                  - <b>Бізнес сторінка на Facebook привʼязана</b> до вашого Instagram акаунту.
                </li>
              </ul>
              Детальніше про підключення за{' '}
              <a
                className="link"
                href="https://influencer.com.ua/content/yak-pidklyuchiti-instagram-na-platformi"
              >
                посиланням
              </a>
              .
              <br />
              <br />
              <div className="mb-2">
                <b>Гайд з підключення профілю Instagram до платформи:</b>
              </div>
              <iframe
                style={{ width: '100%', maxWidth: 560 }}
                // width="560"
                height="315"
                src="https://www.youtube.com/embed/lKLkH2YJoUA?si=UgctIkprzR3PxOTr"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin"
                allowFullScreen
              />
            </div>
          </div>
        }
        beforeImagesBlock={
          <Typography
            variant="body2"
            color="grey"
            maxWidth={400}
            fontStyle="italic"
            className="max-w-[600px]"
          >
            <ErrorOutlineIcon color="warning" />
            Додайте скріншоти статистики
            <br />
            <br />
            <b className="bold">Сторіз:</b>
            <ul>
              <li>• Аналітика переглядів історій;</li>
              <li>
                • "Кліки по посиланню": кількість кліків на посилання, яке ви додали до історії;
              </li>
              <li>• "Відповіді": кількість повідомлень у дірект або реакцій на вашу історію;</li>
              <li>
                • "Торкання наклейок": кількість натискань на наклейки з ім'ям користувача або
                переходів на чужі історії, які ви репостнули. Ім'я користувача буде відображено у
                статистиці.
              </li>
            </ul>
            <br />
            <b>Допис</b>
            <ul>
              <li>• скріншот з аналітикою допису</li>
            </ul>
            <br />
            <b>Reels</b>
            <ul>
              <li>• прикріпіть аналітику ваших рілз, охоплення за останні 7 днів</li>
            </ul>
            Інстаграм аналітика повинна оновлюватись раз у тиждень
          </Typography>
        }
      />

      {!edit && (
        <div className="mt-2">
          Якщо у вас не працює попередня кнопка, підключіть бізнес акаунт і спробуйте ще раз, за цим
          <a
            className="link ml-1"
            target="_self"
            href={`https://www.facebook.com/v16.0/dialog/oauth?response_type=token&display=popup&client_id=3440643999550657&redirect_uri=${redUri}&auth_type=rerequest&scope=instagram_basic%2Cinstagram_manage_insights%2Cpages_show_list%2Cbusiness_management`}
          >
            посиланням
          </a>
          .
        </div>
      )}
    </SocialsConnectRouteLayout>
  );
};
