import logo from './img/logo.svg';
import logoInverted from './img/logo-inverted.svg';
import { AppRoutes } from './app-routes';

export const configuration = {
  // webUrl: 'http://localhost:3000',
  // webUrl: 'https://app.influencer.com.ua',
  basename: `/${process.env.REACT_APP_BASENAME}`,
  webUrl: "https://influencer.com.ua/app" || process.env.REACT_APP_WEB_URL,
  s3: { bucket: 'https://brandname-images.s3.eu-central-1.amazonaws.com/' },
  api: {
    url: process.env.REACT_APP_API_URL,
    // url: process.env.REACT_APP_API_URL,
    // url: "http://192.168.101.73:3002",
    // paymentCallbackUrl: process.env.REACT_APP_API_URL,
    paymentCallbackUrl: 'https://api.influencer.com.ua/api/orders/payment/webhook',
  },

  ENABLED_PWA: Boolean(process.env.ENABLED_PWA),
  ENABLED_INSTAGRAM_REMOTE_CONNECTION: true,
  ENABLED_TIKTOK_REMOTE_CONNECTION: true,
  ENABLED_YOUTUBE_REMOTE_CONNECTION: true,
  ENABLED_SHOW_USERNAME: true,

  global: {
    logo,
    logoInverted,

    termsOfServiceUrl: 'https://influencer.com.ua/content/terms-and-conditions/',
    privacyPolicyUrl: 'https://influencer.com.ua/content/private-policy/',
    about: 'https://influencer.com.ua',
    faq: 'https://influencer.com.ua/faq',
    contacts: 'https://influencer.com.ua/contacts',

    rootForBlogger: AppRoutes.campaigns(),
    rootForOwner: AppRoutes.campaignsMy(),
  },
};

export const CampaignState = {
  pending: 'pending',
  processing: 'processing',
  finished: 'finished',
  paused: 'paused',
  stopped: 'stopped',
  WAIT_FOR_PAYMENT: 'wait_for_payment',
};

export const Roles = {
  USER: 'user',
  BRAND: 'brand',
};

export const OrdersState = {
  PENDING: 'pending',
  PUBLICATION_WAIT_REVIEW: 'publication_wait_review',
  PUBLICATION_PROGRESS: 'publication_progress',
  DECLINED: 'declined',
  FINISHED: 'finished',
  CAMPAIGN_FINISHED: 'campaign_finished',
  OFFERED_TO_BLOGGER: 'offered_to_blogger',
};

export const LINK_TO_TERMS_AND_CONDITIONS_BRAND = '';

export const PaymentType = {
  MONEY: 'money',
  BARTER: 'barter',
  BARTER_MONEY: 'barter_money',
};
