export const campaignFields = {
  name: 'name',
  categories: 'categories',
  products: 'products',
  questionFromBrand: 'questionFromBrand',
  dateStart: 'dateStart',
  dateEnd: 'dateEnd',
  description: 'description',
  requirements: 'requirements',
  socialTypes: 'socialTypes',
  campaignType: 'campaignType',
  settings: 'settings',
  image: 'image',
  images: 'images',
  price: 'price',
  shortDescription: 'shortDescription',
  publicationCount: 'publicationCount',
  link: 'link',
  paymentType: 'paymentType',
  termsAndConditions: 'termsAndConditions',
  followersCount: 'minFollowersCount',
  defaultShowInSearch: 'defaultShowInSearch',
};

export const productFields = {
  description: 'description',
  images: 'images',
  price: 'price',
  title: 'title',
};

export const campaignRequirementsFields = {
  mentions: 'mentions',
  keywords: 'keywords',
  tutorialDo: 'tutorial_do',
  tutorialDont: 'tutorial_dont',
  hashtags: 'hashtags',
  publishStart: 'publishStart',
  publishEnd: 'publishEnd',
  rules: 'rules',
  moodboard: 'moodboard',
  notCopyright: 'notCopyright',
  notOther_brands: 'notOtherBrands',
  attachStatistic: 'isAttachStatistic',
  other: 'other',
};

export const CAMPAIGN_PLACEHOLDER_IMAGE =
  'assets/images/apps/ecommerce/product-image-placeholder.png';

export const CAMPAIGN_REDIRECT_TIMEOUT = 5000;
