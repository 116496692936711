import { Suspense } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import styled from '@emotion/styled';
import Loading from '../components/Loading';
import { Footer } from '../components/Footer/Footer';
import { useAuth } from '../modules/auth/AuthContext';
import { AppRoutes } from '../configs/app-routes';
import {UserSocialsGuard} from "../modules/common/UserSocialsGuard";

const Root = styled.div({
  '& .container': {
    width: '100%',
    margin: '0 auto',
  },
});

export const AuthRoutesLayout = () => {
  const { isAuthenticated } = useAuth();
  const { pathname } = useLocation();

  const isSignOut = pathname.includes(AppRoutes.profileLogout());
  const isForgotPassword = pathname.includes(AppRoutes.forgotPassword());

  if (isAuthenticated && !isSignOut && !isForgotPassword) {
    return <Navigate to={AppRoutes.campaigns()} />;
  }

  return (
    <Root
      id="fuse-layout"
      className="w-full flex h-full flex-col flex-1"
    >
      <div className="flex flex-auto min-w-0">
        <main
          id="fuse-main"
          className="flex flex-col flex-auto min-h-full min-w-0 relative z-10"
        >
          <div className="flex bg-[#F2F5F9] flex-col flex-auto min-h-0 relative z-10">
            <Suspense fallback={<Loading delay={0} />}>
              <Outlet />
            </Suspense>
          </div>

          <Footer />
        </main>
      </div>
    </Root>
  );
};
