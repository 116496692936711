import { Box, Divider } from '@mui/material';
import Card from '@mui/material/Card';
import { useSearchParams } from 'react-router-dom';
import Pagination from '@mui/material/Pagination';
import { useMemo, useState } from 'react';
import qs from 'query-string';
import { FaUserSlash } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { UserItem } from './UserItem';
import { NotFoundPanel } from '../../components/NotFoundPanel';
import { useUsersFetchQuery } from './users-api';
import { UserPropose } from './UserPropose';
import { PanelHeader } from '../../components/PanelHeader';

export const UsersList = () => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [proposeUser, setProposeUser] = useState();

  const limit = searchParams.get('limit') || 10;
  const offset = searchParams.get('offset') || 0;

  const values = useMemo(() => {
    const query = qs.parse(searchParams.toString());

    if (query.categories && !Array.isArray(query.categories)) {
      query.categories = [Number(query.categories)];
    } else if (Array.isArray(query.categories)) {
      query.categories = query.categories.map(Number);
    }

    if (!query.categories) {
      query.categories = [];
    }

    query.limit = limit;
    query.offset = offset;

    return query;
  }, [searchParams, limit, offset]);

  const { data: { data = [], meta } = {}, isLoading, isSuccess } = useUsersFetchQuery(values);

  const totalCount = meta?.filterCount;
  const page = Number(offset) ? Math.floor(offset / limit) + 1 : 1;

  const handlePageChange = (event, page) => {
    searchParams.set('offset', (page - 1) * limit);
    setSearchParams(searchParams);
  };

  const showPagination = totalCount > limit;

  if (!data && isLoading) {
    return <div>Loading...</div>;
  }

  if (!data) {
    return (
      <Card
        className="max-w-[1200px] lg:mr-6 mr-0"
        variant="outlined"
      >
        <NotFoundPanel
          icon={<FaUserSlash className="w-[120px]" />}
          className="min-h-[300px]"
          title={t('users.notFoundTitle', {
            defaultValue: 'За даним пошуковим запитом блогерів не знайдено',
          })}
        />
      </Card>
    );
  }

  return (
    <>
      <UserPropose
        open={Boolean(proposeUser)}
        user={proposeUser}
        onClose={() => setProposeUser(undefined)}
      />

      <Card
        className="max-w-[1200px] lg:mr-6 mr-0"
        variant="outlined"
      >
        <div className="p-4 max-w-[600px]">
          <PanelHeader
            title={t('users.title', { defaultValue: 'Cписок блогерів' })}
            subtitle={t('users.subtitle', {
              defaultValue:
                'Ви можете надіслати пропозиції щодо співпраці блогерам, які знаходяться у нашій добірці',
            })}
            hideDivider
          />
        </div>

        {isSuccess && !(data && data.length) ? (
          <>
            <div className="justify-center min-h-[160px] flex items-center">
              <NotFoundPanel
                icon={<FaUserSlash className="text-[120px] text-slate-500" />}
                className="min-h-[300px] max-w-[420px] text-center"
                title={t('users.notFoundTitle', {
                  defaultValue: 'За даним пошуковим запитом блогерів не знайдено',
                })}
              />
            </div>
          </>
        ) : (
          data.map((item) => (
            <UserItem
              onClickOffer={() => setProposeUser(item)}
              key={item.id}
              user={item}
            />
          ))
        )}
      </Card>

      {showPagination && (
        <Box
          my={4}
          justifyContent="center"
          display="flex"
          style={{ opacity: 0.5, pointerEvents: 'none' }}
        >
          <Pagination
            page={page}
            rowsPerPage={limit}
            onChange={handlePageChange}
            onPageChange={handlePageChange}
            count={Math.ceil(totalCount / limit)}
            color="primary"
          />
        </Box>
      )}

      {!(data && data.length) && (
        <div className="min-h-[300px] flex flex-col">
          <Divider />
          <NotFoundPanel
            icon={<FaUserSlash className="text-[120px] text-slate-500" />}
            className="min-h-[300px] max-w-[420px] text-center"
            title={t('users.notFoundTitle', {
              defaultValue: 'За даним пошуковим запитом блогерів не знайдено',
            })}
          />
        </div>
      )}
    </>
  );
};
