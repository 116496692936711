import { AppRoutes } from '../../configs/app-routes';
import { Socials } from './Socials';
import { SocialsConnectRouteInstagramWebhook } from '../socials-connect/SocialsConnectRouteInstagramWebhook';
import { ProtectedRoute } from '../../routes/ProtectedRoute';
import { Roles } from '../../configs/configuration';
import { SocialsConnectRouteTiktok } from '../socials-connect/SocialsConnectRouteTiktok';
import { SocialsEditRoute } from '../socials-connect/SocialsEditRoute';
import { SocialsConnectRouteYoutube } from '../socials-connect/SocialsConnectRouteYoutube';
import { TITLES } from '../../configs/titles';
import { breadcrumbs } from '../../messages';
import { SocialsConnectRouteInstagram } from '../socials-connect/SocialsConnectRouteInstagram';

export const socialsRoutes = [
  {
    path: AppRoutes.profileSocials(),
    handle: {
      crumb: () => breadcrumbs.profileSocials,
      title: () => TITLES.profileSocials(),
    },
    children: [
      {
        index: true,
        element: (
          <ProtectedRoute roles={[Roles.USER]}>
            <Socials />
          </ProtectedRoute>
        ),
      },
      {
        path: AppRoutes.profileSocialsConnectInstagramWebhook(),
        element: <SocialsConnectRouteInstagram />,
        handle: {
          crumb: () => breadcrumbs.profileSocialsConnectInstagram,
          title: () => TITLES.profileSocialsConnectInstagram(),
        },
      },

      {
        path: AppRoutes.profileSocialsConnectTiktok(),
        element: <SocialsConnectRouteTiktok />,
        handle: {
          crumb: () => breadcrumbs.profileSocialsConnectTiktok,
          title: () => TITLES.profileSocialsConnectTiktok(),
        },
      },

      {
        path: AppRoutes.profileSocialsConnectTiktokWebhook(),
        element: <SocialsConnectRouteTiktok />,
        handle: {
          crumb: () => breadcrumbs.profileSocialsConnectTiktok,
          title: () => TITLES.profileSocialsConnectTiktok(),
        },
      },

      {
        path: AppRoutes.profileSocialsConnectYoutubeWebhook(),
        element: <SocialsConnectRouteYoutube />,
        handle: {
          crumb: () => breadcrumbs.profileSocialsConnectTiktok,
          title: () => TITLES.profileSocialsConnectYoutube(),
        },
      },

      {
        path: AppRoutes.profileSocialsConnectInstagram(),
        element: <SocialsConnectRouteInstagram />,
        handle: {
          crumb: () => breadcrumbs.profileSocialsConnectInstagram,
          title: () => TITLES.profileSocialsConnectInstagram(),
        },
      },

      {
        path: AppRoutes.profileSocialsViewById(),
        element: <SocialsEditRoute />,
        handle: {
          crumb: () => breadcrumbs.profileSocialsEdit,
          title: () => TITLES.profileSocialsEdit(),
        },
      },

      {
        path: AppRoutes.profileSocialsConnectYoutube(),
        element: <SocialsConnectRouteYoutube />,
        handle: {
          crumb: () => breadcrumbs.profileSocialsConnectYoutube,
          title: () => TITLES.profileSocialsConnectYoutube(),
        },
      },
    ],
  },
];
