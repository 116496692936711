import Box from '@mui/material/Box';
import { Link, useSearchParams } from 'react-router-dom';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import { useTranslation } from 'react-i18next';
import { messages } from '../../messages';
import { NotFoundPanel } from '../../components/NotFoundPanel';
import { AppRoutes } from '../../configs/app-routes';
import CampaignsOwnerItem from './CampaignsOwnerItem';
import { useCampaignViewOwnerListQuery } from '../campaign-view/redux/campaign-view-api';
import { PageBody } from '../../components/PageContainer/PageBody';
import { PageHeader } from '../../components/PageContainer/PageHeader';
import { Meta } from '../meta/Meta';
import { TITLES } from '../../configs/titles';
import { Loading } from '../../components/Loading';

const m = messages.owner;

function CampaignsOwner() {
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();
  const query = searchParams.get('query') || 'all';

  const { data: { data = [] } = {}, isLoading } = useCampaignViewOwnerListQuery(query);

  return (
    <>
      <Meta title={TITLES.campaignsMy()} />
      <PageHeader
        title={t('campaignsOwner.title', { defaultValue: 'Мої кампанії' })}
        right={
          <Button
            component={Link}
            startIcon={<ControlPointIcon />}
            variant="outlined"
            color="primary"
            to={AppRoutes.campaignsCreate()}
          >
            {t('campaignsOwner.create', { defaultValue: 'Створити нову кампанію' })}
          </Button>
        }
      />

      <PageBody>
        {!data || data.length === 0 ? (
          <Card
            component={Box}
            flex="1"
            display="flex"
            alignItems="center"
            minHeight={600}
            variant="outlined"
          >
            {isLoading ? (
              <Loading />
            ) : (
              <NotFoundPanel
                title={t('campaignsOwner.notFoundTitle', { defaultValue: 'Кампаній не знайдено' })}
                linkText={t('campaignsOwner.notFoundLinkText', {
                  defaultValue: 'Створити нову кампанію',
                })}
                link={AppRoutes.campaignsCreate()}
              />
            )}
          </Card>
        ) : (
          data.map((campaign) => (
            <div
              className="mb-4"
              key={campaign.id}
            >
              <CampaignsOwnerItem
                key={campaign.id}
                campaign={campaign}
              />
            </div>
          ))
        )}
      </PageBody>
    </>
  );
}

export default CampaignsOwner;
