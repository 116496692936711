import { useMemo, useState } from 'react';
import { Divider } from '@mui/material';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import { useParams, Link as RouterLink } from 'react-router-dom';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { SmartSearchItem } from './SmartSearchItem';
import { NotFoundPanel } from '../../components/NotFoundPanel';
import { useFetchQuery } from './smart-search-api';
import { SmartSearchOffer } from './SmartSearchOffer';
import { AppRoutes } from '../../configs/app-routes';
import { useGetQuery } from '../campaign-view/redux/campaign-view-api';

export const SmartSearch = () => {
  const { id: campaignId } = useParams();
  const { t } = useTranslation();
  const { data: campaign } = useGetQuery(campaignId);
  const { data = [], isLoading, isSuccess } = useFetchQuery(campaignId);
  const [showDialog, setShowDialog] = useState(false);
  const [selected, setSelected] = useState<Array<string | number>>([]);

  const selectedCount = useMemo(() => Object.keys(selected).length, [selected]);
  const toggleSelect = (id: string) => {
    let result = [...selected];
    if (result.includes(id)) {
      result = result.filter((item) => item !== id);
    } else {
      result.push(id);
    }

    setSelected(result);
  };

  if ((!data && isLoading) || !campaign) {
    return <div>Loading...</div>;
  }

  const socialType = campaign?.socialTypes[0]?.socialType?.title;

  return (
    <>
      <SmartSearchOffer
        open={Boolean(showDialog)}
        campaign={campaign}
        selected={selected}
        count={selectedCount}
        onClose={() => setShowDialog(false)}
      />
      <Card
        className="mt-6 mx-3 max-w-[1200px]"
        variant="outlined"
      >
        <div className="p-4 max-w-[600px]">
          <Typography className="text-16 sm:text-20 truncate font-semibold">
            {t('smartSearch.title', { defaultValue: 'Рекомендації блогерів' })}
          </Typography>
          <Typography
            variant="caption"
            className="font-medium"
          >
            {t('smartSearch.subtitle', {
              defaultValue:
                'Ви можете надіслати пропозиції щодо співпраці блогерам, які знаходяться у нашій добірці. Не забувайте про важливість взаємовигідної співпраці та відкритості до їхніх ідей та пропозицій.',
            })}
          </Typography>
        </div>

        {isSuccess && !(data && data.length) ? (
          <div className="justify-center min-h-[160px] flex items-center">
            <Typography variant="h4">
              {t('smartSearch.notFoundMessage2', { defaultValue: 'Рекомендацій не знайдено' })}
            </Typography>
          </div>
        ) : (
          data.map((item) => (
            <SmartSearchItem
              onClickOffer={(id) => toggleSelect(id)}
              selected={selected.includes(item.id)}
              key={item.id}
              user={item}
              socialType={socialType}
            />
          ))
        )}

        <div className="mt-5">
          <Divider />
        </div>

        <div className="mt-6 mb-6 flex md:flex-row flex-col px-6">
          <Button
            variant="contained"
            onClick={() => setShowDialog(true)}
            color="primary"
            disabled={!selectedCount}
          >
            {t('smartSearch.offer', {
              defaultValue: 'Запропонувати кампанію {{count}} блогерам',
              count: selectedCount,
            })}
          </Button>

          <Button
            variant="contained"
            className="md:ml-2 ml-0 mt-2 md:mt-0"
            component={RouterLink}
            to={AppRoutes.campaignView(campaign.id)}
            color="primary"
          >
            {t('smartSearch.next', { defaultValue: 'Перейти до кампанії' })}
          </Button>
        </div>
      </Card>

      {!(data && data.length) && (
        <div className="min-h-[300px] flex flex-col">
          <Divider />
          <NotFoundPanel
            title={t('smartSearch.notFoundMessage', { defaultValue: 'Рекомендацій не знайдено' })}
          />
        </div>
      )}
    </>
  );
};
