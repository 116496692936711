import { useFormContext, useWatch } from 'react-hook-form';
import Box from '@mui/material/Box';
import { messages } from '../../messages';
import CampaignFormItem from './components/CampaignFormItem';
import { campaignFields } from '../campaign-view/campaignConfig';
import { campaignTypesList, campaignTypesTokens } from '../../configs/campaign-type';

const m = messages.campaignCreate;

const lists = {};
Object.keys(campaignTypesTokens).map((key) => {
  const keys = campaignTypesTokens[key];
  lists[key] = keys.map((item) => campaignTypesList[item]);
});

function CampaignCreateType(props) {
  const form = useWatch();
  const { formState, control } = useFormContext();
  const socialType = form.socialTypes && form.socialTypes[0];

  const selectList = lists[socialType];

  if (!selectList) {
    return null;
  }

  const selectListWithPlaceholder = [{ label: 'Не вибрано', value: undefined }, ...selectList];

  return (
    <Box mt={1}>
      <CampaignFormItem
        size="medium"
        required
        orient="horizontal"
        control={control}
        errors={formState.errors}
        name={campaignFields.campaignType}
        label={m.campaignType}
        variant="select"
        props={{
          items: selectListWithPlaceholder,
        }}
      />
    </Box>
  );
}

export default CampaignCreateType;
