import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import { AppRoutes } from '../../../configs/app-routes';
import { NotificationBase } from './NotificationBase';
import { Username } from '../../../components/Username';

export const NotificationOrdersCreated = ({ payload, onClick, ...rest }) => {
  return (
    <NotificationBase
      {...rest}
      title="Ви отримали новий запит"
      content={
        <>
          Ви отримали новий запит від блогера: <Username user={payload.creator} />
        </>
      }
      Buttons={
        <>
          <Button
            size="small"
            color="primary"
            variant="contained"
            className="mb-1 mt-2"
            component={Link}
            onClick={() => {
              onClick && onClick();
            }}
            to={AppRoutes.campaignViewAll(payload.campaign.id || payload.campaign, payload.orderId)}
          >
            Переглянути пропозицію
          </Button>
        </>
      }
    />
  );
};
