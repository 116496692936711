import Typography from '@mui/material/Typography';
import { SocialTypes, socialTypesIcons } from '../../configs/social-types';
import { SocialEntity } from '../../types';
import { messages } from './messages';

const linkMapping = {
  [SocialTypes.INSTA]: (social: SocialEntity) => `https://instagram.com/${social.username}`,
  [SocialTypes.YOUTUBE]: (social: SocialEntity) => `https://youtube.com/@${social.username}`,
  [SocialTypes.TIKTOK]: (social: SocialEntity) => `https://tiktok.com/@${social.username}`,
};

export const SocialLink = ({ social }: { social?: SocialEntity }) => {
  let link = social && linkMapping[social.socialType](social);
  const Icon = social && socialTypesIcons[social.socialType];

  if (social?.socialType === SocialTypes.TIKTOK && social?.json) {
    link = social.json && social.json.profileDeepLink;
  }

  return (
    <>
      {social && Icon ? (
        <span className="hover:underline text-blue-800">
          <Icon />
          <Typography
            component="a"
            href={link}
            target="_blank"
            variant="subtitle2"
          >
            {social.username}
          </Typography>
        </span>
      ) : (
        <Typography
          className="text-blue-800"
          component="span"
          variant="subtitle2"
        >
          {messages.deleted}
        </Typography>
      )}
    </>
  );
};
