import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../utils/axiosBaseQuery';

export const campaignOrdersApi = createApi({
  reducerPath: 'campaign-orders',
  tagTypes: ['campaign-orders'],
  baseQuery: axiosBaseQuery({ baseUrl: '/api' }),
  endpoints: (builder) => ({
    fetch: builder.query({
      query: ({ id, ...query }) => {
        return {
          url: `/campaigns/${id}/orders`,
          params: query,
        };
      },
      providesTags: ['fetchCampaignOrders'],
    }),

    fetchOne: builder.query({
      query: (id) => {
        return {
          url: `/orders/${id}`,
        };
      },
    }),

    apply: builder.mutation({
      query: (id) => ({
        url: `/orders/${id}/apply`,
        method: 'POST',
      }),
    }),

    accept: builder.mutation({
      query: (id) => ({
        url: `/orders/${id}/accept`,
        method: 'POST',
      }),
      invalidatesTags: ['fetchCampaignOrders'],
    }),

    decline: builder.mutation({
      query: (id) => ({
        url: `/orders/${id}/decline`,
        method: 'POST',
      }),
      invalidatesTags: ['fetchCampaignOrders'],
    }),

    payment: builder.mutation({
      query: (id) => ({
        url: `/orders/${id}/invoice`,
        method: 'POST',
      }),
      invalidatesTags: ['fetchCampaignOrders'],
    }),

    finish: builder.mutation({
      query: (id) => ({
        url: `/orders/${id}/finish`,
        method: 'POST',
      }),
      invalidatesTags: ['fetchCampaignOrders', 'CampaignView'],
    }),

    create: builder.mutation({
      query: (body) => {
        return {
          url: `/campaigns/${body.campaignId}/orders`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['fetchPublication'],
    }),

    fetchPublication: builder.query({
      query: (id) => {
        return {
          url: `/campaigns/${id}/orders/publication`,
        };
      },
      providesTags: ['fetchPublication'],
    }),

    publish: builder.mutation({
      query: ({ orderId, ...fd }) => ({
        url: `/orders/${orderId}/publish`,
        method: 'POST',
        body: fd,
      }),
      invalidatesTags: ['fetchPublication'],
    }),

    attachStatistic: builder.mutation({
      query: ({ orderId, ...fd }) => ({
        url: `/orders/${orderId}/attach-statistic`,
        method: 'POST',
        body: fd,
      }),
      invalidatesTags: ['fetchPublication'],
    }),

    changePublication: builder.mutation({
      query: ({ orderId, ...fd }) => ({
        url: `/orders/${orderId}/publish`,
        method: 'PATCH',
        body: fd,
      }),
      invalidatesTags: ['fetchPublication'],
    }),

    changePrice: builder.mutation({
      query: ({ orderId, ...fd }) => ({
        url: `/orders/${orderId}/change-price`,
        method: 'POST',
        body: fd,
      }),
    }),

    bloggerAccept: builder.mutation({
      query: (id) => ({
        url: `/orders/${id}/blogger-accept`,
        method: 'POST',
      }),
      invalidatesTags: ['fetchPublication'],
    }),

    bloggerDecline: builder.mutation({
      query: (id) => ({
        url: `/orders/${id}/blogger-decline`,
        method: 'POST',
      }),
      invalidatesTags: ['fetchPublication'],
    }),
  }),
});

export const useCampaignOrdersFetchQuery = campaignOrdersApi.endpoints.fetch.useQuery;
export const useCampaignOrdersFetchOneQuery = campaignOrdersApi.endpoints.fetchOne.useQuery;
export const useCampaignOrdersApplyMutation = campaignOrdersApi.endpoints.apply.useMutation;
export const useCampaignOrdersAcceptMutation = campaignOrdersApi.endpoints.accept.useMutation;
export const useCampaignOrdersDeclineMutation = campaignOrdersApi.endpoints.decline.useMutation;
export const useCampaignOrdersFinishMutation = campaignOrdersApi.endpoints.finish.useMutation;
export const useCampaignOrdersPublishMutation = campaignOrdersApi.endpoints.publish.useMutation;

export const useCampaignOrdersChangePublicationMutation =
  campaignOrdersApi.endpoints.changePublication.useMutation;

export const useCampaignOrdersAttachStatisticMutation =
  campaignOrdersApi.endpoints.attachStatistic.useMutation;

export const useCampaignOrdersPaymentMutation = campaignOrdersApi.endpoints.payment.useMutation;

export const useCampaignOrdersChangePriceMutation =
  campaignOrdersApi.endpoints.changePrice.useMutation;
export const useCampaignOrdersPublicationQuery =
  campaignOrdersApi.endpoints.fetchPublication.useQuery;

export const useCampaignOrdersCreateMutation = campaignOrdersApi.endpoints.create.useMutation;

export const useCampaignOrdersBloggerAcceptMutation =
  campaignOrdersApi.endpoints.bloggerAccept.useMutation;
export const useCampaignOrdersBloggerDeclineMutation =
  campaignOrdersApi.endpoints.bloggerDecline.useMutation;
