import AppBar from '@mui/material/AppBar';
import Hidden from '@mui/material/Hidden';
import Toolbar from '@mui/material/Toolbar';
import clsx from 'clsx';
import { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import { navbarToggle, selectFuseNavbar } from '../../store/fuse/navbarSlice';
import NotificationPanelToggleButton from '../../modules/notifications/NotificationPanelToggleButton';
import NavbarToggleButton from './NavbarToggleButton';
import UserMenu from './UserMenu';
import ChatPanelToggleButton from './ChatPanelToggleButton';
import { configuration, Roles } from '../../configs/configuration';
import { HeaderNavigation } from './HeaderNavigation';
import { useUser } from '../../modules/common/useUser';

function ToolbarLayout1(props) {
  const navbar = useSelector(selectFuseNavbar);
  const dispatch = useDispatch();
  const matches = useMediaQuery((theme) => theme.breakpoints.up('lg'));
  const { role } = useUser();

  useEffect(() => {
    if (matches) {
      dispatch(navbarToggle());
    }
  }, [matches]);

  return (
    <AppBar
      id="fuse-toolbar"
      className={clsx('flex relative z-20 border-0 border-b-2 bg-white shadow-sm', props.className)}
      color="default"
      variant="outlined"
      elevation={0}
      position="static"
    >
      <Toolbar className="lg:px-4 md:px-3 px-2 min-h-48 md:min-h-64 max-w-screen-xl w-full m-auto">
        <div className="flex flex-1">
          {!(navbar.open || navbar.mobileOpen) && (
            <Link
              to={
                role === Roles.USER
                  ? configuration.global.rootForBlogger
                  : configuration.global.rootForOwner
              }
              className="mr-2"
            >
              <img
                className="w-10 rounded"
                src={configuration.global.logo}
                alt="logo"
              />
            </Link>
          )}

          {!(navbar.open || navbar.mobileOpen) && (
            <Hidden lgUp>
              <NavbarToggleButton className="w-10 h-10 p-0 mx-0" />
            </Hidden>
          )}
        </div>

        <div className="flex items-center h-full overflow-x-auto">
          <ChatPanelToggleButton />

          <NotificationPanelToggleButton />

          <UserMenu />
        </div>
      </Toolbar>
    </AppBar>
  );
}

export default memo(ToolbarLayout1);
