import { createContext, ReactNode, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { UserEntity } from '../../types';
import { useProfileGetMeQuery } from '../profile/profile-api';
import { useAuth } from '../auth/AuthContext';
import { AppRoutes } from '../../configs/app-routes';
import { referral } from '../../utils/referral';

export type ProfileContextProps = {
  profile: UserEntity;
  refetch: () => void;
  isLoaded: boolean;
};
export const ProfileContext = createContext<ProfileContextProps>({} as any);

export const ProfileContextProvider = ({ children }: { children: ReactNode }) => {
  const { data: profile = {}, refetch, isSuccess, isError } = useProfileGetMeQuery();
  const { isAuthenticated } = useAuth();

  const isLoaded = isSuccess || isError;

  const hideContent = isAuthenticated && !profile;

  useEffect(() => {
    if (profile && profile.id) {
      referral.setUserId(profile.id);
    }
  }, [profile]);

  return (
    <ProfileContext.Provider value={{ profile: profile || {}, refetch, isLoaded }}>
      {profile?.blockedAt && <Navigate to={AppRoutes.profileLogout()} />}

      {hideContent ? null : children}
    </ProfileContext.Provider>
  );
};
