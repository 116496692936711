import { useTranslation } from 'react-i18next';
import { ProfileFormItem } from './ProfileFormItem';
import { profileConfigFields } from '../fields';
import { regionsForList } from '../../common/cities';

export const ProfileContacts = (props: { profile: any; config: any }) => {
  const { config, profile } = props;
  const { t } = useTranslation();

  return (
    <>
      <ProfileFormItem
        name={profileConfigFields.address.region}
        label={t('profile.addressRegion', { defaultValue: 'Область:' })}
        value={profile?.address?.region}
        orient="horizontal"
        errors={props.errors}
        variant="select"
        edit
        props={{
          size: 'small',
          items: [
            {
              id: undefined,
              label: 'Не вказано',
            },
            ...regionsForList,
          ],
        }}
      />

      {config.address.map((item) => (
        <ProfileFormItem
          orient="horizontal"
          errors={props.errors}
          edit
          {...item}
          key={item.name}
        />
      ))}
    </>
  );
};
