import { CampaignEntity, UserEntity } from '../types';

export const getSameSocialFromUserCampaign = (campaign: CampaignEntity, user: UserEntity) => {
  const socials = user?.socials;

  const { socialType } = campaign.socialTypes[0];
  const social = socials && socials.find((item) => item.socialType === socialType.title);

  return social;
};
