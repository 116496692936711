export const messages = {
  title: 'Список кампаній',

  filters: {
    type: 'Тип оплати',
    money: 'Оплата',
    barter: 'Бартер',
    barterMoney: 'Продукт з оплатою',
    socialTypes: 'Платформа',
    categories: 'Категорії',
  },

  header: 'Заголовок',

  notFound: {
    title: 'На жаль, нових кампаній не знайдено',
    content: 'Спробуйте змінити фільтр або дочекайтесь оновлення',
    button: 'Створити нову кампанію',
  },
};
