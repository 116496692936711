import dayjs from 'dayjs';
import CampaignFormItem, { CampaignFormItemContainer } from './CampaignFormItem';
import { campaignFields } from '../../campaign-view/campaignConfig';
import { categoriesConfig } from '../../../configs/categories-config';
import { messages } from '../../../messages';
import CampaignSocial from './CampaignSocial';
import CampaignCreateType from '../CampaignCreateType';
import CampaignPriceField from './CampaignPriceField';
import { CampaignFormSubtitle } from '../CampaignStyles';
import CampaignImages from './CampaignImages';
import { i18n } from '../../../i18n';

const m = messages.campaignCreate;

/**
 * Form Validation Schema
 */
const formConfig = [
  {
    variant: 'textfield',
    name: campaignFields.name,
    label: i18n.t('campaignCreate.name', { defaultValue: m.name }),
    required: true,
  },

  {
    label: i18n.t('campaignCreate.socialTypes', { defaultValue: m.socialTypes }),
    name: campaignFields.socialTypes,
    // variant: 'textfield',
    FieldComponent: CampaignSocial,
    required: true,
  },

  {
    Component: CampaignCreateType,
  },

  // {
  //   name: 'block',
  //   children: [
  //     {
  //       label: i18n.t'campaignCreatem.'dateStart, { defaultValue: m.dateStart ),
  //       name: campaignFields.dateStart,
  //       variant: 'date',
  //     },
  //     {
  //       label: i18n.t'campaignCreatem.'dateEnd, { defaultValue: m.dateEnd ),
  //       name: campaignFields.dateEnd,
  //       variant: 'date',
  //     },
  //   ],
  // },

  {
    label: i18n.t('campaignCreate.categories', { defaultValue: m.categories }),
    name: campaignFields.categories,
    variant: 'autocomplete',
    list: categoriesConfig,
    required: true,
    props: {
      limitTags: 3,
    },
  },

  // {
  //   label: i18n.t'campaignCreatem.'shortDescription, { defaultValue: m.shortDescription ),
  //   name: campaignFields.shortDescription,
  //   variant: "textarea",
  // },

  {
    label: i18n.t('campaignCreate.paymentType', { defaultValue: m.paymentType }),
    name: campaignFields.paymentType,
    variant: 'switcher',
    required: true,
    props: {
      items: [
        {
          label: i18n.t('campaignCreate.paymentTypeMoney', { defaultValue: m.paymentTypeMoney }),
          value: 'money',
        },
        {
          label: i18n.t('campaignCreate.paymentTypeBarter', { defaultValue: m.paymentTypeBarter }),
          value: 'barter',
        },
        {
          label: i18n.t('campaignCreate.paymentTypeBarterMoney', {
            defaultValue: m.paymentTypeBarterMoney,
          }),
          value: 'barter_money',
        },
      ],
    },
  },
  {
    label: i18n.t('campaignCreate.dateEnd', { defaultValue: m.dateEnd }),
    name: campaignFields.dateEnd,
    variant: 'date',
    required: true,
    props: {
      inputProps: {
        lang: 'uk',
        min: dayjs().startOf('day').toISOString(),
      },
      size: 'medium',
    },
  },

  { Component: CampaignPriceField },

  // {
  //   label: i18n.t'campaignCreatem.'publicationCount, { defaultValue: m.publicationCount ),
  //   name: campaignFields.publicationCount,
  //   required: true,
  //   props: {
  //     type: 'number',
  //     style: {
  //       width: 120,
  //     },
  //   },
  // },

  // {
  //   label: i18n.t'campaignCreatem.'price, { defaultValue: m.price ),
  //   name: campaignFields.price,
  //   description: m.desc.price,
  //   required: true,
  //   props: {
  //     fullWidth: false,
  //     width: 400,
  //   },
  // },

  {
    label: i18n.t('campaignCreate.description', { defaultValue: m.description }),
    name: campaignFields.description,
    variant: 'textarea',
    required: true,
    description: i18n.t('campaignCreate.descDescription', {
      defaultValue: 'Короткий опис бренду та кампанії, яку Ви збираєтесь промоутити',
    }),
    props: {
      rows: 12,
      resize: true,
    },
  },

  {
    Component: () => (
      <CampaignFormItemContainer orient="horizontal">
        <CampaignFormSubtitle>
          {i18n.t('campaignCreate.image', { defaultValue: 'Обкладинка:' })}
        </CampaignFormSubtitle>
        <CampaignImages
          multiple={false}
          name={`${campaignFields.image}`}
        />
      </CampaignFormItemContainer>
    ),
  },

  {
    label: i18n.t('campaignCreate.link', { defaultValue: m.link }),
    description: m.desc.link,
    name: campaignFields.link,
  },
];

function CampaignFormBaseInfo(props) {
  return (
    <>
      {formConfig.map((config) => {
        if (config.Component) {
          return (
            <config.Component
              {...config}
              key={config.name}
            />
          );
        }

        // if (config.name === "block") {
        //   return (
        //     <Box display="flex" key={config.name}>
        //       {config.children.map((_config, index) => (
        //         <Fragment key={_config.name}>
        //           {index === 1 && <Box width={20} />}
        //
        //           <Box flex={1}>
        //             <CampaignFormItem errors={props.errors} {..._config} />
        //           </Box>
        //         </Fragment>
        //       ))}
        //     </Box>
        //   );
        // }

        return (
          <CampaignFormItem
            size="medium"
            orient="horizontal"
            errors={props.errors}
            {...config}
            key={config.name}
            user={props.user}
          />
        );
      })}
    </>
  );
}

export default CampaignFormBaseInfo;
