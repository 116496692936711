import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { DialogConfirm } from '../../components/DialogConfirm';

export type CampaignViewStopProps = {
  onSubmit: () => void;
  onClose: () => void;
  open: boolean;
};

export const CampaignViewStop = (props: CampaignViewStopProps) => {
  const { t } = useTranslation();

  return (
    <DialogConfirm
      title={t('campaignViewStop.title', {
        defaultValue: 'Ви впевнені що хочете завершити кампанію?',
      })}
      iconHide
      maxWidth={520}
      {...props}
      content={t('campaignViewStop.content', {
        defaultValue: `Якщо ви завершити кампанію:
- Ви не можете скасувати цю дію
- Кампанія більше не буде відображатися в пошуку і ви не зможете її заново запустити
- Ви <b>не можете завершити</b> кампанію, якщо вже почали співпрацю з блогером`,
      })}
      buttons={
        <>
          <div className="min-w-[120px]">
            <Button
              fullWidth
              type="button"
              variant="outlined"
              color="primary"
              onClick={props.onClose}
            >
              {t('campaignViewStop.no', { defaultValue: 'Скасувати' })}
            </Button>
          </div>

          <div className="min-w-[120px] ml-4">
            <Button
              fullWidth
              type="button"
              variant="contained"
              color="primary"
              onClick={props.onSubmit}
            >
              {t('campaignViewStop.yes', { defaultValue: 'Завершити' })}
            </Button>
          </div>
        </>
      }
    />
  );
};
