import { useTranslation } from 'react-i18next';
import { UserPriceEntity } from '../../types';
import { Money } from '../../components/Money';
import { campaignTypesList } from '../../configs/campaign-type';

export type ProfilePriceListProps = {
  prices: Array<UserPriceEntity>;
};
export const ProfilePriceList = ({ prices }: ProfilePriceListProps) => {
  const { t } = useTranslation();

  return (
    <div className="border-t">
      <h3 className="title-3 mb-5 pt-5">{t('profileView.prices', { defaultValue: 'Прайсліст:' })}</h3>

      <table className="w-full text-sm text-left rtl:text-right">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            <th
              scope="col"
              className="px-6 py-3"
            >
              {t('profileView.type', { defaultValue: 'Тип співпраці' })}
            </th>
            <th
              scope="col"
              className="px-6 py-3"
            >
              {t('profileView.price', { defaultValue: 'Ціна' })}
            </th>
            <th
              scope="col"
              className="px-6 py-3"
            >
              {t('profileView.description', { defaultValue: 'Примітка' })}
            </th>
          </tr>
        </thead>
        <tbody>
          {prices.map((item) => (
            <tr
              key={item?.value + item?.price}
              className="bg-white border-b last:border-none"
            >
              <td className="px-6 py-4 font-bold whitespace-nowrap">
                {/* @ts-ignore */}
                {campaignTypesList[item?.value]?.label}
              </td>
              <td className="px-6 py-4">
                <Money amount={Number(item?.price)} />
              </td>
              <td className="px-6 py-4">{item?.description || '-'}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
