import { i18n } from '../i18n';

export const messages = {
  dashboards: 'Unique dashboard designs',
  campaigns: 'Campaigns',
  'dashboards.project': 'Campaigns',
  'campaigns.my': 'Campaigns My',
  'dashboards.product': 'New campaign',
  'settings.profile': 'Profile',
  orders: 'Orders',
  'orders.owner': 'Orders (Owner)',
  'chat.project': 'Messages',
  profile: 'Profile',
  'profile.profile': 'Profile settings',
  'profile.security': 'Security',
  'profile.logout': 'Вийти',
  info: 'Info',
  'info.start': 'C чего начать',
  'info.rules': 'Правила работы',
  'info.support': 'Поддержка',

  finished: i18n.t('common.finished', { defaultValue: 'Завершено' }),
  processing: i18n.t('common.processing', { defaultValue: 'В процесі' }),
  pending: i18n.t('common.pending', { defaultValue: 'В процесі' }),
  paused: i18n.t('common.paused', { defaultValue: 'Пошук зупинено' }),
  payment: i18n.t('common.payment', { defaultValue: 'Сплатити' }),
  waitForPayment: i18n.t('common.waitForPayment', { defaultValue: 'Очікує оплати' }),
  stopped: i18n.t('common.stopped', { defaultValue: 'Завершена' }),

  edit: 'Редагувати',
  stop: 'Зупинити',
  rerun: 'Повторний запуск',

  campaignTypes: {
    instagram_stories: 'Instagram сторіз',
    instagram_reels: 'Instagram reels',
    instagram_carousel: 'Instagram карусель',
    post: 'Пост',
    native_integration: 'Нативна інтеграція',
    ambasador: 'Бренд - амбасадор',
    youtube_ad: 'YouTube огляд',
    youtube_review: 'YouTube відгук',
    youtube_mention: 'YouTube згадка',
    tiktok_video: 'TikTok відео',
    tiktok_sound: 'TikTok звук',
    common_feedback: 'Відгук',
  },
};
