import { Fragment } from 'react';
import Box from '@mui/material/Box';
import Rating from '@mui/material/Rating';
import Typography from '@mui/material/Typography';
import dayjs from 'dayjs';
import CommentsDisabledIcon from '@mui/icons-material/CommentsDisabled';
import { useTranslation } from 'react-i18next';
import { UserPanel } from '../../components/UserPanel';
import { ContentText } from '../../components/Content';
import { NotFoundPanel } from '../../components/NotFoundPanel';
import { useFeedbacksFetchQuery } from '../feedbacks/feedbacks-api';
import { CanView } from '../../components/CanView';
import { Roles } from '../../configs/configuration';
import { UserEntity } from '../../types';
import 'dayjs/locale/uk';

const FORMAT = 'DD MMMM YYYY';

type ProfileViewFeedbacksProps = {
  user: UserEntity;
};

export const ProfileViewFeedbacks = ({ user }: ProfileViewFeedbacksProps) => {
  const { data: { data = [] } = {} } = useFeedbacksFetchQuery(user.id);
  const { t } = useTranslation();

  return (
    <div className="divide-y -mt-4">
      {data.map((feedback) => (
        <Fragment key={feedback.id}>
          <div className="flex md:flex-row flex-col py-4 ">
            <UserPanel
              user={feedback.userCreator}
              size="small"
              hideRating
            />

            <div>
              <ContentText
                label={t('profileView.rate', { defaultValue: 'Оцінка:' })}
                boldLabel
                content={
                  <Rating
                    size="small"
                    readOnly
                    className="mt-1"
                    defaultValue={feedback.rate}
                    style={{ verticalAlign: 'top' }}
                  />
                }
              />

              <Box maxWidth={500}>
                <Typography
                  variant="body2"
                  fontStyle="italic"
                  whiteSpace="pre-wrap"
                >
                  {feedback.feedback}
                </Typography>
              </Box>

              {feedback.createdAt && (
                <Typography
                  component={Box}
                  variant="body2"
                  color="grey"
                  mt={2}
                >
                  {dayjs(feedback.createdAt).locale('uk').format(FORMAT)}
                </Typography>
              )}
            </div>
          </div>
        </Fragment>
      ))}

      {data.length === 0 && (
        <Box
          minHeight={250}
          display="flex"
          alignItems="center"
        >
          <NotFoundPanel
            Icon={CommentsDisabledIcon}
            content={t('profileView.feedbacksNotFound', { defaultValue: 'На даний момент відгуки відсутні' })}
          />
        </Box>
      )}
    </div>
  );
};
