import { SocialTypes } from './configs/social-types';

export type SocialEntity = {
  socialType: SocialTypes;
  [key: string]: any;
};

export type UserPriceEntity = {
  value: string;
  description?: string;
  price: number;
};

export type UserEntity = {
  [key: string]: any;

  socials: Array<SocialEntity> | null;
  prices?: UserPriceEntity[];
};

export enum CampaignState {
  DRAFT = 'draft',
  PENDING = 'pending',
  PAUSED = 'paused',
  FINISHED = 'finished',
  STOPPED = 'stopped',
  WAIT_FOR_PAYMENT = 'wait_for_payment',
}

export enum PaymentType {
  BARTER = 'barter',
  MONEY = 'MONEY',
  BARTER_MONEY = 'barter_money',
}

export class CampaignSocial {
  socialType: SocialEntity;
}

export type CampaignEntity = {
  id: number;
  name: string;
  moderated: boolean;
  description: string;
  settings: any;
  requirements: object;
  image: string;
  state: CampaignState;
  images: string[];
  price: number;
  link?: string;
  campaignType: string;
  paymentType: PaymentType;
  minFollowersCount: number;
  publicationCount: string;
  user?: UserEntity;
  userId?: number;
  dateStart: string;
  dateEnd: string;
  isAttachStatistic: boolean;
  isShowInSearch: boolean;
  isOnlyOneBlogger: boolean;
  created_at: string;
  updated_at: string;
  socialTypes: CampaignSocial[];
  isApplied: boolean;
};

export type FeedbackEntity = any;
export type OrderEntity = any;
