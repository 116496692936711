import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../utils/axiosBaseQuery';
import { UserEntity } from '../../types';

export const smartSearchApi = createApi({
  reducerPath: 'smart-search',
  tagTypes: ['smart-search'],
  baseQuery: axiosBaseQuery({ baseUrl: '/smart-search' }),
  endpoints: (builder) => ({
    fetch: builder.query<UserEntity[], string>({
      query: (campaignId) => ({
        url: `/${campaignId}`,
      }),
    }),
  }),
});

export const { useFetchQuery } = smartSearchApi;
