import { ProfileWrapper } from './components/ProfileWrapper';
import { AppRoutes } from '../../configs/app-routes';
import { ProfileInterest } from './ProfileInterest';
import { ProfileView } from '../profile-view/ProfileView';
import { ProfileSecurity } from './components/ProfileSecurity';
import Profile from './Profile';
import { socialsRoutes } from '../socials/socials-routes';
import { TITLES } from '../../configs/titles';
import { breadcrumbs } from '../../messages';

export const ProfileRoutes = [
  {
    element: <ProfileWrapper />,
    handle: {
      crumb: () => breadcrumbs.profile,
    },
    path: AppRoutes.profile(),
    children: [
      ...socialsRoutes,

      {
        element: <Profile />,
        handle: {
          title: () => TITLES.profile(),
        },
        index: true,
      },

      {
        path: AppRoutes.profileSecurity(),
        element: <ProfileSecurity />,
        handle: {
          crumb: () => breadcrumbs.profileSecurity,
          title: () => TITLES.profileSecurity(),
        },
      },

      {
        path: AppRoutes.profileInterest(),
        element: <ProfileInterest />,
      },
    ],
  },

  {
    path: AppRoutes.profileInfo(),
    element: <ProfileView />,
  },
];
