import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import { ReactNode } from 'react';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import { HiArrowSmLeft } from 'react-icons/hi';
import { messages } from '../../../messages';
import { AppRoutes } from '../../../configs/app-routes';

export const SocialsConnectRouteLayout = ({ children }: { children: ReactNode }) => (
  <>
    <div className="mb-6">
      <Typography
        className="flex items-center sm:mb-3"
        component={Link}
        role="button"
        to={AppRoutes.profileSocials()}
        color="inherit"
      >
        <HiArrowSmLeft size={20} />
        <span className="flex mx-4 font-medium hover:underline">{messages.socials.back}</span>
      </Typography>
    </div>

    <Box
      component={Card}
      className="md:p-8 p-4"
    >
      {children}
    </Box>
  </>
);
