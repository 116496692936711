import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Chip from '@mui/material/Chip';
import { useTranslation } from 'react-i18next';
import { CampaignCard } from '../../components/CampaignCard/CampaignCard';
import { CampaignEntity } from '../../types';

type CampaignsFeedListItemType = {
  campaign: CampaignEntity;
};

function CampaignsFeedListItem(props: CampaignsFeedListItemType) {
  const { campaign } = props;
  const { t } = useTranslation();
  const { link } = campaign;

  const showAppliedBadge = campaign.isApplied;

  return (
    <CampaignCard
      campaign={campaign}
      badges={
        showAppliedBadge ? (
          <div className="ml-auto text-center">
            <Chip
              color="primary"
              overlap="circular"
              label={t('campaigns.feedApplied', { defaultValue: 'В роботі' })}
              style={{ whiteSpace: 'nowrap' }}
            />
          </div>
        ) : null
      }
      content={
        false &&
        link &&
        link !== '' && (
          <div>
            <Typography
              variant="subtitle2"
              fontWeight="bold"
            >
              {t('campaigns.feedLink', { defaultValue: 'Link to object:' })}
            </Typography>

            <Link
              href={campaign.link}
              target="_blank"
            >
              {link}
            </Link>
          </div>
        )
      }
    />
  );
}

export default CampaignsFeedListItem;
