import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import { AppRoutes } from '../../../configs/app-routes';
import { NotificationBase } from './NotificationBase';

export const NotificationOrderPublished = ({ payload, onClick, ...rest }) => {
  return (
    <NotificationBase
      {...rest}
      title="Кампанія успішно опублікована"
      // createdAt={payload.createdAt}
      content={
        <>
          Кампанія{' '}
          <Link
            className="link"
            onClick={onClick}
            to={AppRoutes.campaignView(payload.campaign.id)}
          >
            {payload.campaign.name}
          </Link>{' '}
          успішно опублікована
        </>
      }
      Buttons={
        <>
          <Button
            size="small"
            color="primary"
            variant="contained"
            className="mb-1 mt-2"
            component={Link}
            onClick={onClick}
            to={AppRoutes.campaignView(payload.campaign.id)}
          >
            Переглянути кампанію
          </Button>
        </>
      }
    />
  );
};
