import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import { useFormContext, useWatch } from 'react-hook-form';
import { campaignFields } from '../../campaign-view/campaignConfig';
import { messages } from '../../../messages';
import CampaignFormItem from './CampaignFormItem';

function CampaignPriceField(props) {
  const form = useWatch();
  const { formState, control } = useFormContext();
  const paymentType = form[campaignFields.paymentType];

  if (paymentType === 'barter') {
    return null;
  }

  return (
    <Box mt={1}>
      <CampaignFormItem
        size="medium"
        required
        orient="horizontal"
        control={control}
        errors={formState.errors}
        name={campaignFields.price}
        label={messages.campaignCreate.price}
        props={{
          fullWidth: false,
          sx: { width: 120 },
          InputProps: {
            endAdornment: <InputAdornment position="start">₴</InputAdornment>,
          },
          inputProps: {
            maxLength: 7,
            inputMode: 'numeric',
            pattern: '[0-9.]*',
          },
        }}
      />
    </Box>
  );
}

export default CampaignPriceField;
