import { Dialog } from '@mui/material';
import { useEffect, useState, memo } from 'react';
import { useUser } from '../common/useUser';
import { Roles } from '../../configs/configuration';
import { WelcomeBlogger } from './WelcomeBlogger';
import { WelcomeOwner } from './WelcomeOwner';

const brandKey = 'welcome_brand';
const isFilledProfile = !(localStorage.getItem('welcome') || localStorage.getItem(brandKey));

export const Welcome = memo(() => {
  const { user } = useUser();
  const [showWelcome, setShowWelcome] = useState(isFilledProfile);

  useEffect(() => {
    if (user && isFilledProfile) {
      if (user.role === Roles.BRAND) {
        localStorage.setItem(brandKey, '1');
      } else {
        localStorage.setItem('welcome', '1');
      }
      setShowWelcome(true);
    }
  }, [user]);

  const handleClose = () => {
    setShowWelcome(false);
  };

  return (
    <Dialog
      open={showWelcome}
      onClose={handleClose}
      PaperProps={{
        variant: 'elevation',
      }}
    >
      {user.role === Roles.USER ? (
        <WelcomeBlogger onClose={handleClose} />
      ) : (
        <WelcomeOwner onClose={handleClose} />
      )}
    </Dialog>
  );
});
