import { configureStore } from '@reduxjs/toolkit';
import createReducer from './rootReducer';
import { campaignInfluencerApi } from '../modules/campaigns-influencer/campaign-influencer-api';
import { reportProblemApi } from '../modules/report-problem/report-problem-api';
import { smartSearchApi } from '../modules/smart-search/smart-search-api';
import { notificationsApi } from '../modules/notifications/notifications-api';
import { campaignsOffers } from '../modules/campaign-view/redux/campaign-offer-api';
import { campaignViewApi } from '../modules/campaign-view/redux/campaign-view-api';
import { socialsApi } from '../modules/socials/socials-api';
import { campaignOrdersApi } from '../modules/orders/campaign-orders-api';
import { campaignFeedApi } from '../modules/campaigns-feed/campaign-feed-api';
import { chatApi } from '../modules/chat/chat-api';
import { profileApi } from '../modules/profile/profile-api';
import { feedbacksApi } from '../modules/feedbacks/feedbacks-api';
import { forgotPasswordApi } from '../modules/forgot-password/forgot-password-api';
import { creditsApi } from '../modules/credits/credits-api';
import { usersApi } from '../modules/users/users-api';
import { balanceApi } from '../modules/balance/balance-api';
import { paymentsApi } from '../modules/balance/payments-api';
import { userPaymentsApi } from '../modules/payments/payments-api';
import { userFeedbackApi } from '../modules/user-feedback/user-feedback-api';
import { userQuizApi } from '../modules/user-quiz/user-quiz-api';
import { campaignApi } from '../modules/campaign-view/redux/campaign-api';
import { notificationsPushApi } from '../modules/common/push-notifications/push-notification-api';

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./rootReducer', () => {
    const newRootReducer = require('./rootReducer').default;
    store.replaceReducer(newRootReducer.createReducer());
  });
}

const middlewares = [
  campaignInfluencerApi.middleware,
  reportProblemApi.middleware,
  smartSearchApi.middleware,
  notificationsApi.middleware,
  campaignsOffers.middleware,
  campaignViewApi.middleware,
  socialsApi.middleware,
  campaignOrdersApi.middleware,
  campaignFeedApi.middleware,
  chatApi.middleware,
  profileApi.middleware,
  feedbacksApi.middleware,
  forgotPasswordApi.middleware,
  creditsApi.middleware,
  usersApi.middleware,
  balanceApi.middleware,
  campaignApi.middleware,
  paymentsApi.middleware,
  userPaymentsApi.middleware,
  userFeedbackApi.middleware,
  userQuizApi.middleware,
  notificationsPushApi.middleware,
];

if (process.env.NODE_ENV === 'development') {
  const { createLogger } = require(`redux-logger`);
  const logger = createLogger({ collapsed: (getState, action, logEntry) => !logEntry.error });

  middlewares.push(logger);
}

const store = configureStore({
  reducer: createReducer(),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }).concat(middlewares),
  devTools: process.env.NODE_ENV === 'development',
});

store.asyncReducers = {};

export const injectReducer = (key, reducer) => {
  if (store.asyncReducers[key]) {
    return false;
  }
  store.asyncReducers[key] = reducer;
  store.replaceReducer(createReducer(store.asyncReducers));
  return store;
};

export default store;
