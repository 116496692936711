import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import InputLabel, { InputLabelProps } from '@mui/material/InputLabel';
import styled from '@emotion/styled';
import { ReactNode } from 'react';

export const CampaignFormTitle = (props) => (
  <Box my={1}>
    <Typography
      variant="h6"
      fontWeight="bold"
      {...props}
    />
  </Box>
);

export const CampaignFormTitleDescription = (props) => (
  <Box
    mt={-1}
    mb={4}
  >
    <Typography
      variant="caption"
      className="font-medium"
      {...props}
    />
  </Box>
);

export type CampaignFormSubtitleProps = InputLabelProps & {
  children?: ReactNode;
  className?: string;
};
export const CampaignFormSubtitle = ({
  children,
  dangerouslySetInnerHTML,
  className,
  ...props
}: CampaignFormSubtitleProps) => (
  <InputLabel
    style={{ whiteSpace: 'break-spaces' }}
    className={className}
    {...props}
  >
    <Typography
      variant="subtitle2"
      noWrap={false}
      component="span"
      color="black"
      dangerouslySetInnerHTML={dangerouslySetInnerHTML}
      {...props}
    >
      {children}
    </Typography>
  </InputLabel>
);

export const CampaignFormDescription = (props) => (
  <Typography
    variant="caption"
    component="div"
    whiteSpace="pre-wrap"
    color="gray"
    {...props}
  />
);

export const CampaignFormBody = styled.div`
  opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : '')};
`;
