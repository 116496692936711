import { useState } from 'react';
import Button from '@mui/material/Button';
import { messages } from '../../messages';
import { useCampaignOrdersBloggerDeclineMutation } from '../orders/campaign-orders-api';
import { DialogDefaultConfirmation } from '../../components/DialogDefaultConfirmation';
import { useToasts } from '../../components/Toasts';

const m = messages.publications;

export const OrdersOfferDecline = ({ orderId, onDecline }) => {
  const [bloggerDecline] = useCampaignOrdersBloggerDeclineMutation();
  const { success, error } = useToasts();

  const [showConfirmation, setShowConfirmation] = useState(false);

  const handleDecline = () => {
    bloggerDecline(orderId)
      .unwrap()
      .then(() => {
        success(m.offer.declineConfirmationSuccess);

        if (onDecline) {
          onDecline();
        }
      })
      .catch(() => {
        error(messages.error);
      });
  };

  return (
    <>
      <DialogDefaultConfirmation
        title={m.offer.declineConfirmation}
        open={showConfirmation}
        onSubmit={handleDecline}
        onClose={() => setShowConfirmation(false)}
      />
      <Button
        variant="outlined"
        color="primary"
        fullWidth
        size='small'
        onClick={() => setShowConfirmation(true)}
      >
        {m.offer.decline}
      </Button>
    </>
  );
};
