import { Link } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { useMemo } from 'react';
import { AppRoutes } from '../../configs/app-routes';
import { UserEntity } from '../../types';

type UsernameProps = {
  user: UserEntity;
  disabled?: boolean;
};
export const Username = ({ user, disabled }: UsernameProps) => {
  const { firstName, lastName } = user;
  const trimmedText = useMemo(
    () => `${firstName ? firstName.trim() : ''}${lastName ? ` ${lastName.trim()}` : ''}`,
    [firstName, lastName]
  );

  if (disabled) {
    return <span className="text-sm">{trimmedText}</span>;
  }

  return (
    <Link
      target="_blank"
      disabled={disabled}
      className="text-sm link"
      to={AppRoutes.profileView(user.uuid)}
      component={RouterLink}
    >
      {trimmedText}
    </Link>
  );
};
