import { useTranslation } from 'react-i18next';
import { FinalFields } from '../../../components/FinalFields/FinalFields';
import CampaignFormItem from '../../campaign-create/components/CampaignFormItem';
import { ProfileCard } from '../Profile.styles';
import { campaignTypesList } from '../../../configs/campaign-type';

export type ProfilePriceListProps = {
  errors: any;
};
export const ProfilePriceList = ({ errors }: ProfilePriceListProps) => {
  const { t } = useTranslation();
  const list = Object.values(campaignTypesList);

  return (
    <ProfileCard
      title={t('profile.priceListTitle', { defaultValue: 'Прайсліст' })}
      subtitle={t('profile.priceListSubtitle', {
        defaultValue: 'Зазначте ціни на Ваші послуги нижче:',
      })}
    >
      <div
        className="w-full max-w-full mb-4"
        style={{ maxWidth: '100%', marginRight: 0, marginTop: 0 }}
      >
        <FinalFields
          name="prices"
          FieldComponent={({ index, control }) => (
            <div className="-mb-4 flex-1 flex  gap-2">
              <div className="max-w-[200px]">
                <CampaignFormItem
                  name={`prices[${index}].value`}
                  label={t('profile.priceListType', { defaultValue: 'Тип' })}
                  control={control}
                  variant="select"
                  required
                  props={{
                    items: list,
                  }}
                  errors={errors}
                  size="small"
                />
              </div>

              <div className="max-w-[120px]">
                <CampaignFormItem
                  label={t('profile.priceListPrice', { defaultValue: 'Ціна' })}
                  name={`prices[${index}].price`}
                  control={control}
                  required
                  errors={errors}
                  size="small"
                  type="number"
                  placeholder="500 (грн)"
                />
              </div>

              <CampaignFormItem
                name={`prices[${index}].description`}
                label={t('profile.priceListDescription', { defaultValue: 'Примітка' })}
                control={control}
                errors={errors}
                size="small"
              />
            </div>
          )}
          appendLabel={t('profile.priceListAppend', { defaultValue: 'Додати прайс' })}
        />
      </div>
    </ProfileCard>
  );
};
