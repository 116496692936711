import flatten from 'lodash/flatten';
import { useMemo } from 'react';
import { FollowersCountChart } from '../charts/FollowersCountChart';
import { messages } from '../socials/messages';
import { CityDemographics } from '../charts/CityDemographics';
import { ChartBarAgeDemographics } from '../charts/ChartBarAgeDemographics';
import { ChartBarCountriesDemographics } from '../charts/ChartBarCountriesDemographics';
import { ProfileViewInstagramStat } from './ProfileViewInstagramStat';
import { CardStatistic } from '../../components/CardStatistic';
// import flatMap

const findDataByName = (data, name) => {
  return flatten(data).find((item) => item?.name === name);
};
export const ProfileViewCharts = ({ data }) => {
  let detailsStatForMonth = (data && data[1]) || [];

  if (detailsStatForMonth.data) {
    detailsStatForMonth = detailsStatForMonth.data;
  }

  const followersData = findDataByName(data, 'follower_count');
  const followersDemographic = findDataByName(data, 'follower_demographics');
  const followersDemographicAge = data && data[4] && data[4][0];
  const followersDemographicCountries = data && data[5] && data[5][0];

  let mediaTypeStat = data && data[6];

  const filteredDetails = useMemo(() => {
    return detailsStatForMonth.filter((item) => messages.instagram.fiels[item?.name]);
  }, [detailsStatForMonth]);

  const mediaTypeStatByPlatform = useMemo(() => {
    const result: { [key: string]: any } = {};

    if (mediaTypeStat) {
      if (mediaTypeStat.data) {
        mediaTypeStat = mediaTypeStat.data;
      }
      mediaTypeStat.map((item) => {
        console.log('ITEM', item);
        const { totalValue } = item;
        const { total_value } = item;
        const breakdowns = (totalValue || total_value).breakdowns[0].results;
        const key = item.name;

        breakdowns &&
          breakdowns.forEach((breakdown) => {
            const dimension_value = breakdown.dimensionValues
              ? breakdown.dimensionValues[0]
              : breakdown.dimension_values[0];
            const { value } = breakdown;

            if (!result[dimension_value]) {
              result[dimension_value] = { [key]: value };
            } else {
              result[dimension_value][key] = value;
            }
          });
      });
    }

    return result;
  }, [mediaTypeStat]);

  const followersDemographicAgeFormatted =
    followersDemographicAge &&
    (followersDemographicAge?.totalValue?.breakdowns[0].results ||
      followersDemographicAge?.total_value?.breakdowns[0].results);

  const reach = findDataByName(data, 'reach');
  const accounts_engaged = findDataByName(data, 'accounts_engaged');

  const reachValue = reach && (reach?.total_value.value || reach.totalValue.value);
  const accountsEngagedValue =
    reach && (accounts_engaged?.total_value.value || accounts_engaged.totalValue.value);

  const reelsStat = mediaTypeStatByPlatform && mediaTypeStatByPlatform.REEL;
  const postsStat = mediaTypeStatByPlatform && mediaTypeStatByPlatform.POST;

  return (
    <div>
      <h2 className="mt-8 title-2">{messages.instagram.statForMonth}</h2>

      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3 mt-4">
        {filteredDetails.map((item) => (
          <CardStatistic
            key={messages.instagram.fiels[item?.name]}
            value={item?.totalValue?.value || item?.total_value?.value}
            title={messages.instagram.fiels[item?.name]}
          />
        ))}

        {reachValue && (
          <CardStatistic
            title="Engagement Rate:"
            value={`${((accountsEngagedValue / reachValue) * 100).toFixed(2)}%`}
          />
        )}
      </div>

      {reelsStat && (
        <ProfileViewInstagramStat
          data={reelsStat}
          title="Статистика Reels:"
        />
      )}

      {postsStat && (
        <ProfileViewInstagramStat
          data={postsStat}
          title="Статистика Постів:"
        />
      )}

      <div className="grid grid-cols-1 xl:grid-cols-2 gap-4 mt-8">
        {followersDemographicCountries && (
          // <Card>
          <ChartBarCountriesDemographics data={followersDemographicCountries} />
          // </Card>
        )}

        {followersDemographicAge && (
          // <Card>
          <ChartBarAgeDemographics data={followersDemographicAgeFormatted} />
          // </Card>
        )}

        {followersData && (
          // <Card>
          <FollowersCountChart data={followersData} />
          // </Card>
        )}

        {followersDemographic && <CityDemographics data={followersDemographic} />}
      </div>
    </div>
  );
};
