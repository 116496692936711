import ForgotPassword from './ForgotPassword';
import authRoles from '../auth/authRoles';
import {AppRoutes} from "../../configs/app-routes";
import ForgotPasswordRecovery from "./ForgotPasswordRecovery";

const ForgotPasswordConfig = {
  settings: {
    layout: {
      config: {
        navbar: {
          display: false,
        },
        toolbar: {
          display: false,
        },
        footer: {
          display: false,
        },
        leftSidePanel: {
          display: false,
        },
        rightSidePanel: {
          display: false,
        },
      },
    },
  },
  auth: authRoles.onlyGuest,
  routes: [
    {
      path: AppRoutes.forgotPassword(),
      element: <ForgotPassword />,
    },
    {
      path: AppRoutes.forgotPasswordRecovery(),
      element: <ForgotPasswordRecovery />,
    },
  ],
};

export default ForgotPasswordConfig;
