import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../utils/axiosBaseQuery';

export const creditsApi = createApi({
  reducerPath: 'credits',
  tagTypes: ['credits'],
  baseQuery: axiosBaseQuery({ baseUrl: '/api/credits' }),
  endpoints: (builder) => ({
    fetchCount: builder.query({
      query: (campaignId) => ({
        url: `/me`,
      }),
    }),
  }),
  refetchOnMountOrArgChange: true,
});

export const useCreditsFetch = creditsApi.endpoints.fetchCount.useQuery;
