import { createBrowserRouter, Navigate } from 'react-router-dom';
import Loading from '../components/Loading';
import SignInConfig from '../modules/sign-in/SignInConfig';
import SignUpConfig from '../modules/sign-up/SignUpConfig';
import SignOutConfig from '../modules/sign-out/SignOutConfig';
import { AppRoutes } from '../configs/app-routes';
import { ProfileRoutes } from '../modules/profile/profileRoutes';
import ChatsConfig from '../modules/chat/ChatsConfig';
import { CampaignsInfluencer } from '../modules/campaigns-influencer/CampaignsInfluencer';
import Error404Page from './Error404Page';
import { NotificationsPage } from '../modules/notifications/NotificationsPage';
import ForgotPasswordConfig from '../modules/forgot-password/ForgotPasswordConfig';
import { Credits } from '../modules/credits';
import { Payments } from '../modules/payments';
import { Users } from '../modules/users';
import OrderPayment from '../modules/orders/OrderPayment';
import { RoutesLayout } from './RoutesLayout';
import { ProtectedRoute } from './ProtectedRoute';
import { Roles } from '../configs/configuration';
import { AuthRoutesLayout } from './AuthRoutesLayout';
import { CampaignRoutes } from './campaigns-routes';
import { AuthRoutesLayoutNoGuard } from './AuthRoutesLayoutNoGuard';
import { SignUpBloggerPage } from '../modules/sign-up/SignUpBlogger';

const routes = createBrowserRouter(
  [
    {
      path: AppRoutes.signUpBlogger(),
      element: (
        <AuthRoutesLayoutNoGuard>
          <SignUpBloggerPage />
        </AuthRoutesLayoutNoGuard>
      ),
    },
    {
      path: '/',
      element: <RoutesLayout />,
      children: [
        ...ProfileRoutes,
        ...ChatsConfig,

        CampaignRoutes,

        {
          index: true,
          element: <Navigate to={AppRoutes.campaigns()} />,
        },

        {
          path: AppRoutes.credits(),
          element: (
            <ProtectedRoute roles={[Roles.USER]}>
              <Credits />
            </ProtectedRoute>
          ),
        },
        {
          path: AppRoutes.users(),
          element: <Users />,
        },
        // {
        //   path: AppRoutes.balance(),
        //   element: <Balance />,
        // },
        {
          path: AppRoutes.payments(),
          element: <Payments />,
        },
        {
          path: AppRoutes.campaignsInfluencer(),
          element: (
            <ProtectedRoute roles={[Roles.USER]}>
              <CampaignsInfluencer />
            </ProtectedRoute>
          ),
        },
        {
          path: AppRoutes.notifications(),
          element: <NotificationsPage />,
        },
        {
          path: AppRoutes.acceptOrderPayment(),
          element: <OrderPayment />,
        },

        // {
        //   path: '/',
        //   element: <Navigate to={AppRoutes.campaigns()} />,
        // },

        {
          path: 'loading',
          element: <Loading />,
        },
      ],
    },
    {
      path: '/',
      element: <AuthRoutesLayout />,
      children: [
        // {
        //   path: '/',
        //   element: <Navigate to={AppRoutes.signIn()} />,
        // },
        ...SignOutConfig.routes,
        ...SignInConfig.routes,
        ...ForgotPasswordConfig.routes,
        ...SignUpConfig.routes,
      ],
    },

    {
      path: '*',
      element: <RoutesLayout />,
      children: [
        {
          index: true,
          path: '*',
          element: <Error404Page />,
          // element: <div />,
        },
      ],
    },
  ],
  {
    basename: '/app',
  }
);

export default routes;
