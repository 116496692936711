import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import { useState } from 'react';
import { messages } from '../../messages';
import { useToasts } from '../../components/Toasts';
import { useUsersCreateOrderToBloggerByCampaignId } from '../users/users-api';
import { DialogConfirm } from '../../components/DialogConfirm';

export const SmartSearchOffer = ({ selected, count, campaign, onClose, ...props }) => {
  const { t } = useTranslation();
  const [disabled, setDisabled] = useState(false);

  const [offer] = useUsersCreateOrderToBloggerByCampaignId();

  const { success, error } = useToasts();

  const submit = async () => {
    setDisabled(true);
    try {
      await Promise.all(
        selected.map((userId) => offer({ ...campaign, userId }).unwrap())
      );
      success(t('smartSearchDialog.sent', { defaultValue: 'Ми надіслали вашу пропозицію.' }));
      onClose();
    } catch (err) {
      error(messages.error);
      onClose();
    }
  };

  return (
    <DialogConfirm
      title={t('smartSearchDialog.title', {
        defaultValue: `Ви впевнені, що хочете запропонувати роботу блогерам?`,
      })}
      iconHide
      maxWidth={520}
      content={t('smartSearchDialog.content', {
        defaultValue: `Ви обрали {{count}} блогерів`,
        count,
      })}
      {...props}
      buttons={
        <>
          <Box minWidth={140}>
            <Button
              fullWidth
              type="button"
              variant="outlined"
              color="primary"
              onClick={onClose}
            >
              {t('smartSearchDialog.no', { defaultValue: 'Ні' })}
            </Button>
          </Box>

          <Box
            minWidth={140}
            ml={2}
          >
            <Button
              fullWidth
              type="button"
              variant="contained"
              color="primary"
              onClick={submit}
              loading={disabled}
            >
              {t('smartSearchDialog.yes', { defaultValue: 'Так' })}
            </Button>
          </Box>
        </>
      }
    />
  );
};
