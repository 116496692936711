import { useState } from 'react';
import { Divider } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Tab from '@mui/material/Tab';
import { NavLink, useParams, Navigate, useLocation } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import Pagination from '@mui/material/Pagination';
import { FaRegSadTear } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { AppRoutes } from '../../configs/app-routes';
import { OrderItem } from './OrderItem';
import { NotFoundPanel } from '../../components/NotFoundPanel';
import { FeedbackForm } from '../feedbacks';
import { OrdersState } from '../../configs/configuration';
import { Anchors } from '../../configs/anchors';
import { useCampaignOrdersFetchQuery } from './campaign-orders-api';

const Tabs = {
  PENDING: 'pending',
  PROCESSING: 'processing',
  ALL: 'all',
};

const queryByParams = {
  [Tabs.PENDING]: { state: OrdersState.PENDING },
  [Tabs.PROCESSING]: {
    state: [OrdersState.PUBLICATION_WAIT_REVIEW, OrdersState.PUBLICATION_PROGRESS],
  },
  [Tabs.ALL]: {},
};

export const Orders = (props) => {
  const { campaign = {}, onOrderFinish } = props;
  const { t } = useTranslation();
  const { palette } = useTheme();
  const params = useParams();
  const { pathname } = useLocation();
  const query = queryByParams[params.state];
  const { data: { data = [] } = {}, refetch } = useCampaignOrdersFetchQuery({
    id: campaign.id,
    ...query,
  });

  const [feedbackOrder, setFeedbackOrder] = useState();

  const activeStyle = {
    color: palette.secondary.main,
    fontWeight: 'bold',
  };

  if (pathname === AppRoutes.campaignView(campaign.id)) {
    return (
      <Navigate
        replace
        to={AppRoutes.campaignPendingRequests(campaign.id, Tabs.ALL)}
      />
    );
  }

  return (
    <div className="flex-1">
      <Divider />

      <FeedbackForm
        order={feedbackOrder}
        campaign={campaign}
        onSubmit={refetch}
        open={Boolean(feedbackOrder)}
        user={feedbackOrder?.blogger}
        onClose={() => setFeedbackOrder(undefined)}
      />

      <div className="mb-5 mt-6 flex items-center">
        <Typography
          id={Anchors.ORDERS}
          variant="h5"
          fontWeight="bold"
          align="middle"
        >
          {t('orders.title', { defaultValue: 'Пропозиції' })}
        </Typography>

        {false && (
          <>
            <Box
              aria-label="nav tabs example"
              ml="auto"
            >
              <Tab
                label={t('orders.navAll', { defaultValue: 'Всі заявки' })}
                component={NavLink}
                to={AppRoutes.campaignPendingRequests(campaign.id, Tabs.ALL)}
                style={({ isActive }) => (isActive ? activeStyle : undefined)}
              />

              <Tab
                label={t('orders.navPending', { defaultValue: 'Очікувані запити' })}
                component={NavLink}
                to={AppRoutes.campaignPendingRequests(campaign.id, Tabs.PENDING)}
                style={({ isActive }) => (isActive ? activeStyle : undefined)}
              />

              <Tab
                label={t('orders.navProcessing', { defaultValue: 'Обробка заявок' })}
                component={NavLink}
                to={AppRoutes.campaignPendingRequests(campaign.id, Tabs.PROCESSING)}
                style={({ isActive }) => (isActive ? activeStyle : undefined)}
              />
            </Box>
          </>
        )}
      </div>

      {data.map((item) => (
        <OrderItem
          onClickFeedback={() => setFeedbackOrder(item)}
          order={item}
          key={item.id}
          onFinish={onOrderFinish}
        />
      ))}

      {false && Boolean(data.length) && (
        <Box
          display="flex"
          justifyContent="center"
        >
          <Pagination />
        </Box>
      )}

      {!(data && data.length) && (
        <Box
          minHeight={300}
          display="flex"
          flexDirection="column"
        >
          <Divider />
          <NotFoundPanel
            Icon={FaRegSadTear}
            title={t('orders.notFoundTitle', { defaultValue: 'Заявки наразі відсутні' })}
            content={t('orders.notFoundContent', {
              defaultValue: 'Очікуйте нові запити від блогерів',
            })}
          />
        </Box>
      )}
    </div>
  );
};
