import styled from '@emotion/styled';
import { Suspense } from 'react';
import { Navigate, Outlet, useLocation, ScrollRestoration } from 'react-router-dom';
import Loading from '../components/Loading';
import { Welcome } from '../modules/welcome';
import { Footer } from '../components/Footer/Footer';
import { useAuth } from '../modules/auth/AuthContext';
import NavbarWrapperLayout1 from '../components/Layout/NavbarWrapperLayout1';
import ToolbarLayout1 from '../components/Layout/ToolbarLayout1';
import { AppRoutes } from '../configs/app-routes';
import { ProfileContextProvider } from '../modules/common/ProfileContext';
import { PageContainer } from '../components/PageContainer';
import { RoutesTitleHandler } from '../components/RoutesTitleHandler';
import { UserSocialsGuard } from '../modules/common/UserSocialsGuard';
// import { PushNotification } from '../modules/common/push-notifications/PushNotification';

const Root = styled.div({
  '& .container': {
    width: '100%',
    margin: '0 auto',
  },
});

export const RoutesLayout = () => {
  const { isAuthenticated, waitAuthCheck } = useAuth();
  const { pathname } = useLocation();
  // const [isOpenDialog, setIsOpenDialog] = useState(true);
  // const closeDialog = () => {
  //   setIsOpenDialog(false);
  // };

  const isAuthRoute =
    pathname === AppRoutes.signIn() ||
    pathname === AppRoutes.signUp() ||
    pathname === AppRoutes.profileLogout();

  if (!isAuthenticated && !waitAuthCheck) {
    return <Navigate to={AppRoutes.signIn()} />;
  }

  return (
    <ProfileContextProvider>
      <ScrollRestoration />

      <UserSocialsGuard />

      <Root
        id="fuse-layout"
        className="w-full flex h-full flex-col flex-1"
      >
        {isAuthenticated && !isAuthRoute && <Welcome />}

        <div className="flex flex-auto min-w-0">
          <NavbarWrapperLayout1 />

          <main
            id="fuse-main"
            className="flex flex-col flex-auto min-h-full min-w-0 relative z-10"
          >
            <ToolbarLayout1 className={'fixed' && 'sticky top-0'} />

            <div className="flex bg-[#f1f5f9] flex-col flex-auto min-h-0 relative z-10">
              <PageContainer>
                {/* <Breadcrumbs /> */}

                <RoutesTitleHandler />

                <Suspense fallback={<Loading delay={0} />}>
                  <Outlet />
                </Suspense>
              </PageContainer>
            </div>

            <Footer />
          </main>
        </div>
      </Root>
    </ProfileContextProvider>
  );
};
