import { FC, ReactElement } from 'react';
import { useUser } from '../../modules/common/useUser';

type CanViewProps = {
  role?: any;
  children: ReactElement;
  condition?: boolean;
  roles?: any[];
};

export const CanView: FC<CanViewProps> = ({
  role,
  children,
  condition = true,
  roles,
}: CanViewProps) => {
  const { user } = useUser();

  if (user.role === role && condition) {
    return children;
  }

  if (roles && roles.length === 0 && condition) {
    return children;
  }

  if (roles && roles.includes(user.role)) {
    return children;
  }

  return <>{null}</>;
};
