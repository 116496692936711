import { useEffect, useState } from 'react';
import { useProfile } from '../profile/useProfile';

export const USER_QUIZ_AUTO_SHOW_TIME = 15000;

export const useUserQuiz = () => {
  const { profile, hideAutoShowFeedbackForm } = useProfile();

  const [autoShowFeedbackForm, setAutoShowFeedbackForm] = useState(false);

  useEffect(() => {
    if (profile && profile.autoShowFeedbackForm) {
      setTimeout(() => {
        setAutoShowFeedbackForm(true);
      }, USER_QUIZ_AUTO_SHOW_TIME);
    }
  }, [profile]);
  const hide = async () => {
    setAutoShowFeedbackForm(false);
    await hideAutoShowFeedbackForm({});
  };

  return {
    autoShowFeedbackForm,
    hide,
  };
};
