import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PanelSuccess } from '../../components/PanelSuccess';
import { AppRoutes } from '../../configs/app-routes';
import { FeedbackForm } from '../feedbacks';

export type CampaignPublicationFinishedProps = {
  order: any;
  campaign: any;
  onSubmitFeedback: () => void;
};
export const OrdersPublicationFinished = ({
  order,
  campaign,
  onSubmitFeedback,
}: CampaignPublicationFinishedProps) => {
  const [showFeedback, setShowFeedback] = useState<boolean>(false);
  const { t } = useTranslation();

  const { hasBloggerFeedback } = order;

  return (
    <>
      <FeedbackForm
        order={order}
        campaign={campaign}
        open={showFeedback}
        user={order?.user}
        onSubmit={() => {
          onSubmitFeedback();
        }}
        onClose={() => setShowFeedback(false)}
      />

      <PanelSuccess
        Icon={DoneOutlineIcon}
        title={t('orderPublication.orderFinished', { defaultValue: 'Публікацію затверджено' })}
        content={t('orderPublication.orderFinishedDescription', {
          defaultValue:
            'Ваша публікація успішно завершена! Ви можете залишити відгук про співпрацю з брендом або переглянути інші кампанії.',
        })}
        footer={
          <div className="mt-6 max-w-[480px] mx-auto flex justify-center">
            {!hasBloggerFeedback && (
              <Button
                onClick={() => setShowFeedback(true)}
                className="mr-4"
                variant="contained"
                fullWidth
                color="primary"
              >
                {t('orderPublication.orderFinishedFeedback', { defaultValue: 'Додати відгук' })}
              </Button>
            )}

            <Button
              component={Link}
              to={AppRoutes.campaigns()}
              variant="outlined"
              fullWidth
              color="primary"
              className="lg:max-w-[260px]"
            >
              {t('orderPublication.returnToCampaigns', { defaultValue: 'Усі кампанії' })}
            </Button>
          </div>
        }
      />
    </>
  );
};
