import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Divider } from '@mui/material';
import { messages } from '../../messages';
import { Anchors } from '../../configs/anchors';
import { OrdersOfferAccept } from '../orders-offer/OrdersOfferAccept';
import { OrdersOfferDecline } from '../orders-offer/OrdersOfferDecline';

const m = messages.publications;

export type OrdersPublicationOffer = {
  publication: any;
  campaign: any;
};

export const OrdersPublicationOffer = ({ publication, campaign }: OrdersPublicationOffer) => {
  return (
    <Box
      flex={1}
      mt={4}
    >
      <Divider />

      <Box mt={4}>
        <Typography
          id={Anchors.ORDERS}
          variant="h6"
          fontWeight="bold"
          align="center"
        >
          {m.offer.title}
        </Typography>
      </Box>

      <Box
        display="flex"
        justifyContent="center"
        mt={4}
      >
        <Box
          width={160}
          mr={2}
        >
          <OrdersOfferAccept
            campaign={campaign}
            orderId={publication.id}
          />
        </Box>

        <Box width={160}>
          <OrdersOfferDecline
            campaign={campaign}
            orderId={publication.id}
          />
        </Box>
      </Box>
    </Box>
  );
};
