import CampaignsOwnerList from './CampaignsInfluencerList';
import { messages } from '../../messages';
import { PageHeader } from '../../components/PageContainer/PageHeader';
import { PageBody } from '../../components/PageContainer/PageBody';
import { Meta } from '../meta/Meta';
import { TITLES } from '../../configs/titles';

const m = messages.campaignsInfluencer;

export const CampaignsInfluencer = () => (
  <>
    <PageHeader
      title={m.title}
      // right={<CampaignsInfluencerSwitcher />}
    />
    <Meta title={TITLES.campaignsInfluencer()} />

    <PageBody>
      <CampaignsOwnerList />
    </PageBody>
  </>
);
