import { ReactNode } from 'react';
import classNames from 'classnames';
import { SocialLink } from '../../../components/SocialLink';
import { ContentText } from '../../../components/Content';
import { messages } from '../../../messages';
import { Number } from '../../../components/Number/Number';
import { SocialEntity } from '../../../types';
import { configuration } from '../../../configs/configuration';
import { socialTypesImages } from '../../../configs/social-types';

const m = messages.socials;

export type SocialViewProps = {
  social: SocialEntity;
  content?: ReactNode;
  isStyleGrid?: boolean;
  isShowImage?: boolean;
};

export const SocialConnectView = ({
  social,
  content,
  isStyleGrid = true,
  isShowImage = true,
}: SocialViewProps) => {
  const img = socialTypesImages[social.socialType];

  return (
    <>
      <div className="flex flex-1 flex-wrap items-start">
        {isShowImage && (
          <div className="mr-4 max-w-[70px] mb-3 md:mb-3">
            <img src={img} />
          </div>
        )}

        <div className="flex flex-1 flex-wrap">
          <div
            className={classNames('flex-1', {
              'lg:grid lg:grid-cols-2': isStyleGrid,
            })}
          >
            {configuration.ENABLED_SHOW_USERNAME && social.username && (
              <ContentText
                label={m.view.account}
                content={<SocialLink social={social} />}
              />
            )}

            <ContentText
              label={m.view.followersCount}
              content={<Number amount={social?.followersCount} />}
              className="order-1"
            />

            {content}
          </div>
        </div>
      </div>
    </>
  );
};
