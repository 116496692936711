import { useCallback, useContext } from 'react';
import { useToasts } from '../../components/Toasts';
import {useProfileHideAuthShowFeedbackFromMutation, useProfilePatchMutation} from './profile-api';
import { ProfileContext } from '../common/ProfileContext';

export const useProfile = () => {
  const { error, success } = useToasts();
  const { profile, refetch, isLoaded } = useContext(ProfileContext);
  const [changeMutation, { isLoading }] = useProfilePatchMutation();
  const [hideAutoShowFeedbackForm] = useProfileHideAuthShowFeedbackFromMutation();

  const changeState = isLoading && 'pending';

  const change = useCallback(
    (dto, showTooltip = true) => {
      return changeMutation(dto)
        .unwrap()
        .then(() => {
          if (showTooltip) {
            success('Зміни були успішно збережені');
          }
          return refetch();
        })
        .catch((err) => error(err.message));
    },
    [error]
  );

  return {
    profile,
    change,
    isLoaded,
    changeState,
    hideAutoShowFeedbackForm,
    load: refetch,
  };
};
