import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, FormProvider } from 'react-hook-form';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import Paper from '@mui/material/Paper';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { configuration } from '../../configs/configuration';
import { Meta } from '../meta/Meta';
import { TITLES } from '../../configs/titles';
import { ProfileInterest } from '../profile/ProfileInterest';
import { useProfile } from '../profile/useProfile';
import CampaignFormItem from '../campaign-create/components/CampaignFormItem';
import { profileConfigFields } from '../profile/fields';
import { AppRoutes } from '../../configs/app-routes';
import { Button } from '../../components/Button';

const schemaByPage = {
  0: yup.object().shape({
    about: yup.string().required(),
  }),
  1: yup.object().shape({
    categories: yup.array().of(yup.string()).min(1).required(),
  }),
  2: yup.object(),
};

const getSchema = (page: number) => schemaByPage[page as 0 | 1 | 2];

const defaultValues = {
  categories: [],
};

export function SignUpBloggerPage() {
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const schema = useMemo(() => getSchema(page), [page]);
  const { t } = useTranslation();
  // const { } = use

  const form = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });
  const { profile, change } = useProfile();

  const { isValid } = form.formState;

  const onSubmit = (dto: any) => {
    if (page === 2) {
      change({ ...profile, ...dto }, false).then(() => {
        navigate(AppRoutes.profileSocials());
      });
    } else {
      setPage(page + 1);
    }
  };

  return (
    <div className="flex py-10 flex-col flex-auto items-center sm:justify-center min-w-0  ">
      <Meta title={TITLES.signUpBlogger()} />
      <Paper className="w-full rounded-0 py-4 px-2 sm:p-8 rounded-lg max-w-md">
        <div className="w-full max-w-320 sm:w-320 mx-auto sm:mx-0">
          <img
            className="mx-auto h-24 rounded-lg w-auto"
            src={configuration.global.logo}
            alt="logo"
          />

          <Typography className="mt-10 text-center text-xl font-bold leading-9 tracking-tight text-gray-900">
            {t('sign_up.title', { defaultValue: 'Заповніть Ваш профайл' })} ({page + 1}/3)
          </Typography>

          <FormProvider {...form}>
            <form
              name="registerForm"
              noValidate
              className="flex flex-col justify-center w-full mt-6"
              onSubmit={form.handleSubmit(onSubmit)}
            >
              <>
                {page === 0 && (
                  <>
                    <CampaignFormItem
                      name={profileConfigFields.about}
                      label={t('sign_up.about_label', { defaultValue: 'Біографія:' })}
                      placeholder={t('sign_up.about_placeholder', {
                        defaultValue: 'Надайте коротку інформацію про Вас:',
                      })}
                      variant="textarea"
                      required
                      rows={9}
                      size="small"
                    />
                  </>
                )}

                {page === 1 && (
                  <>
                    <ProfileInterest
                      hideHobbies
                      grid
                    />
                  </>
                )}

                {page === 2 && (
                  <div className="text-center">
                    <span className="font-medium text-[14px]">
                      {t('sign_up.success', {
                        defaultValue: 'Вітаємо! Ви успішно заповнили свій профайл!',
                      })}
                    </span>
                    <div className="mt-2">
                      {t('sign_up.success_description', {
                        defaultValue:
                          'Вам залишилося підключити соціальні платформи. Після цього Ви зможете розпочати роботу на платформі',
                      })}
                    </div>
                  </div>
                )}

                <div className="flex">
                  {page !== 0 && (
                    <Button
                      variant="outlined"
                      color="secondary"
                      className="w-full mt-6 mr-2"
                      onClick={() => setPage(page - 1)}
                    >
                      {t('sign_up.back', { defaultValue: 'Назад' })}
                    </Button>
                  )}

                  <Button
                    gaTrigger={`SignUpBloggerPage.${page}`}
                    variant="contained"
                    color="secondary"
                    className="w-full mt-6"
                    aria-label="Register"
                    disabled={!isValid}
                    type="submit"
                  >
                    {t('sign_up.next', { defaultValue: 'Далі' })}
                  </Button>
                </div>
              </>
            </form>
          </FormProvider>
        </div>
      </Paper>
    </div>
  );
}
