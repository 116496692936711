import { createContext, createElement, useCallback, useState } from 'react';
import { ToastField } from './ToastField';

export const ToastsContext = createContext({});

const ToastProvider = ({ children }) => {
  const [toast, setToast] = useState(undefined);
  const show = useCallback((element) => {
    setToast(element);
  }, []);

  const close = useCallback(() => {
    setToast(undefined);
  }, []);

  const success = useCallback(
    (text, config = {}) => show({ text, ...config, variant: 'success' }),
    [show]
  );

  const error = useCallback(
    (text = 'Something went wrong', config) =>
      show({
        text,
        ...config,
        variant: 'error',
      }),
    [show]
  );

  const info = useCallback(
    (text, config = {}) => show({ text, ...config, variant: 'info' }),
    [show]
  );

  return (
    <ToastsContext.Provider
      value={{
        show,
        close,
        error,
        success,
        info,
      }}
    >
      {toast ? createElement(ToastField, { hide: () => close(), ...toast }) : null}
      {children}
    </ToastsContext.Provider>
  );
};

export { ToastProvider };
