import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';

import relativeTime from 'dayjs/plugin/relativeTime';
import dayjs from 'dayjs';
import { NotificationsDescription } from '../Notifications.styled';

require('dayjs/locale/uk');

dayjs.extend(relativeTime);

export const NotificationBase = ({ createdAt, content, Buttons }) => (
  <div className="flex flex-col flex-auto">
    {/* {title && <Typography className="text-sm font-semibold">{title}</Typography>} */}

    {content && (
      <NotificationsDescription className="line-clamp-3 text-xs">
        {content}
      </NotificationsDescription>
    )}

    {createdAt && (
      <Typography
        className="mt-1 text-xs leading-none "
        color="text.secondary"
      >
        {dayjs(createdAt).locale('uk').from()}
      </Typography>
    )}

    <Box mt={1}>{Buttons && Buttons}</Box>
  </div>
);
