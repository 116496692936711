import { useFormContext } from 'react-hook-form';
import styled from '@emotion/styled';

export const CheckboxStyledDiv = styled.label`
  margin-right: 12px;
  cursor: pointer;
  margin-bottom: 12px;

  input:checked + div,
  &.active > div {
    border-color: ${({ theme }) => theme.palette.secondary.main};
    color: ${({ theme }) => theme.palette.secondary.main};
    border-width: 2px;

    svg {
      color: ${({ theme }) => theme.palette.secondary.main};
    }
  }

  span {
    margin-top: 4px;
    display: inline-block;
    font-size: 10px;
  }
`;

export const CheckboxStyled = styled.div`
  min-width: 70px;
  min-height: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  color: ${({ theme }) => theme.palette.grey['700']};
  border: 1px solid ${({ theme }) => theme.palette.grey['700']};
  text-align: center;
  padding: 12px;
  font-weight: bold;
`;

const MAX_COUNT = 3;

export const ProfileInfoCheckbox = (props) => {
  const { watch, setValue } = useFormContext();
  const type = props.variant === 'radio' ? 'radio' : 'checkbox';
  const values = watch(props.name);

  if (!values) {
    return null;
  }

  return (
    <CheckboxStyledDiv>
      <input
        hidden
        value={props.value}
        type={type}
        checked={values.includes(props.value)}
        onChange={(e) => {
          const set = new Set(values);
          if (set.has(props.value)) {
            set.delete(props.value);
          } else {
            set.add(props.value);
          }

          if (set.size > MAX_COUNT) {
            return;
          }

          setValue(props.name, Array.from(set), { shouldValidate: true });
        }}
      />

      <CheckboxStyled
        style={props.style}
        size={props.size}
        key={props.label}
      >
        {props.Icon && <props.Icon color="icon" className='w-8 h-8' />}

        <span>{props.label}</span>
      </CheckboxStyled>
    </CheckboxStyledDiv>
  );
};
