import { Dialog } from '@mui/material';
import FormHelperText from '@mui/material/FormHelperText';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Rating } from '@mui/lab';
import Button from '@mui/material/Button';
import { useMemo, useState } from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from 'react-i18next';
import { ContentCardBody, ContentField } from '../../components/Content';
import CampaignFormItem from '../campaign-create/components/CampaignFormItem';
import { CampaignFormSubtitle } from '../campaign-create/CampaignStyles';
import { DialogSuccess } from '../../components/DialogSuccess';
import { UserPanel } from '../../components/UserPanel';
import { useToasts } from '../../components/Toasts';
import { useFeedbacksCreateMutation } from './feedbacks-api';
import { CampaignEntity, OrderEntity, UserEntity } from '../../types';

const getSchema = () =>
  yup.object().shape({
    rate: yup.number().required(),
    feedback: yup.string().required(),
  });

export type FeedbackFormProps = {
  open: boolean;
  user: UserEntity;
  onClose: () => void;
  campaign: CampaignEntity;
  order: OrderEntity;
  onSubmit?: () => void;
  isBrand?: boolean;
};

export const FeedbackForm = ({
  open,
  user,
  onClose,
  campaign,
  order,
  onSubmit,
  isBrand = false,
}: FeedbackFormProps) => {
  const { t } = useTranslation();
  const [success, showSuccess] = useState<boolean>(false);
  const { error } = useToasts();
  const [create] = useFeedbacksCreateMutation();
  const schema = useMemo(getSchema, []);
  const methods = useForm({
    mode: 'onChange',
    defaultValues: {},
    resolver: yupResolver(schema),
  });
  const submit = (fd: any) => {
    fd.user = user.id;
    fd.campaign = campaign.id;
    fd.orderId = order.id;

    create(fd)
      .then(() => {
        showSuccess(true);
        if (onSubmit) {
          onSubmit();
        }
      })
      .catch((err) => {
        error(err.message);
      });
  };

  if (!user) {
    return null;
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
    >
      <DialogSuccess
        open={success}
        content={t('feedback.successContent', { defaultValue: 'Відгук надіслано' })}
        title={t('feedback.successTitle', {
          defaultValue: 'Дякуємо за співпрацю, Influencer.com.ua',
        })}
        buttonText={t('feedback.successContinue', { defaultValue: 'Продовжити роботу' })}
        onClose={() => {
          showSuccess(false);
          onClose();
        }}
      />
      <FormProvider {...methods}>
        <form
          className="px-6 pb-6 pt-4"
          onSubmit={methods.handleSubmit(submit)}
        >
          <DialogTitle
            className="font-black"
            textAlign="center"
          >
            {isBrand
              ? t('feedback.brandTitle', {
                  defaultValue: 'Залишіть відгук про співпрацю з брендом:',
                })
              : t('feedback.title', { defaultValue: 'Залиште відгук про співпрацю з блогером:' })}
          </DialogTitle>
          <div className="flex md:flex-row flex-col mt-2">
            <UserPanel user={user} />

            <ContentCardBody>
              <ContentField>
                <Controller
                  name="rate"
                  control={methods.control}
                  render={({ field, fieldState }) => (
                    <>
                      <CampaignFormSubtitle htmlFor="rate">
                        {t('feedback.rating', { defaultValue: 'Оцінка:' })}
                      </CampaignFormSubtitle>
                      <Rating
                        id="rate"
                        onChange={(event, value) => field.onChange(value)}
                      />
                      {fieldState.error && (
                        <FormHelperText error>{fieldState.error.message}</FormHelperText>
                      )}
                    </>
                  )}
                />
              </ContentField>

              <ContentField className="md:min-w-[380px]">
                <CampaignFormItem
                  name="feedback"
                  label={t('feedback.comment', { defaultValue: 'Коментар: ' })}
                  size="small"
                  variant="textarea"
                  control={methods.control}
                  errors={methods.formState.errors}
                  props={{
                    inputProps: {
                      maxLength: 255,
                    },
                  }}
                />
              </ContentField>

              <div className="mt-4 w-[200px]">
                <Button
                  fullWidth
                  type="submit"
                  color="primary"
                  variant="contained"
                >
                  {t('feedback.save', { defaultValue: 'Надіслати відгук' })}
                </Button>
              </div>
            </ContentCardBody>
          </div>
        </form>
      </FormProvider>
    </Dialog>
  );
};
