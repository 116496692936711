import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';
import { ComponentType, ReactNode } from 'react';

// icon={<FaUserSlash className="text-[120px] text-slate-500" />}

export type NotFoundPanelProps = {
  title: string;
  linkText?: string;
  content?: string;
  link?: string;
  className?: string;
  Icon?: ComponentType<any>;
  icon?: ReactNode;
};

export const NotFoundPanel = ({
  title,
  linkText,
  content,
  link,
  className,
  icon,
  Icon,
}: NotFoundPanelProps) => (
  <div className={`flex flex-col flex-1 items-center justify-center ${className}`}>
    {Icon && (
      <div className="mb-4">
        <Icon className="text-[120px] text-slate-500" />
      </div>
    )}

    {icon && <div className="mb-4">{icon}</div>}

    {title && (
      <Typography
        color="text.secondary"
        variant="h5"
        textAlign="center"
      >
        {title}
      </Typography>
    )}

    {content && (
      <Box
        mt={2}
        typography="body2"
        textAlign="center"
        color="grey"
        whiteSpace="pre-wrap"
      >
        {content}
      </Box>
    )}

    {link && (
      <Button
        className="mt-8"
        component={Link}
        variant="outlined"
        color="primary"
        to={link}
      >
        {linkText}
      </Button>
    )}
  </div>
);
