import CampaignView from '../modules/campaign-view/CampaignView';
import CampaignCreate from '../modules/campaign-create/CampaignCreate';
import CampaignsListApp from '../modules/campaigns-feed/CampaignsFeedListApp';
import { AppRoutes } from '../configs/app-routes';
import CampaignsOwner from '../modules/campaigns-owner/CampaignsOwner';
import { SmartSearch } from '../modules/smart-search';
import { ProtectedRoute } from './ProtectedRoute';
import { Roles } from '../configs/configuration';

export const CampaignRoutes = {
  path: AppRoutes.campaigns(),
  children: [
    {
      index: true,
      element: <CampaignsListApp />,
    },

    {
      path: AppRoutes.campaignsCreate(),
      element: (
        <ProtectedRoute roles={[Roles.BRAND]}>
          <CampaignCreate />
        </ProtectedRoute>
      ),
    },
    {
      path: AppRoutes.usersProposeCampaign(),
      element: (
        <ProtectedRoute roles={[Roles.BRAND]}>
          <CampaignCreate />
        </ProtectedRoute>
      ),
    },
    // {
    //   path: AppRoutes.campaignCreatePayment(),
    //   element: (
    //     <ProtectedRoute roles={[Roles.BRAND]}>
    //       <CampaignCreatePayment />
    //     </ProtectedRoute>
    //   ),
    // },
    {
      path: AppRoutes.campaignView(),
      element: <CampaignView />,
    },
    {
      path: AppRoutes.campaignsSmartSearch(),
      element: (
        <ProtectedRoute roles={[Roles.BRAND, Roles.USER]}>
          <SmartSearch />
        </ProtectedRoute>
      ),
    },
    {
      path: AppRoutes.campaignPendingRequests(),
      element: <CampaignView />,
    },
    {
      path: AppRoutes.campaignEdit(),
      element: (
        <ProtectedRoute roles={[Roles.BRAND]}>
          <CampaignCreate />
        </ProtectedRoute>
      ),
    },
    {
      path: AppRoutes.campaignsMy(),
      element: (
        <ProtectedRoute roles={[Roles.BRAND]}>
          <CampaignsOwner />
        </ProtectedRoute>
      ),
    },
  ],
};
