import { ReactNode } from 'react';
import { Card } from '../Card';

export type CardStatisticProps = {
  title: ReactNode;
  value: ReactNode;
};
export const CardStatistic = ({ title, value }: CardStatisticProps) => (
  <Card className="flex flex-col items-center justify-center">
    <div className="mb-2 text-xl font-extrabold">{value}</div>
    <div className="text-gray-500 dark:text-gray-400 text-center">{title}</div>
  </Card>
);
