export const messages = {
  // toastPlatformAdded: 'Платформа успешно добавлена',
  // toastPlatformDisabled: 'Платформа успешно отключена',
  // toastPlatformUpdated: 'Платформа успешно обновлена',
  //
  // connectInstagram: 'Connect Instagram',
  //
  // instagram: {
  //   followersCount: 'Follower count:',
  //   disconnect: 'Disconnect',
  //   connecting: 'Connecting Instagram...',
  // },
  //
  // title: 'My social networks',
  // subTitle: 'Add your social networks to connect more easily.',
  // important:
  //   'Important: when linking a social\n' +
  //   "        network, we haven't access to your password. We cannot change your\n" +
  //   '        account, publish content, like or comment for you.',
  //
  // connectedAccount: 'Connected accounts:',
  // connectNetwork: 'Connect another network',
  // followersCheckmark: (count) => `✔️ ${count} followers`,

  toastPlatformAdded: 'Платформу успішно додано',
  toastPlatformDisabled: 'Платформу успішно вимкнено',
  toastPlatformUpdated: 'Платформу успішно оновлено',

  connectInstagram: 'Підключити Instagram',

  showStatistic: 'Переглянути статистику',
  hideStatistic: 'Приховати статистику',

  instagram: {
    update: 'Редагувати',
    followersCount: 'Кількість фоловерів:',
    disconnect: 'Відключити',
    connecting: 'Підключення Instagram...',
    connectionFailed: 'Instagram failed',
    statForMonth: 'Статистика за останні 28 днів:',

    fiels: {
      total_interactions: 'Загальна взаємодія',
      impressions: 'Покази',
      reach: 'Охоплені акаунти',
      accounts_engaged: 'Залучені акаунти',
      saves: 'Збереження',
      likes: 'Відмітки "Подобається"',
      comments: 'Коментарі',
      profile_views: 'Відвідування профілю',
    },
  },

  title: 'Мої плафторми',
  subTitle: 'Додайте Ваші плафторми, щоб легше підключатися',
  important:
    'Важливо: при підключенні соціальної мережі мережі, ми не маємо доступу до вашого паролю. Ми не можемо змінити ваш акаунт акаунт, публікувати контент, лайкати або коментувати за вас.',

  connectedAccount: 'Підключені акаунти:',
  connectNetwork: 'Підключити іншу мережу',
  followersCheckmark: (count) => `✔️ ${count} followers`,

  imagesTitle: 'Прикрепіть статистику та аналітику',

  images: {
    tiktokDescription: `Прикріпіть скріни аналітики за тиждень:
- лайки/перегляди/коментарі
- скрін ЦА (країни, стать, вік)`,
    youtubeDescription: `Прикріпіть скріни аналітики за тиждень:
- час перегляду, охоплення, конверсія, клікабельність, аудиторія - географічне положення, вік, стать
    `,
    instagramDescription: `Прикріпіть скріни історій за тиждень:
- аналітики переглядів сторіз;
- «Кліки по посиланню» - показує число кліків по прикріпленому вами посиланням в Історії.
- «Відповіді» - показує, скільки користувачів написали вам в Дірект з Історії або відреагували на неї емодзі.
- «Число торкань наклейок» - якщо ви прикріпили наклейку з ім'ям користувача Інстаграм (бренду), покаже кількість натиснень користувачів. Або якщо ви, наприклад, репоснули чужу Історію, покаже число переходів на неї. У статистиці буде відображено і ім'я користувача.

Допис:
- скрін аналітики допису

Reels:
- прикріпіть аналітику ваших рілз, охоплення за останні 14 днів

Інстаграм аналітика повинна оновлюватись раз у тиждень.`,
  },

  followersCount: 'Кількість фоловерів:',
  mediaCount: 'Кількість медіа:',
  videoCount: 'Кількість відео:',
  viewsCount: 'Кількість переглядів:',

  connect: 'Підключити',
  disconnect: 'Відключити',
};
