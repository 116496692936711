import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import BlockIcon from '@mui/icons-material/Block';
import { useTranslation } from 'react-i18next';

export const CampaignNotEnoughFollowers = () => {
  const { t } = useTranslation();
  return (
    <Box
      textAlign="center"
      alignItems="center"
      pb={4}
    >
      <Box mt={4}>
        <Divider />
      </Box>

      <Box
        mb={3}
        mt={6}
      >
        <BlockIcon style={{ fontSize: 120 }} />
      </Box>

      <Typography
        variant="h5"
        fontWeight={500}
      >
        {t('campaign.notEnoughFollowers', {
          defaultValue: 'У вас недостатня кількість фоловерів для цієї кампанії',
        })}
      </Typography>
    </Box>
  );
};
