import IconButton from '@mui/material/IconButton';
import { Close } from '@mui/icons-material';

export type DialogCloseProps = {
  onClose: () => void;
};
export const DialogClose = ({ onClose }: DialogCloseProps) => (
  <div className="absolute top-[8px] right-[8px]">
    <IconButton onClick={onClose}>
      <Close />
    </IconButton>
  </div>
);
