import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../utils/axiosBaseQuery';
import { SocialTypes } from '../../configs/social-types';
import { referral } from '../../utils/referral';


export const socialsApi = createApi({
  reducerPath: 'socials-api',
  tagTypes: ['SocialFetch'],
  baseQuery: axiosBaseQuery({ baseUrl: '/api/socials' }),
  endpoints: (builder) => ({
    fetch: builder.query({
      query: (id) => ({
        url: `/${id}`,
      }),
      providesTags: ['SocialFetch'],
    }),

    update: builder.mutation({
      query: ({ id, dto }) => ({
        url: `/${id}`,
        method: 'PATCH',
        body: dto,
      }),
      invalidatesTags: ['SocialFetch'],
    }),

    fetchMe: builder.query({
      query: () => ({
        url: `/me`,
      }),
    }),

    disconnect: builder.mutation({
      query: (id: number) => ({
        url: `/${id}/disconnect`,
        method: 'POST',
      }),
      invalidatesTags: ['SocialFetch'],
    }),

    getYoutubeLink: builder.mutation({
      query: () => ({
        url: `/youtube/link`,
        method: 'GET',
      }),
    }),

    connectManual: builder.mutation({
      query: (body) => {
        window.gtag('event', 'connect_social', { type: body.socialType });
        referral.emit('connect_social');

        if (body.socialType === SocialTypes.TIKTOK) {
          return {
            url: `/tiktok/connect`,
            method: 'POST',
            body,
          };
        }

        if (body.socialType === SocialTypes.YOUTUBE) {
          return {
            url: `/youtube/connect`,
            method: 'POST',
            body,
          };
        }
      },
    }),
  }),
});

export const useSocialFetchQuery = socialsApi.endpoints.fetch.useQuery;
export const useSocialFetchMeQuery = socialsApi.endpoints.fetchMe.useQuery;

export const useSocialUpdateMutation = socialsApi.endpoints.update.useMutation;

export const useSocialConnectManualMutation = socialsApi.endpoints.connectManual.useMutation;
export const useSocialDisconnectMutation = socialsApi.endpoints?.disconnect.useMutation;
export const useSocialGetYoutubeLinkMutation = socialsApi.endpoints?.getYoutubeLink.useMutation;
