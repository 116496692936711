import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { teal, pink, indigo } from '@mui/material/colors';
import { ReactNode } from 'react';
import { CardChart } from '../../components/CardChart';

const messages = {
  F: 'Жінки',
  M: 'Чоловіки',
  U: 'Не вказано',
};

export type ChartBarAgeDemographicsProps = {
  data: any;
  showUndefinedGender?: boolean;
  title?: ReactNode;
};

export const ChartBarAgeDemographics = ({
  data: _data,
  showUndefinedGender = true,
  title,
}: ChartBarAgeDemographicsProps) => {
  const labels = _data.map((item) => [item.dimensionValues[0]]);

  const summary = _data.map((item) => item.value).reduce((acc, item) => acc + item, 0);

  const formattedData = _data;

  const createDataset = (label: string, color: string) => {
    const val = formattedData.map((item) => {
      if (item.dimensionValues[1] === label) {
        return item.value;
      }

      return undefined;
    });

    return {
      indexAxis: 'y',
      label: messages[label],
      data: val,
      borderRadius: 4,
      grouped: false,
      backgroundColor: [color],
    };
  };

  const data = {
    labels,
    plugins: [ChartDataLabels],
    options: {
      responsive: true,
      maintainAspectRatio: false,
      indexAxis: 'y',
    },
    datasets: [
      createDataset('F', pink[300]),
      createDataset('M', indigo[300]),
      showUndefinedGender ? createDataset('U', teal[300]) : undefined,
    ].filter(Boolean),
  };

  return (
    <CardChart title={title || 'Розподіл фоловерів по віку та статі:'}>
      <Bar
        datasetIdKey="ageDemoId"
        height={350}
        data={data}
        plugins={[ChartDataLabels]}
        options={{
          plugins: {
            datalabels: {
              formatter: (value) => {
                if (value) {
                  return `${Math.round((value / summary) * 100)}%`;
                }
                return '';
              },
              color: 'white',
            },
          },
        }}
      />
    </CardChart>
  );
};
