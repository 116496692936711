import { useState } from 'react';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { messages } from '../../messages';
import { useCampaignOrdersBloggerAcceptMutation } from '../orders/campaign-orders-api';
import { DialogDefaultConfirmation } from '../../components/DialogDefaultConfirmation';
import { useToasts } from '../../components/Toasts';

type OrdersOfferAcceptProps = {
  orderId: number;
  onAccept: () => void;
};
export const OrdersOfferAccept = ({ orderId, onAccept }: OrdersOfferAcceptProps) => {
  const [bloggerAccept] = useCampaignOrdersBloggerAcceptMutation();
  const { t } = useTranslation();
  const { success, error } = useToasts();

  const [showConfirmationAccept, setShowConfirmationAccept] = useState(false);

  const handleBloggerAccept = () => {
    bloggerAccept(orderId)
      .unwrap()
      .then(() => {
        success(
          t('orders.offer.acceptConfirmationSuccess', {
            defaultValue: 'Пропозиція успішно була прийнята',
          })
        );

        if (onAccept) {
          onAccept();
        }
      })
      .catch(() => {
        error(messages.error);
      });
  };

  return (
    <>
      <DialogDefaultConfirmation
        title={t('orders.offer.acceptConfirmation', {
          defaultValue: 'Ви впевнені, що хочете прийняти пропозицію?',
        })}
        open={showConfirmationAccept}
        onSubmit={handleBloggerAccept}
        onClose={() => setShowConfirmationAccept(false)}
      />
      <Button
        variant="contained"
        color="primary"
        fullWidth
        size='small'
        onClick={() => setShowConfirmationAccept(true)}
      >
        {t('orders.offer.accept', { defaultValue: 'Прийняти' })}
      </Button>
    </>
  );
};
