import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { DialogConfirm } from '../../components/DialogConfirm';
import { messages } from '../../messages';

const m = messages.campaigns.rerunConfirmation;

export const CampaignViewRerun = (props) => {
  return (
    <DialogConfirm
      title={m.title}
      iconHide
      maxWidth={520}
      content={m.content}
      {...props}
      buttons={
        <>
          <Box minWidth={120}>
            <Button
              fullWidth
              type="button"
              variant="outlined"
              color="primary"
              onClick={props.onClose}
            >
              {m.no}
            </Button>
          </Box>

          <Box minWidth={120} ml={2}>
            <Button
              fullWidth
              type="button"
              variant="contained"
              color="primary"
              onClick={props.onSubmit}
            >
              {m.yes}
            </Button>
          </Box>
        </>
      }
    />
  );
};
