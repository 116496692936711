export const AppRoutes = {
  campaignsRoot: () => `/campaigns/`,
  campaigns: () => `/campaigns`,
  campaignsMy: () => `/campaigns/owner`,
  campaignsInfluencer: () => `/campaigns/my`,
  campaignsCreate: () => `/campaigns/create`,
  campaignCreatePayment: (id = ':campaignId') => `/campaigns/${id}/payment`,
  campaignsSmartSearch: (id = ':id') => `/campaigns/${id}/smart-search`,
  campaignView: (id = ':campaignId', anchor?: string) =>
    `/campaigns/${id}/view${anchor ? `#${anchor}` : ''}`,
  campaignViewAll: (id = ':campaignId', anchor?: string) =>
    `/campaigns/${id}/view/all${anchor ? `#${anchor}` : ''}`,
  campaignEdit: (id = ':campaignId') => `/campaigns/${id}/edit`,
  campaignPendingRequests: (id = ':campaignId', state = ':state') =>
    `/campaigns/${id}/view/${state}`,

  profileRoot: () => `/profile`,
  profile: () => `/profile`,
  profileInfo: (id = ':id') => `/profile/${id}`,
  profileInterest: () => `/profile/interest`,
  profileSecurity: () => `/profile/security`,
  profileAbout: () => `/profile/about`,
  profileSocials: () => `/profile/socials`,

  profileSocialsConnectInstagram: () => `/profile/socials/instagram`,
  profileSocialsConnectYoutube: () => `/profile/socials/youtube`,
  profileSocialsConnectTiktok: () => `/profile/socials/tiktok`,
  profileSocialsViewById: (id: string = ":socialId") => `/profile/socials/${id}`,

  profileLogout: () => `/sign-out`,
  profileSocialsConnectInstagramWebhook: () => `/profile/socials/connect/instagram`,
  profileSocialsConnectTiktokWebhook: () => `/profile/socials/tiktok/remote-connect`,

  profileSocialsConnectYoutubeWebhook: () => `/profile/socials/youtube/remote-connect`,

  profileView: (id = ':id') => `/profile/${id}`,
  profileViewStatistic: (id = ':id') => `/profile/${id}?showStatistic=1`,

  ordersUser: () => `/orders/user/pending`,
  ordersUserWaitForPublish: () => `/orders/user/wait-for-publish`,
  ordersUserPending: () => `/orders/user/pending`,
  ordersUserArchive: () => `/orders/user/archive`,

  acceptOrderPayment: (id = ':orderId') => `/payment/orders/${id}`,
  acceptOrderPaymentSuccess: (id = ':orderId') => `/payment/orders/${id}/success`,

  ordersOwner: () => `/orders/owner/pending`,
  ordersOwnerWaitForPublish: () => `/orders/owner/wait-for-publish`,
  ordersOwnerPending: () => `/orders/owner/pending`,
  ordersOwnerArchive: () => `/orders/owner/archive`,

  signIn: () => `/sign-in`,
  signUp: () => `/sign-up`,
  signUpBlogger: () => `/sign-up/blogger`,

  chats: () => `/chats`,
  chat: (id = ':id') => `/chats/${id}`,

  notifications: () => `/notifications`,
  forgotPassword: () => `/forgot-password`,
  forgotPasswordRecovery: () => `/forgot-password-recovery`,

  credits: () => `/credits`,
  balance: () => `/balance`,

  payments: () => `/payments`,

  users: () => `/users`,
  usersProposeCampaign: (userId = ':userId') => `/campaigns/create-proposal/${userId}`,
};
