import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../utils/axiosBaseQuery';
import type { UserFeedbackDto } from './UserFeedback';

export const userFeedbackApi = createApi({
  reducerPath: 'user-feedback',
  baseQuery: axiosBaseQuery({ baseUrl: '/api/customer-feedbacks' }),
  endpoints: (builder) => ({
    create: builder.mutation({
      query: (fd: UserFeedbackDto) => {
        return {
          url: ``,
          method: 'POST',
          body: fd,
        };
      },
    }),
  }),
});

export const useCreateMutation = userFeedbackApi.endpoints.create.useMutation;
