import Box from '@mui/material/Box';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ProfileInterestCheckboxes } from './ProfileInterestCheckboxes';
import CampaignFormItem from '../campaign-create/components/CampaignFormItem';
import { CampaignFormSubtitle } from '../campaign-create/CampaignStyles';
import { profileConfigFields } from './fields';
import { CanView } from '../../components/CanView';
import { Roles } from '../../configs/configuration';

export type ProfileInterestProps = {
  grid?: boolean;
  allFieldsRequired?: boolean;
  hideHobbies?: boolean;
};
export const ProfileInterest = ({
  grid = false,
  allFieldsRequired = false,
  hideHobbies = false,
}: ProfileInterestProps) => {
  const methods = useFormContext();
  const { t } = useTranslation();

  return (
    <>
      <Box mb={2}>
        <Box mb={1}>
          <CampaignFormSubtitle required>
            {t('profile.categories', { defaultValue: 'Виберіть категорії (максимально 3):' })}
          </CampaignFormSubtitle>
        </Box>

        <ProfileInterestCheckboxes grid={grid} />
      </Box>

      {!hideHobbies && (
        <CanView role={Roles.USER}>
          <CampaignFormItem
            name={profileConfigFields.hobbies}
            label={t('profile.hobby_label', { defaultValue: 'Яке Ваше хоббі?' })}
            variant="autocomplete"
            required={allFieldsRequired}
            list={[]}
            control={methods.control}
            props={{
              size: 'small',
              freeSolo: true,
            }}
          />

          <CampaignFormItem
            name={profileConfigFields.brands}
            label={t('profile.brands_label', { defaultValue: 'Перечисліть улюблені бренди:' })}
            variant="autocomplete"
            required={allFieldsRequired}
            list={[]}
            control={methods.control}
            props={{
              size: 'small',
              freeSolo: true,
            }}
          />

          <CampaignFormItem
            name={profileConfigFields.influencers}
            label={t('profile.influencers_label', { defaultValue: 'У Вас є улюблені блогери?' })}
            required={allFieldsRequired}
            description={
              !allFieldsRequired &&
              t('profile.influencers_description', { defaultValue: 'Якщо так, впишіть їх' })
            }
            variant="autocomplete"
            list={[]}
            control={methods.control}
            props={{
              size: 'small',
              freeSolo: true,
            }}
          />
        </CanView>
      )}
    </>
  );
};
