import { useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { messages } from '../../messages';
import { Button } from '../../components/Button';
import { useUser } from '../common/useUser';
import { ContentText } from '../../components/Content';
import { useToasts } from '../../components/Toasts';
import { Money } from '../../components/Money/Money';
import {
  useCampaignOrdersFetchOneQuery,
  useCampaignOrdersPaymentMutation,
} from './campaign-orders-api';
import { CampaignFormBody } from '../campaign-create/CampaignStyles';
import { CampaignNotFound } from '../campaign-create/components/CampaignNotFound';
import { usePaymentsCreateInvoice } from '../balance/payments-api';
import OrderPaymentPaid from './OrderPaymentPaid';

// import $ipsp from 'ipsp-js-sdk';

// const $ipsp = ipsp();

// console.log("$ipsp", $ipsp.get('button'));

function OrderPayment() {
  const { orderId } = useParams();
  const { success } = useToasts();
  const { data: { data: order = {} } = {} } = useCampaignOrdersFetchOneQuery(orderId);
  const { campaign } = order;
  const {
    user: { id: currentUserId },
  } = useUser();
  const { t } = useTranslation();

  const [createInvoice] = usePaymentsCreateInvoice();

  const [payment] = useCampaignOrdersPaymentMutation(orderId);

  if (!campaign) {
    return <CampaignNotFound />;
  }

  // const showNotPermitted = currentUserId !== campaign.user;
  const showNotPermitted = false;
  const showAlreadyPaid = false;

  // console.log("test", { currentUserId, userId: campaign.user })
  // const showAlreadyPaid = campaign.state && campaign.state !== CampaignState.WAIT_FOR_PAYMENT;

  const createOrder = async () => {
    const {
      data: { data },
    } = await payment(orderId);

    return data;
    // const description = `Тестова плата за публікацію кампанії #${campaign.id}, заявка №${order.id}`;
    // const responseUrl = `${configuration.webUrl}/${Routes.acceptOrderPaymentSuccess(order.id)}`;
    // // const callbackUrl = `${configuration.api.url}/api/test-callback`;
    // const callbackUrl = configuration.api.paymentCallbackUrl;
    //
    // const button = $ipsp.get('button');
    // // button.setMerchantId(1531051);
    // button.setMerchantId(1396424);
    // button.setAmount(campaign.price, 'UAH');
    // button.setHost('pay.fondy.eu');
    // button.addField({ label: 'Опис', name: 'order_desc', value: description });
    // button.setResponseUrl(responseUrl);
    // button.addParam('preauth', 'Y');
    // button.addParam('orderId', order.id);
    // button.addParam(
    //   'merchant_data',
    //   JSON.stringify({ orderId: order.id, campaignId: campaign.id })
    // );
    // button.addParam('product_id', order.id);
    // button.addParam('design_id', `${280256}`);
    // button.addParam('server_callback_url', callbackUrl);
    // // button.addField({ name: 'preauth', value: 'Y' });
    // return button.getUrl();
  };

  const handleClick = async () => {
    const data = await createOrder();
    location.href = data.checkout_url;
  };

  const submit = (fd) => {
    createInvoice(orderId)
      .unwrap()
      .then((res) => {
        window.open(res.data.paymentPageUrl, '_blank').focus();
      })
      .catch(() => {
        success(messages.error);
      });
  };

  if (order && order.paid) {
    return <OrderPaymentPaid campaign={campaign} />;
  }

  return (
    <>
      <Card
        component={Box}
        maxWidth={1200}
        margin={4}
        variant="outlined"
      >
        <div className="p-4 sm:p-6 max-w-4xl">
          <CampaignFormBody>
            <Typography className="text-16 sm:text-20 truncate font-semibold">
              {t('orderPayment.title', { defaultValue: 'Оплата' })}
            </Typography>

            {showNotPermitted && (
              <Box mt={2}>
                {t('orderPayment.notPermitted', {
                  defaultValue: 'Ви не маєте доступу до цієї кампанії',
                })}
              </Box>
            )}

            {showAlreadyPaid && (
              <Box mt={2}>
                {t('orderPayment.alreadyPaid', { defaultValue: 'Цю кампанію вже оплачено.' })}
              </Box>
            )}

            {!showNotPermitted && !showAlreadyPaid && (
              <>
                <ContentText
                  mt={1}
                  label={t('orderPayment.name', { defaultValue: 'Назва кампанії:' })}
                  content={campaign.name}
                />

                <ContentText
                  mt={1}
                  label={t('orderPayment.budget', { defaultValue: 'Бюджет:' })}
                  content={<Money amount={campaign.publicationCount * campaign.price} />}
                />

                <ContentText
                  mt={1}
                  label={t('orderPayment.toPaid', { defaultValue: 'До оплати:' })}
                  content={<Money amount={campaign.publicationCount * campaign.price} />}
                />

                <ContentText
                  label={t('orderPayment.rules', { defaultValue: 'Умови:' })}
                  content={
                    <Box>
                      <Typography
                        className="text-14"
                        whiteSpace="pre-wrap"
                      >
                        {t('orderPayment.description', {
                          defaultValue: `Гроші повернутися автоматично до вас на рахунок якщо:
- ви не знайдете відповідного блогера
- ви видалите/скасуєте кампанію, не розпочавши роботу з блогерами`,
                        })}
                      </Typography>
                    </Box>
                  }
                />

                <Box
                  width={220}
                  mt={2}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={handleClick}
                  >
                    {t('orderPayment.submit', { defaultValue: 'Оплатити' })}
                  </Button>
                </Box>
              </>
            )}
          </CampaignFormBody>
        </div>
      </Card>
    </>
  );
}

export default OrderPayment;
