import { lazy } from 'react';
import Chat from './Chat';
import { AppRoutes } from '../../configs/app-routes';

const ChatApp = lazy(() => import('./Chats'));

const ChatsConfig = [
  {
    path: AppRoutes.chats(),
    element: <ChatApp />,
  },
  {
    path: AppRoutes.chat(),
    element: <Chat />,
  },
];

export default ChatsConfig;
