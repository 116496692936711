import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../utils/axiosBaseQuery';

export const notificationsApi = createApi({
  reducerPath: 'notifications-rtk',
  tagTypes: ['notifications-rtk'],
  baseQuery: axiosBaseQuery({ baseUrl: '/api/notifications' }),
  endpoints: (builder) => ({
    hasUnread: builder.query({
      query: () => ({ url: '/has-unread' }),
    }),

    notifications: builder.query({
      query: ({ limit }: { limit?: number } = {}) => ({ url: '/me', params: { limit } }),
      providesTags: ['notifications'],
    }),

    hide: builder.mutation({
      query: (id) => ({
        url: `/${id}/hide`,
        method: 'POST',
      }),
      invalidatesTags: ['notifications'],
    }),

    patch: builder.mutation({
      query: ({ id, ...dto }) => ({
        url: `/${id}`,
        method: 'PATCH',
        body: dto,
      }),

      async onQueryStarted({ id, ...dto }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          notificationsApi.util.updateQueryData('notifications', undefined, (draft) => {
            const index = draft.findIndex((element) => element.id === id);
            Object.assign(draft[index].payload, dto);
          })
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),

    read: builder.mutation({
      query: () => ({
        url: `/read`,
        method: 'POST',
      }),

      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          notificationsApi.util.updateQueryData('hasUnread', undefined, (draft) => {
            Object.assign(draft, { hasUnread: false });
          })
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
  }),
});

export const useGetHasUnreadQuery = notificationsApi.endpoints.hasUnread.useQuery;
export const useGetNotificationsQuery = notificationsApi.endpoints.notifications.useQuery;
export const useReadMutation = notificationsApi.endpoints.read.useMutation;
export const useHideMutation = notificationsApi.endpoints.hide.useMutation;
export const usePatchMutation = notificationsApi.endpoints.patch.useMutation;
