import { useEffect, useMemo, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate, Link } from 'react-router-dom';
import * as yup from 'yup';
import Button from '@mui/material/Button';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import CampaignFormItem from '../campaign-create/components/CampaignFormItem';
import { ContentField } from '../../components/Content';
import { messages } from '../../messages';
import { AppRoutes } from '../../configs/app-routes';
import {
  useCampaignOrdersAttachStatisticMutation,
  useCampaignOrdersChangePublicationMutation,
  useCampaignOrdersPublishMutation,
} from '../orders/campaign-orders-api';
import CampaignImages from '../campaign-create/components/CampaignImages';
import { CampaignFormSubtitle } from '../campaign-create/CampaignStyles';
import { useToasts } from '../../components/Toasts';
import { DialogSuccess } from '../../components/DialogSuccess';
import { FinalFields } from '../../components/FinalFields/FinalFields';

const getSchema = () =>
  yup.object().shape({
    publicationLinks: yup.array().of(
      yup.object().shape({
        value: yup.string().url(messages.form.linkValidation),
      })
    ),
    publicationsMessage: yup.string().required(),
  });

const Form = styled.form`
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};
`;

const Block = styled.div`
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};
`;

export type CampaignPublicationFormProps = {
  publication: any;
};
export const OrdersPublicationForm = ({ publication }: CampaignPublicationFormProps) => {
  const navigate = useNavigate();
  const { error, success } = useToasts();
  const { t } = useTranslation();

  const [publish] = useCampaignOrdersPublishMutation();
  const [attachStatistic] = useCampaignOrdersAttachStatisticMutation();
  const [changePublication] = useCampaignOrdersChangePublicationMutation();
  const [showSuccess, setShowSuccess] = useState(false);

  const schema = useMemo(() => getSchema(), []);

  const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      publicationLinks: publication?.publicationLinks || [{ value: '' }],
      publicationsMessage: publication?.publicationsMessage,
      images: publication?.images,
    },
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    methods.reset({
      ...publication,
      publicationLinks: publication?.publicationLinks || [{ value: '' }],
    });
  }, [publication]);

  const isAttachStatistic =
    Boolean(publication.publicationsMessage) && !(publication.images && publication.images.length);

  const submit = (fd) => {
    if (!publication) {
      publish({ ...fd, orderId: publication.id })
        .unwrap()
        .then(() => {
          setShowSuccess(true);

          setTimeout(() => {
            navigate(AppRoutes.campaigns());
          }, 5000);
        })
        .catch(() => {
          error(messages.error);
        });
    } else if (isAttachStatistic) {
      attachStatistic({ ...fd, orderId: publication.id })
        .unwrap()
        .then(() => {
          success(
            t('orderPublication.successStatistic', { defaultValue: 'Статистику успішно надіслано' })
          );
        })
        .catch(() => {
          error(messages.error);
        });
    } else {
      changePublication({ ...fd, orderId: publication.id })
        .unwrap()
        .then(() => {
          console.log('changePublication', { showSuccess });
          setShowSuccess(true);
        })
        .catch(() => {
          error(messages.error);
        });
    }
  };

  if (!publication) {
    return null;
  }

  // const waitReview = publication.state === OrdersState.PUBLICATION_WAIT_REVIEW;

  const showStatistic = true;
  const disabledStatistic = Boolean(publication.images);

  const disabled = false;

  return (
    <>
      <DialogSuccess
        title={t('orderPublication.successTitle', {
          defaultValue: 'Вашу публікацію успішно надіслано',
        })}
        content={t('orderPublication.successContent', {
          defaultValue:
            'Зачекайте, доки бренд перегляне вашу публікацію і підтвердить її виконання або надішле правки',
        })}
        buttonText={t('orderPublication.successClose', { defaultValue: 'Закрити' })}
        open={showSuccess}
        onClose={() => setShowSuccess(false)}
      />

      <FormProvider {...methods}>
        <Form
          onSubmit={methods.handleSubmit(submit)}
          className="max-w-lg"
        >
          <>
            <Block disabled={disabled}>
              <CampaignFormSubtitle>
                {t('orderPublication.publicationsLink', { defaultValue: 'Посилання на публікацію:' })}
              </CampaignFormSubtitle>

              <FinalFields
                FieldComponent={({ index, control }) => (
                  <div className="-mb-4 w-full">
                    <CampaignFormItem
                      name={`publicationLinks[${index}].value`}
                      control={control}
                      errors={methods.formState.errors}
                      size="small"
                    />
                  </div>
                )}
                name="publicationLinks"
                appendLabel={t('orderPublication.publicationsAddLink', {
                  defaultValue: 'Додати посилання',
                })}
              />
            </Block>

            <Block
              className="mt-4"
              disabled={disabled}
            >
              <CampaignFormItem
                name="publicationsMessage"
                label={t('orderPublication.publicationsMessage', {
                  defaultValue: 'Повідомлення:',
                })}
                variant="textarea"
                control={methods.control}
                errors={methods.formState.errors}
                size="small"
              />
            </Block>
          </>

          <Block>
            <CampaignFormSubtitle className="mb-2">
              {t('orderPublication.publicationStatistic', {
                defaultValue: 'Будь ласка, надайте статистику вашої рекламної публікації:',
              })}
            </CampaignFormSubtitle>
            <CampaignImages name="images" />
          </Block>

          <ContentField>
            <Button
              variant="contained"
              type="submit"
              color="primary"
            >
              {t('orderPublication.send', { defaultValue: 'Відправити' })}
            </Button>

            <Button
              component={Link}
              to={AppRoutes.chat(publication.id)}
              className="ml-2"
              variant="outlined"
              color="primary"
            >
              {t('orderPublication.buttonChat', { defaultValue: 'Повідомлення' })}
            </Button>
          </ContentField>
        </Form>
      </FormProvider>
    </>
  );
};
