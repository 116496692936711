import { useTranslation } from 'react-i18next';
import { NotFoundPanel } from '../../../components/NotFoundPanel';
import { AppRoutes } from '../../../configs/app-routes';

export const CampaignNotFound = () => {
  const { t } = useTranslation();
  return (
    <NotFoundPanel
      title={t('campaign.notFoundTitle', { defaultValue: 'Такої кампанії не існує' })}
      link={AppRoutes.campaignsMy()}
      linkText={t('campaign.notFoundLink', { defaultValue: 'На головну' })}
    />
  );
};
