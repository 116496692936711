import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { ReactNode } from 'react';

type PanelHeaderProps = {
  title: ReactNode;
  subtitle?: ReactNode;
  hideDivider?: boolean;
};
export const PanelHeader = ({ title, subtitle, hideDivider = false }: PanelHeaderProps) => (
  <>
    <div className="title-3 max-w-[700px] mb-2 truncate">{title}</div>

    {subtitle && (
      <Typography
        variant="caption"
        display="block"
        className="font-medium max-w-[700px]"
      >
        {subtitle}
      </Typography>
    )}

    {!hideDivider && (
      <Box
        mt={subtitle ? 3 : 2}
        mb={3}
      >
        <Divider />
      </Box>
    )}
  </>
);
