import * as React from 'react';
import { Button, Typography } from '@mui/material';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PaymentEntity, PaymentsState, usePaymentsFetch } from './payments-api';
import { PaymentsSummary } from './components/PaymentsSummary';
import { PaymentsTable } from './components/PaymentsTable';
import { DialogSuccess } from '../../components/DialogSuccess';

export const PaymentsUnpaid = () => {
  const { t } = useTranslation();
  const { data: { data: paymentsPendingRows = [] } = {} } = usePaymentsFetch({
    state: PaymentsState.PENDING,
  });

  const [showSuccess, setShowSuccess] = useState(false);
  const [selectedRows, setSelectedRows] = useState<PaymentEntity[]>([]);

  const total = useMemo(() => {
    return selectedRows.reduce((sum, current) => sum + current.amount, 0);
  }, [selectedRows]);

  const handleSelect = (event: React.MouseEventHandler, id: number) => {
    const hasRow = selectedRows.find((row) => row.id === id);

    if (hasRow) {
      const filteredRows = selectedRows.filter((row) => row.id !== id);
      setSelectedRows(filteredRows);
    } else {
      const selectedRow = paymentsPendingRows.find((row) => row.id === id);
      if (selectedRow) {
        setSelectedRows([...selectedRows, selectedRow]);
      }
    }
  };

  return (
    <>
      <DialogSuccess
        open={showSuccess}
        onClose={() => setShowSuccess(false)}
        content={t('paymentsUnpaid.dialogSuccessText', {
          defaultValue: `Ваша оплата пройшла успішно`,
        })}
        buttonText={t('paymentsUnpaid.dialogButtonText', { defaultValue: `Закрити` })}
      />

      <div className="mt-6">
        <Typography
          className="mb-6"
          variant="h5"
        >
          {t('paymentsUnpaid.unpaidTitle', { defaultValue: `Кампанії до оплати` })}
        </Typography>
        <PaymentsTable
          rows={paymentsPendingRows}
          selectedRows={selectedRows}
          onSelect={handleSelect}
          emptyMessage={t('paymentsUnpaid.emptyUnpaid', {
            defaultValue: `У Вас нема неоплачених компаній`,
          })}
          hasCheckbox
        />
        {paymentsPendingRows.length > 0 && (
          <>
            <PaymentsSummary
              title={t('paymentsUnpaid.cost', { defaultValue: `Сума до оплати` })}
              total={total}
            />
            <Button
              variant="contained"
              type="button"
              color="primary"
              onClick={() => setShowSuccess(true)}
            >
              {t('paymentsUnpaid.paymentButtonText', { defaultValue: `Перейти до оплати` })}
            </Button>
          </>
        )}
      </div>
    </>
  );
};
