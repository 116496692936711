export const messages = {
  title: 'Profile',
  subtitle: 'Profile',

  about: 'Біографія:',

  generalInfoTitle: 'Основна інформація',
  generalInfoSubtitle: 'Заповніть основну інформацію про себе: ваші дані, контакти та інтереси',
  generalInfoSubtitleBrand: 'Заповніть основну інформацію про Ваш бренд',
  contactsTitle: 'Контактні дані',
  contactsSubtitle: 'Надайте Вашу інформацію для подальшої роботи',
  interestsTitle: 'Інтереси',
  interestsSubtitle:
    'Заповніть цю інформацію, щоб ми могли запропонувати вам співпрацю, яка максимально відповідає вашим уподобанням',

  categories: '',
  categoriesRequired: 'Виберіть хоча б одну категорію',
  data: 'Дані',
  createdAt: 'Створено при',
  displayName: "Ім'я відображення",
  email: 'E-mail:',
  emailPlaceholder: 'E-mail',
  brandLink: 'Посилання на бренд:',
  brandLinkAdd: 'Додати посилання',
  isShowInSearch: 'Показувати мою анкету в пошуку:',
  firstName: "Ім'я:",
  firstNamePlaceholder: "",
  brandName: 'Назва бренду:',
  id: 'Ідентифікатор',
  lastName: 'Прізвище:',
  lastNamePlaceholder: '',
  orders: 'Пропозиції',
  goToSocials: 'Перейти до моїх платформ',

  publishedAt: 'Опублікований при',
  role: 'Роль',
  updatedAt: 'Оновлений при',

  password: 'Старий пароль',
  passwordPlaceholder: 'Введіть старий пароль',
  newPassword: 'Новий пароль',
  newPasswordPlaceholder: 'Введіть новий пароль',
  confirmPassword: 'Підтвердити пароль',
  confirmPasswordPlaceholder: 'Введіть ще раз',

  emailWriteToSupport: 'Щоб змінити електронну пошту, напишіть на support@influencer.com.ua',

  address: {
    region: 'Область:',
    city: 'Місто:',
    cityPlaceholder: 'Населений пункт',
    location: 'Адреса:',
    newPost: 'Відділення Нової пошти:',
    locationPlaceholder: 'Вулиця/будинок',
    index: 'Індекс:',
    indexPlaceholder: '',
  },

  gender: 'Стать',
  genderPlaceholder: '-',
  birthday: 'Дата народження:',
  birthdayPlaceholder: 'Дата народження',
  notShowBirthday: 'Не показувати вік в профілі',
  image: 'Зображення:',
  imagePlaceholder: '',
  phone: 'Телефон:',
  phonePlaceholder: '+380',
  aboutPlaceholder: 'Надайте коротку інформацію про Вас',
  aboutPlaceholderBrand: 'Коротка інформація про Ваш бренд',

  brand: {
    title: 'Назва кампанії',
    image: 'Логотип або зображення:',
    // about: 'Про Ваш бренд:',birthday
    about: 'Біографія:',
    aboutPlaceholder: 'Коротка інформація про Ваш бренд',
    birthday: 'Дата народження бренду:',
  },

  view: {
    about: 'Біо:',
    age: 'Вік:',
    city: 'Місто:',
    socials: 'Платформи:',
    company: 'Компанія:',
    reviews: 'Відгуки:',
    detailsInfo: 'Показати інформацію:',
  },

  interest: {
    brand: 'Перечисліть улюблені бренди:',
    influencers: ' Вас є улюблені блогери?',
    influencersDescription: 'Якщо так, впишіть їх',

    hobbies: 'Яке Ваше хоббі?',
  },

  security: {
    title: 'Безпека',
    changePassword: 'Зміна паролю:',
    goToChangePassword: 'Перейти до відновлення паролю',
    goToChangeDescription: 'Щоб змінити пароль, скористайтеся опцією "Відновлення паролю".\nВам буде відправлено листа з інструкціями щодо зміни паролю.',
    changeEmail: 'Зміна електронної пошти:',
    changeEmailDescription:
      'Для зміни, надішліть листа на <a href="mailto:support@influencer.com.ua" class="link">support@influencer.com.ua</a>  \n' +
      '\n' +
      'Примітка: Щоб внести зміни, надішліть листа з пошти, котра привʼязана до вашого облікового запису в influencer.ua\n' +
      '\n' +
      '<b>Вкажіть тему листа: Зміна email 📩</b> \n' +
      '\n' +
      'Якщо ви не маєте доступу до своєї пошти, зверніться до нашого саппорту. Ми готові вам допомогти відновити доступ, якщо ви можете підтвердити свою особу через соціальні мережі або іншим методом верифікації.',
  },
  //
  // categories: 'Categories',
  // data: 'Data',
  // createdAt: 'Created At',
  // displayName: 'Display Name',
  // email: 'Email',
  // firstName: 'First Name',
  // id: 'Id',
  // isFilledProfile: 'IsFilledProfile',
  // lastName: 'Last Name',
  // orders: 'Orders',
  //
  // publishedAt: 'Published At',
  // role: 'Role',
  // updatedAt: 'Updated At',
  //
  // password: 'Password',
  // confirmPassword: 'Confirm Password',
  //
  // address: {
  //   city: 'City',
  //   location: 'Location',
  //   index: 'Index',
  // },
  //
  // // to aDd:
  // gender: 'Gender',
  // birthday: 'Birthday',
  // image: 'Image',
  // phone: 'Phone',
  //
  // brand: {
  //   title: 'Company Name',
  //   image: 'Image',
  // },
  //
  // view: {
  //   age: 'Age:',
  //   city: 'City:',
  //   socials: 'Socials:',
  //   company: 'Company:',
  //   reviews: 'Reviews:',
  //   detailsInfo: 'Show info:',
  // },
};
