import { useEffect } from 'react';

export const useFormFocus = (formContext) => {
  const { formState, setFocus } = formContext;

  useEffect(() => {
    const { errors } = formState;
    const firstError = Object.keys(errors).reduce((field, a) => {
      return errors[field] ? field : a;
    }, null);

    if (firstError) {
      const element = document.querySelector(`body [name=${firstError}]`);

      if (element) {
        element.focus();
      }
    }
  }, [formState.errors, setFocus]);
};
