import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AppRoutes } from '../../configs/app-routes';
import { Button } from '../../components/Button';

type WelcomeOwnerProps = {
  onClose: () => void;
}
export const WelcomeOwner = ({ onClose }: WelcomeOwnerProps) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="flex flex-shrink-0 items-center justify-between rounded-t-md border-b border-neutral-100 border-opacity-100 md:p-5 p-4">
        <div className="text-xl font-medium leading-normal text-neutral-800  mr-4">
          <span
            dangerouslySetInnerHTML={{
              __html: t('welcome_owner.title', {
                defaultValue:
                  'Вітаємо, Ви успішно стали частиною спільноти <a href="https://influencer.com.ua">INFLUENCER.COM.UA</a>',
              }),
            }}
          />
        </div>
      </div>

      <div className="relative flex-auto md:p-5 p-4">
        <Typography
          whiteSpace="pre-wrap"
          dangerouslySetInnerHTML={{
            __html: t('welcome_owner.contentOwner', {
              defaultValue: `Щоб почати роботу, вам необхідно створити рекламну кампанію. Після створення:

- Ваша кампанія з'явиться в результатах пошуку.
- Блогери отримають сповіщення про Вашу кампанію. 
- Ви зможете переглядати статистику, зв'язатися та почати роботу з блогерами, які зацікавляться Вашою кампанією. 

Детальніше про створення кампанії за посиланням: <a target="_blank" class="link" href="https://influencer.com.ua/content/how-start-to-work-brand/">https://influencer.com.ua/content/how-start-to-work-brand/</a>`,
            }),
          }}
        />
      </div>

      <div className="flex flex-shrink-0 flex-wrap items-center justify-start rounded-b-md border-t border-neutral-100 border-opacity-100 md:p-5 p-4">
        <Button
          color="primary"
          variant="contained"
          component={Link}
          to={AppRoutes.campaignsCreate()}
          onClick={onClose}
          gaTrigger="welcome:blogger:campaign-create"
        >
          {t('welcome_owner.buttonCampaign', { defaultValue: 'Перейти до створення кампаній' })}
        </Button>
      </div>
    </>
  );
};
