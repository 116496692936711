import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AppRoutes } from '../../configs/app-routes';
import { CampaignState } from '../../configs/configuration';
import { CampaignEntity } from '../../types';
import { ContentField } from '../../components/Content';
import { useCampaignsOwnerControlProvider } from '../campaigns-owner/CampaignsOwnerControlProvider';

export type CampaignViewControlProps = {
  campaign: CampaignEntity;
  showRecommendation: boolean;
};

export const CampaignViewControl = ({
  campaign,
  showRecommendation = false,
}: CampaignViewControlProps) => {
  const { t } = useTranslation();
  const campaignId = campaign?.id;

  const showEditButton = campaign.state === CampaignState.pending;
  const showPauseButton = campaign.state === CampaignState.pending;
  const showStopButton =
    campaign.state === CampaignState.pending || campaign.state === CampaignState.paused;
  const showRerunButton = campaign.state === CampaignState.paused;

  const showButtonProblem =
    campaign.state !== CampaignState.paused || campaign.state !== CampaignState.finished;

  const { showStop, showRerun, showPause, showReport } = useCampaignsOwnerControlProvider();

  return (
    <>
      {showRecommendation && (
        <ContentField>
          <Button
            component={Link}
            to={AppRoutes.campaignsSmartSearch(campaign.id)}
            variant="contained"
            color="primary"
            fullWidth
          >
            {t('campaign.smartSearch', { defaultValue: 'Рекомендації' })}
          </Button>
        </ContentField>
      )}

      {showEditButton && (
        <ContentField>
          <Button
            component={Link}
            to={AppRoutes.campaignEdit(campaign.id)}
            variant="contained"
            color="primary"
            fullWidth
          >
            {t('campaign.edit', { defaultValue: 'Редагувати' })}
          </Button>
        </ContentField>
      )}

      {showPauseButton && (
        <ContentField>
          <Button
            variant="outlined"
            color="secondary"
            fullWidth
            onClick={(event) => {
              event.stopPropagation();
              showPause(campaignId);
            }}
          >
            {t('campaign.pause', { defaultValue: 'Зупинити пошук' })}
          </Button>
        </ContentField>
      )}

      {showRerunButton && (
        <ContentField>
          <Button
            variant="outlined"
            color="primary"
            fullWidth
            onClick={(event) => {
              event.stopPropagation();
              showRerun(campaignId);
            }}
          >
            {t('campaign.rerun', { defaultValue: 'Відновити пошук' })}
          </Button>
        </ContentField>
      )}

      {showStopButton && (
        <ContentField>
          <Button
            variant="outlined"
            color="error"
            fullWidth
            onClick={(event) => {
              event.stopPropagation();
              showStop(campaignId);
            }}
          >
            {t('campaign.stop', { defaultValue: 'Завершити' })}
          </Button>
        </ContentField>
      )}

      {showButtonProblem && (
        <ContentField>
          <Button
            onClick={(event) => {
              event.stopPropagation();
              showReport(campaignId);
            }}
            variant="outlined"
            color="primary"
            fullWidth
          >
            {t('campaign.reportProblem', { defaultValue: 'Повідомити про проблему' })}
          </Button>
        </ContentField>
      )}
    </>
  );
};
