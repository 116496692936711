import FuseNavigation from '@fuse/core/FuseNavigation';
import clsx from 'clsx';
import { memo, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import useThemeMediaQuery from '@fuse/hooks/useThemeMediaQuery';
import { navbarCloseMobile } from '../../store/fuse/navbarSlice';
import navigationConfig from '../../configs/navigation-config';

function Navigation(props) {
  const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down('lg'));

  const dispatch = useDispatch();

  return useMemo(() => {
    function handleItemClick(item) {
      if (isMobile) {
        dispatch(navbarCloseMobile());
      }
    }

    return (
      <FuseNavigation
        className={clsx('navigation', props.className)}
        navigation={navigationConfig}
        layout={props.layout}
        dense={props.dense}
        active={props.active}
        onItemClick={handleItemClick}
      />
    );
  }, [
    dispatch,
    isMobile,
    navigationConfig,
    props.active,
    props.className,
    props.dense,
    props.layout,
  ]);
}

Navigation.defaultProps = {
  layout: 'vertical',
};

export default memo(Navigation);
