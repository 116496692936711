import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../../utils/axiosBaseQuery';

export const campaignsOffers = createApi({
  reducerPath: 'campaigns-offers',
  tagTypes: ['campaigns-offers'],
  baseQuery: axiosBaseQuery({ baseUrl: '/api/offers' }),
  endpoints: (builder) => ({
    hasOffer: builder.query({
      query: (campaignId) => ({
        url: `/has-offer?campaign=${campaignId}`,
      }),
    }),
  }),
});

export const useGetHasOfferQuery = campaignsOffers.endpoints.hasOffer.useQuery;
