import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Checkbox from '@mui/material/Checkbox';
import dayjs from 'dayjs';
import isEmpty from 'lodash/isEmpty';
import { useTranslation } from 'react-i18next';
import CampaignHeader from './components/CampaignHeader';
import CampaignRequirements from './components/CampaignRequirements';
import CampaignFormBaseInfo from './components/CampaignFormBaseInfo';
import {
  CampaignFormBody,
  CampaignFormSubtitle,
  CampaignFormTitle,
  CampaignFormTitleDescription,
} from './CampaignStyles';
import { AppRoutes } from '../../configs/app-routes';
import { messages } from '../../messages';
import { CampaignNotFound } from './components/CampaignNotFound';
import CampaignImages from './components/CampaignImages';
import { campaignFields } from '../campaign-view/campaignConfig';
import { CampaignFormItemContainer } from './components/CampaignFormItem';
import { schema } from './schema';
import { Button } from '../../components/Button';
import { useToasts } from '../../components/Toasts';
import { useCampaignCreateMutation } from '../campaign-view/redux/campaign-api';
import {
  useCampaignPatchMutation,
  useCampaignViewGetQuery,
} from '../campaign-view/redux/campaign-view-api';
import { Meta } from '../meta/Meta';
import { TITLES } from '../../configs/titles';
import { useFormFocus } from '../../hooks/useFormFocus';
import { useProfileGetQuery } from '../profile/profile-api';
import { Username } from '../../components/Username';
import { useUsersCreateOrderToBlogger } from '../users/users-api';
import { PanelHeader } from '../../components/PanelHeader';
import { Panel } from '../../components/Panel';
import { configuration } from '../../configs/configuration';

function CampaignCreate() {
  const navigate = useNavigate();
  const { userId } = useParams();
  const { t } = useTranslation();

  const [create] = useCampaignCreateMutation();
  const [createProposal] = useUsersCreateOrderToBlogger();
  const [edit] = useCampaignPatchMutation();

  const { pathname } = useLocation();
  const [loading, setLoading] = useState(false);
  const { success, error } = useToasts();

  const createToUserMode = Boolean(userId);
  const { data: user } = useProfileGetQuery(userId, { skip: !createToUserMode });

  const isEdit = pathname.endsWith('edit');

  const routeParams = useParams();
  const { campaignId } = routeParams;

  const { data: campaign, isLoading } = useCampaignViewGetQuery(campaignId, {
    skip: !campaignId,
  });

  const noProduct = isEdit && !campaign;

  const campaignFormatted = useMemo(() => {
    if (!campaign || isEmpty(campaign)) {
      return null;
    }

    return {
      ...campaign,
      socialTypes: campaign.socialTypes.map(({ socialType }) => socialType.title),
      categories: campaign.categories.map(({ category }) => ({
        label: category.title,
        id: category.title,
      })),
      image: campaign.image,
      termsAndConditions: isEdit,
      dateEnd: dayjs(campaign.dateEnd).format('YYYY-MM-DD'),
    };
  }, [campaign]);

  const defaultValues = useMemo(() => {
    if (campaignFormatted) {
      return campaignFormatted;
    }
    return {
      socialTypes: ['tiktok'],
      publicationCount: 1,
      paymentType: 'money',
      requirements: {
        notOtherBrands: true,
        isAttachStatistic: true,
      },
      minFollowersCount: 0,

      defaultShowInSearch: !user,
    };
  }, [campaignFormatted, isEdit, user]);

  const methods = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema()),
  });
  const { watch, reset, control, formState, handleSubmit } = methods;
  const form = watch();

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues]);

  useFormFocus(methods);

  const disabled = !form[campaignFields.termsAndConditions];

  if (noProduct || isLoading) {
    return <CampaignNotFound />;
  }

  const submit = (fd) => {
    setLoading(true);
    if (isEdit) {
      fd.campaignId = campaign.id;
      edit(fd)
        .unwrap()
        .then((res) => {
          success(t('campaignsCreate.editSuccess', { defaultValue: 'Зміни успішно збережено' }));
          navigate(AppRoutes.campaignView(res.id));
        })
        .catch(() => {
          error(messages.error);
        })
        .finally(() => setLoading(false));
    } else {
      const fn = createToUserMode ? createProposal : create;
      const formattedData = { ...fd };

      if (createToUserMode) {
        formattedData.userId = user.id;
      }

      fn(formattedData)
        .unwrap()
        .then((res) => {
          success(
            t('campaignsCreate.createSuccess', {
              defaultValue:
                'Вітаємо, кампанію успішно створено. Вас автоматично перенаправлять на сторінку кампанії.',
            })
          );
          navigate(AppRoutes.campaignsSmartSearch(res.id));
          // navigate(AppRoutes.campaignView(res.id));
        })
        .catch(() => {
          error(
            t('campaignCreate.error', {
              defaultValue:
                "Під час створення виникла помилка. Спробуйте ще раз або зв'яжіться зі службою підтримки",
            })
          );
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <>
      <Meta title={isEdit ? TITLES.campaignEdit(campaign.name) : TITLES.campaignsCreate()} />
      <FormProvider {...methods}>
        <CampaignHeader />

        <Panel>
          <form
            className="max-w-4xl"
            onSubmit={(e) => {
              handleSubmit(submit)(e);
            }}
          >
            <CampaignFormBody>
              <PanelHeader
                title={t('campaignCreate.newCampaign', {
                  defaultValue: 'Нова кампанія',
                })}
                subtitle={t('campaignCreate.newCampaignDetail', {
                  defaultValue: 'Детальна інформація про кампанію',
                })}
              />
              {user && (
                <div>
                  <Typography className="text-16 sm:text-20 font-semibold">
                    {t('campaignCreate.createToUser', {
                      defaultValue: 'Ви створюєте цю пропозицію для користувача: ',
                    })}
                    <Username user={user} />
                  </Typography>

                  <Typography
                    variant="caption"
                    className="font-medium whitespace-pre-wrap"
                  >
                    {t('campaignCreate.createToUserDescription', {
                      defaultValue:
                        'Початково ця кампанія не буде відображена у загальній стрічці для всіх користувачів.\\nЩоб зробити її видимою, відзначте опцію "Показати в стрічці"',
                    })}
                  </Typography>

                  <Box
                    mt={3}
                    mb={3}
                  >
                    <Divider />
                  </Box>
                </div>
              )}

              <CampaignFormBaseInfo
                errors={formState.errors}
                control={control}
                user={user}
              />

              <Box
                mt={3}
                mb={3}
              >
                <Divider />
              </Box>
              <CampaignFormTitle>
                {t('campaignCreate.newCampaignRequirements', {
                  defaultValue: 'Вимоги до блогера:',
                })}
              </CampaignFormTitle>
              <CampaignFormTitleDescription>
                {t('campaignCreate.newCampaignDetail', {
                  defaultValue: 'Детальна інформація про кампанію',
                })}
              </CampaignFormTitleDescription>
              <CampaignRequirements
                errors={formState.errors}
                socialTypes={form.socialTypes}
                showFollowersCount={!user}
              />
              <Box
                mt={3}
                mb={3}
              >
                <Divider />
              </Box>
              <CampaignFormItemContainer orient="horizontal">
                <CampaignFormSubtitle>
                  {t('campaignCreate.images', { defaultValue: 'Мудборд:' })}
                </CampaignFormSubtitle>
                <CampaignImages
                  multiple
                  name={`${campaignFields.images}`}
                />
              </CampaignFormItemContainer>
              <Box
                mt={3}
                mb={3}
              >
                <Divider />
              </Box>
              {createToUserMode && (
                <CampaignFormItemContainer
                  orient="horizontal"
                  className="items-center flex-row"
                >
                  <span className="text-blue-600 hover:underline" />
                  <CampaignFormSubtitle
                    dangerouslySetInnerHTML={{
                      __html: t('campaignCreate.defaultShowInSearch', {
                        defaultValue: 'Показати в стрічці для всіх:',
                      }),
                    }}
                  />

                  <div>
                    <Controller
                      name={campaignFields.defaultShowInSearch}
                      render={({ field }) => (
                        <Checkbox
                          size="large"
                          checked={field.value || false}
                          {...field}
                        />
                      )}
                    />
                  </div>
                </CampaignFormItemContainer>
              )}
              <CampaignFormItemContainer
                orient="horizontal"
                className=" items-center flex-row"
              >
                <span className="text-blue-600 hover:underline" />

                <CampaignFormSubtitle
                  dangerouslySetInnerHTML={{
                    __html: t('campaignCreate.termsAndConditions', {
                      defaultValue: `Я прочитав(-ла) наведені <a href="${configuration.global.termsOfServiceUrl}" class="text-blue-600 hover:underline" target="_blank">умови</a> та приймаю їх:`,
                    }),
                  }}
                />

                <div>
                  <Controller
                    name={campaignFields.termsAndConditions}
                    render={({ field }) => (
                      <Checkbox
                        size="large"
                        checked={field.value || false}
                        {...field}
                      />
                    )}
                  />
                </div>
              </CampaignFormItemContainer>
              <Box mt={2}>
                <CampaignFormItemContainer orient="horizontal">
                  <CampaignFormSubtitle />

                  <Box width={220}>
                    <Button
                      loading={loading}
                      disabled={disabled}
                      variant="contained"
                      size="medium"
                      color="primary"
                      type="submit"
                      gaTrigger="campaign_create"
                      fullWidth
                    >
                      {isEdit
                        ? t('campaignCreate.edit', { defaultValue: 'Редагувати' })
                        : t('campaignCreate.save', { defaultValue: 'Cтворити кампанію' })}
                    </Button>
                  </Box>
                </CampaignFormItemContainer>
              </Box>
            </CampaignFormBody>
          </form>
        </Panel>
      </FormProvider>
    </>
  );
}

export default CampaignCreate;
