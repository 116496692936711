import { Link } from 'react-router-dom';
import { AppRoutes } from '../../../configs/app-routes';
import { NotificationBase } from './NotificationBase';

export const NotificationOrderDeclined = ({ payload, ...rest }) => {
  return (
    <NotificationBase
      {...rest}
      title="Ваша заявка відхилена"
      createdAt={rest.createdAt}
      content={
        <>
          Ваша заявка на проведення кампанії{' '}
          <Link
            to={AppRoutes.campaignView(payload.campaign.id)}
            rel="noreferrer"
            className="link"
          >
            {payload.campaign.name}
          </Link>{' '}
          відхилена'
        </>
      }
    />
  );
};
