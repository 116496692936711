import { ReactNode, useState } from 'react';
import { messages } from '../../../messages';
import { SocialEntity } from '../../../types';
import { SocialConnectView } from '../../socials-connect/components/SocialsConnectView';
import { SocialYoutubeConfigView } from './SocialYoutubeConfigView';
import { YoutubeCharts } from '../../charts/youtube/YoutubeCharts';
import { SocialsImages } from '../components/SocialsImages';
import { Button } from '../../../components/Button';

const m = messages.socials;

export type SocialYoutubeConnectViewProps = {
  social: SocialEntity;
  view?: boolean;
  beforeImagesBlock?: ReactNode;
};
export const SocialYoutubeConnectView = ({
  social: data,
  beforeImagesBlock,
  view,
}: SocialYoutubeConnectViewProps) => {
  const [showStatistic, setShowStatistic] = useState(false);
  const stat = data?.json && data?.json?.items && data?.json?.items[0].statistics;
  const json = data?.json;

  if (!stat) {
    return null;
  }

  return (
    <>
      <SocialConnectView
        social={data}
        isShowImage={false}
        isStyleGrid={false}
        content={<SocialYoutubeConfigView data={data} />}
      />

      <div className="max-w-6xl mt-3">
        {!view && (
          <SocialsImages
            beforeImagesBlock={beforeImagesBlock}
            rowOrient
            social={data}
            name="images"
          />
        )}
      </div>

      {json && (
        <Button
          variant="contained"
          color="primary"
          size="medium"
          onClick={() => setShowStatistic(!showStatistic)}
        >
          {!showStatistic ? m.showStatistic : m.hideStatistic}
        </Button>
      )}

      {json && <>{showStatistic && <YoutubeCharts data={json} />}</>}
    </>
  );
};
