import { createApi } from '@reduxjs/toolkit/query/react';
import type { AxiosResponse } from 'axios';
import { axiosBaseQuery } from '../../utils/axiosBaseQuery';
import { FeedbackEntity } from '../../types';

export const feedbacksApi = createApi({
  reducerPath: 'feedbacks',
  tagTypes: ['feedbacks'],
  baseQuery: axiosBaseQuery({ baseUrl: '/api/feedbacks' }),
  endpoints: (builder) => ({
    fetch: builder.query<AxiosResponse<FeedbackEntity[]>, string>({
      query: (id) => ({
        url: `/${id}`,
      }),
      providesTags: ['feedbacks'],
    }),

    create: builder.mutation({
      query: (body) => ({
        url: `/`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['feedbacks'],
    }),
  }),
});

export const useFeedbacksFetchQuery = feedbacksApi.endpoints.fetch.useQuery;
export const useFeedbacksCreateMutation = feedbacksApi.endpoints.create.useMutation;
