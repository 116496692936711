// import '@mock-api';
import { SnackbarProvider } from 'notistack';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import axios from 'axios';
import { RouterProvider } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import withAppProviders from './withAppProviders';
import { AuthProvider } from '../modules/auth/AuthContext';
import { deserialize } from '../utils/deserialize';
import { ToastProvider } from '../components/Toasts';
import 'dayjs/locale/uk';
import { configuration } from '../configs/configuration';
import routes from '../routes/routes';
import { mainTheme } from '../components/theme';
import { CampaignsOwnerControlProvider } from '../modules/campaigns-owner/CampaignsOwnerControlProvider';
import { referral } from '../utils/referral';
import '../i18n';

// ReactGA.initialize('G-FNZJ98C0Q1');

/**
 * Axios HTTP Request defaults
 */
// axios.defaults.baseURL = 'http://localhost:3002';
axios.defaults.baseURL = configuration.api.url;
// axios.defaults.baseURL = configuration.api.url;
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
axios.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded';
axios.interceptors.response.use(
  (response) => {
    return {
      ...response,
      data: deserialize(response.data),
    };
  },
  (error) => {
    return Promise.reject(error);
  }
);

const emotionCacheOptions = {
  key: 'muiltr',
  stylisPlugins: [],
  insertionPoint: document.getElementById('emotion-insertion-point'),
};

referral.init();

const App = () => {
  return (
    <CacheProvider value={createCache(emotionCacheOptions)}>
      <ThemeProvider theme={mainTheme}>
        <ToastProvider>
          <AuthProvider>
            <CampaignsOwnerControlProvider>
              <SnackbarProvider
                maxSnack={5}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                classes={{
                  containerRoot: 'bottom-0 right-0 mb-52 md:mb-68 mr-8 lg:mr-80 z-99',
                }}
              >
                <RouterProvider
                  router={routes}
                  fallbackElement={<p>Initial Load...</p>}
                />
              </SnackbarProvider>
            </CampaignsOwnerControlProvider>
          </AuthProvider>
        </ToastProvider>
      </ThemeProvider>
    </CacheProvider>
  );
};

export default withAppProviders(App)();
