import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { useState, MouseEvent, useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { BiCoinStack, BiUser } from 'react-icons/bi';
import { IoMdExit } from 'react-icons/io';
import { HiLockClosed } from 'react-icons/hi';
import Icon from '@mui/material/Icon';
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';
import { AppRoutes } from '../../configs/app-routes';
import { Roles } from '../../configs/configuration';
import { CanView } from '../CanView';
import { messages } from '../../messages';
import { useProfile } from '../../modules/profile/useProfile';
import { SignOutDialog } from '../../modules/sign-out/SignOutDialog';
import { MdOutlineLogout, MdOutlineSecurity } from "react-icons/md";

function UserMenu() {
  const { profile: user } = useProfile();
  const navigate = useNavigate();
  const [showLogout, setShowLogout] = useState(false);
  const { role } = user;

  const [userMenu, setUserMenu] = useState<Element | null>(null);

  const userMenuClick = (event: MouseEvent) => {
    setUserMenu(event.currentTarget);
  };

  const userMenuClose = () => {
    setUserMenu(null);
  };

  const navigation = useMemo(
    () => [
      {
        title: 'Профіль',
        icon: <BiUser size={20} style={{ color: '#100d73' }} />,
        to: AppRoutes.profile(),
      },
      {
        title: 'Мої платформи',
        icon: <ConnectWithoutContactIcon size={20} style={{ color: '#100d73' }} />,
        to: AppRoutes.profileSocials(),
        role: Roles.USER,
      },
      {
        title: 'Безпека',
        icon: <MdOutlineSecurity size={20} style={{ color: '#100d73' }} />,
        to: AppRoutes.profileSecurity(),
      },
      {
        title: 'Кредити',
        icon: (
          <Icon style={{ color: '#100d73' }}>
            <BiCoinStack />
          </Icon>
        ),
        to: AppRoutes.credits(),
        role: Roles.USER,
      },
      //
      // {
      //   title: 'Баланс та картки',
      //   icon: <CreditCardIcon />,
      //   to: AppRoutes.balance(),
      // },

      {
        title: 'Вийти',
        icon: <MdOutlineLogout size={20} style={{ color: '#100d73' }} />,
        onClick: () => {
          setShowLogout(true);
        },
        // to:
      },
    ],
    [navigate]
  );

  return (
    <>
      <SignOutDialog
        open={showLogout}
        onClose={() => setShowLogout(false)}
        onSubmit={() => navigate(AppRoutes.profileLogout())}
      />

      <Button
        className="min-h-10 min-w-10 px-4 ml-4 px-0 py-0 md:py-2"
        onClick={userMenuClick}
        color="inherit"
      >
        <div className="hidden md:flex flex-col mx-1 items-end">
          <Typography
            component="span"
            className="font-semibold flex"
          >
            {user.firstName}
          </Typography>
          <Typography
            className="text-11 font-medium capitalize"
            color="text.secondary"
          >
            {messages.roles[role]}
          </Typography>
        </div>

        {user.image ? (
          <Avatar
            className="md:mx-4"
            alt="user photo"
            src={user.image}
          />
        ) : (
          <Avatar className="md:mx-4">{user.firstName && user.firstName[0]}</Avatar>
        )}
      </Button>

      <Popover
        open={Boolean(userMenu)}
        anchorEl={userMenu}
        onClose={userMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        classes={{
          paper: 'py-2 px-2 rounded -ml-8 shadow-lg',
        }}
      >
        {navigation.map((item) =>
          item.role ? (
            <CanView
              key={item.title}
              role={item.role}
            >
              <MenuItem
                className="min-w-[160px] py-2 rounded"
                component={Link}
                to={item.to}
                onClick={userMenuClose}
                role="button"
              >
                <ListItemIcon
                  className="min-w-2 text-center "
                  // style={{ minWidth: 28 }}
                >
                  {item.icon}
                </ListItemIcon>
                <ListItemText
                  className="font-medium"
                  primary={item.title}
                  primaryTypographyProps={{ variant: 'body2', fontWeight: 'medium' }}
                />
              </MenuItem>
            </CanView>
          ) : (
            <MenuItem
              className="min-w-[160px] py-2 rounded"
              key={item.title}
              component={!item.onClick ? Link : undefined}
              to={!item.onClick ? item.to : undefined}
              onClick={
                !item.onClick
                  ? userMenuClose
                  : () => {
                      item.onClick();
                      userMenuClose();
                    }
              }
              role="button"
            >
              <ListItemIcon
                className="min-w-2 text-center "
                // style={{ minWidth: 28 }}
              >
                {item.icon}
              </ListItemIcon>
              <ListItemText
                className="font-medium"
                primary={item.title}
                primaryTypographyProps={{ variant: 'body2', fontWeight: 'medium' }}
              />
            </MenuItem>
          )
        )}
      </Popover>
    </>
  );
}

export default UserMenu;
