import { useFormContext } from 'react-hook-form';
import { Checkbox } from '../../components/Checkbox/Checkbox';
import { ChipBarter, ChipBarterMoney, ChipMoney } from '../../components/Chips';
import { PaymentType } from '../../configs/configuration';

function CampaignsFeedPaymentTypes({ activeIds = [] }: { activeIds: string[] }) {
  const { register } = useFormContext();

  const registeredMoney = register('types');
  const registeredBarter = register('types');
  const registeredBarterMoney = register('types');

  return (
    <>
      <label className="block mb-2">
        <Checkbox
          checked={activeIds.includes(PaymentType.MONEY)}
          value={PaymentType.MONEY}
          inputProps={registeredMoney}
        />
        <ChipMoney />
      </label>

      <label className="block mb-2">
        <Checkbox
          checked={activeIds.includes(PaymentType.BARTER_MONEY)}
          value={PaymentType.BARTER_MONEY}
          inputProps={registeredBarterMoney}
        />
        <ChipBarterMoney />
      </label>

      <label className="block mb-2">
        <Checkbox
          checked={activeIds.includes(PaymentType.BARTER)}
          value={PaymentType.BARTER}
          inputProps={registeredBarter}
        />
        <ChipBarter />
      </label>
    </>
  );
}

export default CampaignsFeedPaymentTypes;
