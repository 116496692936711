import { Controller, useFormContext } from 'react-hook-form';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import FormHelperText from '@mui/material/FormHelperText';
import { messages } from './messages';

export const ProfileNotShowBirthday = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Controller
      name="notShowBirthday"
      control={control}
      render={({ field }) => (
        <FormControl
          className="items-center md:ml-[320px] -mt-3 mb-2"
          error={!!errors.notShowBirthday}
        >
          <FormControlLabel
            label={<div dangerouslySetInnerHTML={{ __html: messages.notShowBirthday }} />}
            control={
              <Checkbox
                size="small"
                defaultChecked={field.value}
                {...field}
              />
            }
          />
          <FormHelperText>{errors?.notShowBirthday?.message}</FormHelperText>
        </FormControl>
      )}
    />
  );
};
