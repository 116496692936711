export const messages = {
  unpaidTitle: `Кампанії до оплати`,
  paidTitle: `Оплачені кампанії`,
  total: `Сума до оплати`,
  paymentButtonText: `Перейти до оплати`,
  companyName: `Назва кампанії`,
  userName: `Блоггер`,
  publicationDate: `Дата публікації`,
  cost: `Сума до оплати`,
  dialogSuccessText: `Ваша оплата пройшла успішно`,
  dialogErrorText: `Під час оплати виникла проблема. Спробуйте ще раз або зв'яжіться із саппортом поштою: support@influencer.com.ua.`,
  dialogButtonText: `Закрити`,
  emptyUnpaid: `У Вас нема неоплачених компаній`,
  emptyPaid: `Дані про оплачені кампанії відсутні`,
};
