import IconButton from '@mui/material/IconButton';
import { useNavigate } from 'react-router-dom';
import { HiOutlineChat } from 'react-icons/hi';
import Badge from '@mui/material/Badge';
import { AppRoutes } from '../../configs/app-routes';
import { useContactsUnreadQuery } from '../../modules/chat/chat-api';

const ChatPanelToggleButton = () => {
  const navigate = useNavigate();
  const { data: { data } = {} } = useContactsUnreadQuery();

  const hasUnread = Boolean(data && data.length);

  return (
    <IconButton
      color="primary"
      className="w-10 h-10 -mt-2"
      onClick={(ev) => navigate(AppRoutes.chats())}
      size="large"
    >
      <div>
        <Badge
          color="secondary"
          variant="dot"
          invisible={!hasUnread}
        >
          <HiOutlineChat size={24} />
        </Badge>
      </div>
    </IconButton>
  );
};

export default ChatPanelToggleButton;
