import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import { AppRoutes } from '../../configs/app-routes';
import { messages } from '../../messages';
import CampaignViewLayout from './CampaignViewLayout';

const m = messages.campaigns;

export const CampaignViewNotFound = () => (
  <CampaignViewLayout>
    <div className="flex flex-col flex-1 items-center justify-center h-full min-h-[400px] text-center px-4">
      <Typography
        color="text.secondary"
        variant="h5"
      >
        {m.view.notFound}
      </Typography>
      <Button
        className="mt-24"
        component={Link}
        variant="outlined"
        to={AppRoutes.campaigns()}
        color="inherit"
      >
        {m.view.goToCampaignsPage}
      </Button>
    </div>
  </CampaignViewLayout>
);
