import Box from '@mui/material/Box';

export const PageContainer = ({ className = '', children, ...rest }) => (
  <Box
    className={`${className} max-w-screen-xl lg:mt-3 lg:mb-8 mt-3 mb-12 mx-auto w-full sm:p-5 p-3`}
    {...rest}
  >
    {children}
  </Box>
);
