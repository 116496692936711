import Checkbox from '@mui/material/Checkbox';
import styled from '@emotion/styled';
import { useFormContext, useWatch } from 'react-hook-form';
import { useEffect, useMemo } from 'react';
import { campaignFields } from '../../campaign-view/campaignConfig';
import { socialTypesConfig, socialTypesIcons } from '../../../configs/social-types';
import { UserEntity } from '../../../types';

const CheckboxStyled = styled(Checkbox)`
  margin: 0 8px;
  &.Mui-checked {
    box-shadow: 0 0 10px;
    border-radius: 0;
    border-radius: 8px;
  }
`;

function CampaignSocial({ user }: { user: UserEntity }) {
  const { setValue } = useFormContext();
  const { socialTypes } = useWatch();

  const socialTypesConfigFilters = useMemo(() => {
    if (!user) {
      return socialTypesConfig;
    }

    return socialTypesConfig.filter((item) => {
      return user.socials.find((social) => social.socialType === item.key);
    });
  }, [user]);

  useEffect(() => {
    if (user && socialTypesConfigFilters) {
      setTimeout(() => {
        setValue('socialTypes', [socialTypesConfigFilters[0].key]);
      }, 1000);
    }
  }, [user, socialTypesConfigFilters, setValue]);

  return (
    <div className="mt-2 mb-4 md:mt-0">
      {socialTypesConfigFilters.map((config) => {
        const Icon = socialTypesIcons[config.key];
        return (
          <CheckboxStyled
            type="radio"
            key={config.key}
            id={config.key}
            checked={socialTypes.includes(config.key)}
            icon={<Icon />}
            checkedIcon={<Icon />}
            value={config.key}
            onChange={() => setValue(campaignFields.socialTypes, [config.key])}
          />
        );
      })}
    </div>
  );
}

export default CampaignSocial;
