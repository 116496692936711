import Button from '@mui/material/Button';
import { ReactNode } from 'react';
import classNames from 'classnames';
import { socialTypesImages } from '../../../configs/social-types';
import { SocialsImages } from './SocialsImages';
import { SocialLink } from '../../../components/SocialLink';
import { ContentText } from '../../../components/Content';
import { messages } from '../../../messages';
import { Number } from '../../../components/Number/Number';
import { SocialEntity } from '../../../types';
import {configuration} from "../../../configs/configuration";

const m = messages.socials;

export type SocialViewProps = {
  social: SocialEntity;
  content?: ReactNode;
  imagesTitle?: ReactNode;
  imagesDescription?: ReactNode;
  controlButton?: ReactNode;
  onDisconnect: (id: string) => void;

  control?: boolean;
  images?: boolean;

  isStyleGrid?: boolean;
};

export const SocialView = ({
  social,
  content,
  control = false,
  controlButton,
  onDisconnect,
  imagesTitle,
  imagesDescription,
  images = true,

  isStyleGrid = true,
}: SocialViewProps) => {
  const img = socialTypesImages[social.socialType];
  return (
    <>
      <div className="flex flex-1 flex-wrap items-start">
        <div className="mr-4 max-w-[70px] mb-3 md:mb-3">
          <img src={img} />
        </div>

        <div
          className={classNames('flex-1', {
            'lg:grid lg:grid-cols-2': isStyleGrid,
          })}
        >
          {configuration.ENABLED_SHOW_USERNAME && social.username && (
            <ContentText
              label={m.view.account}
              content={<SocialLink social={social} />}
            />
          )}

          <ContentText
            label={m.view.followersCount}
            content={<Number amount={social?.followersCount} />}
          />

          {content}
        </div>

        {control && (
          <div className="ml-auto md:w-[200px] md:mt-0 mt-4 w-full">
            {controlButton}
            <div className="flex-1 mt-4">
              <Button
                onClick={() => onDisconnect(social.id)}
                size="medium"
                variant="contained"
                color="secondary"
                fullWidth
              >
                {m.disconnect}
              </Button>
            </div>
          </div>
        )}
      </div>

      {images && (
        <div className="mt-5">
          <SocialsImages
            social={social}
            title={imagesTitle}
            name="images"
            description={imagesDescription}
          />
        </div>
      )}
    </>
  );
};
