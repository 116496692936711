import IconButton from '@mui/material/IconButton';
import { useDispatch } from 'react-redux';
import useThemeMediaQuery from '@fuse/hooks/useThemeMediaQuery';
import { HiOutlineViewList } from 'react-icons/hi';
import { navbarToggle, navbarToggleMobile } from '../../store/fuse/navbarSlice';

function NavbarToggleButton(props) {
  const dispatch = useDispatch();
  const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down('lg'));

  return (
    <IconButton
      className={props.className}
      color="inherit"
      size="small"
      onClick={(ev) => {
        if (isMobile) {
          dispatch(navbarToggleMobile());
        } else {
          dispatch(navbarToggle());
        }
      }}
    >
      <div>
        <HiOutlineViewList
          size={20}
          color="action"
        />
      </div>
    </IconButton>
  );
}

export default NavbarToggleButton;
