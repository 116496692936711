import Avatar from '@mui/material/Avatar';
import { UserEntity } from '../../types';

export type ChatAvatarProps = {
  user: UserEntity;
  size?: number;
};

function ChatAvatar({ user, size = 56 }: ChatAvatarProps) {
  return (
    <Avatar
      src={user.image}
      alt={user.name}
      sx={{ width: size, height: size }}
    >
      {!user.image || user.image === '' ? user.firstName && user.firstName[0] : ''}
    </Avatar>
  );
}

export default ChatAvatar;
