import Button from '@mui/material/Button';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import Card from '@mui/material/Card';
import { useTranslation } from 'react-i18next';
import CampaignViewRequirements from './CampaignViewRequirements';
import CampaignApply from '../campaign-apply/CampaignApply';
import { Orders } from '../orders/Orders';
import { OrdersPublication } from '../orders-publication/OrdersPublication';
import { CanView } from '../../components/CanView';
import { CampaignState, Roles } from '../../configs/configuration';
import {
  ContentCardBody,
  ContentCardImage,
  ContentCardLeft,
  ContentCardRight,
  ContentCategories,
  ContentText,
} from '../../components/Content';
import { useUser } from '../common/useUser';
import { messages } from '../../messages';
import { useCampaignViewGetQuery } from './redux/campaign-view-api';
import { Money } from '../../components/Money/Money';
import { Username } from '../../components/Username';
import { useCampaignOrdersPublicationQuery } from '../orders/campaign-orders-api';
import { CampaignViewControl } from './CampaignViewControl';
import { CampaignViewTitle } from './CampaignViewTitle';
import { CampaignViewMoodboard } from './CampaignViewMoodboard';
import { CampaignViewDetails } from './CampaignViewDetails';
import { CHIPS_BY_PAYMENT_TYPE, CHIPS_BY_SOCIAL } from '../../components/Chips';
import { DialogSuccess } from '../../components/DialogSuccess';
import { Meta } from '../meta/Meta';
import { TITLES } from '../../configs/titles';
import { CampaignViewLayout } from './CampaignViewLayout';
import { CampaignViewNotFound } from './CampaignViewNotFound';
import { Loading } from '../../components/Loading';
import { Date } from '../../components/Date';

const successMessages = messages.orderCreate;

function CampaignView() {
  const { campaignId } = useParams();
  const {
    user: { id: currentUserId },
    role,
  } = useUser();

  const { data: campaign, refetch, isLoading } = useCampaignViewGetQuery(campaignId);
  const [showApply, setShowApply] = useState(false);
  const [dialog, setShowDialog] = useState(false);

  const { t } = useTranslation();

  const { data: publication = {} } = useCampaignOrdersPublicationQuery(campaignId, {
    skip: !role || role === Roles.BRAND,
  });

  const socialType = campaign?.socialTypes[0].socialType;
  const isStopped =
    campaign?.state === CampaignState.stopped || campaign?.state === CampaignState.finished;
  const isApplied = !!publication;
  const isOwner = currentUserId === campaign?.user.id;

  const showControl = !isStopped;

  const categories = campaign?.categories.map((item) => item.category);

  const SocialChip = CHIPS_BY_SOCIAL[socialType?.title];
  const PaymentChip = CHIPS_BY_PAYMENT_TYPE[campaign?.paymentType];

  const campaignFinished =
    campaign?.state === CampaignState.finished ||
    campaign?.state === CampaignState.paused ||
    campaign?.state === CampaignState.stopped;

  const handleOrderFinish = () => {
    refetch();
  };

  if (isLoading) {
    return (
      <CampaignViewLayout>
        <Loading />
      </CampaignViewLayout>
    );
  }

  if (!campaign) {
    return <CampaignViewNotFound />;
  }

  return (
    <CampaignViewLayout>
      <DialogSuccess
        open={dialog}
        content={successMessages.success.content}
        onClose={() => {
          setShowDialog(false);
        }}
        title={successMessages.success.title}
        buttonText={successMessages.success.close}
      />

      <Meta title={TITLES.campaignView(campaign.name)} />

      <div className="flex p-4 lg:p-8 flex-1 flex-wrap">
        {campaign.image && (
          <ContentCardLeft className="md:w-1/3 md:min-w-[180px] md:max-w-[280px] md:mr-6 w-full md:mb-0 mb-4">
            <Card variant="outlined">
              <ContentCardImage
                image={campaign.image}
                className="pt-[100%] md:pt-[85%] lg:pt-[100%]"
                borderRadius={0}
                style={{ borderWidth: 0 }}
              />
            </Card>
          </ContentCardLeft>
        )}

        <ContentCardBody flex={1}>
          <div className="mb-3">
            <CampaignViewTitle campaign={campaign} />
          </div>

          <ContentText
            boldLabel
            label={t('campaign.author', { defaultValue: 'Бренд:' })}
            content={<Username user={campaign.user} />}
          />

          <ContentText
            boldLabel
            label={t('campaign.campaignType', { defaultValue: 'Формат реклами:' })}
            content={messages.campaignTypes[campaign.campaignType]}
          />

          {PaymentChip && (
            <ContentText
              boldLabel
              label={t('campaign.paymentType', { defaultValue: 'Тип співпраці:' })}
              content={<PaymentChip size="small" />}
            />
          )}

          <ContentText
            boldLabel
            label={t('campaign.createdAt', { defaultValue: 'Дата створення:' })}
            content={<Date date={campaign?.createdAt} />}
          />

          {campaign?.dateEnd && (
            <ContentText
              boldLabel
              label={t('campaign.publishEnd', { defaultValue: 'Фінальний термін подачі заявок:' })}
              content={<Date date={campaign?.dateEnd} />}
            />
          )}

          {SocialChip && (
            <ContentText
              boldLabel
              label={t('campaign.platform', { defaultValue: 'Платформа:' })}
              content={<SocialChip size="small" />}
            />
          )}

          {campaign.paymentType !== 'barter' && (
            <ContentText
              boldLabel
              label={t('campaign.price', { defaultValue: 'Бюджет:' })}
              content={<Money amount={campaign.price} />}
            />
          )}

          {campaign.link && (
            <ContentText
              boldLabel
              label={t('campaign.link', { defaultValue: 'Посилання на продукт:' })}
              content={
                <a
                  href={campaign.link}
                  rel="noreferrer"
                  target="_blank"
                  style={{ wordBreak: 'break-all' }}
                  className="link"
                >
                  {campaign.link}
                </a>
              }
            />
          )}

          <CanView
            role={Roles.BRAND}
            condition={isOwner && showControl}
          >
            <ContentText
              boldLabel
              label={t('campaign.isShowInSearch', { defaultValue: 'Зробити кампанію публічною:' })}
              content={campaign.isShowInSearch ? 'Так' : 'Ні'}
            />
          </CanView>

          {Boolean(categories && categories.length) && (
            <div className="-mt-4 -mb-2">
              <ContentText
                boldLabel
                display="flex"
                alignItems="center"
                label={t('campaign.categories', { defaultValue: 'Категорії:' })}
                content={
                  <ContentCategories
                    className="ml-2"
                    categories={categories}
                  />
                }
              />
            </div>
          )}

          {campaign.description && <CampaignViewDetails campaign={campaign} />}

          {campaign.requirements && (
            <CampaignViewRequirements
              campaign={campaign}
              requirements={campaign.requirements}
            />
          )}

          <CampaignViewMoodboard images={campaign.images} />

          {!isApplied && !campaignFinished && (
            <CanView role={Roles.USER}>
              <div className="mt-8 max-w-xs">
                <Button
                  className="m-auto"
                  variant="outlined"
                  fullWidth
                  size="large"
                  color="primary"
                  type="submit"
                  onClick={() => setShowApply(true)}
                >
                  {t('campaign.apply', {
                    defaultValue: 'Зробити пропозицію на проведення кампанії',
                  })}
                </Button>
              </div>
            </CanView>
          )}
        </ContentCardBody>

        <CanView
          role={Roles.BRAND}
          condition={isOwner && showControl}
        >
          <ContentCardRight>
            <CampaignViewControl
              showRecommendation
              campaign={campaign}
            />
          </ContentCardRight>
        </CanView>
      </div>

      <div className="p-6">
        {!isApplied && showApply && (
          <CanView role={Roles.USER}>
            <CampaignApply
              socialType={socialType}
              open={showApply}
              campaign={campaign}
              onSubmit={() => {
                setShowDialog(true);
                setShowApply(false);
              }}
            />
          </CanView>
        )}

        <CanView role={Roles.USER}>
          <OrdersPublication campaign={campaign} />
        </CanView>

        <CanView
          role={Roles.BRAND}
          condition={isOwner}
        >
          <Orders
            campaign={campaign}
            onOrderFinish={handleOrderFinish}
          />
        </CanView>
      </div>
    </CampaignViewLayout>
  );
}

export default CampaignView;
