import styled from '@emotion/styled';
import { useFormContext } from 'react-hook-form';
import get from 'lodash/get';

const SwitchButton = styled.p`
  min-height: 43px;
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 4px;
  display: flex;
  flex: 1;
  width: 100%;
  margin-top: 8px;

  @media (max-width: 780px) {
    font-size: 0.8rem;
    margin-bottom: 8px;
    margin-top: 0;
  }
`;

const SwitchButtonItem = styled('button')`
  background-color: ${({ active, theme }) =>
    active ? theme.palette.secondary.main : 'transparent'};
  color: ${({ active }) => (active ? 'white' : 'black')};
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  padding: 0 4px;
`;

export function Switcher(props) {
  const { setValue, watch, control } = useFormContext();
  const { name } = props;

  const value = get(watch(), name);

  const buttons = props.items.map((item) => (
    <SwitchButtonItem
      type="button"
      onClick={() => {
        setValue(name, item.value);
      }}
      active={item.value === value}
      key={item.value}
    >
      {item.label}
    </SwitchButtonItem>
  ));

  return <SwitchButton {...props}>{buttons}</SwitchButton>;
}
