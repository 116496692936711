import { Controller, useFormContext } from 'react-hook-form';
import { RateItem } from './RateItem';
import { RATES_EMOJI } from './constants';

type RateProps = {
  name: string;
};

export const Rates = ({ name }: RateProps) => {
  const { control } = useFormContext();
  return (
    <div className="flex justify-between space-x-1 max-w-[350px]">
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value } }) => (
          <>
            {RATES_EMOJI.map((rate, index) => (
              <RateItem
                key={rate}
                onClick={() => onChange(index + 1)}
                active={value === index + 1}
              >
                {rate}
              </RateItem>
            ))}
          </>
        )}
      />
    </div>
  )
}
