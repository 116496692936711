import { CampaignFormSubtitle } from '../../campaign-create/CampaignStyles';
import { FinalFields } from '../../../components/FinalFields/FinalFields';
import CampaignFormItem, {
  CampaignFormItemContainer,
} from '../../campaign-create/components/CampaignFormItem';
import { messages } from '../messages';

export type ProfileLinksProps = {
  errors: any;
};
export const ProfileLinks = ({ errors }: ProfileLinksProps) => (
  <CampaignFormItemContainer orient="horizontal">
    <CampaignFormSubtitle>{messages.brandLink}</CampaignFormSubtitle>

    <div
      className="w-full max-w-full mb-4"
      style={{ maxWidth: '100%', marginRight: 0, marginTop: 0 }}
    >
      <FinalFields
        FieldComponent={({ index, control }) => (
          <div className="-mb-4 flex-1">
            <CampaignFormItem
              name={`brandLinks[${index}].value`}
              control={control}
              errors={errors}
              size="small"
              placeholder="https://www.instagram.com/influencer.ua/"
            />
          </div>
        )}
        name="brandLinks"
        appendLabel={messages.brandLinkAdd}
      />
    </div>
  </CampaignFormItemContainer>
);
