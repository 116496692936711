import { Box, Typography, Card, BoxProps } from '@mui/material';
import { Link } from 'react-router-dom';
import Chip from '@mui/material/Chip';
import ImageIcon from '@mui/icons-material/ImageOutlined';
import styled from '@emotion/styled';
import { ReactNode } from 'react';
import { socialTypesIcons } from '../../configs/social-types';
import themesConfig from '../themes-config';

export const ContentField = (props) => (
  <Box
    mb={1}
    {...props}
  />
);

type ContentTextProps = Omit<BoxProps, 'content'> & {
  label: ReactNode;
  boldLabel?: boolean;
  content?: ReactNode | string;
  link?: ReactNode;
  to?: string;
  orientRow?: boolean;
};

export const ContentText = ({
  label,
  boldLabel,
  content,
  link,
  to,
  orientRow,
  ...props
}: ContentTextProps) => (
  <Box
    mb={1}
    {...props}
  >
    <Typography
      variant="subtitle2"
      display="inline"
      fontWeight={boldLabel ? 'bold' : 'regular'}
    >
      {label}
    </Typography>{' '}
    {content && (
      <Typography
        display={!orientRow ? 'inline' : 'block'}
        whiteSpace="pre-wrap"
        variant="body2"
        component="div"
      >
        {content}
      </Typography>
    )}
    {link && <Link to={to}>{link}</Link>}
  </Box>
);

export const ContentCategories = ({ categories, activeIds, onClick, size = 'small', ...props }) => (
  <ContentField
    mt={1}
    {...props}
  >
    {Boolean(categories.length) &&
      categories.map((category) => {
        const active = activeIds && activeIds.includes(category.id);
        return (
          <Chip
            component="span"
            id={category.id}
            icon={category.icon}
            variant={active ? 'contained' : 'outlined'}
            key={category.id}
            className="mt-1 mr-1 max-w-full pointer capitalize"
            color={category.color || (active ? 'primary' : 'default')}
            onClick={onClick && (() => onClick(category.id))}
            label={category.title}
            size={size}
          />
        );
      })}
  </ContentField>
);

export const ContentSocialTypes = ({ socialTypes, ...rest }) => (
  <ContentField {...rest}>
    {socialTypes.map(({ title }) => {
      const Icon = socialTypesIcons[title];
      return (
        <Icon
          key={title}
          color="black"
        />
      );
    })}
  </ContentField>
);

export const StyledCard = styled(Card)`
  &:hover {
    border-color: ${themesConfig.default.palette.primary.main};
  }
`;

export const ContentCard = ({ children, ...rest }) => (
  <StyledCard
    variant="outlined"
    {...rest}
  >
    <div className="flex-1 relative py-4 px-3 flex flex-wrap ">{children}</div>
  </StyledCard>
);

export const ContentCardLeft = (props) => <Box {...props} />;

export const ContentCardBody = (props) => <Box {...props} />;

const CampaignsListItemImage = styled.div`
  padding-top: calc(100% - 20px);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #f1f5f9;
  overflow: hidden;

  position: relative;

  @media (max-width: 640px) {
    padding-top: 50%;
  }
`;

const CampaignsListItemImageIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

export const ContentCardImage = ({ image, fullHeight = false, borderRadius = 8, ...rest }) => (
  <CampaignsListItemImage
    {...rest}
    style={{
      backgroundImage: `url(${image})`,
      flex: 1,
      backgroundSize: 'contain',
      borderLeft: '10px solid #f1f5f9',
      borderRight: '10px solid #f1f5f9',
      borderRadius,
      minHeight: fullHeight ? '100%' : 'auto',
      ...(rest.style || {}),
    }}
  >
    {!image && (
      <CampaignsListItemImageIcon>
        <ImageIcon
          fontSize="large"
          color="icon"
        />
      </CampaignsListItemImageIcon>
    )}
  </CampaignsListItemImage>
);

export const ContentCardRight = (props) => (
  <Box
    ml="auto"
    className="w-full lg:w-auto lg:min-w-[200px] lg:max-w-[200px] lg:pl-4 mt-4 lg:mt-0"
    {...props}
  />
);
