import { useNavigate, useParams } from 'react-router-dom';
import { SocialTypes } from '../../configs/social-types';
import { AppRoutes } from '../../configs/app-routes';
import { useSocialFetchQuery } from '../socials/socials-api';
import { SocialsConnectRouteInstagram } from './SocialsConnectRouteInstagram';
import { SocialsConnectRouteYoutube } from './SocialsConnectRouteYoutube';
import { SocialsConnectRouteTiktok } from './SocialsConnectRouteTiktok';

const SOCIALS_ROUTE_BY_TYPE = {
  [SocialTypes.TIKTOK]: SocialsConnectRouteTiktok,
  [SocialTypes.INSTA]: SocialsConnectRouteInstagram,
  [SocialTypes.YOUTUBE]: SocialsConnectRouteYoutube,
};

export const SocialsEditRoute = () => {
  const navigate = useNavigate();
  const { socialId } = useParams();
  const { data: social } = useSocialFetchQuery(socialId, { skip: !socialId });

  const Component = social && SOCIALS_ROUTE_BY_TYPE[social.socialType];

  return (
    <>
      {Component && (
        <Component
          edit
          socialType={social.socialType}
          social={social}
          onSubmit={() => {
            navigate(AppRoutes.profileSocials());
          }}
        />
      )}
    </>
  );
};
