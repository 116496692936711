import { Control, useFieldArray, useFormContext } from 'react-hook-form';
import { Box } from '@mui/system';
import { IconButton } from '@mui/material';
import IconDelete from '@mui/icons-material/Delete';
import { ComponentType } from 'react';
import { Button } from '../Button';

export type FinalFieldsButtonProps = {
  onClick: () => void;
  label?: string;
};

export type FinalFieldsFieldProps = {
  control: Control;
  index: number;
};

export type FinalFieldsProps = {
  FieldComponent: ComponentType<FinalFieldsFieldProps>;
  DeleteComponent?: ComponentType<FinalFieldsButtonProps>;
  AppendComponent?: ComponentType<FinalFieldsButtonProps>;
  appendLabel?: string;
  name: string;
};

const DefaultDeleteComponent = ({ onClick }: FinalFieldsButtonProps) => (
  <Box ml={2}>
    <IconButton onClick={onClick}>
      <IconDelete />
    </IconButton>
  </Box>
);

const DefaultAppendComponent = ({ onClick, label }: FinalFieldsButtonProps) => (
  <Button
    type="button"
    variant="contained"
    color="primary"
    onClick={onClick}
  >
    {label || 'Додати'}
  </Button>
);

export const FinalFields = ({
  DeleteComponent = DefaultDeleteComponent,
  FieldComponent,
  AppendComponent = DefaultAppendComponent,
  name,
  appendLabel,
}: FinalFieldsProps) => {
  const { control } = useFormContext();

  const { fields, append, remove } = useFieldArray({ control, name });

  return (
    <>
      {fields.map((_, index) => (
        <div
          className="flex flex-1 mb-4 items-end"
          key={index}
        >
          <FieldComponent
            index={index}
            control={control}
          />

          {index !== 0 && DeleteComponent && <DeleteComponent onClick={() => remove(index)} />}
        </div>
      ))}

      {AppendComponent && (
        <AppendComponent
          label={appendLabel}
          onClick={() => append({})}
        />
      )}
    </>
  );
};
