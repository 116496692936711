import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Link } from 'react-router-dom';
import { messages } from '../../messages';
import { Button } from '../../components/Button';

import { CampaignFormBody } from '../campaign-create/CampaignStyles';
import { AppRoutes } from '../../configs/app-routes';

const m = messages.campaigns.payment;

function OrderPaymentPaid({ campaign }) {
  return (
    <>
      <Card
        component={Box}
        maxWidth={1200}
        margin={4}
        variant="outlined"
      >
        <div className="p-4 sm:p-6 max-w-4xl">
          <CampaignFormBody>
            <Typography className="text-16 sm:text-20 truncate text-center font-semibold">
              {m.paidTitle}
            </Typography>

            <div className="text-center my-4">
              <CheckCircleOutlineIcon
                color="primary"
                className="w-[120px] h-[120px]"
              />
            </div>

            <div className="mt-4 text-center">
              <Button
                component={Link}
                to={AppRoutes.campaignView(campaign.id)}
                className="mx-auto"
                size="medium"
                variant="contained"
                color="primary"
              >
                {m.paidGoToCampaign}
              </Button>
            </div>
          </CampaignFormBody>
        </div>
      </Card>
    </>
  );
}

export default OrderPaymentPaid;
