import axios, { AxiosError, AxiosRequestConfig } from 'axios';

interface AxiosBaseQuery extends AxiosRequestConfig {
  body?: any;
}

export const axiosBaseQuery =
  ({ baseUrl } = { baseUrl: '' }) =>
  async ({ url, method, data, params, body, ...rest }: AxiosBaseQuery) => {
    try {
      if (body) {
        data = body;
      }
      const result = await axios({ url: baseUrl + url, method, data, params, ...rest });

      return { data: result.data };
    } catch (axiosError) {
      const err = axiosError as AxiosError;
      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      };
    }
  };
