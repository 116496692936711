import styled from '@emotion/styled';

export const NotificationsDescription = styled.div`
  margin-top: 4px;
  a {
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
`;
