import { useSocials } from '../useSocials';
import { SocialView } from '../components/SocialView';
import { YoutubeConnectButton } from './YoutubeConnectButton';
import { SocialYoutubeConfigView } from './SocialYoutubeConfigView';

type SocialYoutubeViewProps = {
  control?: boolean;
  data: any;
  isStyleGrid?: boolean;
};
export const SocialYoutubeView = ({
  data,
  control = true,
  isStyleGrid,
}: SocialYoutubeViewProps) => {
  const stat = data?.json && data?.json.items[0]?.statistics;
  const { disconnect } = useSocials();

  if (!stat) {
    return null;
  }

  return (
    <SocialView
      isStyleGrid={isStyleGrid}
      social={data}
      control={control}
      controlButton={
        <YoutubeConnectButton
          id={data?.id}
          edit
        />
      }
      onDisconnect={disconnect}
      images={false}
      content={<SocialYoutubeConfigView data={data} />}
    />
  );
};
