import { EventEmitter } from '@fuse/utils/FuseUtils';
import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import jwtServiceConfig from './jwtServiceConfig';

/* eslint-disable camelcase */

class JwtService extends EventEmitter {
  init() {
    createAuthRefreshInterceptor(axios, async (failedRequest) => {
      console.log('ERROR 401', failedRequest);

      await axios.post('api/auth/refresh', { refreshToken: this.getRefreshToken() }).then((res) => {
        this.setAccessToken(res.data.accessToken);

        failedRequest.response.config.headers.Authorization = `Bearer ${res.data.accessToken}`;
        return Promise.resolve();
      });
    });
    this.handleAuthentication();
  }

  isAuthenticated = () => {
    const accessToken = this.getAccessToken();
    return this.isAuthTokenValid(accessToken);
  };

  handleAuthentication = () => {
    const accessToken = this.getAccessToken();

    if (!accessToken) {
      this.emit('onNoAccessToken');

      return;
    }

    if (this.isAuthTokenValid(accessToken)) {
      this.setSession(accessToken);
      this.emit('onAutoLogin', true);
    } else {
      this.setSession(null);
      this.emit('onAutoLogout', 'access_token expired');
    }
  };

  createUser = (data) => {
    return axios.post(jwtServiceConfig.signUp, data).then((response) => {
      if (response.data.user) {
        this.setSession(response.data.accessToken);
        // this.emit('onLogin', response.data.user);
        return response.data.user;
      }
    });
  };

  signInWithEmailAndPassword = (email, password) => {
    return new Promise((resolve, reject) => {
      axios
        .post(jwtServiceConfig.signIn, {
          email,
          password,
        })
        .then((response) => {
          if (response.data.user) {
            this.setSession(response.data.accessToken);
            this.setRefreshToken(response.data.refreshToken);
            resolve(response.data.user);
            this.emit('onLogin', response.data.user);
          } else {
            reject(response?.data?.error);
          }
        })
        .catch((err) => {
          reject(err?.response?.data);
        });
    });
  };

  signInWithToken = () => {
    return new Promise((resolve, reject) => {
      axios
        .get(jwtServiceConfig.accessToken, {
          // data: {
          //   accessToken: this.getAccessToken(),
          // },
        })
        .then((response) => {
          if (response.data) {
            this.setSession(this.getAccessToken());
            resolve(response.data);
          } else {
            this.logout();
            reject(new Error('Failed to login with token.'));
          }
        })
        .catch((error) => {
          this.logout();
          reject(new Error('Failed to login with token.'));
        });
    });
  };

  updateUserData = (user) => {
    return axios.post(jwtServiceConfig.updateUser, {
      user,
    });
  };

  setSession = (accessToken) => {
    if (accessToken) {
      localStorage.setItem('jwt_accessToken', accessToken);
      axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    } else {
      localStorage.removeItem('jwt_accessToken');
      delete axios.defaults.headers.common.Authorization;
    }
  };

  logout = () => {
    this.setSession(null);
    this.setRefreshToken('');
    this.setAccessToken('');
  };

  isAuthTokenValid = (accessToken) => {
    if (!accessToken) {
      return false;
    }
    // const decoded = jwtDecode(accessToken);
    // const currentTime = Date.now() / 1000;
    // if (decoded.exp < currentTime) {
    //   console.warn('access token expired');
    //   return false;
    // }

    return true;
  };

  getAccessToken = () => {
    return window.localStorage.getItem('jwt_accessToken');
  };

  setAccessToken = (value: string) => {
    return window.localStorage.setItem('jwt_accessToken', value);
  };

  getRefreshToken = () => {
    return window.localStorage.getItem('jwt_refreshToken');
  };

  setRefreshToken = (token) => {
    return window.localStorage.setItem('jwt_refreshToken', token);
  };
}

const instance = new JwtService();
export default instance;
