import Badge from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';
import { useState } from 'react';
import Popover from '@mui/material/Popover';
import { HiOutlineBell } from 'react-icons/hi';
import { useInterval } from 'react-use';
import { useGetHasUnreadQuery } from './notifications-api';
import NotificationPanel from './NotificationPanel';

const NOTIFICATION_HAS_UNREAD_UPDATE_TIMEOUT = 40000;

function NotificationPanelToggleButton() {
  const { data, refetch } = useGetHasUnreadQuery();
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState();

  useInterval(refetch, NOTIFICATION_HAS_UNREAD_UPDATE_TIMEOUT);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(!open);
  };

  const hasUnread = data && data.hasUnread;

  return (
    <>
      <IconButton
        className="w-10 h-10"
        onClick={handleOpen}
        size="large"
        color="primary"
      >
        <Badge
          color="secondary"
          variant="dot"
          invisible={!hasUnread}
        >
          <HiOutlineBell size={24} />
        </Badge>
      </IconButton>

      <Popover
        open={open}
        anchorEl={anchorEl}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        onClose={() => setOpen(false)}
      >
        <NotificationPanel
          open={open}
          onClose={() => setOpen(false)}
        />
      </Popover>
    </>
  );
}

export default NotificationPanelToggleButton;
