import { Typography } from '@mui/material';
import { PaymentEntity } from '../payments-api';
import { Money } from '../../../components/Money';

type PaymentsSummaryProps = {
  title: string;
  selectedRows: PaymentEntity[];
};

export const PaymentsSummary = ({ title, total }: PaymentsSummaryProps) => {
  return (
    <Typography
      className="mb-6"
      color="inherit"
      variant="h5"
    >
      {title}: <Money amount={total} />
    </Typography>
  );
};
