import { HiPlusCircle, HiUserCircle, HiLockClosed } from 'react-icons/hi2';
import { MdWorkspaces } from 'react-icons/md';
import { FiCheckCircle } from 'react-icons/fi';
import { IoMdExit } from 'react-icons/io';
import {
  CampaignOutlined,
  ConnectWithoutContactOutlined,
  Bolt,
  SupportAgentOutlined,
  Chat,
} from '@mui/icons-material';
import Icon from '@mui/material/Icon';
import { BiCoinStack } from 'react-icons/bi';
import { AppRoutes } from './app-routes';
import { configuration, Roles } from './configuration';
import { i18n } from '../i18n';

const { t } = i18n;

const navigationConfig = [
  {
    id: 'campaigns',
    title: t('navigation.campaigns', { defaultValue: 'Кампанії' }),
    type: 'group',
    children: [
      {
        id: 'dashboards.project',
        title: t('navigation.project', { defaultValue: 'Усі кампанії' }),
        type: 'item',
        icon: <CampaignOutlined />,
        url: AppRoutes.campaigns(),
        end: true,
        auth: Roles.USER,
      },
      // {
      //   id: 'dashboards.users',
      //   title: 'Знайти блогера',
      //   type: 'item',
      //   icon: <CgSearchLoading size={24} />,
      //   url: AppRoutes.users(),
      //   auth: Roles.BRAND,
      // },
      {
        id: 'dashboards.product',
        title: t('navigation.product', { defaultValue: 'Створити кампанію' }),
        type: 'item',
        // icon: 'heroicons-outline:plus-circle',
        icon: <HiPlusCircle size={24} />,
        url: AppRoutes.campaignsCreate(),
        auth: Roles.BRAND,
      },

      {
        id: 'campaigns.my',
        title: t('navigation.my', { defaultValue: 'Мої кампанії' }),
        type: 'item',
        icon: <FiCheckCircle size={24} />,
        url: AppRoutes.campaignsMy(),
        auth: Roles.BRAND,
      },
      {
        id: 'campaigns.myInfluencer',
        title: t('navigation.myInfluencer', { defaultValue: 'Мої заявки' }),
        type: 'item',
        icon: <MdWorkspaces size={24} />,
        url: AppRoutes.campaignsInfluencer(),
        auth: Roles.USER,
      },

      // {
      //   id: 'settings.profile',
      //   title: "Профіль",
      //   type: 'item',
      //   icon: 'heroicons-outline:user-circle',
      //   url: Routes.profile(),
      // },
      // {
      //   id: 'orders',
      //   title: 'Замовлення',
      //   type: 'item',
      //   icon: 'heroicons-outline:clipboard-check',
      //   url: Routes.ordersUser(),
      //   auth: Roles.USER,
      // },
      // {
      //   id: 'orders.owner',
      //   title: 'Замовлення (Власник)',
      //   type: 'item',
      //   icon: 'heroicons-outline:clipboard-check',
      //   url: Routes.ordersOwner(),
      //   auth: Roles.BRAND,
      // },
      {
        id: 'chat.project',
        title: t('navigation.project', { defaultValue: 'Повідомлення' }),
        type: 'item',
        icon: <Chat />,
        url: AppRoutes.chats(),
      },
      {
        id: 'dashboards.project',
        title: t('navigation.project', { defaultValue: 'Усі кампанії' }),
        type: 'item',
        icon: <CampaignOutlined />,
        url: AppRoutes.campaigns(),
        end: true,
        auth: Roles.BRAND,
      },
    ],
  },

  {
    type: 'divider',
    id: 'divider-2',
  },
  {
    id: 'profile',
    title: 'Профіль',
    type: 'group',
    children: [
      {
        id: 'profile.profile',
        title: t('navigation.profile', { defaultValue: 'Профіль' }),
        type: 'item',
        icon: <HiUserCircle size={24} />,
        url: AppRoutes.profile(),
        end: true,
      },
      {
        id: 'profile.social',
        title: t('navigation.social', { defaultValue: 'Мої платформи' }),
        type: 'item',
        icon: <ConnectWithoutContactOutlined />,
        url: AppRoutes.profileSocials(),
        auth: Roles.USER,
      },
      {
        id: 'profile.credits',
        title: t('navigation.credits', { defaultValue: 'Мої кредити' }),
        type: 'item',
        icon: (
          <Icon className="-mb-1">
            <BiCoinStack />
          </Icon>
        ),
        url: AppRoutes.credits(),
        auth: Roles.USER,
      },
      {
        id: 'profile.security',
        title: t('navigation.security', { defaultValue: 'Безпека' }),
        type: 'item',
        icon: <HiLockClosed size={24} />,
        url: AppRoutes.profileSecurity(),
      },

      {
        id: 'profile.logout',
        title: t('navigation.logout', { defaultValue: 'Вийти' }),
        type: 'item',
        disableLink: true,
        icon: <IoMdExit size={24} />,
        // url: AppRoutes.profileLogout('navigation), { defaultValue: } ,
        translate: 'Вийти',
      },
    ],
  },

  {
    type: 'divider',
    id: 'divider',
  },
  {
    id: 'info',
    title: 'Інформація',
    type: 'group',
    children: [
      {
        id: 'info.start',
        title: t('navigation.start', { defaultValue: 'З чого почати' }),
        type: 'link',
        icon: <Bolt />,
        url: configuration.global.faq,
      },
      // {
      //   id: 'info.rules',
      //   title: 'Правила роботи',
      //   type: 'link',
      //   icon: <HiInformationCircle size={24} />,
      //   url: configuration.global.faq,
      // },
      {
        id: 'info.support',
        title: t('navigation.support', { defaultValue: 'Підтримка' }),
        type: 'link',
        icon: <SupportAgentOutlined />,
        url: configuration.global.contacts,
      },
    ],
  },
];

export default navigationConfig;
