import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CampaignEntity } from '../../types';

export function CampaignViewDetails({ campaign }: { campaign: CampaignEntity }) {
  const { t } = useTranslation();
  return (
    <>
      <Box
        mt={3}
        mb={2}
      >
        <Divider />
      </Box>

      <Box mb={2}>
        <Typography
          className="break-words"
          variant="h6"
        >
          {t('campaignViewDetails.description', { defaultValue: 'Опис бренду та кампанії:' })}
        </Typography>
      </Box>

      <Typography
        whiteSpace="pre-wrap"
        variant="body2"
        fontStyle="italic"
        className="break-words"
        style={{ wordBreak: 'break-word' }}
      >
        {campaign.description}
      </Typography>
    </>
  );
}
