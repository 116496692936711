import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import { grey } from '@mui/material/colors';
import { useTranslation } from 'react-i18next';
import { AppRoutes } from '../../configs/app-routes';
import { Button } from '../../components/Button';
import { messages } from '../../messages';
import { OrdersState } from '../../configs/configuration';
import { Anchors } from '../../configs/anchors';
import { OrdersOfferAccept } from '../orders-offer/OrdersOfferAccept';
import { OrdersOfferDecline } from '../orders-offer/OrdersOfferDecline';
import { CampaignCard } from '../../components/CampaignCard/CampaignCard';

const m = messages.campaignsInfluencer;

const badgeConfigByState = {
  [OrdersState.PUBLICATION_PROGRESS]: m.states.processing,
  [OrdersState.DECLINED]: m.states.rejected,
  [OrdersState.FINISHED]: m.states.finished,
  [OrdersState.CAMPAIGN_FINISHED]: m.states.campaignFinished,
  [OrdersState.PENDING]: m.states.waitAnswer,
  [OrdersState.PUBLICATION_WAIT_REVIEW]: m.states.waitFeedback,
  [OrdersState.OFFERED_TO_BLOGGER]: m.states.offerFromBlogger,
};
const badgeColorByState = {
  [OrdersState.PUBLICATION_PROGRESS]: 'primary',
  [OrdersState.DECLINED]: 'error',
  [OrdersState.FINISHED]: 'primary',
  [OrdersState.CAMPAIGN_FINISHED]: 'default',
  [OrdersState.PENDING]: 'primary',
  [OrdersState.PUBLICATION_WAIT_REVIEW]: 'primary',
  [OrdersState.OFFERED_TO_BLOGGER]: 'warning',
};

function CampaignsInfluencerListItem(props) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { order, onClickReport, refetch, onClickFeedback } = props;
  const { campaign } = order;
  const orderState = order.state;

  const badgeVariant = badgeColorByState[orderState];
  const badgeConfig = badgeConfigByState[orderState] || badgeConfigByState.pending;
  const badgeContent = badgeConfig;
  const debug = false;

  const showButtonPublication = debug || order.state === OrdersState.PUBLICATION_PROGRESS;
  const showButtonProblem =
    debug ||
    (order.state !== OrdersState.PENDING && order.state !== OrdersState.OFFERED_TO_BLOGGER);
  const showButtonSee =
    debug || order.state === OrdersState.PENDING || order.state === OrdersState.OFFERED_TO_BLOGGER;
  const showAcceptAndDeclineButtons = debug || order.state === OrdersState.OFFERED_TO_BLOGGER;
  const showFeedbackButton =
    debug || (order.state === OrdersState.FINISHED && !order.hasBloggerFeedback);

  const finishedState =
    order.state === OrdersState.FINISHED ||
    order.state === OrdersState.DECLINED ||
    order.state === OrdersState.CAMPAIGN_FINISHED;

  const badgeAbsolute = Boolean(campaign?.image);

  if (!campaign) {
    return null;
  }

  return (
    <CampaignCard
      isLink
      showDefaultHelpText={false}
      finished={finishedState}
      badges={
        badgeContent && (
          <Box
            textAlign="center"
            {...(badgeAbsolute
              ? {
                  position: 'absolute',
                  left: -12,
                  top: 8,
                }
              : {})}
            top={8}
            ml="auto"
          >
            <Chip
              color={badgeVariant}
              overlap="circular"
              label={badgeContent}
              style={{
                whiteSpace: 'nowrap',
                backgroundColor: badgeVariant === 'default' ? grey[200] : 'undefined',
              }}
            />
          </Box>
        )
      }
      campaign={campaign}
      right={
        <>
          {showAcceptAndDeclineButtons && (
            <>
              <div className="mb-4">
                <OrdersOfferAccept
                  campaign={campaign}
                  orderId={order.id}
                  onAccept={refetch}
                />
              </div>

              <div className="mb-4">
                <OrdersOfferDecline
                  campaign={campaign}
                  orderId={order.id}
                  onDecline={refetch}
                />
              </div>
            </>
          )}

          {showButtonSee && (
            <div className="mb-4">
              <Button
                onClick={(event) => {
                  event.stopPropagation();
                  navigate(AppRoutes.campaignView(campaign.id));
                }}
                variant="contained"
                color="primary"
                fullWidth
              >
                {t('campaign-influencers.buttonsSee', { defaultValue: m.buttons.see })}
              </Button>
            </div>
          )}

          {showFeedbackButton && (
            <div className="mb-4">
              <Button
                onClick={(event) => {
                  event.stopPropagation();
                  onClickFeedback();
                }}
                variant="contained"
                color="secondary"
                fullWidth
              >
                {t('campaign-influencers.buttonsShowFeedback', {
                  defaultValue: m.buttons.showFeedback,
                })}
              </Button>
            </div>
          )}

          {showButtonPublication && (
            <div className="mb-4">
              <Button
                onClick={(event) => {
                  event.stopPropagation();
                  navigate(AppRoutes.campaignView(campaign.id, Anchors.ORDERS));
                }}
                variant="contained"
                color="primary"
                fullWidth
              >
                {t('campaign-influencers.buttonsPublish', { defaultValue: m.buttons.publish })}
              </Button>
            </div>
          )}

          {showButtonProblem && (
            <div className="mb-4">
              <Button
                onClick={(event) => {
                  event.stopPropagation();
                  onClickReport({
                    campaignId: campaign.id,
                  });
                }}
                variant="outlined"
                color="primary"
                fullWidth
              >
                {t('campaign-influencers.buttonsProblem', { defaultValue: m.buttons.problem })}
              </Button>
            </div>
          )}
        </>
      }
    />
  );
}

export default CampaignsInfluencerListItem;
