import Typography, { TypographyProps } from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import { messages } from '../../messages';
import { CampaignState } from '../../configs/configuration';
import { CampaignEntity } from '../../types';

const m = messages.campaigns;

const helpTextMap = {
  [CampaignState.finished]: m.view.stopped,
  [CampaignState.stopped]: m.view.stopped,
  [CampaignState.paused]: m.view.paused,
};

interface CampaignViewTitleProps {
  campaign: CampaignEntity;
  to?: string;
  variant?: TypographyProps['variant'];
  helpText?: string;
  showDefaultHelpText?: boolean;
}

export function CampaignViewTitle({
  campaign,
  to,
  variant = 'h6',
  helpText: _helpText,
  showDefaultHelpText = true,
}: CampaignViewTitleProps) {
  const helpText = (showDefaultHelpText ? helpTextMap[campaign?.state] : undefined) || _helpText;
  const isLink = Boolean(to);

  return (
    <Typography
      variant={variant}
      component={isLink ? Link : (undefined as any)}
      to={to}
      data-testid="campaign-link"
      className={isLink ? 'font-black' : 'font-black'}
    >
      {campaign.name} {helpText && <span className="text-gray-500">({helpText})</span>}
    </Typography>
  );
}
