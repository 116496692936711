import { orange } from '@mui/material/colors';
import { lighten, styled } from '@mui/material/styles';
import { Controller, useFormContext } from 'react-hook-form';
import DeleteIcon from '@mui/icons-material/Delete';
import Box from '@mui/material/Box';
import clsx from 'clsx';
import { CircularProgress, IconButton } from '@mui/material';
import { Gallery, Item } from 'react-photoswipe-gallery';
import { useState } from 'react';
import { HiOutlineUpload } from 'react-icons/hi';
import { useUpload } from '../../common/useUpload';

import 'photoswipe/dist/photoswipe.css';

const Root = styled('div')(({ theme }) => ({
  '& .productImageFeaturedStar': {
    position: 'absolute',
    top: 0,
    right: 0,
    color: orange[400],
    opacity: 0,
  },

  '& .productImageUpload': {
    transitionProperty: 'box-shadow',
    transitionDuration: theme.transitions.duration.short,
    transitionTimingFunction: theme.transitions.easing.easeInOut,
  },

  '& .productImageItem': {
    transitionProperty: 'box-shadow',
    transitionDuration: theme.transitions.duration.short,
    transitionTimingFunction: theme.transitions.easing.easeInOut,
    '&:hover': {
      '& .productImageFeaturedStar': {
        opacity: 0.8,
      },
    },
    '&.featured': {
      pointerEvents: 'none',
      boxShadow: theme.shadows[3],
      '& .productImageFeaturedStar': {
        opacity: 1,
      },
      '&:hover .productImageFeaturedStar': {
        opacity: 1,
      },
    },
  },
}));

function CampaignImages(props) {
  const methods = useFormContext();
  const { control, watch, setValue, getValues } = methods;
  const { useId, onRemove, onUpload } = props;
  const { upload, remove } = useUpload();
  const { multiple = true, name } = props;
  const [imageSizes, setImageSizes] = useState({});
  const [loading, setLoading] = useState(false);

  const setImageSize = (image, { width, height }) => {
    const obj = { ...imageSizes };
    setImageSizes({ ...imageSizes, [image]: { width, height } });
  };

  const img = watch(props.name) || [];
  const images = Array.isArray(img) ? img : [img];

  return (
    <Root>
      <div className="flex justify-start flex-wrap -mx-2 mt-2 md:mt-0">
        <Controller
          name={props.name}
          control={control}
          render={({ field: { onChange, value } }) => (
            <Box
              sx={{
                backgroundColor: (theme) =>
                  theme.palette.mode === 'light'
                    ? lighten(theme.palette.background.default, 0.4)
                    : lighten(theme.palette.background.default, 0.02),
              }}
              component="label"
              className="productImageUpload flex items-center justify-center relative w-32 h-32 rounded-4 mx-3 mb-6 overflow-hidden cursor-pointer shadow hover:shadow-lg"
            >
              {loading && (
                <div className="absolute top-0 left-0 bottom-0 right-0 flex justify-center items-center bg-[#00000026]">
                  <CircularProgress />
                </div>
              )}

              <input
                accept="image/*"
                name={name}
                className="hidden"
                multiple={multiple && 'multiple'}
                type="file"
                onChange={async (e) => {
                  setLoading(true);
                  await upload(Array.from(e.target.files)).then((items) => {
                    const urls = items.map(({ url }) => url);
                    let result;

                    if (useId) {
                      if (multiple) {
                        result = [...items, ...(value || [])];
                      } else {
                        result = [...items];
                      }
                    } else if (multiple) {
                      result = [...urls, ...(value || [])];
                    } else {
                      result = [...urls];
                    }

                    onChange(result);
                    if (onUpload) {
                      onUpload(result);
                    }

                    setLoading(false);
                  });
                }}
              />

              <HiOutlineUpload
                size={32}
                color="action"
              />
            </Box>
          )}
        />

        <Gallery>
          {images &&
            images.map((media) => (
              <div
                role="button"
                tabIndex={0}
                className={clsx(
                  'productImageItem flex items-center justify-center relative w-32 h-32 rounded-4 mx-3 mb-6 overflow-hidden cursor-pointer outline-none shadow hover:shadow-lg'
                  // media.id === value && 'featured'
                )}
                key={useId ? media.url : media}
              >
                <IconButton
                  className="absolute top-0 right-0"
                  onClick={async () => {
                    if (useId && onRemove) {
                      await onRemove(media);
                    }

                    await remove(useId ? media.url : media, media);

                    if (!(useId && onRemove)) {
                      const arr = images.filter((img) =>
                        useId ? img.url !== media : img !== media
                      );

                      // set
                      setValue(name, arr, { shouldDirty: true });
                    }
                  }}
                >
                  <DeleteIcon />
                </IconButton>

                <Item
                  original={useId ? media.url : media}
                  width={
                    imageSizes[useId ? media.url : media] &&
                    imageSizes[useId ? media.url : media].width
                  }
                  height={
                    imageSizes[useId ? media.url : media] &&
                    imageSizes[useId ? media.url : media].height
                  }
                >
                  {({ ref, open }) => (
                    <div className="rounded-lg p-3 w-full border-gray-300 border cursor-pointer md:max-w-[200px] md:min-w-[128px]">
                      <img
                        className="max-w-none w-auto h-full"
                        src={useId ? media.url : media}
                        alt="product"
                        style={{ objectFit: 'contain', width: '100%', display: 'block' }}
                        // src={image}
                        onLoad={(img) => {
                          setImageSize(useId ? media.url : media, {
                            width: img.target.naturalWidth,
                            height: img.target.naturalHeight,
                          });
                        }}
                        ref={ref}
                        onClick={open}
                      />
                    </div>
                  )}
                </Item>
              </div>
            ))}
        </Gallery>
      </div>
    </Root>
  );
}

export default CampaignImages;
