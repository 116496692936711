const defaultTitle = ' | Influencer.com.ua';

export const TITLES = {
  campaignsRoot: () => `Cписок кампаний${defaultTitle}`,
  campaignsMy: () => `Ваші кампанії${defaultTitle}`,
  campaignsInfluencer: () => `Ваші кампанії${defaultTitle}`,
  campaignsCreate: () => `Створити кампанию${defaultTitle}`,
  campaignView: (name = ':campaignId') => `${name}${defaultTitle}`,
  campaignEdit: (name = ':campaignId') => `Редагувати кампанію - ${name}${defaultTitle}`,

  profileRoot: () => `/profile${defaultTitle}`,
  profile: () => `Ваш профіль${defaultTitle}`,
  profileSecurity: () => `Безпека${defaultTitle}`,
  profileSocials: () => `Мої платформи${defaultTitle}`,
  profileLogout: () => `/sign-out${defaultTitle}`,
  profileSocialsConnectInstagram: () => `Підключити Instagram ${defaultTitle}`,
  profileSocialsConnectYoutube: () => `Підключити Youtube ${defaultTitle}`,
  profileSocialsConnectTiktok: () => `Підключити Tiktok ${defaultTitle}`,

  profileSocialsEdit: () => `Редагувати платформу ${defaultTitle}`,

  profileView: (id = ':id') => `/profile/${id}${defaultTitle}`,

  ordersUser: () => `/orders/user/pending${defaultTitle}`,
  ordersUserWaitForPublish: () => `/orders/user/wait-for-publish${defaultTitle}`,
  ordersUserPending: () => `/orders/user/pending${defaultTitle}`,
  ordersUserArchive: () => `/orders/user/archive${defaultTitle}`,

  ordersOwner: () => `/orders/owner/pending${defaultTitle}`,
  ordersOwnerWaitForPublish: () => `/orders/owner/wait-for-publish${defaultTitle}`,
  ordersOwnerPending: () => `/orders/owner/pending${defaultTitle}`,
  ordersOwnerArchive: () => `/orders/owner/archive${defaultTitle}`,

  signIn: () => `Вхід${defaultTitle}`,
  signUp: () => `Створити новий обліковий запис${defaultTitle}`,
  signUpBlogger: () => `Заповнити обліковий запис${defaultTitle}`,

  chats: () => `Повідомлення${defaultTitle}`,
  chat: (id = ':id') => `/chat/${id}${defaultTitle}`,

  credits: () => `Кредити${defaultTitle}`,
  balance: () => `Баланс${defaultTitle}`,

  users: () => `Список блогерів${defaultTitle}`,
};
