import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../utils/axiosBaseQuery';

export const paymentsApi = createApi({
  reducerPath: 'payments',
  tagTypes: ['payments'],
  baseQuery: axiosBaseQuery({ baseUrl: '/api' }),
  endpoints: (builder) => ({
    fetchCards: builder.query({
      query: () => ({
        url: `/economy/cards`,
      }),
      providesTags: ['paymentCards'],
    }),

    cardCreate: builder.mutation({
      query: (dto) => ({
        url: `/economy/cards`,
        method: 'POST',
        body: dto,
      }),
      invalidatesTags: ['paymentCards'],
    }),

    cardSetDefault: builder.mutation({
      query: (id) => ({
        url: `/economy/cards/change-default-payment/${id}`,
        method: 'POST',
      }),
      invalidatesTags: ['paymentCards'],
    }),

    cardRemove: builder.mutation({
      query: (id) => ({
        url: `/economy/cards/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['paymentCards'],
    }),

    getCardConnectToken: builder.query({
      query: (id) => ({
        url: `/economy/cards/token`,
      }),
    }),

    createInvoice: builder.mutation({
      query: (orderId) => ({
        url: `/orders/${orderId}/invoice`,
        method: 'POST',
      }),
    }),
  }),
});

export const usePaymentsCardsFetch = paymentsApi.endpoints.fetchCards.useQuery;
export const usePaymentsCardCreate = paymentsApi.endpoints.cardCreate.useMutation;
export const usePaymentsCardSetDefault = paymentsApi.endpoints.cardSetDefault.useMutation;
export const usePaymentsCardRemove = paymentsApi.endpoints.cardRemove.useMutation;
export const usePaymentsGetCardConnectToken = paymentsApi.endpoints.getCardConnectToken.useQuery;

export const usePaymentsCreateInvoice = paymentsApi.endpoints.createInvoice.useMutation;
