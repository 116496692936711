import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../../utils/axiosBaseQuery';
import { campaignCreateFormatData } from './campaign-api';

export const campaignViewApi = createApi({
  reducerPath: 'campaign-view',
  tagTypes: ['campaign-view'],
  baseQuery: axiosBaseQuery({ baseUrl: '/api/campaigns' }),
  endpoints: (builder) => ({
    get: builder.query({
      query: (id) => ({
        url: `/${id}`,
      }),
      providesTags: ['CampaignView'],
    }),

    stop: builder.mutation({
      query: (id) => ({
        url: `/${id}/finish`,
        method: 'POST',
      }),
      invalidatesTags: ['CampaignView', 'CampaignsOwner'],
    }),

    pause: builder.mutation({
      query: (id) => ({
        url: `/${id}/pause`,
        method: 'POST',
      }),
      invalidatesTags: ['CampaignView', 'CampaignsOwner'],
    }),

    rerun: builder.mutation({
      query: (id) => ({
        url: `/${id}/rerun`,
        method: 'POST',
      }),
      invalidatesTags: ['CampaignView', 'CampaignsOwner'],
    }),

    payment: builder.mutation({
      query: (id) => ({
        url: `/${id}/payment`,
        method: 'POST',
      }),
      invalidatesTags: ['CampaignView'],
    }),

    ownerList: builder.query({
      query: (query = {}) => {
        return {
          url: `/owner`,
          params: { query },
        };
      },
      providesTags: ['CampaignsOwner'],
    }),

    patch: builder.mutation({
      query: (dto) => {
        return {
          url: `/${dto.campaignId}`,
          method: 'PATCH',
          // body: formatData(dto),
          body: campaignCreateFormatData(dto),
        };
      },
      invalidatesTags: ['CampaignView'],
    }),
  }),
});

// export const useFetchInfluencerCampaigns = campaign-view.useGetQuery;
export const useCampaignViewGetQuery = campaignViewApi.endpoints.get.useQuery;
export const useCampaignViewStopMutation = campaignViewApi.endpoints.stop.useMutation;
export const useCampaignViewPauseMutation = campaignViewApi.endpoints.pause.useMutation;
export const useCampaignViewPaymentMutation = campaignViewApi.endpoints.payment.useMutation;

export const useCampaignViewRerunMutation = campaignViewApi.endpoints.rerun.useMutation;
export const useCampaignViewOwnerListQuery = campaignViewApi.endpoints.ownerList.useQuery;
export const useCampaignPatchMutation = campaignViewApi.endpoints.patch.useMutation;

export const { useGetQuery } = campaignViewApi;
