import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import { HiArrowSmLeft } from 'react-icons/hi';
import { messages } from '../../messages';
import { useUser } from '../common/useUser';
import { Roles, configuration } from '../../configs/configuration';

function CampaignViewHeader() {
  const { user } = useUser();
  const backLink =
    user.role === Roles.USER
      ? configuration.global.rootForBlogger
      : configuration.global.rootForOwner;

  return (
    <div className="mb-6">
      <Typography
        className="flex items-center sm:mb-3"
        component={Link}
        role="button"
        to={backLink}
        color="inherit"
      >
        <HiArrowSmLeft size={20} />
        <span className="flex mx-4 font-medium hover:underline">
          {messages.campaignCreate.view.backToCampaigns}
        </span>
      </Typography>
    </div>
  );
}

export default CampaignViewHeader;
