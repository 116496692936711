import { categoriesConfig } from '../../configs/categories-config';
import { profileConfigFields } from './fields';
import { ProfileNotShowBirthday } from './ProfileNotShowBirthday';
import { Roles } from '../../configs/configuration';
import { UserEntity } from '../../types';
import { ProfileLinks } from './components/ProfileLinks';
import { i18n } from '../../i18n';

export const getProfileFormConfig = (profile: UserEntity) => ({
  general: [
    {
      name: profileConfigFields.image,
      label:
        profile.role === Roles.USER
          ? i18n.t('profile.image', { defaultValue: 'Зображення:' })
          : i18n.t('profile.brandMage', { defaultValue: 'Логотип або зображення:' }),
      placeholder: '',
      value: profile.image,
      variant: 'image',
    },
    {
      name: profileConfigFields.firstName,
      label:
        profile.role === Roles.USER
          ? i18n.t('profile.firstName', { defaultValue: "Ім'я:" })
          : i18n.t('profile.brandName', { defaultValue: 'Назва бренду:' }),
      placeholder: '',
      value: profile.firstName,
      required: true,
      props: {
        size: 'small',
      },
    },
    profile.role === Roles.USER
      ? {
          required: true,
          name: profileConfigFields.lastName,
          label: i18n.t('profile.lastName', { defaultValue: 'Прізвище:' }),
          placeholder: '',
          value: profile.lastName,
          props: {
            size: 'small',
          },
        }
      : undefined,
    profile.role === Roles.USER
      ? {
          name: profileConfigFields.gender,
          label: i18n.t('profile.gender', { defaultValue: 'Стать' }),
          placeholder: i18n.t('profile.genderPlaceholder', {
            defaultValue: '-',
          }),
          value: profile.gender,
          variant: 'select',
          props: {
            items: [
              { label: 'Не вказано', value: undefined },
              { label: 'Чоловіча', value: 'male' },
              { label: 'Жіноча', value: 'female' },
              { label: 'Інша', value: 'other' },
            ],
            size: 'small',
          },
        }
      : undefined,
    {
      name: profileConfigFields.birthday,
      label:
        profile.role === Roles.USER
          ? i18n.t('profile.birthday', { defaultValue: 'Дата народження:' })
          : i18n.t('profile.brandBirthday', { defaultValue: 'Дата народження бренду:' }),
      placeholder: i18n.t('profile.birthdayPlaceholder', {
        defaultValue: 'Дата народження',
      }),
      value: profile.birthday,
      variant: 'date',
      props: {
        size: 'small',
      },
    },

    profile.role === Roles.USER
      ? {
          Component: () => <ProfileNotShowBirthday />,
        }
      : undefined,
    // {
    //   name: profileConfigFields.email,
    //   label: i18n.t('profile.email', { defaultValue: messages.email } ),
    //   placeholder: i18n.t('profile.email', { defaultValue: messages.email }),
    //   value: profile.email,
    //   disabled: true,
    //   props: {
    //     size: 'small',
    //   },
    // },
    {
      name: profileConfigFields.about,
      label:
        profile.role === Roles.USER
          ? i18n.t('profile.about', { defaultValue: 'Біографія:' })
          : i18n.t('profile.brandAbout', { defaultValue: 'Біографія:' }),
      value: profile.about,
      // placeholder: profile.aboutPlaceholder,
      placeholder: i18n.t(
        profile.role === Roles.USER
          ? i18n.t('profile.aboutPlaceholder', {
              defaultValue: 'Надайте коротку інформацію про Вас',
            })
          : i18n.t('profile.brandAboutPlaceholder', {
              defaultValue: 'Коротка інформація про Ваш бренд',
            })
      ),
      variant: 'textarea',
      required: true,
      props: {
        rows: 9,
        size: 'small',
      },
    },

    profile.role === Roles.BRAND
      ? {
          Component: ProfileLinks,
          // name: profileConfigFields.brandLink,
          // label: i18n.t('profile.brandInk', { defaultValue: messages.brandLink }),
          // value: profile.brandLink,
          // placeholder: 'https://www.instagram.com/influencer.ua/',
          // props: {
          //   size: 'small',
          // },
        }
      : undefined,
    {
      name: profileConfigFields.email,
      label: i18n.t('profile.email', { defaultValue: 'E-mail:' }),
      placeholder: i18n.t('profile.emailPlaceholder', { defaultValue: 'E-mail' }),
      value: profile.email,
      description: i18n.t('profile.emailWriteToSupport', {
        defaultValue: 'Щоб змінити електронну пошту, напишіть на support@influencer.com.ua',
      }),
      disabled: true,
      props: {
        size: 'small',
      },
    },
  ].filter(Boolean),

  address: [
    {
      name: profileConfigFields.address.city,
      label: i18n.t('profile.addressCity', { defaultValue: 'Місто:' }),
      placeholder: i18n.t('profile.addressCityPlaceholder', {
        defaultValue: 'Населений пункт',
      }),
      props: {
        size: 'small',
      },
    },
    {
      name: profileConfigFields.phone,
      label: i18n.t('profile.phone', { defaultValue: 'Телефон:' }),
      placeholder: i18n.t('profile.phonePlaceholder', { defaultValue: '+380' }),
      props: {
        size: 'small',
      },
    },
    {
      name: profileConfigFields.address.location,
      label: i18n.t('profile.address', { defaultValue: 'Адреса:' }),
      placeholder: i18n.t('profile.address', {
        defaultValue: 'Вулиця/будинок',
      }),
      value: profile?.address?.location,
      props: {
        size: 'small',
      },
    },

    profile.role === Roles.USER
      ? {
          name: profileConfigFields.address.newPost,
          label: i18n.t('profile.address', { defaultValue: 'Відділення Нової пошти:' }),
          value: profile?.address?.newPost,
          props: {
            size: 'small',
          },
        }
      : undefined,

    {
      name: profileConfigFields.address.index,
      label: i18n.t('profile.addressIndex', { defaultValue: 'Індекс:' }),
      placeholder: '',
      value: profile?.address?.index,
      props: {
        size: 'small',
      },
    },
  ].filter(Boolean),

  security: [
    {
      name: profileConfigFields.password,
      label: i18n.t('profile.password', { defaultValue: 'Старий пароль' }),
      placeholder: i18n.t('profile.passwordPlaceholder', {
        defaultValue: 'Введіть старий пароль',
      }),
      value: profile.password,
      props: {
        size: 'small',
      },
    },
    {
      name: profileConfigFields.newPassword,
      label: i18n.t('profile.newPassword', { defaultValue: 'Новий пароль' }),
      placeholder: i18n.t('profile.newPasswordPlaceholder', {
        defaultValue: 'Введіть новий пароль',
      }),
      props: {
        size: 'small',
      },
    },
    {
      name: profileConfigFields.confirmPassword,
      label: i18n.t('profile.confirmPassword', { defaultValue: 'Підтвердити пароль' }),
      placeholder: i18n.t('profile.confirmPasswordPlaceholder', {
        defaultValue: 'Введіть ще раз',
      }),
      value: profile.confirmPassword,
      props: {
        size: 'small',
      },
    },
  ],

  brand: [
    {
      name: profileConfigFields.brand.title,
      label: i18n.t('profile.brandTitle', { defaultValue: 'Назва кампанії' }),
      placeholder: i18n.t('profile.brandTitle', { defaultValue: 'Назва кампанії' }),
      value: profile?.brand?.title,
      props: {
        size: 'small',
      },
    },
    {
      name: profileConfigFields.brand.image,
      label: i18n.t('profile.brandImage', { defaultValue: 'Логотип або зображення:' }),
      placeholder: i18n.t('profile.brandImage', { defaultValue: 'Логотип або зображення:' }),
      value: profile?.brand?.image,
      variant: 'image',
      props: {
        size: 'small',
      },
    },
  ],
});

export const profileInterestConfig = categoriesConfig.map((category) => ({
  ...category,
  value: category.strapiId,
}));
