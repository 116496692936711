import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { useTranslation } from 'react-i18next';
import { useCreditsFetch } from './credits-api';
import { Meta } from '../meta/Meta';
import { TITLES } from '../../configs/titles';

export const Credits = () => {
  const { data } = useCreditsFetch();
  const { t } = useTranslation();

  return (
    <>
      <Meta title={TITLES.credits()} />

      <Box
        component={Card}
        variant="outlined"
        padding={4}
      >
        <Typography className="truncate title-2">
          {t('credits.title', { defaultValue: 'Кредити' })}
        </Typography>

        <Box
          mt={3}
          mb={3}
        >
          <Divider />
        </Box>

        <div>
          <div className="text-lg font-semibold mb-2">
            {t('credits.definition', { defaultValue: 'Що таке кредит?' })}
          </div>
          <div
            dangerouslySetInnerHTML={{
              __html: t('credits.definitionList', {
                defaultValue: `<p>Кредит - це одиниця виміру, яка використовується на платформі для здійснення кампаній.</p><p>Ви маєте 10 кредитів - за кожну заявку на проведення кампанії ви втрачаєте 1 кредит.</p>`,
              }),
            }}
          />
          <div className="text-lg mt-8 font-semibold">
            {t('credits.mainTitle', { defaultValue: 'Як отримати кредит? ' })}
          </div>
          <div
            dangerouslySetInnerHTML={{
              __html: t('credits.mainDescription', {
                defaultValue:
                  '<ul class="mt-2"><li>1. За кожну успішно проведену кампанію ви отримуєте +1 кредит</li>' +
                  '<li>2. Ваші кредити оновлюються на початку місяця</li>' +
                  '<li>3. Якщо бренд відхилив заявку на співпрацю - кредит списується </li></ul>',
              }),
            }}
          />
          <div className="text-lg mt-8 font-semibold ">
            {t('credits.creditsLeft', { defaultValue: 'Кредитів залишилось:' })}{' '}
            <Typography
              color="primary"
              className="text-2xl align-middle font-bold"
              component="span"
            >
              {data?.count}
            </Typography>
          </div>
        </div>
      </Box>
    </>
  );
};
