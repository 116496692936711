import Divider from '@mui/material/Divider';
import { Typography } from '@mui/material';

import 'photoswipe/dist/photoswipe.css';

import { Gallery, Item } from 'react-photoswipe-gallery';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export function CampaignViewMoodboard({ images }) {
  const { t } = useTranslation();
  const [imageSizes, setImageSizes] = useState({});

  const setImageSize = (image, { width, height }) => {
    setImageSizes({ ...imageSizes, [image]: { width, height } });
  };

  if (!(images && images.length)) {
    return null;
  }

  return (
    <>
      <div className="mt-6 mb-4">
        <Divider />
      </div>

      <div className="mb-3">
        <Typography variant="h6">
          {t('campaign.moodboard', { defaultValue: 'Мудборд:' })}
        </Typography>
      </div>

      <div className="flex flex-wrap">
        <Gallery>
          {images &&
            images.map((image) => (
              <Item
                key={image}
                original={image}
                width={imageSizes[image] && imageSizes[image].width}
                height={imageSizes[image] && imageSizes[image].height}
              >
                {({ ref, open }) => (
                  <div className="rounded-lg mr-3 p-3 mb-3 border-gray-300 border cursor-pointer md:max-w-[200px] md:min-w-[200px] max-w-[46%] min-w-[46%]">
                    <img
                      src={image}
                      onLoad={(img) => {
                        setImageSize(image, {
                          width: img.target.naturalWidth,
                          height: img.target.naturalHeight,
                        });
                      }}
                      ref={ref}
                      onClick={open}
                      className="object-contain"
                    />
                  </div>
                )}
              </Item>
            ))}
        </Gallery>
      </div>
    </>
  );
}
