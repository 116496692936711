import { useContext } from 'react';
import { ToastsContext } from './ToastsProvider';

function useToasts() {
  const { show, error, success, close, info } = useContext(ToastsContext);

  return {
    show,
    success,
    close,
    error,
    info,
  };
}

export { useToasts };
