import { combineReducers } from '@reduxjs/toolkit';
import navbar from './navbarSlice';
import navigation from './navigationSlice';

const fuseReducers = combineReducers({
  navigation,
  navbar,
});

export default fuseReducers;
