import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import * as yup from 'yup';
import Paper from '@mui/material/Paper';
import { useMemo, useState } from 'react';
import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { configuration } from '../../configs/configuration';
import CampaignFormItem from '../campaign-create/components/CampaignFormItem';
import { messages } from '../../messages';
import { useForgotPassword } from './forgot-password-api';
import { useToasts } from '../../components/Toasts';

const getSchema = () =>
  yup.object().shape({
    email: yup.string().email().required(),
  });

const defaultValues = {
  email: '',
};

function ForgotPassword() {
  const schema = useMemo(getSchema, []);
  const { t } = useTranslation();
  const { control, formState, handleSubmit } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });
  const { error } = useToasts();

  const { isValid, errors } = formState;
  const [finished, setFinished] = useState(false);
  const [forgotPassword] = useForgotPassword();

  const onSubmit = async (fd: { email: string }) => {
    await forgotPassword({ email: fd.email })
      .unwrap()
      .then(() => {
        setFinished(true);
      })
      .catch(() => {
        error(messages.error);
      });
  };

  return (
    <div className="flex py-10 flex-col flex-auto items-center sm:justify-center min-w-0  ">
      <Paper className="w-full rounded-0 py-4 px-2 sm:p-8 rounded-lg max-w-md">
        <div className="w-full max-w-320 sm:w-320 mx-auto sm:mx-0">
          <img
            className="mx-auto h-24 rounded-lg w-auto"
            src={configuration.global.logo}
            alt="logo"
          />

          <Typography className="mt-10 text-center text-xl font-bold leading-9 tracking-tight text-gray-900">
            {t('forgotPassword.title', { defaultValue: 'Відновлення паролю' })}
          </Typography>

          {!finished ? (
            <form
              noValidate
              className="flex flex-col justify-center w-full mt-6"
              onSubmit={handleSubmit(onSubmit)}
            >
              <CampaignFormItem
                name="email"
                control={control}
                label={t('forgotPassword.email', { defaultValue: 'Email' })}
                errors={errors}
                size="small"
              />

              <Button
                variant="contained"
                color="primary"
                className="w-full mt-4"
                aria-label="Register"
                disabled={!isValid}
                type="submit"
                size="small"
              >
                {t('forgotPassword.submit', { defaultValue: 'Відправити' })}
              </Button>
            </form>
          ) : (
            <Box mt={2}>
              {t('forgotPassword.finished', {
                defaultValue:
                  'Вам на пошту прийде посилання на відновлення паролю протягом 5 хвилин. Перейдіть по ній і відновіть обліковий запис.',
              })}
            </Box>
          )}
        </div>
      </Paper>
    </div>
  );
}

export default ForgotPassword;
