import { FormProvider, useForm } from 'react-hook-form';
import { ComponentType, ReactNode, useEffect, useMemo, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import qs from 'qs';
import {
  SocialTypes,
  socialTypesConfigNormalized,
  socialTypesImages,
} from '../../../configs/social-types';
import { messages } from '../../../messages';
import { socialsImagesGetDefaultImageValues } from '../../socials/components/SocialsImages';
import { useSocials } from '../../socials/useSocials';
import { formatUpdateDto } from '../utils';
import { useProfile } from '../../profile/useProfile';
import { AppRoutes } from '../../../configs/app-routes';
import { SocialEntity } from '../../../types';
import { getSocialManualConnectByUsernameSchema } from '../schema';
import { useSocialConnectManualMutation } from '../../socials/socials-api';
import { Button } from '../../../components/Button';
import { useToasts } from '../../../components/Toasts';
import { referral } from '../../../utils/referral';

const m = messages.socials;

type SocialsConnectRemoteProps = {
  social: SocialEntity;
  socialType?: SocialTypes;
  edit?: boolean;
  description?: ReactNode;
  beforeImagesBlock?: ReactNode;
  cancelButton?: ReactNode;
  onClickCancel?: () => void;
  onSubmit?: () => void;
  ButtonComponent: ComponentType<any>;
  InfoComponent: ComponentType<any>;
  connectDescription?: ReactNode;
};

export type SocialManualConnectByUsernameFd = {
  images: any[];
  username: string;
};

export const SocialsConnectRemote = ({
  social: _social,
  socialType = SocialTypes.TIKTOK,
  edit: _edit = false,
  beforeImagesBlock,
  cancelButton,
  connectDescription,
  onClickCancel,
  onSubmit,
  ButtonComponent,
  InfoComponent,
}: SocialsConnectRemoteProps) => {
  const navigate = useNavigate();
  const { profile } = useProfile();
  const [searchParams, setSearchParams] = useSearchParams();
  const currentSchema = useMemo(
    () => getSocialManualConnectByUsernameSchema(socialType),
    [socialType]
  );

  const [instaErr, setInstaErr] = useState(undefined);
  const code = searchParams.get('code');

  const [connectManual, { isLoading, error, data }] = useSocialConnectManualMutation();
  const { disconnect, update } = useSocials();

  const social = data || _social;
  const edit = _edit || Boolean(data);

  const { hash } = useLocation();
  const { connectInstagram } = useSocials();
  const { error: toastError } = useToasts();

  useEffect(() => {
    if (socialType === SocialTypes.INSTA) {
      const search = hash.replace('#', '');
      const query = qs.parse(search);

      const accessToken = query.access_token;
      const longLivedToken = query.long_lived_token;

      if (accessToken) {
        connectInstagram({ accessToken, longLivedToken })
          .then((resp) => {
            navigate(AppRoutes.profileSocialsViewById(resp.id));
          })
          .catch((err) => {
            console.log('err.data.response', err, err.response);
            setInstaErr(err);
            setSearchParams(new URLSearchParams({ access_token: '' }));
            // toastError();
          });
      }
    }
  }, [searchParams]);

  const defaultValues = useMemo(
    () => ({
      ...social,
      images: socialsImagesGetDefaultImageValues(social?.images || []),
    }),
    [social]
  );

  const form = useForm<SocialManualConnectByUsernameFd>({
    defaultValues,
    resolver: yupResolver(currentSchema),
  });
  const { title } = socialTypesConfigNormalized[socialType];
  const onDisconnect = () => {
    disconnect(social.id);
    navigate(AppRoutes.profileSocials());
  };

  const disabledButton = !form.formState.isDirty;

  const handleDisconnect = () => {
    navigate(AppRoutes.profileSocials());

    if (onDisconnect) {
      onDisconnect();
    }
  };

  useEffect(() => {
    if (code) {
      connectManual({
        socialType,
        code,
      })
        .then((resp) => {
          referral.emit('connect_social_finished');
          navigate(AppRoutes.profileSocialsViewById(resp.data.id));
        })
        .catch(() => {
          setSearchParams(new URLSearchParams({ code: '' }));
        });
    }
  }, [code]);

  useEffect(() => {
    form.reset(defaultValues);
  }, [defaultValues]);

  const submit = (fd: SocialManualConnectByUsernameFd) => {
    if (edit) {
      update({ id: social.id, dto: formatUpdateDto(fd, defaultValues) }).then(() => {
        if (onSubmit) {
          onSubmit();
        }

        form.reset(undefined, { keepValues: true });
      });
    }
  };

  const verificationCode = profile.id;
  const showForbiddenError = error && 'status' in error && error.status === 403;
  const showFollowersCountError = error && 'status' in error && error.status === 400;
  const showCommonError = !showFollowersCountError && !showForbiddenError && error;
  const img = socialTypesImages[socialType];

  const isInstagram = socialType === SocialTypes.INSTA;

  return (
    <FormProvider {...form}>
      <form
        onSubmit={form.handleSubmit(submit)}
        className="mb-8"
      >
        <div className="font-black text-xl mb-4 flex items-center">
          <div className="mr-2 max-w-[56px]">
            <img src={img} />
          </div>
          {title}
          {/*: {edit ? m.editTitle : m.connectTitle} */}
        </div>

        {connectDescription && !social && <div className="">{connectDescription}</div>}

        {social && InfoComponent && (
          <InfoComponent
            beforeImagesBlock={beforeImagesBlock}
            social={social}
            isShowImage={false}
          />
        )}

        {showForbiddenError && (
          <div className="text-red-700 my-4 max-w-[360px] font-bold">
            {m.connectForbiddenError(verificationCode)}
          </div>
        )}

        {!isInstagram && showCommonError && (
          <div className="text-red-700 my-4 max-w-[360px] font-bold">{m.connectCommonError}</div>
        )}

        {instaErr && (
          <div className="text-red-700 my-4 max-w-[460px] whitespace-pre-wrap font-bold">
            {m.connectInstagramError}
          </div>
        )}

        {showFollowersCountError && (
          <div className="text-red-700 my-4 max-w-[360px] font-bold">
            {m.connectFollowersCountError}
          </div>
        )}

        <div className="mt-4 flex">
          {!edit && (
            <div className="md:min-w-[180px] min-w-[120px]">
              <ButtonComponent
                remoteConnect
                loading={isLoading}
              />
            </div>
          )}

          {edit && (
            <Button
              variant="contained"
              type="submit"
              className="md:min-w-[180px] min-w-[120px]"
              color="primary"
              size="medium"
              loading={isLoading}
              disabled={disabledButton}
            >
              {edit ? m.tiktok.update : m.tiktok.connect}
            </Button>
          )}

          {cancelButton && (
            <Button
              variant="outlined"
              type="button"
              color="primary"
              size="medium"
              className="ml-3 md:min-w-[180px] min-w-[120px]"
              onClick={onClickCancel}
            >
              {cancelButton}
            </Button>
          )}

          {edit && (
            <Button
              className="ml-3 md:min-w-[180px] min-w-[120px]"
              variant="outlined"
              type="button"
              color="primary"
              size="medium"
              onClick={handleDisconnect}
            >
              {m.instagram.disconnect}
            </Button>
          )}
        </div>
      </form>
    </FormProvider>
  );
};
