import { useContext } from 'react';
import { ProfileContext } from './ProfileContext';

export const useUser = () => {
  const { profile = {}, isLoaded } = useContext(ProfileContext);

  return {
    user: profile,
    role: profile?.role,
    isLoaded,
  };
};
