import { YoutubeChartData, YoutubeChartNormalizedData } from './YoutubeCharts';

export type Channel = {
  id: string;
  name: string;
  description: string;
  thumbnailUrl: string;
  publishedAt: Date;
  details?: ChannelDetails;
};

export type ChannelDetails = {
  tags: string[];
  uploadsPlaylistId: string;
  subscriberCount: number;
  totalViewCount: number;
  videoCount: number;
};

export type Video = {
  id: string;
  title: string;
  thumbnailUrl: string;
  publishedAt: Date;
  stats?: VideoStats;
};

export type VideoStats = {
  viewCount: number;
  likeCount: number;
  dislikeCount: number;
  commentCount: number;
};

enum AnalyticsNames {
  viewCount = 'Views',
  likeCount = 'Likes',
  dislikeCount = 'Dislikes',
  commentCount = 'Comments',
  engagementRate = 'Engagement Rate',
  reach = 'Reach',
  totalViews = 'Total Views',
  uploadedVideos = 'Uploaded Videos',
}

export type DataPoint = {
  name: string;
  data: number[];
};

export type OverallAnalytics = {
  name: string;
  value: string;
};

export function getOverallAnalytics(
  channelDetails: ChannelDetails,
  videos: Video[]
): OverallAnalytics[] {
  const overallAnalyticsObj: any = videos.reduce(
    (acc, video: Video) => ({
      [AnalyticsNames.viewCount]: acc[AnalyticsNames.viewCount] + video.stats.viewCount,
      [AnalyticsNames.likeCount]: acc[AnalyticsNames.likeCount] + video.stats.likeCount,
      [AnalyticsNames.dislikeCount]: acc[AnalyticsNames.dislikeCount] + video.stats.dislikeCount,
      [AnalyticsNames.commentCount]: acc[AnalyticsNames.commentCount] + video.stats.commentCount,
      [AnalyticsNames.engagementRate]: 0,
      [AnalyticsNames.reach]: 0,
      [AnalyticsNames.totalViews]: 0,
      [AnalyticsNames.uploadedVideos]: 0,
    }),
    {
      [AnalyticsNames.viewCount]: 0,
      [AnalyticsNames.likeCount]: 0,
      [AnalyticsNames.dislikeCount]: 0,
      [AnalyticsNames.commentCount]: 0,
      [AnalyticsNames.engagementRate]: 0,
      [AnalyticsNames.reach]: 0,
      [AnalyticsNames.totalViews]: 0,
      [AnalyticsNames.uploadedVideos]: 0,
    }
  );

  const viewCount: number = overallAnalyticsObj[AnalyticsNames.viewCount];
  const likeCount: number = overallAnalyticsObj[AnalyticsNames.likeCount];
  const dislikeCount: number = overallAnalyticsObj[AnalyticsNames.dislikeCount];
  const commentCount: number = overallAnalyticsObj[AnalyticsNames.commentCount];

  overallAnalyticsObj[AnalyticsNames.engagementRate] =
    (likeCount + dislikeCount + commentCount) / channelDetails.subscriberCount;
  overallAnalyticsObj[AnalyticsNames.reach] = (likeCount + dislikeCount + commentCount) / viewCount;
  overallAnalyticsObj[AnalyticsNames.totalViews] = channelDetails.totalViewCount;
  overallAnalyticsObj[AnalyticsNames.uploadedVideos] = channelDetails.videoCount;

  return Object.entries(overallAnalyticsObj).map((entry): OverallAnalytics => {
    const [name, value] = entry;

    return {
      name,
      value: value.toLocaleString() + ((name === AnalyticsNames.engagementRate && '%') || ''),
    };
  });
}

export const formatDataForChart = (data: YoutubeChartData): YoutubeChartNormalizedData => {
  const result: any = {};

  data.columnHeaders.forEach((item, index) => {
    const { name } = item;
    result[name] = [];

    if (index !== 0) {
      data.rows.forEach((row) => {
        const date = row[0];
        const value = row[index];

        result[name].push({
          label: date,
          value,
        });
      });
    }
  });

  return result;
};

export const formatFlatData = (data: YoutubeChartData): YoutubeChartNormalizedData => {
  const result: any = {};

  data.columnHeaders.forEach((item, index) => {
    const { name } = item;
    result[name] = [];

    data.rows.forEach((row) => {
      const value = row[index];

      result[name] = value;
    });
  });

  return result;
};
