import Card from '@mui/material/Card';
import { Box } from '@mui/system';
import Divider from '@mui/material/Divider';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetNotificationsQuery } from './notifications-api';
import NotificationCard from './NotificationCard';

export const NotificationsPage = () => {
  const { t } = useTranslation();
  const { data: notifications } = useGetNotificationsQuery(
    { limit: 100 },
    { refetchOnMountOrArgChange: true }
  );

  if (!notifications) {
    return null;
  }

  return (
    <>
      <div className="title-2 mb-6">
        {t('notifications.titleLong', { defaultValue: 'Центр сповіщень' })}
      </div>
      <Box
        component={Card}
        variant="outlined"
        padding={4}
      >
        {notifications.map((item, index) => (
          <Fragment key={item.id}>
            <NotificationCard
              className="mb-4"
              item={item}
            />
            {index !== notifications.length - 1 && (
              <Box my={2}>
                <Divider />
              </Box>
            )}
          </Fragment>
        ))}
      </Box>
    </>
  );
};
