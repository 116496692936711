import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import { AppRoutes } from '../../../configs/app-routes';
import { usePatchMutation } from '../notifications-api';
import { NotificationBase } from './NotificationBase';

export const NotificationOfferFromOwner = ({ payload, onClick, id, ...rest }) => {
  const [patch] = usePatchMutation();
  const { declined } = payload;

  if (declined) {
    return null;
  }

  return (
    <NotificationBase
      {...rest}
      title="Ви отримали пропозицію від блогера"
      content={
        <>
          Ви отримали пропозицію від бренда на просування кампанії{' '}
          <Link
            target="_blank"
            to={AppRoutes.campaignView(payload.campaign)}
            rel="noreferrer"
            className="link"
          >
            {payload.campaignName}
          </Link>
        </>
      }
      Buttons={
        <>
          <Button
            size="small"
            color="primary"
            variant="contained"
            className="mb-1 mt-2"
            component={Link}
            onClick={() => {
              onClick && onClick();
            }}
            to={AppRoutes.campaignViewAll(payload.campaign, payload.orderId)}
          >
            Переглянути пропозицію
          </Button>
        </>
      }
    />
  );
};
