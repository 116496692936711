import Card from '@mui/material/Card';
import { ReactNode } from 'react';
import CampaignViewHeader from './CampaignViewHeader';

export const CampaignViewLayout = ({ children }: { children: ReactNode }) => {
  return (
    <>
      <CampaignViewHeader />

      <Card variant="outlined">{children}</Card>
    </>
  );
};

export default CampaignViewLayout;
