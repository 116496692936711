import { createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit';
import merge from 'lodash/merge';
import navigationConfig from '../../configs/navigation-config';

const navigationAdapter = createEntityAdapter();
const emptyInitialState = navigationAdapter.getInitialState();
const initialState = navigationAdapter.upsertMany(emptyInitialState, navigationConfig);

const hasPermission = (authArr, userRole) => {
  /**
   * If auth array is not defined
   * Pass and allow
   */
  if (authArr === null || authArr === undefined) {
    // console.info("auth is null || undefined:", authArr);
    return true;
  }
  if (authArr.length === 0) {
    /**
     * if auth array is empty means,
     * allow only user role is guest (null or empty[])
     */
    // console.info("auth is empty[]:", authArr);
    return !userRole || userRole.length === 0;
  }
  /**
   * Check if user has grants
   */
  // console.info("auth arr:", authArr);
  /*
          Check if user role is array,
          */
  if (userRole && Array.isArray(userRole)) {
    const arr = Array.isArray(authArr) ? authArr : [authArr];
    return arr.some((r) => userRole.indexOf(r) >= 0);
  }

  /*
          Check if user role is string,
          */
  return authArr.includes(userRole);
};

export const { selectAll: selectNavigationAll } = navigationAdapter.getSelectors(
  (state) => state.fuse.navigation
);

const navigationSlice = createSlice({
  name: 'navigation',
  initialState,
  reducers: {
    setNavigation: navigationAdapter.setAll,
    resetNavigation: (state, action) => initialState,
  },
});

const getUserRole = (state) => state.user.role;

export const selectNavigation = createSelector(
  [selectNavigationAll, getUserRole],
  (navigation, userRole) => {
    return merge([], navigation);
  }
);

function filterRecursively(arr, predicate) {
  return arr.filter(predicate).map((item) => {
    item = { ...item };
    if (item.children) {
      item.children = filterRecursively(item.children, predicate);
    }
    return item;
  });
}

export default navigationSlice.reducer;
