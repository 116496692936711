import Dialog from '@mui/material/Dialog';
import { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { messages } from '../../messages';
import {
  useProposeToCampaignsFetchQuery,
  useUsersCreateOrderToBloggerByCampaignId,
} from './users-api';
import { Checkbox } from '../../components/Checkbox/Checkbox';
import { Button } from '../../components/Button';
import { AppRoutes } from '../../configs/app-routes';
import { useToasts } from '../../components/Toasts';
import { UserEntity } from '../../types';
import { getSameSocialFromUserCampaign } from '../../utils/get-same-social-from-user-campaign';

export type UserProposeProps = {
  user: UserEntity;
  onClose: () => void;
  open: boolean;
};

export const UserPropose = ({ user, onClose, ...rest }: UserProposeProps) => {
  const { data: { data = [] } = {} } = useProposeToCampaignsFetchQuery();
  const { t } = useTranslation();
  const [selectedCampaign, setSelectedCampaign] = useState();
  const [createProposalByCampaignId] = useUsersCreateOrderToBloggerByCampaignId();
  const { success, error } = useToasts();

  const filteredData = useMemo(
    () =>
      data.filter((campaign) => {
        const social = getSameSocialFromUserCampaign(campaign, user);
        const enoughFollowers = social && campaign.minFollowersCount < social?.followersCount;

        return enoughFollowers;
      }),
    [data, user]
  );

  const handleCreateProposalByCampaignId = () => {
    createProposalByCampaignId({ ...selectedCampaign, userId: user.id })
      .unwrap()
      .then(() => {
        onClose();
        success(
          t('orderPublication.success', {
            defaultValue: 'Ваша пропозиція блогеру успішно надіслана.',
          })
        );
      })
      .catch((err) => {
        if (err.status === 409) {
          error(
            t('orderPublication.errorConflict', {
              defaultValue:
                'Ви вже працюєте з цим інфлюенсером з цієї кампанії. Виберіть іншого блогера',
            })
          );
        } else {
          error(messages.error);
        }
      });
  };

  return (
    <Dialog
      {...rest}
      onClose={onClose}
      style={{ zIndex: 10 }}
    >
      <>
        <div className="flex-shrink-0 items-center justify-between rounded-t-md border-b border-neutral-100 border-opacity-100 md:p-5 p-4 dark:border-opacity-50">
          <div className="text-xl font-black leading-normal text-neutral-800  mr-4">
            {t('userPropose.title', { defaultValue: 'Запропонуйте блогеру кампанію' }) || 'title'}
          </div>

          <div className="mt-2">
            {t('userPropose.subtitle', {
              defaultValue: 'Упс, у Вас немає відповідних кампаній для співпраці з цим блогером',
            })}
          </div>
        </div>

        <div className="relative flex-auto md:p-5 p-4">
          <div className="mt-2">
            {filteredData &&
              filteredData.map((item) => (
                <div
                  key={item.id}
                  className="flex my-2 items-center cursor-pointer hover:bg-blue-50"
                  onClick={() => setSelectedCampaign(item)}
                >
                  <div>
                    <Checkbox checked={item.id === selectedCampaign?.id} />
                  </div>

                  {item.image && (
                    <div className="mr-4">
                      <img
                        alt={item.image}
                        className="w-[80px] h-[60px] object-contain block"
                        src={item.image}
                      />
                    </div>
                  )}

                  <div className="font-bold text-[14px]">{item.name}</div>
                </div>
              ))}

            {!filteredData?.length && (
              <div>
                {t('userPropose.propose', {
                  defaultValue:
                    'Упс, у Вас немає відповідних кампаній для співпраці з цим блогером',
                })}
              </div>
            )}
          </div>
        </div>

        <div className="flex flex-shrink-0 flex-wrap items-center justify-end rounded-b-md border-t border-neutral-100 border-opacity-100 md:p-5 p-4">
          <Button
            disabled={!selectedCampaign}
            onClick={() => handleCreateProposalByCampaignId()}
            variant="contained"
            color="primary"
            className="md:w-[240px] w-full"
            size="medium"
          >
            {t('userPropose.propose', {
              defaultValue: 'Виберіть з поточних або створіть нову',
            })}
          </Button>

          <Button
            component={Link}
            to={AppRoutes.usersProposeCampaign(user && user.id)}
            variant="contained"
            color="primary"
            className="md:w-[240px] w-full mt-2 md:mt-0 md:ml-4"
            size="medium"
          >
            {t('userPropose.create', { defaultValue: 'Cтворити нову пропозицію' })}
          </Button>
        </div>
      </>
    </Dialog>
  );
};
