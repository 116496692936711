function formatToUnits(n) {
  if (n < 1e3) return n;
  if (n >= 1e3 * 10 && n < 1e6) return `${+(n / 1e3).toFixed(1)} тис.`;
  if (n >= 1e6 && n < 1e9) return `${+(n / 1e6).toFixed(1)} міл.`;
  if (n >= 1e9 && n < 1e12) return `${+(n / 1e9).toFixed(1)}B`;
  if (n >= 1e12) return `${+(n / 1e12).toFixed(1)}T`;
  return n;
}

export const Number = ({ amount }) => {
  return <>{formatToUnits(amount)}</>;
};
