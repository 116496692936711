import { Fragment, useEffect } from 'react';
import { Box } from '@mui/system';
import Divider from '@mui/material/Divider';
import { messages } from '../../messages';
import { useGetNotificationsQuery, useReadMutation } from './notifications-api';
import NotificationCard from './NotificationCard';
import { Link } from '../../components/Link';
import { AppRoutes } from '../../configs/app-routes';

const m = messages.notifications;

function NotificationPanel(props) {
  const { open } = props;
  const [read] = useReadMutation();

  const { data: notifications = [] } = useGetNotificationsQuery(
    {},
    { refetchOnMountOrArgChange: true }
  );

  useEffect(() => {
    if (open) {
      read();
    }
  }, [open, read]);

  return (
    <Box
      width={300}
      height={320}
    >
      {notifications.length > 0 ? (
        <div className="flex flex-col h-full">
          <div className="flex-1">
            <div className="flex absolute top-0 bottom-[32px] left-0 right-0 overflow-auto flex-col p-4">
              {notifications.map((item, index) => (
                <Fragment key={item.id}>
                  <NotificationCard
                    key={item.id}
                    item={item}
                    onClose={props.onClose}
                  />

                  {index !== notifications.length - 1 && (
                    <Box my={2}>
                      <Divider />
                    </Box>
                  )}
                </Fragment>
              ))}
            </div>
          </div>

          <Box
            component={Link}
            onClick={props.onClose}
            to={AppRoutes.notifications()}
            height={32}
            borderTop="1px solid #e2e8f0"
            display="flex"
            alignItems="center"
            justifyContent="center"
            className="text-xs"
          >
            {m.allNotifications}
          </Box>
        </div>
      ) : (
        <div className="flex flex-1 items-center justify-center p-4">
          <div className="text-14 font-semibold text-center p-4">{m.notFound}</div>
        </div>
      )}
    </Box>
  );
}

export default NotificationPanel;
