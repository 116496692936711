import Typography from '@mui/material/Typography';
import { ReactNode } from 'react';

type SocialAddProps = {
  title: ReactNode;
  body: ReactNode;
  image: string;
  connectButton: ReactNode;
  edit?: boolean;
};
export const SocialAdd = ({ title, body, image, connectButton }: SocialAddProps) => (
  <div className="flex w-full mt-5">
    <div className="max-w-[80px] mr-6">
      <img
        src={image}
        alt={title}
      />
    </div>

    <div>
      <div className="mb-2">
        <Typography variant="subtitle2">{title}</Typography>
      </div>

      <div>{body}</div>

      <div className="w-[200px] mt-3">
        {connectButton}
        {/* <YoutubeConnect /> */}
      </div>
    </div>
  </div>
);
