import React from 'react';
import { useLocation } from 'react-router-dom';

export const useScrollToLocation = () => {
  const scrolledRef = React.useRef(false);
  const { hash } = useLocation();
  const hashRef = React.useRef(hash);

  React.useLayoutEffect(() => {
    if (hash) {
      if (hashRef.current !== hash) {
        hashRef.current = hash;
        scrolledRef.current = false;
      }

      if (!scrolledRef.current) {
        setTimeout(() => {
          const id = hash.replace('#', '');
          const element = document.getElementById(id);

          if (element) {
            element.scrollIntoView();
            scrolledRef.current = true;
          }
        }, 250);
      }
    }
  }, [hash]);
};
