import { Gallery, Item } from 'react-photoswipe-gallery';
import { useState } from 'react';

export type OrderItemImagesProps = {
  images: string[];
};

export type OrderItemImage = { width: number; height: number };

export const OrderItemImages = ({ images }: OrderItemImagesProps) => {
  const [imageSizes, setImageSizes] = useState<{ [key: string]: OrderItemImage }>({});

  const setImageSize = (image: string, { width, height }: OrderItemImage) => {
    setImageSizes({ ...imageSizes, [image]: { width, height } });
  };

  return (
    <Gallery>
      {images &&
        images.map((media) => (
          <div
            role="button"
            tabIndex={0}
            key={media}
            className="max-w-[240px] mr-4 mb-4"
          >
            <Item
              original={media}
              width={imageSizes[media] && imageSizes[media].width}
              height={imageSizes[media] && imageSizes[media].height}
            >
              {({ ref, open }) => (
                <img
                  src={media}
                  onLoad={(img) => {
                    setImageSize(media, {
                      width: img.currentTarget.naturalWidth,
                      height: img.currentTarget.naturalHeight,
                    });
                  }}
                  ref={ref}
                  onClick={open}
                />
              )}
            </Item>
          </div>
        ))}
    </Gallery>
  );
};
