import { Navigate } from 'react-router-dom';
import { useUser } from '../modules/common/useUser';
import { AppRoutes } from '../configs/app-routes';

export const ProtectedRoute = ({ roles = [], children, redirectPath = AppRoutes.signIn() }) => {
  const { role, isLoaded } = useUser();

  if (!isLoaded) {
    return null;
  }

  if (roles.includes(role)) {
    return children;
  }

  return (
    <Navigate
      to={redirectPath}
      replace
    />
  );
};
