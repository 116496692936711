import {configuration} from "../../configs/configuration";

export const messages = {
  signUp: 'Реєстрація',
  signIn: 'Увійти',
  displayName: 'Username:',
  alreadyHaveAccount: 'Вже маєте акаунт?',
  name: "Ім'я",
  email: 'Email',
  password: 'Пароль',
  passwordConfirm: 'Підтвердіть пароль',
  acceptTermsConditions: `Я згоден з <a class="font-semibold text-indigo-600 hover:text-indigo-500" href="${configuration.global.termsOfServiceUrl}" target="_blank">Умовами надання послуг</a> та <a class="font-semibold text-indigo-600 hover:text-indigo-500" href="${configuration.global.privacyPolicyUrl}" target="_blank">Політикою конфіденційності</a>`,
  create: 'Створити акаунт',
  brand: 'Бренд',
  influencer: 'Інфлюенсер',
  brandName: 'Назва бренду',

  errors: {
    required: 'Поле обов\'язкове для введення',
    signUpConflict: 'Акаунт із таким email вже існує',
    base: 'Щось пішло не так...',
    displayName: { required: "Ви повинні ввести ім'я для відображення" },
    email: { required: 'Ви повинні ввести email', valid: 'Ви повинні ввести дійсний email' },
    password: {
      required: 'Будь ласка, введіть ваш пароль',
      valid: 'Пароль занадто короткий - має бути не менше 8 символів',
    },
    passwordConfirm: { match: 'Паролі мають співпадати' },
    acceptTermsConditions: { oneOf: 'Умови повинні бути прийняті' },
  },
};
