import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../../utils/axiosBaseQuery';
import { categoriesConfig } from '../../../configs/categories-config';

const categoriesMapping = categoriesConfig.reduce((acc, item, index) => {
  acc[item.id] = categoriesConfig[index];
  acc[item.label] = categoriesConfig[index];
  return acc;
}, {});

export const campaignCreateFormatData = (dto) => {
  const form = { ...dto };

  // form.socialTypes = form.socialTypes.map((val) => socialTypesMapping[val]);
  form.categories = form.categories.map((item) => {
    return categoriesMapping[item.id || item.label]?.strapiId;
  });

  form.categories = Array.from(new Set(form.categories));

  if (Array.isArray(form.image)) {
    form.image = form.image[0];
  }

  delete form.campaignId;
  delete form.id;

  return form;
};

export const campaignApi = createApi({
  reducerPath: 'campaign',
  tagTypes: ['campaign'],
  baseQuery: axiosBaseQuery({ baseUrl: '/api/campaigns' }),
  endpoints: (builder) => ({
    create: builder.mutation({
      query: (dto) => {
        return {
          url: `/`,
          method: 'POST',
          body: campaignCreateFormatData(dto),
        };
      },
      providesTags: ['CampaignCreate'],
    }),
    //
    // patch: builder.mutation({
    //   query: (dto) => {
    //     return {
    //       url: `/${dto.campaignId}`,
    //       method: 'PATCH',
    //       // body: formatData(dto),
    //       body: campaignCreateFormatData(dto),
    //     };
    //   },
    // }),
  }),
});

export const useCampaignCreateMutation = campaignApi.endpoints.create.useMutation;
// export const useCampaignPatchMutation = campaignApi.endpoints.patch.useMutation;
