import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../utils/axiosBaseQuery';

export const forgotPasswordApi = createApi({
  reducerPath: 'forgot-password',
  tagTypes: ['forgot-password'],
  baseQuery: axiosBaseQuery({ baseUrl: '/api' }),
  endpoints: (builder) => ({
    forgotPassword: builder.mutation({
      query: (body) => {
        return {
          url: `/auth/forgot-password`,
          method: 'POST',
          body,
        };
      },
    }),
    recovery: builder.mutation({
      query: (body) => {
        return {
          url: `/auth/restore-password?token=${body.token}`,
          method: 'POST',
          body,
        };
      },
    }),
  }),
});

export const useForgotPassword = forgotPasswordApi.endpoints.forgotPassword.useMutation;
export const useForgotPasswordRecovery = forgotPasswordApi.endpoints.recovery.useMutation;
