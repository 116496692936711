import Chip from '@mui/material/Chip';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { SocialTypes, socialTypesConfigNormalized } from '../configs/social-types';
import { messages } from '../messages';
import { PaymentType } from '../configs/configuration';

const youtube = socialTypesConfigNormalized[SocialTypes.YOUTUBE];
const instagram = socialTypesConfigNormalized[SocialTypes.INSTA];
const tiktok = socialTypesConfigNormalized[SocialTypes.TIKTOK];

export const ChipTiktok = ({ onClick, active, ...props }) => {
  return (
    <Chip
      {...props}
      onClick={onClick}
      sx={{
        background: 'black',
      }}
      component="span"
      icon={<tiktok.Icon />}
      className="mx-1 max-w-full pointer capitalize ring-1 ring-inset ring-gray-500/10"
      label={tiktok.title}
    />
  );
};

export const ChipInstagram = ({ onClick, active, ...props }) => {
  return (
    <Chip
      {...props}
      onClick={onClick}
      sx={{
        background:
          'linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%);',
        filter:
          "progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 );",
      }}
      component="span"
      icon={<instagram.Icon />}
      className="mx-1 max-w-full pointer capitalize ring-1 ring-inset ring-gray-500/10"
      label={instagram.title}
    />
  );
};

export const ChipYoutube = ({ onClick, active, ...props }) => {
  return (
    <Chip
      {...props}
      onClick={onClick}
      component="span"
      icon={<youtube.Icon />}
      className="mx-1 max-w-full pointer capitalize ring-1 ring-inset ring-gray-500/10"
      color="error"
      label={youtube.title}
    />
  );
};

export const ChipBarter = ({ onClick, ...props }) => (
  <Chip
    {...props}
    component="span"
    onClick={onClick}
    icon={<AutorenewIcon />}
    className="mx-1 max-w-full pointer ring-1 ring-inset ring-gray-500/10"
    color="primary"
    label={messages.paymentTypes[PaymentType.BARTER]}
  />
);

export const ChipMoney = ({ onClick, ...props }) => (
  <Chip
    {...props}
    component="span"
    onClick={onClick}
    icon={<AttachMoneyIcon />}
    className="mx-1 max-w-full pointer ring-1 ring-inset ring-gray-500/10"
    color="success"
    label={messages.paymentTypes[PaymentType.MONEY]}
  />
);

export const ChipBarterMoney = ({ onClick, ...props }) => (
  <Chip
    {...props}
    component="span"
    onClick={onClick}
    icon={<CurrencyExchangeIcon />}
    className="mx-1 max-w-full pointer ring-1 ring-inset ring-gray-500/10"
    color="primary"
    label={messages.paymentTypes[PaymentType.BARTER_MONEY]}
  />
);

export const CHIPS_BY_SOCIAL = {
  [SocialTypes.YOUTUBE]: ChipYoutube,
  [SocialTypes.TIKTOK]: ChipTiktok,
  [SocialTypes.INSTA]: ChipInstagram,
};

export const CHIPS_BY_PAYMENT_TYPE = {
  [PaymentType.BARTER_MONEY]: ChipBarterMoney,
  [PaymentType.BARTER]: ChipBarter,
  [PaymentType.MONEY]: ChipMoney,
};
