import { useMemo } from 'react';
import { Typography } from '@mui/material';
import styled from '@emotion/styled';
import CheckIcon from '@mui/icons-material/CheckBox';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { useTranslation } from 'react-i18next';
import { campaignFields, campaignRequirementsFields } from './campaignConfig';
import themesConfig from '../../components/themes-config';
import { convertToInternationalCurrencySystem } from '../../utils/convertToInternationalCurrencySystem';

const CampaignViewRequirementsCheckMark = styled.div`
  position: absolute;
  left: 0;
  top: 0;
`;

const customCheckMarkFields = [
  campaignRequirementsFields.notCopyright,
  campaignRequirementsFields.attachStatistic,
  campaignRequirementsFields.notOther_brands,
];

function CampaignViewRequirements({ requirements, campaign }) {
  const socialType = campaign?.socialTypes[0]?.title;
  const { t } = useTranslation();

  const formConfig = (name, requirementsObject) => {
    return {
      [campaignRequirementsFields.mentions]: {
        label: t('campaign.mentions', { defaultValue: 'Згадки:' }),
        variant: 'textfield',
        value: requirementsObject.mentions && requirementsObject.mentions.join(', '),
      },
      // [campaignRequirementsFields]: {
      //   label: t('campaign.mentions', { defaultValue: m.view.mentions }),
      //   variant: 'textfield',
      //   value: requirementsObject.mentions && requirementsObject.mentions.join(', '),
      // },
      [campaignRequirementsFields.keywords]: {
        label: t('campaign.keywords', { defaultValue: 'Ключові слова:' }),
        variant: 'textfield',
        value: requirementsObject.keywords && requirementsObject.keywords.join(', '),
      },
      [campaignRequirementsFields.tutorialDo]: {
        label: t('campaign.tutorialDo', { defaultValue: 'Tutorial_do:' }),
        variant: 'textarea',
        value: requirementsObject.tutorialDo,
      },
      [campaignRequirementsFields.tutorialDont]: {
        label: t('campaign.tutorialDont', { defaultValue: 'Tutorial_dont:' }),
        variant: 'textarea',
        value: requirementsObject.tutorialDont,
      },
      [campaignRequirementsFields.hashtags]: {
        label: t('campaign.hashtags', { defaultValue: 'Hashtags:' }),
        variant: 'categories',
        value: requirementsObject.hashtags,
      },
      [campaignRequirementsFields.publishStart]: {
        label: t('campaign.publishStart', { defaultValue: 'PublishStart:' }),
        variant: 'date',
        value: requirementsObject.publishStart,
      },
      [campaignRequirementsFields.publishEnd]: {
        label: t('campaign.publishEnd', { defaultValue: 'Кінець публікації:' }),
        variant: 'date',
        value: requirementsObject.publishEnd,
      },
      [campaignRequirementsFields.rules]: {
        label: t('campaign.rules', { defaultValue: 'Технічне завдання:' }),
        variant: 'textarea',
        value: requirementsObject.rules,
      },
      [campaignRequirementsFields.notOther_brands]: {
        label: t('campaign.notOther_brands', {
          defaultValue: 'НЕ використовувати інші бренди під час публікації',
        }),
        variant: 'checkbox',
        value: requirementsObject.notOther_brands,
      },
      [campaignRequirementsFields.attachStatistic]: {
        label: t('campaign.attachStatistic', {
          defaultValue: 'Прикріпіть статистику успішної публікації, після проведення кампанії',
        }),
        variant: 'checkbox',
        value: requirementsObject.attachStatistic,
      },
      [campaignRequirementsFields.other]: {
        label: t('campaign.other', { defaultValue: 'Інше:' }),
        variant: 'textarea',
        value: requirementsObject.other,
      },
    };
  };

  const config = useMemo(() => {
    const result = [];

    let config: { [key: string]: any } = {};

    if (campaign.minFollowersCount) {
      config.minFollowersCount = {
        label: `${t('campaign.minFollowersCount', {
          defaultValue: 'Мінімальна кількість фоловерів',
        })} - ${convertToInternationalCurrencySystem(campaign.minFollowersCount)}`,
        variant: 'checkbox',
        value: true,
      };
    }

    config = {
      ...config,
      ...formConfig(`${campaignFields.requirements}.${socialType}`, requirements),
    };

    result.push(config);

    return result;
  }, [socialType, requirements]);

  return (
    <>
      {config.map((socialConfig, index) => {
        return (
          <Box
            mt={2}
            key={`socialConfig${index}`}
          >
            <Box
              mt={3}
              mb={2}
            >
              <Divider />
            </Box>

            <Box mb={2}>
              <Typography variant="h6">
                {t('campaign.requirements', { defaultValue: 'Вимоги до публікації' })}
              </Typography>
            </Box>

            <div>
              {Object.keys(socialConfig).map((key) => {
                const item = socialConfig[key];

                if (customCheckMarkFields.includes(key)) {
                  return (
                    <Box
                      key={key}
                      position="relative"
                      pl={6}
                      mb={2}
                    >
                      <CampaignViewRequirementsCheckMark>
                        <CheckIcon
                          fontSize="large"
                          htmlColor={themesConfig.default.palette.primary.main}
                        />
                      </CampaignViewRequirementsCheckMark>
                      <Typography
                        className="pt-1"
                        variant="subtitle2"
                        style={{
                          'word-break': 'break-word',
                        }}
                      >
                        {item.label}
                      </Typography>
                    </Box>
                  );
                }

                if (!item.value) {
                  return null;
                }

                return (
                  <Box
                    key={key}
                    position="relative"
                    pl={6}
                    mb={2}
                  >
                    <CampaignViewRequirementsCheckMark>
                      <CheckIcon
                        fontSize="large"
                        htmlColor={themesConfig.default.palette.primary.main}
                      />
                    </CampaignViewRequirementsCheckMark>
                    <Typography
                      className="pt-1"
                      variant="subtitle2"
                    >
                      {item.label}
                    </Typography>
                    <Typography
                      variant="body2"
                      whiteSpace="pre-wrap"
                      style={{
                        'word-break': 'break-word',
                      }}
                    >
                      {item.value}
                    </Typography>
                  </Box>
                );
              })}
            </div>
          </Box>
        );
      })}
    </>
  );
}

export default CampaignViewRequirements;
