import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../utils/axiosBaseQuery';

export enum PaymentsState {
  PENDING = 'pending',
  REJECTED = 'rejected',
  PAID = 'paid',
}

export type PaymentEntity = {
  amount: number;
  datePaymentDeadline: string;
  dateCreated: string;
  dateUpdated: string;
  id: number;
  state: PaymentsState;
  campaign: {
    name: string;
    id: number;
  };
  user: {
    firstName: string;
    lastName: string | null;
    id: number;
  };
};

export type PaymentsResponse = {
  data: PaymentEntity[];
};

export const userPaymentsApi = createApi({
  reducerPath: 'user-payments',
  baseQuery: axiosBaseQuery({ baseUrl: '/api' }),
  endpoints: (builder) => ({
    fetch: builder.query<PaymentsResponse, { state: PaymentsState }>({
      query: ({ state }) => {
        return {
          url: `/payments`,
          params: {
            state,
          },
        };
      },
    }),
  }),
});

export const usePaymentsFetch = userPaymentsApi.endpoints.fetch.useQuery;
