import * as yup from 'yup';
import dayjs from 'dayjs';
import { messages } from '../../messages';
import { campaignFields, campaignRequirementsFields } from '../campaign-view/campaignConfig';

const m = messages.campaignCreate;

export const schema = () =>
  yup.object().shape({
    [campaignFields.name]: yup.string().required().min(5),

    [campaignFields.description]: yup.string().required().min(5),

    [campaignFields.campaignType]: yup.string().required(),

    [campaignFields.categories]: yup.array().required(),

    // [campaignFields.dateEnd]: yup.string().required(),
    [campaignFields.dateEnd]: yup.date().required().min(dayjs().startOf('day').toISOString()),

    [campaignFields.paymentType]: yup.string().required(),

    [campaignFields.price]: yup.mixed().when(campaignFields.paymentType, (paymentType, schema) => {
      if (paymentType === 'money' || paymentType === 'barter_money') {
        return schema.required();
      }
      return schema;
    }),

    [campaignFields.requirements]: yup
      .object({
        [campaignRequirementsFields.rules]: yup.string().required(),
        // [campaignRequirementsFields.hashtags]: yup.array().max(1).required(),
        // [campaignRequirementsFields.keywords]: yup.array().max(1).required(),
        // [campaignRequirementsFields.mentions]: yup.array().max(1).required(),
      })
      .required(),
  });
