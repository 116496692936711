import Typography from '@mui/material/Typography';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { SocialTypes } from '../../configs/social-types';
import { SocialEntity } from '../../types';
import { SocialsConnectRouteLayout } from './components/SocialsConnectRouteLayout';
import { SocialsConnectRemote } from './components/SocialsConnectRemote';
import { SocialYoutubeConnectView } from '../socials/youtube/SocialYoutubeConnectView';
import { YoutubeConnectButton } from '../socials/youtube/YoutubeConnectButton';

type SocialsConnectRouteProps = {
  edit?: boolean;
  social?: SocialEntity;
};

export const SocialsConnectRouteYoutube = ({ edit, social }: SocialsConnectRouteProps) => {
  const socialType = (social && social.socialType) || SocialTypes.YOUTUBE;

  return (
    <SocialsConnectRouteLayout>
      <SocialsConnectRemote
        edit={edit}
        social={social}
        socialType={socialType}
        ButtonComponent={YoutubeConnectButton}
        InfoComponent={SocialYoutubeConnectView}
        connectDescription={
          <ul className="ml-2 ">
            <li className="mb-2">✔️ 500+ підписників </li>
            <li className="mb-2">✔️ Мінімум 2000 переглядів на останніх 5 відео </li>
            <li className="mb-2">✔️ Релевантний контент </li>
            <li className="mb-2">✔️ Активний канал </li>
            <li className="mb-2">✔️ Автентичність аудиторії: відсутність фальшивих підписників </li>
          </ul>
        }
        beforeImagesBlock={
          <Typography
            variant="body2"
            color="grey"
            maxWidth={400}
            fontStyle="italic"
            className="max-w-[600px]"
          >
            <ErrorOutlineIcon color="warning" /> Додайте скріншоти зі статистикою вашого каналу за
            тиждень, а також інформацію про географічні, вікові та гендерні показники
          </Typography>
        }
      />
    </SocialsConnectRouteLayout>
  );
};
