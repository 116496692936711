import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { SocialTypes, socialTypesImages } from '../../configs/social-types';
import { ContentText } from '../../components/Content';
import { messages } from '../socials/messages';
import { convertToInternationalCurrencySystem } from '../../utils/convertToInternationalCurrencySystem';
import { configuration } from '../../configs/configuration';

const linkBySocialType = {
  [SocialTypes.INSTA]: (username) => `https://instagram.com/${username}`,
  [SocialTypes.YOUTUBE]: (username) => `https://youtube.com/${username}`,
  [SocialTypes.TIKTOK]: (username) => `https://tiktok.com/${username}`,
  [SocialTypes.FEEDBACK]: (username) => `https://tiktok.com/${username}`,
};

export const SmartSearchSocial = ({ data, socialType }) => {
  const linkFn = linkBySocialType[socialType];

  return (
    <div className="flex flex-1 max-w-[600px] items-center mb-2">
      <div className="max-w-[36px] mr-2">
        <img
          src={socialTypesImages[socialType]}
          alt={socialType}
        />
      </div>

      <Box>
        {data.username && configuration.ENABLED_SHOW_USERNAME && (
          <Typography
            component={Link}
            href={linkFn(data.username)}
            target="_blank"
            color="secondary"
          >
            @{data.username}
          </Typography>
        )}

        <ContentText
          mb={0}
          label={messages.instagram.followersCount}
          content={convertToInternationalCurrencySystem(data.followersCount)}
        />
      </Box>
    </div>
  );
};
