import merge from 'lodash/merge';
import { defaultThemeOptions } from '@fuse/default-settings';
import { createTheme } from '@mui/material/styles';
import themesConfig from "./themes-config";

export function generateMuiTheme(theme) {
  const data = merge({}, defaultThemeOptions, theme);
  const response = createTheme(data);
  return response;
}

export const mainTheme = generateMuiTheme(themesConfig.default);
