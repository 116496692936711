import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { DialogConfirm } from '../../components/DialogConfirm';

export const OrderStartWorkConfirmation = (props) => {
  const { t } = useTranslation();
  return (
    <DialogConfirm
      title={t('order.startWorkTitle', {
        defaultValue: `Ви впевнені що хочете почати роботу з цим інфлюенсером?`,
      })}
      iconHide
      maxWidth={520}
      content={t('order.startWorkContent', {
        defaultValue: `Цю дію не можна скасувати.\nВас буде перенаправлено в чат для подальшої комунікації`,
      })}
      {...props}
      buttons={
        <>
          <Box minWidth={140}>
            <Button
              fullWidth
              type="button"
              variant="outlined"
              color="primary"
              onClick={props.onClose}
            >
              {t('order.startWorkNo', { defaultValue: 'Скасувати' })}
            </Button>
          </Box>

          <Box
            ml={2}
            minWidth={140}
          >
            <Button
              fullWidth
              type="button"
              variant="contained"
              color="primary"
              onClick={props.onSubmit}
            >
              {t('order.startWorkYes', { defaultValue: 'Розпочати роботу' })}
            </Button>
          </Box>
        </>
      }
    />
  );
};
