import { LocaleObject } from 'yup';

/* eslint no-template-curly-in-string: 0 */
export const mixed: LocaleObject['mixed'] = {
  default: '${path} є недійсним.',
  required: 'Поле має бути заповнене',
  defined: '${path} має бути визначено',
  notNull: 'Поле не може бути нульовим',
  oneOf: '${path} має бути одним із таких значень: ${values}',
  notOneOf: '${path} не повинен бути одним із таких значень: ${values}',
};

export const string: LocaleObject['string'] = {
  length: '${path} має бути точно ${length} символи',
  min: 'Мінімум ${min} символів',
  max: '${path} має бути не більше максимум ${max} символи',
  matches: '${path} повинен відповідати наступному: "${regex}"',
  email: 'Введіть вірний email',
  url: 'Має бути дійсний URL',
  uuid: '${path} має бути дійсним UUID',
  trim: '${path} Повинен бути обрізаним рядком',
  lowercase: '${path} Повинен бути рядковим рядком',
  uppercase: '${path} має бути рядком верхнього чохла',
};

export const number: LocaleObject['number'] = {
  min: '${path} має бути більшим або дорівнювати ${min}',
  max: '${path} має бути меншим або дорівнювати ${max}',
  lessThan: '${path} має бути меншим, ніж ${less}',
  moreThan: '${path} має бути більшим, ніж ${more}',
  positive: '${path} має бути позитивним числом',
  negative: "${path} має бути від'ємним числом",
  integer: '${path} має бути цілим числом',
};

export const date: LocaleObject['date'] = {
  min: 'Дата має бути більше ніж ${min}',
  max: '${path} Поле має бути в більш ранньому, ніж ${max}',
};

export const boolean: LocaleObject['boolean'] = {
  isValue: '${path} Поле має бути ${value}',
};

export const object: LocaleObject['object'] = {
  noUnknown: "Поле ${path} не може мати ключі, які не вказані у формі об'єкта",
};

export const array: LocaleObject['array'] = {
  min: '${path} Поле повинно мати принаймні ${min} елементів',
  max: '${path} Поле повинно мати менше або дорівнює ${max} елементам',
  length: '${path} повинно мати ${length} елементи',
};
