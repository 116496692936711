import Button from '@mui/material/Button';
import FacebookIcon from '@mui/icons-material/Facebook';
import { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from '../../../configs/app-routes';
import { messages } from '../../../messages';
import { configuration } from '../../../configs/configuration';

type InstagramConnectButtonProps = {
  text?: ReactNode;
  remoteConnect?: boolean;
  id?: string;
  isSecondUrl?: boolean;
};
export const InstagramConnectButton = ({
  text,
  remoteConnect,
  id,
  isSecondUrl,
}: InstagramConnectButtonProps) => {
  const navigate = useNavigate();
  const onClick = () => {
    if (configuration.ENABLED_INSTAGRAM_REMOTE_CONNECTION && remoteConnect) {
      const redUri = `${configuration.webUrl}${AppRoutes.profileSocialsConnectInstagramWebhook()}`;
      // const redUri = `http://localhost:3000/app${AppRoutes.profileSocialsConnectInstagramWebhook()}`;
      // let url = `https://www.facebook.com/v16.0/dialog/oauth?response_type=token&display=page&client_id=3440643999550657&redirect_uri=${redUri}&auth_type=rerequest&extras={"setup":{"channel":"IG_API_ONBOARDING"}}&scope=instagram_basic%2Cinstagram_manage_insights%2Cpages_show_list%2Cbusiness_management`;

      // if (isSecondUrl) {
      // let url = `https://www.facebook.com/v16.0/dialog/oauth?response_type=token&display=popup&client_id=3440643999550657&redirect_uri=${redUri}&auth_type=rerequest&scope=instagram_basic%2Cinstagram_manage_insights%2Cpages_show_list`;
      let url = `https://www.facebook.com/v16.0/dialog/oauth?response_type=token&display=page&client_id=3440643999550657&redirect_uri=${redUri}&auth_type=rerequest&extras={"setup":{"channel":"IG_API_ONBOARDING"}}&scope=instagram_basic%2Cinstagram_manage_insights%2Cpages_show_list%2Cbusiness_management`;
      // }
      // const url = `https://www.facebook.com/v18.0/dialog/oauth?client_id=3440643999550657&display=page&extras={"setup":{"channel":"IG_API_ONBOARDING"}}&redirect_uri=${redUri}&response_type=token&scope=instagram_basic,instagram_manage_insights,pages_show_list`;
      window.open(url, '_self');
    } else if (!id) {
      navigate(AppRoutes.profileSocialsConnectInstagram());
    } else {
      navigate(AppRoutes.profileSocialsViewById(id));
    }
  };

  return (
    <>
      <Button
        startIcon={<FacebookIcon className="w-[24px] h-[24px]" />}
        onClick={onClick}
        size="medium"
        fullWidth
        variant="contained"
        color="secondary"
      >
        {text || messages.socials.connectInstagram}
      </Button>
    </>
  );
};

// "Facebook Platform" "invalid_request" "Unsupported get request. Object with ID 'me' does not exist, cannot be loaded due to missing permissions, or does not support this operation. Please read the Graph API documentation at https://developers.facebook.com/docs/graph-api"
