import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import { HiArrowSmallLeft } from 'react-icons/hi2';
import { AppRoutes } from '../../../configs/app-routes';
import { messages } from '../../../messages';

function CampaignHeader() {
  return (
    <div className="flex flex-col sm:flex-row flex-1 w-full items-center justify-between space-y-8 sm:space-y-0 mb-6">
      <div className="flex flex-col items-start space-y-8 sm:space-y-0 w-full sm:max-w-full min-w-0">
        <Typography
          className="flex items-center"
          component={Link}
          role="button"
          to={AppRoutes.campaignsMy()}
          color="inherit"
        >
          <HiArrowSmallLeft size={20} />
          <span className="flex mx-4 font-medium">{messages.campaignCreate.back}</span>
        </Typography>
      </div>
    </div>
  );
}

export default CampaignHeader;
