import Button from '@mui/material/Button';
import LogoutIcon from '@mui/icons-material/Logout';
import { DialogConfirmNew } from '../../components/DialogConfirmNew';
import { messages } from './messages';

export type SignOutDialogProps = {
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
};

export const SignOutDialog = (props: SignOutDialogProps) => {
  return (
    <DialogConfirmNew
      title={messages.dialog.title}
      maxWidth={380}
      icon={<LogoutIcon className="text-[120px] text-slate-700" />}
      {...props}
      buttons={
        <>
          <div className="md:min-w-[160px] min-w-[120px]">
            <Button
              fullWidth
              type="button"
              variant="outlined"
              color="primary"
              onClick={props.onClose}
            >
              {messages.dialog.no}
            </Button>
          </div>

          <div className="md:min-w-[160px] min-w-[120px]">
            <Button
              fullWidth
              type="button"
              variant="contained"
              color="primary"
              onClick={props.onSubmit}
            >
              {messages.dialog.yes}
            </Button>
          </div>
        </>
      }
    />
  );
};
