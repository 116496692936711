import { Pie } from 'react-chartjs-2';
import sortBy from 'lodash/sortBy';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {
  indigo,
  pink,
  teal,
  green,
  amber,
  cyan,
  grey,
  deepOrange,
  red,
} from '@mui/material/colors';
import { CardChart } from '../../components/CardChart';

const regionNames = new Intl.DisplayNames(['uk-UA'], { type: 'region' });

export const ChartBarCountriesDemographics = ({ data: _data }) => {
  const values = _data.totalValue.breakdowns[0].results.map((item) => item.value);
  const sortedValues = sortBy(values, (val) => -val);
  const MAX_ITEMS_COUNT = 7;
  const minValue = sortedValues[MAX_ITEMS_COUNT - 1];

  const filteredValues = [];
  const labels = [];
  let other = 0;

  _data.totalValue.breakdowns[0].results.forEach((item) => {
    if (item.value >= minValue) {
      const countryCode = item.dimensionValues[0];
      labels.push(regionNames.of(countryCode));
      filteredValues.push(item.value);
    } else {
      other = item.value + other;
    }
  });

  filteredValues.push(other);
  labels.push('Інші');

  const summary = _data.totalValue.breakdowns[0].results
    .map((item) => item.value)
    .reduce((acc, item) => acc + item, 0);

  const data = {
    labels,
    options: {
      responsive: true,
      maintainAspectRatio: false,
      indexAxis: 'y',
      plugins: {
        tooltip: {
          enabled: false,
        },
      },
    },
    datasets: [
      {
        indexAxis: 'y',
        data: filteredValues,
        borderRadius: 4,
        grouped: false,
        backgroundColor: [
          deepOrange[300],
          red[300],
          cyan[300],
          amber[300],
          green[300],
          pink[300],
          indigo[300],
          teal[300],
          grey[300],
        ],
      },
    ],
  };

  return (
    <CardChart title="Розподіл фоловерів країнам:">
      <Pie
        datasetIdKey="countryDemoId"
        height={350}
        data={data}
        plugins={[ChartDataLabels]}
        options={{
          plugins: {
            tooltip: {
              enabled: false,
            },
            datalabels: {
              formatter: (value) => {
                if (value) {
                  return `${Math.round((value / summary) * 100)}%`;
                }
                return '';
              },
              color: 'white',
            },
          },
        }}
      />
    </CardChart>
  );
};
